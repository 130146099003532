import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useMemo } from 'react'
import { ATTRIBUTE_NAME, ATTRIBUTE_PROFILE_NAME, RELATION_TYPE } from '@isdd/metais-common/api'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { useTranslation } from 'react-i18next'
import { useReadCiNeighboursWithAllRels } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { DRAFT, ENTITY_ISVS, PO } from '@isdd/metais-common/constants'

import { CiInformationAccordion } from '@/components/entities/accordion/CiInformationAccordion'
import { CI_TYPE_DATA_ITVS_EXCEPTIONS_BLACK_LIST, getModifiedCiTypeData } from '@/componentHelpers/ci/ciTypeBlackList'
import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import styles from '@/components/views/ci/ITVSExceptions/styles.module.scss'

const ITVSExceptionsInformation = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { entityId, entityName } = useGetEntityParamsFromUrl()

    const { ciItemData, gestorData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(entityId)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(entityName)
    const {
        isLoading,
        isError,
        data: ciWithRelsList,
    } = useReadCiNeighboursWithAllRels(entityId ?? '', {
        relTypes: [RELATION_TYPE.osobitny_postup_vztah_ISVS, RELATION_TYPE.osobitny_postup_vztah_PO],
        state: [DRAFT],
    })

    const ciTypeModified = useMemo(() => {
        return getModifiedCiTypeData(ciTypeData, CI_TYPE_DATA_ITVS_EXCEPTIONS_BLACK_LIST)
    }, [ciTypeData])

    const renderList = (list: string[]) => {
        return (
            <ul className={styles.ul}>
                {list.map((i, index) => (
                    <li key={index}>{i}</li>
                ))}
            </ul>
        )
    }

    return (
        <CiInformationAccordion
            data={{ ciItemData, gestorData, constraintsData, ciTypeData: ciTypeModified, unitsData }}
            isError={[isCiItemError, isAttError, isError].some((item) => item)}
            isLoading={[isCiItemLoading, isAttLoading, isLoading].some((item) => item)}
            additionalProfileInformation={[
                {
                    technicalName: ATTRIBUTE_PROFILE_NAME.Profil_OsobitnyPostup,
                    node: (
                        <>
                            <InformationGridRow
                                label={t('ITVSExceptions.relatedITVS')}
                                value={renderList([
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    ...((ciItemData?.attributes?.[ATTRIBUTE_NAME.Profil_OsobitnyPostup_suvisiace_ITVS_text] as any) ?? []),
                                    ...(ciWithRelsList?.ciWithRels
                                        ?.filter((r) => r.ci?.type === ENTITY_ISVS)
                                        .map((r) => r.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]) ?? []),
                                ])}
                                tooltip={t('ITVSExceptions.relatedITVS')}
                                lang={language}
                            />
                            <InformationGridRow
                                label={t('ITVSExceptions.relatedPO')}
                                value={renderList([
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    ...((ciItemData?.attributes?.[ATTRIBUTE_NAME.Profil_OsobitnyPostup_suvisiace_PO_text] as any) ?? []),
                                    ...(ciWithRelsList?.ciWithRels
                                        ?.filter((r) => r.ci?.type === PO)
                                        .map((r) => r.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]) ?? []),
                                ])}
                                tooltip={t('ITVSExceptions.relatedPO')}
                                lang={language}
                            />
                        </>
                    ),
                },
            ]}
        />
    )
}

export default ITVSExceptionsInformation
