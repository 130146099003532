import { IOption } from '@isdd/idsk-ui-kit/index'
import { RefIdentifierTypeEnum, ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { TFunction } from 'i18next'
import { FieldValues } from 'react-hook-form'

function getSubDataItems(uuids: string[], templateUriOptions: IOption<string>[]) {
    let tempString = ''
    if (!uuids) return ''
    uuids.forEach((uuid) => {
        const diName = templateUriOptions.find((d) => d.value == uuid)?.label
        tempString += '<li>' + diName + '</li>'
    })
    return tempString
}

export const createURIStandardRequest = (
    formData: FieldValues,
    riType: RefIdentifierTypeEnum,
    templateUriOptions: IOption<string>[],
    dataItemTypeOptions: IOption<string>[],
    ownerOptions: IOption<string>[],
    t: TFunction<'translation', undefined, 'translation'>,
    ontology?: ConfigurationItemUi[],
    po?: ConfigurationItemUi[],
): { name: string; description: string } => {
    switch (riType) {
        case RefIdentifierTypeEnum.DatovyPrvok:
            return {
                name: t('refIdentifiers.draftList.dataelement.title') + formData.attributes.Gen_Profil_nazov,
                description:
                    t('refIdentifiers.draftList.dataelement.nameSK') +
                    formData.attributes.Gen_Profil_nazov +
                    t('refIdentifiers.draftList.dataelement.nameEN') +
                    formData.attributes.Gen_Profil_anglicky_nazov +
                    t('refIdentifiers.draftList.dataelement.code') +
                    formData.attributes.Profil_DatovyPrvok_kod_datoveho_prvku +
                    t('refIdentifiers.draftList.dataelement.type') +
                    dataItemTypeOptions.find((d) => d.value == formData.attributes.Profil_DatovyPrvok_typ_datoveho_prvku)?.label +
                    t('refIdentifiers.draftList.dataelement.historyCode') +
                    formData.attributes.Profil_DatovyPrvok_historicky_kod +
                    t('refIdentifiers.draftList.dataelement.uri') +
                    (formData.attributes.Profil_DatovyPrvok_kod_datoveho_prvku ? formData.attributes.Profil_DatovyPrvok_kod_datoveho_prvku : '') +
                    t('refIdentifiers.draftList.dataelement.gestor') +
                    po?.find((p) => p.uuid == formData.po)?.attributes?.Gen_Profil_nazov +
                    t('refIdentifiers.draftList.dataelement.subitems') +
                    getSubDataItems(formData.dataItem, templateUriOptions) +
                    t('refIdentifiers.draftList.dataelement.start') +
                    (formData.attributes.Profil_DatovyPrvok_zaciatok_ucinnosti
                        ? t('date', { date: formData.attributes.Profil_DatovyPrvok_zaciatok_ucinnosti })
                        : '') +
                    t('refIdentifiers.draftList.dataelement.end') +
                    (formData.attributes.Profil_DatovyPrvok_koniec_ucinnosti
                        ? t('date', { date: formData.attributes.Profil_DatovyPrvok_koniec_ucinnosti })
                        : '') +
                    t('refIdentifiers.draftList.dataelement.desc') +
                    formData.attributes.Gen_Profil_popis,
            }

        case RefIdentifierTypeEnum.Individuum:
            return {
                name: t('refIdentifiers.draftList.individuum.title') + formData.attributes.Gen_Profil_nazov,
                description:
                    t('refIdentifiers.draftList.individuum.startUri') +
                    formData.attributes.Profil_Individuum_zaklad_uri +
                    t('refIdentifiers.draftList.individuum.endUri') +
                    templateUriOptions.find((d) => d.value == formData.templateUri)?.label +
                    t('refIdentifiers.draftList.individuum.name') +
                    formData.attributes.Gen_Profil_nazov +
                    t('refIdentifiers.draftList.individuum.desc') +
                    formData.attributes.Gen_Profil_popis +
                    t('refIdentifiers.draftList.individuum.validity') +
                    (formData.attributes.Profil_Individuum_platne_od ? t('date', { date: formData.attributes.Profil_Individuum_platne_od }) : '') +
                    ' - ' +
                    (formData.attributes.Profil_Individuum_platne_do ? t('date', { date: formData.attributes.Profil_Individuum_platne_do }) : ''),
            }

        case RefIdentifierTypeEnum.Ontologia:
            return {
                name: t('refIdentifiers.draftList.ontology.title') + formData.attributes.Gen_Profil_nazov,
                description:
                    t('refIdentifiers.draftList.dataset.uri') +
                    formData.attributes[ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu] +
                    t('refIdentifiers.draftList.dataset.histCode') +
                    formData.attributes[ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod] +
                    t('refIdentifiers.draftList.dataset.nameSk') +
                    formData.attributes.Gen_Profil_nazov +
                    t('refIdentifiers.draftList.dataset.nameEn') +
                    formData.attributes.Gen_Profil_anglicky_nazov +
                    t('refIdentifiers.draftList.dataset.desc') +
                    formData.attributes.Gen_Profil_popis +
                    t('refIdentifiers.draftList.dataset.gestor') +
                    ownerOptions.find((o) => o.value == formData.owner)?.label,
            }
        case RefIdentifierTypeEnum.VerziaOntologie:
            return {
                name: t('refIdentifiers.draftList.ontologyVersion.title') + formData.attributes.Gen_Profil_nazov,
                description:
                    t('refIdentifiers.draftList.ontologyVersion.URI') +
                    formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie] +
                    t('refIdentifiers.draftList.ontologyVersion.ontologyURI') +
                    ontology?.find((o) => o.uuid == formData.ontology)?.attributes?.Gen_Profil_nazov +
                    t('refIdentifiers.draftList.ontologyVersion.mediaType') +
                    formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media] +
                    t('refIdentifiers.draftList.ontologyVersion.version') +
                    formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia] +
                    t('refIdentifiers.draftList.individuum.validity') +
                    (formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od]
                        ? t('date', { date: formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od] })
                        : '') +
                    ' - ' +
                    (formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do]
                        ? t('date', { date: formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do] })
                        : '') +
                    formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url] +
                    t('refIdentifiers.draftList.ontologyVersion.downloadUrl'),
            }
    }
}
