import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ErrorBlock, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Input } from '@isdd/idsk-ui-kit/input/Input'
import { RefAttributesRefType } from '@isdd/metais-common/api/generated/dms-swagger'
import { ApiLink, ApiStandardRequest } from '@isdd/metais-common/api/generated/standards-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { getInfoGuiProfilStandardRequest } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { FileUpload, FileUploadData, IFileUploadRef } from '@isdd/metais-common/components/FileUpload/FileUpload'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { RichTextQuill } from '@isdd/metais-common/components/rich-text-quill/RichTextQuill'
import { Spacer } from '@isdd/metais-common/components/spacer/Spacer'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { API_STANDARD_REQUEST_ATTRIBUTES, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useState } from 'react'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ALLOWED_FILE_TYPES } from '@isdd/metais-common/api'
import { sanitizedHtml } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'

import { DraftListCreateFormDialog } from '@/components/entities/draftslist/DraftListCreateFormDialog'
import { DraftsListAttachmentsZone } from '@/components/entities/draftslist/DraftsListAttachmentsZone'
import styles from '@/components/entities/draftslist/draftsListCreateForm.module.scss'
import { generateSchemaForCreateDraft } from '@/components/entities/draftslist/schema/createDraftSchema'

interface CreateForm {
    data: {
        guiAttributes: Attribute[]
        defaultData: ApiStandardRequest | undefined
    }
    id?: number
    onSubmit(data: FieldValues): Promise<void>
    isError: boolean
    isLoading: boolean
    fileUploadRef: React.RefObject<IFileUploadRef>
    handleUploadSuccess: (data: FileUploadData[]) => void
    sendData: (values: FieldValues, name?: string, email?: string, capthcaToken?: string) => Promise<void>
    handleUploadFailed?: () => void
}

export const DraftsListCreateForm = ({
    data,
    isError,
    isLoading,
    fileUploadRef,
    handleUploadSuccess,
    sendData,
    id,
    handleUploadFailed,
}: CreateForm) => {
    const { t, i18n } = useTranslation()
    const [openCreateFormDialog, setOpenCreateFormDialog] = useState<FieldValues>()
    const navigate = useNavigate()

    const {
        state: { user },
    } = useAuth()
    const { register, handleSubmit, setValue, watch, control, formState } = useForm({
        defaultValues: {
            ...data?.defaultData,
            [API_STANDARD_REQUEST_ATTRIBUTES.description]: data.defaultData?.description ? sanitizedHtml(data.defaultData?.description) : undefined,
            [API_STANDARD_REQUEST_ATTRIBUTES.placementProposal]: data.defaultData?.placementProposal
                ? sanitizedHtml(data.defaultData?.placementProposal)
                : undefined,
            [API_STANDARD_REQUEST_ATTRIBUTES.legislativeTextProposal]: data.defaultData?.legislativeTextProposal
                ? sanitizedHtml(data.defaultData?.legislativeTextProposal)
                : undefined,
            [API_STANDARD_REQUEST_ATTRIBUTES.financialImpact]: data.defaultData?.financialImpact
                ? sanitizedHtml(data.defaultData?.financialImpact)
                : undefined,
            [API_STANDARD_REQUEST_ATTRIBUTES.securityImpact]: data.defaultData?.securityImpact
                ? sanitizedHtml(data.defaultData?.securityImpact)
                : undefined,
            [API_STANDARD_REQUEST_ATTRIBUTES.privacyImpact]: data.defaultData?.privacyImpact
                ? sanitizedHtml(data.defaultData?.privacyImpact)
                : undefined,
        },
        resolver: yupResolver(generateSchemaForCreateDraft(t)),
    })
    const links = watch('links') ?? []

    const addNewLink = () => {
        const newLinks = [
            ...(links ?? []),
            { name: undefined, id: undefined, type: undefined, linkType: undefined, linkSize: undefined, url: '', linkDescription: '' },
        ]
        setValue('links', newLinks)
    }

    const removeLink = (removeIndex: number) => {
        const newAttachments = links?.filter((_: ApiLink, index: number) => index !== removeIndex)
        setValue('links', newAttachments)
    }

    const handleSubmitForm = async (values: FieldValues) => {
        if (user) {
            sendData(values)
        } else {
            setOpenCreateFormDialog(values)
        }
    }

    const errors = formState?.errors

    return (
        <QueryFeedback loading={isLoading} withChildren>
            <DraftListCreateFormDialog
                openCreateFormDialog={openCreateFormDialog}
                closeCreateFormDialog={() => setOpenCreateFormDialog(undefined)}
                handleSubmit={sendData}
            />

            <FlexColumnReverseWrapper>
                <TextHeading size="L">{t('DraftsList.createForm.heading')}</TextHeading>
                <MutationFeedback error={isError} success={false} />
            </FlexColumnReverseWrapper>

            {formState.isSubmitted && !formState.isValid && <ErrorBlock errorTitle={t('formErrors')} hidden />}

            <form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
                <Input
                    {...register(API_STANDARD_REQUEST_ATTRIBUTES.name)}
                    label={getInfoGuiProfilStandardRequest(i18n.language, API_STANDARD_REQUEST_ATTRIBUTES.name, data?.guiAttributes)}
                    info={getInfoGuiProfilStandardRequest(i18n.language, API_STANDARD_REQUEST_ATTRIBUTES.name, data?.guiAttributes)}
                    error={formState?.errors?.name?.message}
                    required
                />

                <Controller
                    name={API_STANDARD_REQUEST_ATTRIBUTES.description}
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextQuill
                                id={API_STANDARD_REQUEST_ATTRIBUTES.description}
                                label={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.description,
                                    data?.guiAttributes,
                                )}
                                info={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.description,
                                    data?.guiAttributes,
                                )}
                                error={formState?.errors?.description?.message}
                                isRequired
                                {...field}
                            />
                        )
                    }}
                />

                <Controller
                    name={API_STANDARD_REQUEST_ATTRIBUTES.placementProposal}
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextQuill
                                id={API_STANDARD_REQUEST_ATTRIBUTES.placementProposal}
                                label={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.placementProposal,
                                    data?.guiAttributes,
                                )}
                                info={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.placementProposal,
                                    data?.guiAttributes,
                                )}
                                error={formState?.errors?.placementProposal?.message}
                                isRequired
                                {...field}
                            />
                        )
                    }}
                />

                <Controller
                    name={API_STANDARD_REQUEST_ATTRIBUTES.legislativeTextProposal}
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextQuill
                                id={API_STANDARD_REQUEST_ATTRIBUTES.legislativeTextProposal}
                                label={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.legislativeTextProposal,
                                    data?.guiAttributes,
                                )}
                                info={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.legislativeTextProposal,
                                    data?.guiAttributes,
                                )}
                                error={formState?.errors?.legislativeTextProposal?.message}
                                isRequired
                                {...field}
                            />
                        )
                    }}
                />

                <Controller
                    name={API_STANDARD_REQUEST_ATTRIBUTES.financialImpact}
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextQuill
                                id={API_STANDARD_REQUEST_ATTRIBUTES.financialImpact}
                                label={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.financialImpact,
                                    data?.guiAttributes,
                                )}
                                info={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.financialImpact,
                                    data?.guiAttributes,
                                )}
                                error={formState?.errors?.financialImpact?.message}
                                isRequired
                                {...field}
                            />
                        )
                    }}
                />

                <Controller
                    name={API_STANDARD_REQUEST_ATTRIBUTES.securityImpact}
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextQuill
                                id={API_STANDARD_REQUEST_ATTRIBUTES.securityImpact}
                                label={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.securityImpact,
                                    data?.guiAttributes,
                                )}
                                info={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.securityImpact,
                                    data?.guiAttributes,
                                )}
                                error={formState?.errors?.securityImpact?.message}
                                isRequired
                                {...field}
                            />
                        )
                    }}
                />

                <Controller
                    name={API_STANDARD_REQUEST_ATTRIBUTES.privacyImpact}
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextQuill
                                id={API_STANDARD_REQUEST_ATTRIBUTES.privacyImpact}
                                label={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.privacyImpact,
                                    data?.guiAttributes,
                                )}
                                info={getInfoGuiProfilStandardRequest(
                                    i18n.language,
                                    API_STANDARD_REQUEST_ATTRIBUTES.privacyImpact,
                                    data?.guiAttributes,
                                )}
                                error={formState?.errors?.privacyImpact?.message}
                                isRequired
                                {...field}
                            />
                        )
                    }}
                />

                <DraftsListAttachmentsZone links={links} register={register} addNewLink={addNewLink} onDelete={removeLink} errors={errors} />
                <TextHeading size="M">{t('DraftsList.createForm.links.andOrAddFile')}</TextHeading>
                {user && (
                    <FileUpload
                        ref={fileUploadRef}
                        allowedFileTypes={ALLOWED_FILE_TYPES}
                        multiple
                        isUsingUuidInFilePath
                        refType={RefAttributesRefType.STANDARD_REQUEST}
                        onUploadSuccess={handleUploadSuccess}
                        refId={id?.toString()}
                        onFileUploadFailed={handleUploadFailed}
                    />
                )}
                <Spacer vertical />
                <div className={styles.buttonGroup}>
                    <Button
                        label={t('DraftsList.createForm.cancel')}
                        variant="secondary"
                        onClick={() => navigate(NavigationSubRoutes.ZOZNAM_NAVRHOV)}
                    />
                    <Button label={t('DraftsList.createForm.submit')} type={'submit'} />
                </div>
            </form>
        </QueryFeedback>
    )
}
