import { latinizeString } from '@isdd/metais-common/componentHelpers/filter/feFilters'
import classNames from 'classnames'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './tableSelectColumns.module.scss'

import { ButtonLink } from '@isdd/idsk-ui-kit/button-link/ButtonLink'
import { Button } from '@isdd/idsk-ui-kit/button/Button'
import { CheckBox } from '@isdd/idsk-ui-kit/checkbox/CheckBox'
import { SearchInput } from '@isdd/idsk-ui-kit/searchInput'
import { TextBody } from '@isdd/idsk-ui-kit/typography/TextBody'
import { Fieldset } from '@isdd/idsk-ui-kit/fieldset/Fieldset'

export interface ISelectColumnType {
    technicalName: string
    name: string
    selected: boolean
    disabled?: boolean
}
export interface ISelectSectionType {
    name: string
    columns: ISelectColumnType[]
}

interface ITableSelectColumnsProps {
    onClose: () => void
    onReset: () => void
    onSave: (selectedColumns: ISelectColumnType[]) => void
    columns?: ISelectColumnType[]
    sections?: ISelectSectionType[]
    header: string
}

export interface ISectionProps {
    sectionName?: string
    columns: ISelectColumnType[]
    search: string
    selectedColumns: ISelectColumnType[]
    updateSelectedValue: (key: string, checked: boolean) => void
}

const ColumnSection: React.FC<ISectionProps> = ({ sectionName, columns, search, selectedColumns, updateSelectedValue }) => {
    if (columns.length === 0) return null
    return (
        <Fieldset
            className={classNames(styles.fieldset, 'govuk-checkboxes govuk-checkboxes--small')}
            legend={
                sectionName && (
                    <TextBody size="S" className={classNames('govuk-!-font-weight-bold', styles.textHeader)}>
                        {sectionName}
                    </TextBody>
                )
            }
        >
            {columns
                .filter((column) => latinizeString(column.name).includes(latinizeString(search)))
                .map((column) => {
                    return (
                        <CheckBox
                            labelClassName={styles.customLabelCheckbox}
                            key={column.technicalName}
                            label={column.name}
                            name={column.technicalName}
                            value={column.technicalName}
                            checked={selectedColumns.find((i) => i.technicalName === column.technicalName)?.selected}
                            onChange={(e) => updateSelectedValue(e.target.value, e.target.checked)}
                        />
                    )
                })}
        </Fieldset>
    )
}

export const TableSelectColumns: React.FC<ITableSelectColumnsProps> = ({ onClose, onReset, onSave, columns, sections, header }) => {
    const { t } = useTranslation()
    const [selectedColumns, setSelectedColumns] = useState([...(columns || [])])
    const [search, setSearch] = useState('')
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const updateSelectedValue = (key: string, checked: boolean) => {
        setSelectedColumns((prev) => {
            const copy = [...prev]
            const columnIndex = copy.findIndex((column) => column.technicalName === key)
            const changedColumn = copy[columnIndex]
            copy[columnIndex] = { ...changedColumn, selected: checked }
            return copy
        })
    }

    return (
        <>
            <div>
                <SearchInput id="search" name="search" className={styles.searchbar} onChange={handleChange} isColumnSearch />
                <TextBody size="S" className={classNames('govuk-!-font-weight-bold', styles.textHeader)}>
                    {header}
                </TextBody>
                <div className={classNames('govuk-checkboxes govuk-checkboxes--small', styles.scroll)}>
                    {sections ? (
                        sections.map((section) => (
                            <ColumnSection
                                key={section.name}
                                sectionName={section.name}
                                columns={section.columns}
                                search={search}
                                selectedColumns={selectedColumns}
                                updateSelectedValue={updateSelectedValue}
                            />
                        ))
                    ) : (
                        <ColumnSection
                            key={'columns'}
                            columns={selectedColumns.filter((column) => latinizeString(column.name).includes(latinizeString(search)))}
                            search={search}
                            selectedColumns={selectedColumns}
                            updateSelectedValue={updateSelectedValue}
                        />
                    )}
                </div>
                <div className={styles.buttonGroup}>
                    <ButtonLink
                        label={t('tableSelectColumns.refreshButton')}
                        onClick={() => {
                            onReset()
                            onClose()
                        }}
                        className={styles.resetDefaultOrderButton}
                    />
                    <ButtonLink label={t('tableSelectColumns.cancelButton')} onClick={onClose} className={styles.cancelButton} />
                    <Button
                        label={t('tableSelectColumns.viewButton')}
                        onClick={() => {
                            onSave(selectedColumns)
                            onClose()
                        }}
                        className={styles.viewButton}
                    />
                </div>
            </div>
        </>
    )
}
