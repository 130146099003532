import React, { useEffect, useState } from 'react'
import { CreateEntityButton, QueryFeedback, formatDateForDefaultValue } from '@isdd/metais-common/index'
import { useTranslation } from 'react-i18next'
import { BreadCrumbs, Filter, GridRow, GridCol, HomeIcon, TextHeading } from '@isdd/idsk-ui-kit/index'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { IFilter } from '@isdd/idsk-ui-kit/types'
import { ApiParameterTypesList, MonitoredItemDto } from '@isdd/metais-common/api/generated/monitoring-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { useNavigate } from 'react-router-dom'
import { RouteNames, RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { getHowToTranslate, setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { HowTo } from '@isdd/metais-common/constants'

import { ServiceDetailViewGraphItem } from './ServiceDetailViewGraphItem'
import styles from './service.module.scss'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { IQueryParamsDetail } from '@/pages/monitoring/services/monitoras/[serviceUuid]'
import { MonitoringDetailFilterData } from '@/components/containers/MonitoringServiceDetailContainer'

interface IServiceDetailView {
    isLoading: boolean
    isError: boolean
    filterParams: MonitoringDetailFilterData
    data?: ApiParameterTypesList
    detailData?: MonitoredItemDto
    queryParams?: IQueryParamsDetail
    defaultFilterValues: MonitoringDetailFilterData
    setDefaultFilterValues: React.Dispatch<React.SetStateAction<MonitoringDetailFilterData>>
    tableDataParam?: EnumType
    handleFilterChange: (filter: IFilter) => void
}

interface IEmptySection {
    isEmpty: boolean
    name: string
}

export const ServiceDetailView: React.FC<IServiceDetailView> = ({
    data,
    detailData,
    isError,
    isLoading,
    defaultFilterValues,
    setDefaultFilterValues,
    queryParams,
    tableDataParam,
    handleFilterChange,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [showEmptyMessage, setEmptyMessage] = useState<boolean>(false)

    const [emptyItems, setEmptyItems] = useState<IEmptySection[]>([])
    const [emptyNameItems, setEmptyNameItems] = useState<string[]>([])
    setDocumentTitle(t('titles.monitoringServicesDetail', { name: detailData?.entityName }))

    useEffect(() => {
        if (emptyItems.find((item) => item.isEmpty)) {
            const names: string[] = []
            emptyItems.map((item) => {
                if (item.isEmpty && !names.includes(item.name)) {
                    names.push(item.name)
                }
            })
            setEmptyNameItems(names)
            setEmptyMessage(true)
        } else {
            setEmptyMessage(false)
        }
    }, [emptyItems])
    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: getHowToTranslate(HowTo.MONITORING_HOWTO, t), href: RouteNames.HOW_TO_MONITORING },
                    { label: t('titles.monitoringServices') ?? '', href: RouterRoutes.MONITORING_SERVICES },
                    { label: String(detailData?.entityName), href: '' },
                ]}
            />
            <MainContentWrapper>
                <QueryFeedback loading={isLoading} error={false} withChildren>
                    <FlexColumnReverseWrapper>
                        <TextHeading size="XL">{detailData?.entityName}</TextHeading>
                        <QueryFeedback loading={false} error={isError} />
                    </FlexColumnReverseWrapper>
                    <Filter<MonitoringDetailFilterData>
                        onlyForm
                        defaultFilterValues={defaultFilterValues}
                        customReset={(reset) => {
                            setDefaultFilterValues({
                                ...defaultFilterValues,
                                dateFrom: defaultFilterValues?.dateFrom,
                                dateTo: defaultFilterValues?.dateTo,
                            })
                            reset()
                        }}
                        handleOnSubmit={(filter) => {
                            handleFilterChange({
                                ...defaultFilterValues,
                                dateFrom: formatDateForDefaultValue(filter?.dateFrom ?? '', 'yyyy-MM-dd'),
                                dateTo: formatDateForDefaultValue(filter?.dateTo ?? '', 'yyyy-MM-dd'),
                            })
                        }}
                        form={({ register, setValue, control, watch }) => {
                            const start = watch('dateFrom')
                            const end = watch('dateTo')
                            const endIsLowerThanStart = start && end && new Date(start) > new Date(end)

                            return (
                                <div>
                                    <GridRow>
                                        <GridCol>
                                            <DateInput
                                                label={t('monitoringServices.table.from')}
                                                {...register('dateFrom')}
                                                control={control}
                                                setValue={setValue}
                                            />
                                        </GridCol>
                                        <GridCol>
                                            <DateInput
                                                label={t('monitoringServices.table.to')}
                                                {...register('dateTo')}
                                                control={control}
                                                setValue={setValue}
                                                error={endIsLowerThanStart ? t('codeListList.requestValidations.dateGreaterThan') : undefined}
                                            />
                                        </GridCol>
                                    </GridRow>
                                </div>
                            )
                        }}
                    />
                </QueryFeedback>
                <CreateEntityButton
                    onClick={() => navigate(`${RouterRoutes.MONITORING_INSERT}?serviceType=${defaultFilterValues?.serviceType}`)}
                    label={t('insertMonitoring.buttonInsertParam')}
                />
                <div className={styles.topSpace}>
                    {detailData?.id &&
                        data?.results?.map((item) => {
                            if (item.code !== 'HeartbeatAS') {
                                return (
                                    <ServiceDetailViewGraphItem
                                        key={item.id}
                                        item={item}
                                        tableDataParam={tableDataParam}
                                        queryParams={queryParams}
                                        onIsEmpty={(isEmpty) => {
                                            setEmptyItems((prevItems) => [...prevItems, { isEmpty, name: item.name ?? '' }])
                                        }}
                                        detailDataId={detailData.id}
                                    />
                                )
                            }
                        })}
                    {showEmptyMessage && <p>{`${t('monitoring.noDataFind')} [${emptyNameItems.join(', ')}]`}</p>}
                </div>
            </MainContentWrapper>
        </>
    )
}
