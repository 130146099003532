import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { Tab, Tabs } from '@isdd/idsk-ui-kit/tabs/Tabs'
import { useReadConfigurationItem } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { CI_ITEM_QUERY_KEY, INVALIDATED, PO, ciInformationTab } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useUserAbility } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { ATTRIBUTE_NAME, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import { POEntityDetailHeader } from './POEntityDetailHeader'

import {
    getCiHowToBreadCrumb,
    getDefaultCiEntityTabList,
    useCiDetailPageTitle,
    useCiListPageHeading,
    useGetEntityParamsFromUrl,
} from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { RelationsListContainer } from '@/components/containers/RelationsListContainer'
import { useCiCheckEntityTypeRedirectHook } from '@/hooks/useCiCheckEntityTypeRedirect.hook'

const POEntityDetailPage: React.FC = () => {
    const { t } = useTranslation()
    const { isActionSuccess } = useActionSuccess()
    const { entityId, entityName: urlEntityName } = useGetEntityParamsFromUrl()

    const entityName = PO

    const userAbility = useUserAbility()
    const { data: ciTypeData, isLoading: isCiTypeDataLoading, isError: isCiTypeDataError } = useGetCiTypeWrapper(entityName ?? '')
    const [selectedTab, setSelectedTab] = useState<string>()

    const {
        data: ciItemData,
        isLoading: isCiItemDataLoading,
        isError: isCiItemDataError,
    } = useReadConfigurationItem(entityId ?? '', {
        query: {
            queryKey: [CI_ITEM_QUERY_KEY, entityId],
        },
    })
    useCiCheckEntityTypeRedirectHook(ciItemData, entityName)

    const { getHeading } = useCiListPageHeading(t(`titles.${urlEntityName}` ?? '') ?? '', t)
    const { getHeading: getHeadingDetail } = useCiDetailPageTitle(
        ciTypeData?.name ?? '',
        ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
        t,
    )
    setDocumentTitle(getHeadingDetail())

    const tabList: Tab[] = getDefaultCiEntityTabList({ userAbility, entityName: urlEntityName ?? '', entityId: entityId ?? '', t })

    const isInvalidated = ciItemData?.metaAttributes?.state === INVALIDATED

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(entityName ?? '', t),
                    { label: getHeading(), href: `/ci/${urlEntityName}` },
                    {
                        label: (ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string) ?? t('breadcrumbs.noName'),
                        href: `/ci/${urlEntityName}/${entityId}`,
                    },
                ]}
            />
            <MainContentWrapper>
                {isCiItemDataError ? (
                    <QueryFeedback loading={false} error={isCiItemDataError} />
                ) : (
                    <CiPermissionsWrapper entityId={entityId ?? ''} entityName={entityName}>
                        <QueryFeedback loading={isCiItemDataLoading || isCiTypeDataLoading} error={isCiTypeDataError}>
                            <FlexColumnReverseWrapper>
                                <POEntityDetailHeader
                                    isInvalidated={isInvalidated}
                                    entityItemName={(ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string) ?? 'Detail'}
                                />
                                <QueryFeedback loading={false} error={isCiItemDataError || isCiTypeDataError} />
                                <MutationFeedback
                                    success={isActionSuccess.value && isActionSuccess.additionalInfo?.type !== 'relationCreated'}
                                    successMessage={
                                        isActionSuccess.additionalInfo?.type === 'create'
                                            ? t('mutationFeedback.successfulCreated')
                                            : t('mutationFeedback.successfulUpdated')
                                    }
                                />
                            </FlexColumnReverseWrapper>
                            <Tabs tabList={tabList} onSelect={(selected) => setSelectedTab(selected.id)} />

                            {selectedTab === ciInformationTab && (
                                <RelationsListContainer
                                    entityId={entityId ?? ''}
                                    technicalName={entityName ?? ''}
                                    entityName={ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                                />
                            )}
                        </QueryFeedback>
                    </CiPermissionsWrapper>
                )}
            </MainContentWrapper>
        </>
    )
}

export default POEntityDetailPage
