import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonGroupRow, ErrorBlock, TextArea } from '@isdd/idsk-ui-kit/index'
import { ConfigurationItemSetUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import {
    ApiContact,
    ApiDescription,
    ApiReferenceRegister,
    ApiReferenceRegisterState,
} from '@isdd/metais-common/api/generated/reference-registers-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { EDIT_CONTACT } from '@isdd/metais-common/navigation/searchKeys'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { QueryFeedback } from '@isdd/metais-common/index'
import { v4 } from 'uuid'

import { EvidenceObjectsRefReg } from '@/components/views/refregisters/evidence-objects/EvidenceObjectsRegReg'
import {
    getInfoRR,
    getLabelRR,
    getUserGroupOptions,
    isRRFieldEditable,
    mapDefaultDataToFormDataRR,
    mapFormDataToApiReferenceRegister,
    showCreatorForm,
    showSourceRegisterForm,
} from '@/componentHelpers/refregisters/helpers'
import { RefRegisterCreateManagerContactSection } from '@/components/views/refregisters/createView/RefRegisterCreateManagerContactSection'
import { RefRegisterCreateMetaSection } from '@/components/views/refregisters/createView/RefRegisterCreateMetaSection'
import { RefRegisterCreateRegistrarContactSection } from '@/components/views/refregisters/createView/RefRegisterCreateRegistrarContactSection'
import { IRefRegisterCreateFormData, createRefRegisterSchema } from '@/components/views/refregisters/schema'
import { RefRegisterViewItems } from '@/types/views'

type Atts = Record<string, unknown>

type AoeRel = {
    isChecked: boolean
    attributes: Atts
}

type EvidenceObject = {
    attributes: Atts
    aoeRels: Record<string, AoeRel>
}

export type EvidenceObjectsRefRegForm = Record<string, EvidenceObject>

export interface IRefRegisterCreateView {
    defaultData?: ApiReferenceRegister
    userGroupData?: ConfigurationItemSetUi
    POData?: ConfigurationItemSetUi
    saveRefRegister?: (formData: ApiReferenceRegister, oeFormData: EvidenceObjectsRefRegForm) => Promise<void>
    updateRefRegister?: (referenceRegisterUuid: string, data: ApiReferenceRegister) => Promise<void>
    updateContact?: (referenceRegisterUuid: string, data: ApiContact, refRegCreator: string, oeFormData: EvidenceObjectsRefRegForm) => Promise<void>
    updateAccessData?: (referenceRegisterUuid: string, data: ApiDescription) => Promise<void>
    renamedAttributes?: Attribute[]
    isLoading: boolean
}

export const RefRegisterCreateView: React.FC<IRefRegisterCreateView> = ({
    defaultData,
    userGroupData,
    POData,
    saveRefRegister,
    updateRefRegister,
    updateContact,
    updateAccessData,
    renamedAttributes,
    isLoading,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [urlParams] = useSearchParams()
    const { entityId } = useParams()
    const isContact = urlParams.get(EDIT_CONTACT) === 'true' ? true : false

    const [userGroupId, setUserGroupId] = useState<string>()
    useEffect(() => {
        if (userGroupData?.configurationItemSet?.length === 1) setUserGroupId(userGroupData.configurationItemSet[0].uuid)
    }, [userGroupData])

    const userGroupOptions = getUserGroupOptions(userGroupData)
    const defaultUserGroup = userGroupOptions?.length === 1 ? userGroupOptions[0] : undefined

    const { register, handleSubmit, setValue, clearErrors, formState, control, watch, reset } = useForm({
        resolver: yupResolver(createRefRegisterSchema(t, showCreatorForm(defaultUserGroup, defaultData), showSourceRegisterForm(defaultData))),
        defaultValues: mapDefaultDataToFormDataRR(defaultData),
    })

    const formMethodsOERels = useForm<EvidenceObjectsRefRegForm>()

    useEffect(() => {
        reset(mapDefaultDataToFormDataRR(defaultData))
    }, [defaultData, reset])

    const onSubmit = useCallback(
        async (formData: IRefRegisterCreateFormData) => {
            if (userGroupData?.configurationItemSet?.length === 1) {
                formData.refRegisters.creator = userGroupData.configurationItemSet[0]?.uuid
            }
            const refCreateUuid = v4()
            if (isContact) {
                await updateContact?.(
                    entityId ?? '',
                    mapFormDataToApiReferenceRegister({ formData, managerUuid: defaultData?.managerUuid }),
                    defaultData?.managerUuid ?? '',
                    formMethodsOERels.getValues(),
                )
            } else if (entityId && defaultData?.state === ApiReferenceRegisterState.IN_CONSTRUCTION) {
                await updateRefRegister?.(entityId, mapFormDataToApiReferenceRegister({ formData, entityId, managerUuid: defaultData?.managerUuid }))
            } else if (!defaultData?.state) {
                await saveRefRegister?.(
                    mapFormDataToApiReferenceRegister({ formData, managerUuid: defaultData?.managerUuid, entityId: refCreateUuid }),
                    formMethodsOERels.getValues(),
                )
            } else {
                await updateAccessData?.(entityId ?? '', { description: formData.refRegisters.additionalData })
            }
        },
        [
            defaultData?.managerUuid,
            defaultData?.state,
            entityId,
            formMethodsOERels,
            isContact,
            saveRefRegister,
            updateAccessData,
            updateContact,
            updateRefRegister,
            userGroupData?.configurationItemSet,
        ],
    )
    const creatorNotSet = userGroupId || !showCreatorForm(defaultUserGroup, defaultData) ? false : true

    const creatorUuid = watch(`refRegisters.creator`)
    return (
        <QueryFeedback loading={isLoading} withChildren>
            {formState.isSubmitted && !formState.isValid && <ErrorBlock errorTitle={t('formErrors')} hidden />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <>
                    <RefRegisterCreateMetaSection
                        defaultData={defaultData}
                        renamedAttributes={renamedAttributes}
                        userGroupData={userGroupData}
                        setUserGroupId={setUserGroupId}
                        userGroupId={userGroupId}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        formState={formState}
                        register={register}
                        isContact={isContact}
                        creatorNotSet={creatorNotSet}
                        control={control}
                    />
                    <RefRegisterCreateManagerContactSection
                        defaultData={defaultData}
                        renamedAttributes={renamedAttributes}
                        userGroupOptions={userGroupOptions}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        formState={formState}
                        register={register}
                        isContact={isContact}
                        creatorNotSet={creatorNotSet}
                        creatorUuid={creatorUuid ?? defaultData?.managerUuid}
                    />

                    <RefRegisterCreateRegistrarContactSection
                        defaultData={defaultData}
                        renamedAttributes={renamedAttributes}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        formState={formState}
                        register={register}
                        isContact={isContact}
                        POData={POData}
                        creatorNotSet={creatorNotSet}
                    />
                    <TextArea
                        label={getLabelRR(RefRegisterViewItems.ADDITIONAL_DATA, renamedAttributes) ?? ''}
                        info={getInfoRR(RefRegisterViewItems.ADDITIONAL_DATA, renamedAttributes)}
                        rows={3}
                        {...register('refRegisters.additionalData')}
                        disabled={!isRRFieldEditable(defaultData?.state, isContact, true) || isContact || creatorNotSet}
                        error={formState.errors?.refRegisters?.additionalData?.message}
                        required
                    />
                    <InformationGridRow
                        key={'state'}
                        label={getLabelRR(RefRegisterViewItems.STATE, renamedAttributes) ?? ''}
                        value={t(`refRegisters.table.state.${defaultData?.state ?? ApiReferenceRegisterState.IN_CONSTRUCTION}`)}
                        tooltip={getInfoRR(RefRegisterViewItems.STATE, renamedAttributes)}
                    />
                    <>
                        {entityId
                            ? !isLoading &&
                              isContact && (
                                  <FormProvider {...formMethodsOERels}>
                                      <EvidenceObjectsRefReg configurationItemId={entityId} />
                                  </FormProvider>
                              )
                            : !isLoading && (
                                  <FormProvider {...formMethodsOERels}>
                                      <EvidenceObjectsRefReg configurationItemId={entityId ?? ''} />
                                  </FormProvider>
                              )}
                    </>

                    <ButtonGroupRow>
                        <Button type="submit" label={t('refRegisters.create.save')} />
                        <Button
                            variant="secondary"
                            label={t('refRegisters.detail.items.cancel')}
                            onClick={() => navigate(`${NavigationSubRoutes.REFERENCE_REGISTER}`)}
                        />
                    </ButtonGroupRow>
                </>
            </form>
        </QueryFeedback>
    )
}
