import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import {
    ConfigurationItemNeighbourSetUi,
    ConfigurationItemUi,
    ConfigurationItemUiAttributes,
    HierarchyRightsUi,
} from '@isdd/metais-common/api/generated/cmdb-swagger'

import { PublicAuthoritiesHierarchyItem } from './CodeListHierarchyContainer'

export const mapRightsToHierarchyItem = (rights?: HierarchyRightsUi[]): PublicAuthoritiesHierarchyItem => {
    const po = rights?.[0]
    const address = po?.address
    return {
        name: po?.poName ?? '',
        uuid: po?.poUUID ?? '',
        address: `${address?.street ?? ''} ${address?.number ? address?.number + ',' : ''} ${address?.zipCode ?? ''} ${address?.village ?? ''}`,
        children: [],
    }
}

const getAddressFromAttributes = (attributes?: ConfigurationItemUiAttributes): string => {
    const street = `${
        (attributes as unknown as ConfigurationItemUiAttributes[])?.find(
            (attr: ConfigurationItemUiAttributes) => attr.name === ATTRIBUTE_NAME.EA_Profil_PO_ulica,
        )?.value ?? ''
    }`
    const number = `${
        (attributes as unknown as ConfigurationItemUiAttributes[])?.find(
            (attr: ConfigurationItemUiAttributes) => attr.name === ATTRIBUTE_NAME.EA_Profil_PO_cislo,
        )?.value ?? ''
    }`
    const zipCode = `${
        (attributes as unknown as ConfigurationItemUiAttributes[])?.find(
            (attr: ConfigurationItemUiAttributes) => attr.name === ATTRIBUTE_NAME.EA_Profil_PO_obec,
        )?.value ?? ''
    }`
    const town = `${
        (attributes as unknown as ConfigurationItemUiAttributes[])?.find(
            (attr: ConfigurationItemUiAttributes) => attr.name === ATTRIBUTE_NAME.EA_Profil_PO_psc,
        )?.value ?? ''
    } `
    const comma = (street || number) && (zipCode || town) ? ',' : ''
    return `${street} ${number}${comma} ${zipCode} ${town}`
}

export const mapCiSetToHierarchyItems = (ciItems: ConfigurationItemUi[]): PublicAuthoritiesHierarchyItem[] => {
    return ciItems
        .map((ci) => {
            const name = (ci.attributes as unknown as { value: string; name: string }[])?.find(
                (attr: ConfigurationItemUiAttributes) => attr.name === ATTRIBUTE_NAME.Gen_Profil_nazov,
            )?.value

            return {
                name: name ?? '',
                uuid: ci.uuid ?? '',
                address: getAddressFromAttributes(ci.attributes),
                children: [],
            }
        })
        .sort((a: PublicAuthoritiesHierarchyItem, b: PublicAuthoritiesHierarchyItem) => {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1
            return 1
        })
}

export const arrayToTree = (flatItems: PublicAuthoritiesHierarchyItem[]): PublicAuthoritiesHierarchyItem[] => {
    while (flatItems.length !== 1) {
        let i = flatItems.length - 1
        const child = flatItems.pop()
        child && flatItems[i - 1].children?.push(child)
        i--
    }
    return flatItems
}

export const findItemInHierarchy = (entireObj: PublicAuthoritiesHierarchyItem[], keyToFind: string, valToFind: string) => {
    let foundObj: PublicAuthoritiesHierarchyItem = { name: '', uuid: '', address: '' }
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue
        }
        return nestedValue
    })
    return foundObj
}

export const findItemInHierarchyWithDepth = (entireObj: PublicAuthoritiesHierarchyItem[], keyToFind: string, valToFind: string) => {
    let foundObj: PublicAuthoritiesHierarchyItem | undefined
    let depth = 0

    const search = (obj: PublicAuthoritiesHierarchyItem, currentDepth: number): PublicAuthoritiesHierarchyItem | null => {
        if (obj.children && obj.children.length > 0) {
            for (const child of obj.children) {
                const result = search(child, currentDepth + 1)
                if (result) {
                    return result
                }
            }
        }
        if (obj[keyToFind as keyof PublicAuthoritiesHierarchyItem] === valToFind) {
            foundObj = obj
            depth = currentDepth
            return obj
        }

        return null
    }

    for (const item of entireObj) {
        const result = search(item, 0)
        if (result) {
            break
        }
    }

    return { foundObj, depth }
}

export const getExpandedStateForDepth = (depth: number, hierarchy?: PublicAuthoritiesHierarchyItem[]) => {
    if (!hierarchy) return
    const expandedState: { [key: string]: boolean } = {}

    let item: PublicAuthoritiesHierarchyItem | undefined = hierarchy[0]
    for (let i = 0; i <= depth; i++) {
        if (item) {
            expandedState[item.uuid] = true
            item = item.children?.[0]
        }
    }

    return expandedState
}

export const setAreChildrenExpandable = (
    neighbours: { [key: string]: ConfigurationItemNeighbourSetUi },
    children: PublicAuthoritiesHierarchyItem[],
) => {
    for (const key in neighbours) {
        if (Object.hasOwn(neighbours, key)) {
            const rels = neighbours[key]
            if (rels.toCiSet && rels.toCiSet.length > 0) {
                children?.map((child) => {
                    return child.uuid === key ? (child.canExpand = true) : child
                })
            }
        }
    }
}
