import { ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumItem } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { latinizeString, removeInvalidURLSymbols } from '@isdd/metais-common/componentHelpers/filter/feFilters'

export const getEliUri = (watch: ConfigurationItemUiAttributes, zz?: EnumItem, withoutDefaults?: boolean) => {
    const getStringWithSlash = (str: string | number | null, withSlash = true) => {
        if (str) {
            return `${str}${withSlash ? '/' : ''}`
        }
        return ''
    }

    const zzName = watch.Profil_AtributObjektuEvidencie_zdroj ? zz?.value : null
    return (
        `http://data.gov.sk/id/eli/sk/` +
        (zzName ? getStringWithSlash(encodeURIComponent(zzName ?? '') || 'zz') : '') +
        (watch.Profil_AtributObjektuEvidencie_rok
            ? getStringWithSlash((watch.Profil_AtributObjektuEvidencie_rok as string) || (withoutDefaults ? '' : 'rok'))
            : '') +
        (watch.Profil_AtributObjektuEvidencie_cislo
            ? getStringWithSlash((watch.Profil_AtributObjektuEvidencie_cislo as string) || (withoutDefaults ? '' : 'cislo'))
            : '') +
        (watch.Profil_AtributObjektuEvidencie_paragraf
            ? getStringWithSlash((watch.Profil_AtributObjektuEvidencie_paragraf as string) || (withoutDefaults ? '' : 'paragraf'))
            : '') +
        (watch.Profil_AtributObjektuEvidencie_odsek
            ? getStringWithSlash((watch.Profil_AtributObjektuEvidencie_odsek as string) || (withoutDefaults ? '' : 'odsek'))
            : '') +
        (watch.Profil_AtributObjektuEvidencie_pismeno
            ? getStringWithSlash((watch.Profil_AtributObjektuEvidencie_pismeno as string) || (withoutDefaults ? '' : 'pismeno'))
            : '') +
        (watch.Profil_AtributObjektuEvidencie_bod
            ? getStringWithSlash((watch.Profil_AtributObjektuEvidencie_bod as string) || (withoutDefaults ? '' : 'bod'))
            : '') +
        (watch.Gen_Profil_nazov
            ? getStringWithSlash(
                  removeInvalidURLSymbols(latinizeString((watch.Gen_Profil_nazov as string) ?? '', true)).replace(/\s/g, '') || 'nazov',
                  false,
              )
            : '')
    )
}
