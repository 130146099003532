import { CiWithRelsResultUi, CiWithRelsUi, ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Canvg } from 'canvg'
import * as d3 from 'd3'
import React, { RefObject } from 'react'
import { GRAPH_SVG_ID } from '@isdd/metais-common/constants'

import { CiItem, RelsItem, TypeFilter } from './RelationshipGraph'

interface GraphData {
    nodes: CiItem[]
    links: RelsItem[]
    total: number
}

const hexToRgb = (hex: string): [number, number, number] => {
    const bigint = parseInt(hex.slice(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return [r, g, b]
}

const getLuminance = (color: string): number => {
    let rgb: [number, number, number]

    if (color.startsWith('#')) {
        rgb = hexToRgb(color)
    } else {
        const matched = color.match(/\d+/g)?.map(Number)
        if (!matched || matched.length < 3) return 0
        rgb = [matched[0], matched[1], matched[2]]
    }

    return 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]
}

const getTextColor = (bgColor: string): string => {
    return getLuminance(bgColor) < 128 ? '#FFFFFF' : '#000000'
}

const getRectWidthHeight = () => {
    const RECT_WIDTH = 120
    const RECT_HEIGHT = 40
    return [RECT_WIDTH, RECT_HEIGHT]
}

const stayWithinBounds = (node: CiItem, width: number, height: number, rectWidth: number, rectHeight: number) => {
    node.x = Math.max(rectWidth / 2, Math.min(width - rectWidth / 2, node.x || 0))
    node.y = Math.max(rectHeight / 2, Math.min(height - rectHeight / 2, node.y || 0))
}

// Function to check for and handle collisions
const avoidCollisions = (nodes: CiItem[], rectWidth: number) => {
    nodes.forEach((node) => {
        nodes.forEach((other) => {
            if (node !== other) {
                const xDiff = (node.x || 0) - (other.x || 0)
                const yDiff = (node.y || 0) - (other.y || 0)
                const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff)
                const minDistance = rectWidth
                if (distance < minDistance) {
                    const angle = Math.atan2(yDiff, xDiff)
                    const moveX = ((minDistance - distance) * Math.cos(angle)) / 2
                    const moveY = ((minDistance - distance) * Math.sin(angle)) / 8
                    node.x = (node.x || 0) + moveX
                    node.y = (node.y || 0) + moveY
                    other.x = (other.x || 0) - moveX
                    other.y = (other.y || 0) - moveY
                }
            }
        })
    })
}

export const drag = (
    simulation: d3.Simulation<CiItem, RelsItem>,
    width: number,
    height: number,
    rectWidth: number,
    rectHeight: number,
): d3.DragBehavior<SVGGElement, CiItem, CiItem> => {
    function dragstarted(event: d3.D3DragEvent<SVGGElement, CiItem, CiItem>) {
        if (!event.active) simulation.alphaTarget(0.3).restart()
        event.subject.fx = event.subject.x
        event.subject.fy = event.subject.y
    }

    function dragged(event: d3.D3DragEvent<SVGGElement, CiItem, CiItem>) {
        event.subject.fx = event.x
        event.subject.fy = event.y
        stayWithinBounds(event.subject, width, height, rectWidth, rectHeight)
    }

    function dragended(event: d3.D3DragEvent<SVGGElement, CiItem, CiItem>) {
        if (!event.active) simulation.alphaTarget(0)
        event.subject.fx = null
        event.subject.fy = null
    }

    return d3.drag<SVGGElement, CiItem, CiItem>().on('start', dragstarted).on('drag', dragged).on('end', dragended)
}

export const tooltip_in_keyboard = (d: CiItem, setNodeDetail: React.Dispatch<React.SetStateAction<CiItem | null>>) => {
    setNodeDetail(d)
}

export const ticked = (
    link: d3.Selection<SVGLineElement, RelsItem, SVGGElement, unknown>,
    rectWidth: number,
    rectHeight: number,
    nodes: CiItem[],
) => {
    const [RECT_WIDTH, RECT_HEIGHT] = [rectWidth, rectHeight]

    link.attr('x1', (d: RelsItem) => (typeof d.source !== 'string' ? d.source.x : 0) || 0)
        .attr('y1', (d: RelsItem) => (typeof d.source !== 'string' ? d.source.y : 0) || 0)
        .attr('x2', (d: RelsItem) => (typeof d.target !== 'string' ? d.target.x : 0) || 0)
        .attr('y2', (d: RelsItem) => (typeof d.target !== 'string' ? d.target.y : 0) || 0)

    d3.selectAll<SVGTextElement, CiItem>('text')
        .attr('x', (d: CiItem) => d.x || 0)
        .attr('y', (d: CiItem) => d.y || 0)

    d3.selectAll<SVGRectElement, CiItem>('rect')
        .attr('x', (d: CiItem) => (d.x || 0) - RECT_WIDTH / 2)
        .attr('y', (d: CiItem) => (d.y || 0) - RECT_HEIGHT / 2)

    avoidCollisions(nodes, RECT_WIDTH)
}

function download(base64EncodedData: string, filename: string) {
    const a = document.createElement('a')
    a.download = filename
    a.href = base64EncodedData
    document.body.appendChild(a)
    a.click()
    a.remove()
}

export const exportGraph = async (graphRef: HTMLDivElement, title: string) => {
    if (!graphRef) return
    const originalSvg = graphRef.querySelector('svg')
    if (!originalSvg) return

    const svg = originalSvg.cloneNode(true) as SVGSVGElement
    if (!svg) return

    const PADDING = 200

    const bbox = originalSvg.getBBox()

    svg.getElementsByTagName('g')[0].setAttribute('transform', '') // remove zoom
    svg.setAttribute('width', `${bbox.width + PADDING}`)
    svg.setAttribute('height', `${bbox.height + PADDING * 2}`)

    const viewBox = [bbox.x - PADDING / 2, bbox.y - PADDING / 2, bbox.width + PADDING, bbox.height + PADDING].join(' ')
    svg.setAttribute('viewBox', viewBox)

    const canvas = document.createElement('canvas')
    canvas.width = Number(svg.getAttribute('width'))
    canvas.height = Number(svg.getAttribute('height'))

    const context = canvas.getContext('2d')
    if (!context) return

    const svgString = new XMLSerializer().serializeToString(svg)
    const v = await Canvg.from(context, svgString, { ignoreMouse: true })
    v.start()

    context.font = '14px Arial'
    context.fillText(title, 20, 36)

    // Set the canvas background to white
    context.globalCompositeOperation = 'destination-over'
    context.fillStyle = '#fff'
    context.fillRect(0, 0, canvas.width, canvas.height)

    const canvasData = canvas.toDataURL('image/png', 1)
    download(canvasData, 'graf.png')
}

export function filterCiName(item: ConfigurationItemUi): string {
    let name = ''
    for (const j in item.attributes) {
        if (j == 'Gen_Profil_Rel_nazov' || j == 'Gen_Profil_nazov') {
            name = item.attributes[j] as string
            break
        }
    }

    return name
}

export function getShortName(name: string, length: number): string {
    if (name && name.length > length) {
        return name.substring(0, length) + '...'
    }

    return name
}

export const prepareData = (data: CiWithRelsResultUi, target: ConfigurationItemUi, typeFilter: TypeFilter): GraphData => {
    const graphData: GraphData = { nodes: [], links: [], total: data?.pagination?.totaltems || 0 }
    const pointMap: { [key: string]: number } = {}
    let start = 0

    function transformCiForGraph(item: ConfigurationItemUi): CiItem {
        const name = filterCiName(item) || ''
        const ciItem: CiItem = {
            uuid: item.uuid || '',
            type: item.type || '',
            color: item.type ? typeFilter[item.type]?.color : undefined,
            name,
            attributes: item.attributes,
            valid: !!(item && item.metaAttributes && item.metaAttributes.state === 'DRAFT'),
            shortName: getShortName(name, 15),
        }
        if (item.uuid && !pointMap[item.uuid]) {
            pointMap[item.uuid] = start
            ciItem.group = start
            start = start + 1
        }
        return ciItem
    }

    graphData.nodes.push(transformCiForGraph(target))
    data.ciWithRels?.forEach(function (item: CiWithRelsUi) {
        if (!item.ci || !item.rels || !typeFilter) return
        if (typeFilter[item.ci?.type || ''] && !typeFilter[item.ci?.type || ''].selected) return
        graphData.nodes.push(transformCiForGraph(item.ci))
        item.rels.forEach(function (relation) {
            if (relation.startUuid && relation.endUuid) {
                graphData.links.push({
                    uuid: relation.uuid || '',
                    type: relation.type || '',
                    startUuid: relation.startUuid,
                    endUuid: relation.endUuid,
                    source: relation.startUuid,
                    target: relation.endUuid,
                })
            }
        })
    })

    return graphData
}

export const generateLinks = (svg: d3.Selection<SVGGElement, unknown, null, undefined>, links: RelsItem[]) => {
    const link = svg
        .append('g')
        .attr('class', 'links')
        .selectAll('.link')
        .data(links)
        .enter()
        .append('line')
        .style('stroke', '#626A6E')
        .attr('stroke', '#626A6E')
        .attr('stroke-width', '1')
        .attr('id', function (d, i) {
            return 'linkId_' + i
        })
        .attr('alt', function (d) {
            return d.type
        })
        .attr('aria-label', function (d) {
            return d.type
        })
        .attr('marker-end', function () {
            return 'url(' + location.href + '#' + 'suit' + ')'
        })

    // arrows
    svg.append('svg:defs')
        .selectAll('marker') // management of  lines
        .data(['suit', 'licensing', 'resolved'])
        .enter()
        .append('svg:marker')
        .attr('id', function (d) {
            return d
        })
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 100)
        .attr('refY', 0)
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5 L10,0 L0, -5')
        .attr('stroke', '#626A6E')
        .attr('stroke-width', '1')
        .attr('fill', '#626A6E')
        .attr('style', 'opacity: 0.6')

    return link
}

export const generateNodes = (
    svg: d3.Selection<SVGGElement, unknown, null, undefined>,
    nodes: CiItem[],
    setNodeDetail: React.Dispatch<React.SetStateAction<CiItem | null>>,
) => {
    const [RECT_WIDTH, RECT_HEIGHT] = getRectWidthHeight()

    const node = svg
        .append('g')
        .attr('class', 'node')
        .selectAll('.node')
        .data(nodes)
        .enter()
        .append('g')
        .attr('stroke', '#626A6E')
        .attr('stroke-width', '1')
        .attr('class', 'node')

    // rectangle node
    node.append('rect')
        .attr('width', RECT_WIDTH)
        .attr('height', RECT_HEIGHT)
        .attr('x', -RECT_WIDTH / 2)
        .attr('y', -RECT_HEIGHT / 2)
        .style('fill', (d) => d.color || '#626A6E')
        .attr('alt', function (d) {
            return d.name
        })
        .attr('aria-label', function (d) {
            return d.name
        })
        .attr('tabindex', 0)
        .attr('data-name', function (d) {
            return d.name
        })
        .attr('id', function (d) {
            return d.uuid
        })
        .attr('aria-haspopup', 'dialog')
        .on('keydown', function (event, d) {
            if (event.key === 'Enter') {
                tooltip_in_keyboard(d, setNodeDetail)
            }
        })

    // text node
    node.append('text')
        .attr('dx', 0)
        .attr('dy', '-.25em')
        .attr('style', (d) => {
            const textColor = getTextColor(d.color || '#626A6E')
            return `font: 10px sans-serif; stroke-width: 0.1; color: ${textColor}; fill: ${textColor};`
        })
        .attr('text-anchor', 'middle')
        .text((d) => {
            return `${d.type}:` || ''
        })
    node.append('text')
        .attr('dx', 0)
        .attr('dy', '.8em')
        .attr('style', (d) => {
            const textColor = getTextColor(d.color || '#626A6E')
            return `font: 10px sans-serif; stroke-width: 0.1; color: ${textColor}; fill: ${textColor};`
        })
        .attr('text-anchor', 'middle')
        .text((d) => {
            return `${d.shortName}` || ''
        })

    return node
}

const getLinksThatHasMatchingNode = ({ nodes, links }: { nodes: CiItem[]; links: RelsItem[] }) => {
    const nodeUuids = new Set(nodes.map((node) => node.uuid))
    const filteredLinks = links.filter((link) => nodeUuids.has(link.source.toString()) && nodeUuids.has(link.target.toString()))

    return filteredLinks
}

export const drawGraph = (
    graphWrapperRef: RefObject<HTMLDivElement>,
    setNodeDetail: React.Dispatch<React.SetStateAction<CiItem | null>>,
    zoom: d3.ZoomBehavior<SVGSVGElement, unknown>,
    data?: GraphData,
) => {
    if (!data || !graphWrapperRef.current) return

    // constants
    const margin = { top: 10, right: 30, bottom: 30, left: 40 }
    const width = graphWrapperRef.current.clientWidth
    const height = 650 - margin.top - margin.bottom
    const [rectWidth, rectHeight] = getRectWidthHeight()
    const linkDistance = rectWidth * 2 + (data.nodes.length > 15 ? (data.nodes.length - 15) * 5 : 0)
    const charge = -300

    const linkedByIndex: { [key: `${number},${number}`]: number } = {}
    for (let i = 0; i < data.nodes.length; i++) {
        linkedByIndex[`${i},${i}`] = 1
    }

    data.links.forEach(function (d) {
        if (typeof d.target !== 'string' && typeof d.source !== 'string') {
            linkedByIndex[`${d.source?.index || 0},${d.target?.index || 0}`] = 1
        }
    })

    //cleanup before rerender
    if (graphWrapperRef.current) {
        d3.select(graphWrapperRef.current.querySelector('svg')).remove()
    }
    const svg = d3
        .select(graphWrapperRef.current)
        .append('svg')
        .attr('id', GRAPH_SVG_ID)
        .attr('width', '100%')
        .attr('height', height + margin.top + margin.bottom)
        .call(zoom)
        .append('g')
        .attr('width', width)
        .on('dblclick.zoom', null)
    const container = svg.append('g').attr('width', width).attr('height', height)

    const link = generateLinks(container, data.links)

    // wrapper for nodes
    const node = generateNodes(container, data.nodes, setNodeDetail)

    node.on('click', function (event, d) {
        tooltip_in_keyboard(d, setNodeDetail)
    })

    // create graph and links
    const force = d3
        .forceSimulation<CiItem>(data.nodes)
        .force(
            'link',
            d3
                .forceLink<CiItem, RelsItem>()
                .id((d: CiItem) => d.uuid)
                .distance(() => linkDistance)
                .links(getLinksThatHasMatchingNode({ nodes: data.nodes, links: data.links })),
        )
        .force('charge', d3.forceManyBody().strength(charge))
        .force('center', d3.forceCenter(width / 2, height / 2))
        .force(
            'collision',
            d3.forceCollide((d: CiItem) => d.degree || 0),
        )
        .on('tick', () => ticked(link, rectWidth, rectHeight, data.nodes))
        .on('end', () => ticked(link, rectWidth, rectHeight, data.nodes))

    node.call(drag(force, width, height, rectWidth, rectHeight))
}
