import { BreadCrumbs, Filter, HomeIcon, Input } from '@isdd/idsk-ui-kit/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ATTRIBUTE_NAME, ATTRIBUTE_PROFILE_NAME, RELATION_TYPE } from '@isdd/metais-common/api'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import { JOIN_OPERATOR, OPERATOR_SEPARATOR, RELATION_FILTER_PREFIX } from '@isdd/metais-common/constants'
import { DynamicFilterAttributes, ExtendedAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { MetainformationColumns } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useSearchParams } from 'react-router-dom'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { CIFilterData } from '@/pages/ci/[entityName]/entity'
import { getCiHowToBreadCrumb, useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { ListWrapper } from '@/components/list-wrapper/ListWrapper'
import { CiListWizard } from '@/components/wizards/CiListWizard'
import ITVSExceptionListContainer from '@/components/containers/ITVS-exceptions/ITVSExceptionListContainer'

const isvsRelType = `${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.osobitny_postup_vztah_ISVS}` as 'isvsRelType'

export interface ITVSExceptionsFilterData extends CIFilterData {
    [isvsRelType]?: string
}
export interface ITVSExceptionsFilterProps {
    defaultFilterValues: ITVSExceptionsFilterData
    entityName: string
    codePrefix: string
    constraintsData: (EnumType | undefined)[] | undefined
    attributes: ExtendedAttribute[] | undefined
    attributeProfiles: AttributeProfile[] | undefined
    rowSelection: RowSelection<object>
}

export const getFiltAttProfOsobitnyPostup = (attributeProfiles: AttributeProfile[] | undefined) => {
    const filteredAttProfiles = attributeProfiles?.map((prof) => {
        if (prof.technicalName === ATTRIBUTE_PROFILE_NAME.Profil_OsobitnyPostup) {
            return {
                ...prof,
                attributes: prof.attributes?.filter(
                    (att) =>
                        att.technicalName != ATTRIBUTE_NAME.Profil_OsobitnyPostup_suvisiace_ITVS_text &&
                        att.technicalName != ATTRIBUTE_NAME.Profil_OsobitnyPostup_suvisiace_PO_text,
                ),
            }
        }
        return prof
    })
    return filteredAttProfiles
}

const ITVSExceptionFilter: React.FC<ITVSExceptionsFilterProps> = ({
    defaultFilterValues,
    attributes,
    attributeProfiles,
    constraintsData,
    codePrefix,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const [urlParams] = useSearchParams()
    const relationFromUrl = urlParams.get(isvsRelType)

    return (
        <Filter<ITVSExceptionsFilterData, object>
            onlyForm
            rowSelection={rowSelection}
            defaultFilterValues={defaultFilterValues}
            form={({ register, filter, setValue, isOpen }) => {
                const filteredAttProfiles = getFiltAttProfOsobitnyPostup(attributeProfiles)

                return (
                    <>
                        <CiLazySelect
                            ciType={'ISVS'}
                            label={t('ITVSExceptions.relatedITVS')}
                            name={isvsRelType}
                            setValue={setValue}
                            defaultValue={
                                relationFromUrl
                                    ? relationFromUrl.includes(JOIN_OPERATOR)
                                        ? relationFromUrl.split(JOIN_OPERATOR)
                                        : relationFromUrl
                                    : defaultFilterValues[isvsRelType] ?? ''
                            }
                            isMulti
                        />
                        <Input
                            id="metais-code"
                            label={t('filter.metaisCode.label')}
                            placeholder={codePrefix}
                            {...register('Gen_Profil_kod_metais')}
                        />
                        <DynamicFilterAttributes
                            setValue={setValue}
                            defaults={defaultFilterValues}
                            filterData={{
                                attributeFilters: filter.attributeFilters ?? {},
                                metaAttributeFilters: filter.metaAttributeFilters ?? {},
                            }}
                            attributes={attributes as ExtendedAttribute[]}
                            attributeProfiles={filteredAttProfiles}
                            constraintsData={constraintsData}
                            isFocusable={isOpen}
                            ignoreInputNames={[
                                MetainformationColumns.OWNER,
                                ATTRIBUTE_NAME.Profil_OsobitnyPostup_suvisiace_ITVS_text,
                                ATTRIBUTE_NAME.Profil_OsobitnyPostup_suvisiace_PO_text,
                            ]}
                        />
                    </>
                )
            }}
        />
    )
}

export const ITVSExceptionListPage: React.FC = () => {
    const { entityName: ciType } = useGetEntityParamsFromUrl()
    const { t } = useTranslation()

    const defaultFilterValues: ITVSExceptionsFilterData = { Gen_Profil_kod_metais: '', [isvsRelType]: '' }

    return (
        <>
            <ITVSExceptionListContainer<ITVSExceptionsFilterData>
                entityName={ciType ?? ''}
                defaultFilterValues={defaultFilterValues}
                ListComponent={(props) => (
                    <>
                        <BreadCrumbs
                            withWidthContainer
                            links={[
                                { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                                ...getCiHowToBreadCrumb(props.entityName, t),
                                {
                                    label: t('titles.ciList', { ci: props.ciTypeData?.name }) ?? '',
                                    href: `/ci/${ciType}`,
                                },
                            ]}
                        />
                        {!props.isLoading && <CiListWizard />}
                        <MainContentWrapper>
                            <ListWrapper
                                customFilter={(filterProps) => <ITVSExceptionFilter {...filterProps} />}
                                {...props}
                                attributes={props.attributes}
                            />
                        </MainContentWrapper>
                    </>
                )}
            />
        </>
    )
}
