import { BreadCrumbs, Filter, HomeIcon, Input } from '@isdd/idsk-ui-kit/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RELATION_TYPE } from '@isdd/metais-common/api'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import { ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE, JOIN_OPERATOR, OPERATOR_SEPARATOR, RELATION_FILTER_PREFIX } from '@isdd/metais-common/constants'
import { DynamicFilterAttributes, ExtendedAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { MetainformationColumns } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useSearchParams } from 'react-router-dom'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { CIFilterData } from '@/pages/ci/[entityName]/entity'
import { getCiHowToBreadCrumb, useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { ListWrapper } from '@/components/list-wrapper/ListWrapper'
import { CiListWizard } from '@/components/wizards/CiListWizard'
import EvidenceObjectListContainer from '@/components/containers/evidence-object/EvidenceObjectListContainer'

const ObjektEvidencieRelType =
    `${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie}` as 'ObjektEvidencieRelType'

export interface ObjektEvidencieFilterData extends CIFilterData {
    [ObjektEvidencieRelType]?: string
}
export interface ObjektEvidenceFilterProps {
    defaultFilterValues: ObjektEvidencieFilterData
    entityName: string
    codePrefix: string
    constraintsData: (EnumType | undefined)[] | undefined
    attributes: ExtendedAttribute[] | undefined
    attributeProfiles: AttributeProfile[] | undefined
    rowSelection: RowSelection<object>
}

const ObjektEvidencieFilter: React.FC<ObjektEvidenceFilterProps> = ({
    defaultFilterValues,
    attributes,
    attributeProfiles,
    constraintsData,
    codePrefix,
    entityName,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const [urlParams] = useSearchParams()
    const relationFromUrl = urlParams.get(ObjektEvidencieRelType)

    return (
        <Filter<ObjektEvidencieFilterData, object>
            defaultFilterValues={defaultFilterValues}
            rowSelection={rowSelection}
            form={({ register, filter, setValue, isOpen }) => {
                return (
                    <>
                        <CiLazySelect
                            ciType={ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE}
                            label={t('ObjektEvidencie.attributObjektuEvidencie')}
                            name={ObjektEvidencieRelType}
                            setValue={setValue}
                            defaultValue={
                                relationFromUrl
                                    ? relationFromUrl.includes(JOIN_OPERATOR)
                                        ? relationFromUrl.split(JOIN_OPERATOR)
                                        : relationFromUrl
                                    : defaultFilterValues[ObjektEvidencieRelType] ?? ''
                            }
                            isMulti
                            tabIndex={isOpen ? undefined : -1}
                        />
                        <Input
                            id="name"
                            label={t(`filter.${entityName}.name`)}
                            placeholder={t(`filter.namePlaceholder`)}
                            {...register('Gen_Profil_nazov')}
                        />

                        <Input
                            id="metais-code"
                            label={t('filter.metaisCode.label')}
                            placeholder={codePrefix}
                            {...register('Gen_Profil_kod_metais')}
                        />
                        <DynamicFilterAttributes
                            setValue={setValue}
                            defaults={defaultFilterValues}
                            filterData={{
                                attributeFilters: filter.attributeFilters ?? {},
                                metaAttributeFilters: filter.metaAttributeFilters ?? {},
                            }}
                            attributes={attributes as ExtendedAttribute[]}
                            attributeProfiles={attributeProfiles}
                            constraintsData={constraintsData}
                            isFocusable={isOpen}
                            ignoreInputNames={[MetainformationColumns.OWNER]}
                        />
                    </>
                )
            }}
        />
    )
}

export const ObjektEvidencieListPage: React.FC = () => {
    const { entityName: ciType } = useGetEntityParamsFromUrl()
    const { t } = useTranslation()

    const defaultFilterValues: ObjektEvidencieFilterData = { Gen_Profil_nazov: '', Gen_Profil_kod_metais: '', [ObjektEvidencieRelType]: '' }

    return (
        <>
            <EvidenceObjectListContainer<ObjektEvidencieFilterData>
                entityName={ciType ?? ''}
                defaultFilterValues={defaultFilterValues}
                ListComponent={(props) => (
                    <>
                        <BreadCrumbs
                            withWidthContainer
                            links={[
                                { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                                ...getCiHowToBreadCrumb(props.entityName, t),
                                {
                                    label: t('titles.ciList', { ci: props.ciTypeData?.name }) ?? '',
                                    href: `/ci/${ciType}`,
                                },
                            ]}
                        />
                        {!props.isLoading && <CiListWizard />}
                        <MainContentWrapper>
                            <ListWrapper
                                customFilter={(filterProps) => <ObjektEvidencieFilter {...filterProps} />}
                                {...props}
                                attributes={props.attributes}
                            />
                        </MainContentWrapper>
                    </>
                )}
            />
        </>
    )
}
