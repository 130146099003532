import { IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import {
    ParameterType,
    ReportDefinition,
    ReportResultObjectResult,
    useExecute,
    useGetReport1,
} from '@isdd/metais-common/api/generated/report-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { mapFilterToExecuteParams } from '@isdd/metais-common/componentHelpers'
import { IFilterParams, useFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { useGetEnumBulk } from '@isdd/metais-common/hooks/useGetEnumBulk'
import React, { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setDocumentTitle, sortByOrder } from '@isdd/metais-common/utils/utils'
import { ColumnOrderState, Updater } from '@tanstack/react-table'
import { ISelectColumnType } from '@isdd/idsk-ui-kit/index'

import { EnhancedHeader, getDefaultValueForKey, getRows, getTableWithOrder, Header, storeColumns } from '@/componentHelpers'
import { mapGenericTypeToPagination } from '@/componentHelpers/pagination'

export type EnhancedReportResultObjectResult = ReportResultObjectResult & {
    headers: EnhancedHeader[]
}
export interface IView {
    data?: ReportDefinition
    filterEmumData?: (EnumType | undefined)[]
    filterParams?: FieldValues & IFilterParams & IFilter
    reportResult?: EnhancedReportResultObjectResult
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    isLoading: boolean
    isError: boolean
    storeColumnsOrder: (updaterOrValue: Updater<ColumnOrderState>) => void
    saveSelectedColumns: (columns: ISelectColumnType[]) => void
    resetSelectedColumns: () => void
}

interface IReportsDetailContainer {
    View: React.FC<IView>
    defaultFilterValues: IFilterParams
}
const TYP = 'typ'

export const ReportsDetailContainer: React.FC<IReportsDetailContainer> = ({ View, defaultFilterValues }) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { isFetching: isReportFetching, isError, data: reportMetaData } = useGetReport1(entityId ?? '')

    const enumCodes =
        reportMetaData?.parameters?.filter((parameter) => parameter.type === ParameterType.ENUMS_REPO).map((parameter) => parameter.metaData) ?? []

    const customFilterParamsKeys = reportMetaData?.parameters?.map((param) =>
        param.key ? [param.key, getDefaultValueForKey(param.key, reportMetaData.parameters)] : [],
    )
    const customFilterParams = customFilterParamsKeys ? Object.fromEntries(customFilterParamsKeys) : {}

    const { filter: filterParams, handleFilterChange } = useFilterParams<FieldValues & IFilterParams & IFilter>({
        ...defaultFilterValues,
        ...customFilterParams,
    })

    const { data: filterEnumData } = useGetEnumBulk(enumCodes)

    const {
        isFetching: isExecuteFetching,
        isError: isErrorReport,
        data: reportData,
    } = useExecute(reportMetaData?.id ?? 0, TYP, mapFilterToExecuteParams(filterParams, reportMetaData?.parameters, filterEnumData))

    const pagination = mapGenericTypeToPagination(filterParams, reportData)
    setDocumentTitle(`${t('titles.reportDetail')} ${reportMetaData?.name ?? ''}`)

    const rows = getRows(reportData)
    const [columnHeaders, setColumnHeaders] = useState<EnhancedHeader[]>([])
    useEffect(() => {
        const headers = getTableWithOrder(entityId ?? '', reportData?.result?.headers as Header[])
        if (headers && headers.length > 0) {
            setColumnHeaders(headers)
        }
    }, [entityId, reportData?.result?.headers])

    const storeColumnHeadersOrder = (updaterOrValue: Updater<ColumnOrderState>) => {
        const updatedColumnHeaders = storeColumns(updaterOrValue, entityId ?? '')
        if (updatedColumnHeaders && updatedColumnHeaders.length > 0) {
            setColumnHeaders(updatedColumnHeaders)
        }
    }

    const processColumnHeadersVisibilityChange = (arr: EnhancedHeader[]) => {
        setColumnHeaders(arr)
        localStorage.setItem(entityId ?? '', JSON.stringify(arr))
    }

    const saveSelectedColumns = (columns: ISelectColumnType[]) => {
        const updatedArray: EnhancedHeader[] = columnHeaders.map((h) => {
            return {
                ...h,
                visible: !!columns.find((c) => c.technicalName == h.name)?.selected,
            }
        })
        processColumnHeadersVisibilityChange(updatedArray)
    }

    const resetSelectedColumns = () => {
        const updatedArray: EnhancedHeader[] = columnHeaders.map((h) => {
            return {
                ...h,
                visible: true,
            }
        })
        processColumnHeadersVisibilityChange(updatedArray)
    }

    return (
        <View
            data={reportMetaData}
            filterEmumData={filterEnumData}
            filterParams={filterParams}
            reportResult={{ headers: sortByOrder(columnHeaders), rows }}
            pagination={pagination}
            handleFilterChange={handleFilterChange}
            isLoading={isReportFetching || isExecuteFetching}
            isError={isError || isErrorReport}
            storeColumnsOrder={(updaterOrValue) => storeColumnHeadersOrder(updaterOrValue)}
            saveSelectedColumns={saveSelectedColumns}
            resetSelectedColumns={resetSelectedColumns}
        />
    )
}
