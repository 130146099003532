//@ts-nocheck
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * MetaIS Trainings
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTrainingsSwaggerClient } from '../hooks/useTrainingsSwaggerClient'
export type GetTrainingsForUserParams = {
    trainingId?: string[]
    page?: number
    perPage?: number
    sortBy?: string
    sortType?: string
}

export type UnregisterTraineeParams = {
    email?: string
}

export interface PaginationUi {
    page?: number
    perPage?: number
    totalPages?: number
    totaltems?: number
}

export interface MetaAttributesUi {
    createdAt?: string
    createdBy?: string
    lastModifiedAt?: string
    lastModifiedBy?: string
    owner?: string
    state?: string
}

export interface RelationshipUi {
    attributes?: AttributeUi[]
    endKodMetaIS?: string
    endName?: string
    endType?: string
    endTypeName?: string
    endUuid?: string
    metaAttributes?: MetaAttributesUi
    owner?: string
    startKodMetaIS?: string
    startName?: string
    startType?: string
    startTypeName?: string
    startUuid?: string
    type?: string
    uuid?: string
}

export interface ConfigurationItemSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    incidentRelationshipSet?: RelationshipUi[]
    pagination?: PaginationUi
}

export type AttributeUiValue = { [key: string]: unknown }

export interface AttributeUi {
    name?: string
    value?: AttributeUiValue
}

export interface ConfigurationItemUi {
    attributes?: AttributeUi[]
    metaAttributes?: MetaAttributesUi
    owner?: string
    type?: string
    uuid?: string
}

export interface RequestIdUi {
    requestId?: string
}

export type ConsentType = (typeof ConsentType)[keyof typeof ConsentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsentType = {
    PERSONAL_DATA_PROCESSING: 'PERSONAL_DATA_PROCESSING',
} as const

export interface Consent {
    accepted?: boolean
    type?: ConsentType
}

export interface Trainee {
    consents?: Consent[]
    createdAt?: string
    deleted?: boolean
    email?: string
    firstName?: string
    id?: number
    lastName?: string
    modifiedAt?: string
    organization?: string
    phone?: string
    trainingId?: string
    userId?: string
}

export interface TraineeList {
    traineeList?: Trainee[]
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useRegisterTraineeHook = () => {
    const registerTrainee = useTrainingsSwaggerClient<RequestIdUi>()

    return useCallback(
        (trainingId: string, trainee: Trainee) => {
            return registerTrainee({ url: `/${trainingId}/trainee`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: trainee })
        },
        [registerTrainee],
    )
}

export const useRegisterTraineeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRegisterTraineeHook>>>,
        TError,
        { trainingId: string; data: Trainee },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRegisterTraineeHook>>>, TError, { trainingId: string; data: Trainee }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const registerTrainee = useRegisterTraineeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRegisterTraineeHook>>>, { trainingId: string; data: Trainee }> = (
        props,
    ) => {
        const { trainingId, data } = props ?? {}

        return registerTrainee(trainingId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RegisterTraineeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRegisterTraineeHook>>>>
export type RegisterTraineeMutationBody = Trainee
export type RegisterTraineeMutationError = ApiError

export const useRegisterTrainee = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRegisterTraineeHook>>>,
        TError,
        { trainingId: string; data: Trainee },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRegisterTraineeHook>>>, TError, { trainingId: string; data: Trainee }, TContext> => {
    const mutationOptions = useRegisterTraineeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUnregisterTraineeHook = () => {
    const unregisterTrainee = useTrainingsSwaggerClient<RequestIdUi>()

    return useCallback(
        (trainingId: string, params?: UnregisterTraineeParams) => {
            return unregisterTrainee({ url: `/${trainingId}/trainee`, method: 'DELETE', params })
        },
        [unregisterTrainee],
    )
}

export const useUnregisterTraineeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUnregisterTraineeHook>>>,
        TError,
        { trainingId: string; params?: UnregisterTraineeParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnregisterTraineeHook>>>,
    TError,
    { trainingId: string; params?: UnregisterTraineeParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const unregisterTrainee = useUnregisterTraineeHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUnregisterTraineeHook>>>,
        { trainingId: string; params?: UnregisterTraineeParams }
    > = (props) => {
        const { trainingId, params } = props ?? {}

        return unregisterTrainee(trainingId, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type UnregisterTraineeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnregisterTraineeHook>>>>

export type UnregisterTraineeMutationError = ApiError

export const useUnregisterTrainee = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUnregisterTraineeHook>>>,
        TError,
        { trainingId: string; params?: UnregisterTraineeParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUnregisterTraineeHook>>>,
    TError,
    { trainingId: string; params?: UnregisterTraineeParams },
    TContext
> => {
    const mutationOptions = useUnregisterTraineeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTraineesHook = () => {
    const getTrainees = useTrainingsSwaggerClient<TraineeList>()

    return useCallback(
        (trainingId: string, signal?: AbortSignal) => {
            return getTrainees({ url: `/${trainingId}/trainees`, method: 'GET', signal })
        },
        [getTrainees],
    )
}

export const getGetTraineesQueryKey = (trainingId: string) => {
    return [`/${trainingId}/trainees`] as const
}

export const useGetTraineesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>, TError = ApiError>(
    trainingId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTraineesQueryKey(trainingId)

    const getTrainees = useGetTraineesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>> = ({ signal }) => getTrainees(trainingId, signal)

    return { queryKey, queryFn, enabled: !!trainingId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTraineesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>>
export type GetTraineesQueryError = ApiError

export const useGetTrainees = <TData = Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>, TError = ApiError>(
    trainingId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTraineesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTraineesQueryOptions(trainingId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useExportTraineesHook = () => {
    const exportTrainees = useTrainingsSwaggerClient<TraineeList>()

    return useCallback(
        (trainingId: string, signal?: AbortSignal) => {
            return exportTrainees({ url: `/${trainingId}/trainees/export`, method: 'GET', signal })
        },
        [exportTrainees],
    )
}

export const getExportTraineesQueryKey = (trainingId: string) => {
    return [`/${trainingId}/trainees/export`] as const
}

export const useExportTraineesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>, TError = ApiError>(
    trainingId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getExportTraineesQueryKey(trainingId)

    const exportTrainees = useExportTraineesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>> = ({ signal }) => exportTrainees(trainingId, signal)

    return { queryKey, queryFn, enabled: !!trainingId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ExportTraineesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>>
export type ExportTraineesQueryError = ApiError

export const useExportTrainees = <TData = Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>, TError = ApiError>(
    trainingId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExportTraineesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useExportTraineesQueryOptions(trainingId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTrainingsForUserHook = () => {
    const getTrainingsForUser = useTrainingsSwaggerClient<ConfigurationItemSetUi>()

    return useCallback(
        (params?: GetTrainingsForUserParams, signal?: AbortSignal) => {
            return getTrainingsForUser({ url: `/userList`, method: 'GET', params, signal })
        },
        [getTrainingsForUser],
    )
}

export const getGetTrainingsForUserQueryKey = (params?: GetTrainingsForUserParams) => {
    return [`/userList`, ...(params ? [params] : [])] as const
}

export const useGetTrainingsForUserQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>, TError = ApiError>(
    params?: GetTrainingsForUserParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTrainingsForUserQueryKey(params)

    const getTrainingsForUser = useGetTrainingsForUserHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>> = ({ signal }) =>
        getTrainingsForUser(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTrainingsForUserQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>>
export type GetTrainingsForUserQueryError = ApiError

export const useGetTrainingsForUser = <TData = Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>, TError = ApiError>(
    params?: GetTrainingsForUserParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTrainingsForUserHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTrainingsForUserQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
