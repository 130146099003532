import { Filter, PaginatorWrapper, SimpleSelect } from '@isdd/idsk-ui-kit/index'
import { Table } from '@isdd/idsk-ui-kit/table/Table'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, RELATION_TYPE } from '@isdd/metais-common/api/constants'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ActionsOverTable, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { DEFAULT_PAGESIZE_OPTIONS, ENTITY_CIEL } from '@isdd/metais-common/constants'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { useRelationTypePermissions } from '@isdd/metais-common/hooks/permissions/useRelationForCiPermissions'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'

import { TableCols, defaultFilter } from '@/components/containers/ActivitiesAndGoalsListContainer'
import { CommitmentToComplyingWithGoals } from '@/components/commitment-to-complying-with-goals/CommitmentToComplyingWithGoals'
import { IView } from '@/components/containers/ProjectActivitiesAndGoalsListContainer'

export const ProjectActivitiesAndGoalsView: React.FC<IView> = ({
    tableData,
    isLoading,
    isError,
    handleFilterChange,
    isOwnerOfCi,
    relateItemToProject,
    filter,
    totaltems,
    invalidateItemRelationToProject,
    isInvalidated,
    ciType,
    ciItemData,
    isMutateLoading,
    isMutateSuccess,
    isMutateError,
    resetSuccess,
    resetError,
    goals,
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const hasSomeCheckedTableItem = !!tableData?.some((item) => item.checked)
    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    const [success, setSuccess] = useState<boolean>()
    const [error, setError] = useState<boolean>()

    const relationAbility = useRelationTypePermissions(ciType, RELATION_TYPE.Projekt_realizuje_aktivitu)

    useEffect(() => {
        if (success || error || isMutateSuccess || isMutateError) {
            scrollToMutationFeedback()
        }
    }, [error, isMutateError, isMutateSuccess, scrollToMutationFeedback, success])

    const columns: Array<ColumnDef<TableCols>> = [
        {
            accessorFn: (row) => row?.attributes?.[`Profil_${ciType}_kategoria`],
            header: t(`${ciType}.target`),
            id: 'target',
            size: 100,
            enableSorting: true,
            meta: {
                getCellContext: (ctx: CellContext<TableCols, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => {
                const currentGoals = Object.entries(goals ?? {}).find((g) => g[0] == ctx?.row.original.uuid)?.[1]
                return currentGoals?.fromCiSet?.map((g, index) => (
                    <div key={g.uuid}>
                        {index != 0 && <br />}
                        <Link to={`/ci/${ENTITY_CIEL}/${g.uuid}`} state={{ from: location }}>
                            {(g.attributes as unknown as { name: string; value: string }[]).find((a) => a.name == 'Gen_Profil_nazov')?.value}
                        </Link>
                    </div>
                ))
            },
        },
        {
            accessorFn: (row) => row?.attributes?.Gen_Profil_nazov,
            header: t(`${ciType}.name`),
            id: 'name',
            size: 200,
            enableSorting: true,
            meta: {
                getCellContext: (ctx: CellContext<TableCols, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => (
                <Link to={`/ci/${ciType}/${ctx?.row.original.uuid}`} state={{ from: location }}>
                    {ctx?.getValue?.() as string}
                </Link>
            ),
        },
        {
            accessorFn: (row) => row?.attributes?.Gen_Profil_popis,
            header: t(`${ciType}.description`),
            id: 'description',
            size: 100,
            enableSorting: true,
            meta: {
                getCellContext: (ctx: CellContext<TableCols, unknown>) => ctx.getValue(),
            },
        },
        {
            accessorFn: (row) => row?.checked,
            header: t(`${ciType}.action`),
            id: 'activities.projectActivity',
            size: 80,
            cell: (row) => {
                const ci = row.getValue() as ConfigurationItemUi
                return (
                    <SimpleSelect
                        disabled={
                            !isOwnerOfCi ||
                            isInvalidated ||
                            !relationAbility.can(Actions.CREATE, `ci.create.newRelationType.${RELATION_TYPE.Projekt_realizuje_aktivitu}`)
                        }
                        label={''}
                        name={'projectActivity'}
                        defaultValue={ci ? 'true' : 'false'}
                        options={[
                            { value: 'true', label: t('radioButton.yes'), disabled: !!ci },
                            { value: 'false', label: t('radioButton.no'), disabled: !ci },
                        ]}
                        onChange={async (newValue) => {
                            newValue == 'true'
                                ? relateItemToProject(row.cell.row.original.uuid)
                                : invalidateItemRelationToProject(row.cell.row.original.uuid, row.cell.row.original.relationUuid)
                        }}
                        isClearable={false}
                    />
                )
            },
        },
    ]

    return (
        <QueryFeedback loading={isLoading || isMutateLoading} error={isError} indicatorProps={{ layer: 'parent' }} withChildren>
            <ActionsOverTable
                pagination={{ pageNumber: filter.pageNumber, pageSize: filter.pageSize, dataLength: totaltems ?? 0 }}
                handleFilterChange={handleFilterChange}
                entityName="documents"
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                hiddenButtons={{ SELECT_COLUMNS: true }}
            />
            <Filter form={() => <></>} defaultFilterValues={defaultFilter} onlySearch />
            <div ref={wrapperRef} />
            <MutationFeedback
                success={success || isMutateSuccess}
                error={error || isMutateError}
                onMessageClose={() => {
                    setSuccess(undefined)
                    setError(undefined)
                    resetSuccess()
                    resetError()
                }}
            />
            {ciType == ENTITY_CIEL && (
                <CommitmentToComplyingWithGoals
                    setError={setError}
                    setSuccess={setSuccess}
                    isOwner={!!isOwnerOfCi}
                    ciItemData={ciItemData}
                    isCiItemInvalidated={isInvalidated}
                    hasSomeCheckedTableItem={hasSomeCheckedTableItem}
                />
            )}
            <Table<TableCols>
                columns={columns}
                data={tableData}
                sort={filter.sort}
                onSortingChange={(columnSort) => {
                    handleFilterChange({ sort: columnSort })
                }}
                isLoading={isLoading || isMutateLoading}
            />
            <PaginatorWrapper
                pageNumber={filter.pageNumber ?? BASE_PAGE_NUMBER}
                pageSize={filter.pageSize ?? BASE_PAGE_SIZE}
                dataLength={totaltems ?? 0}
                handlePageChange={handleFilterChange}
            />
        </QueryFeedback>
    )
}
