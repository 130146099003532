import { ISection, IStepLabel } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import { Gen_Profil } from '@isdd/metais-common/api/constants'
import { ConfigurationItemUiAttributes, HierarchyRightsUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { GidRoleData, useFindByNameWithParams, useFindByNameWithParamsCount } from '@isdd/metais-common/api/generated/iam-swagger'
import { CiCode, CiType, RelationshipCode, RelationshipType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { Languages } from '@isdd/metais-common/localization/languages'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FieldValues, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BASE_PAGE_SIZE } from '@isdd/metais-common/constants'
import { SectionWarning } from '@isdd/idsk-ui-kit/stepper/SectionWarning'

import { CiEntityFormBody } from './CiEntityFormBody'
import { CreateEntitySection } from './CreateEntitySection'
import { getValidAndVisibleAttributes } from './createEntityHelpers'

import { formatForFormDefaultValues } from '@/componentHelpers/ci'
import { RelationAttributeForm } from '@/components/relations-attribute-form/RelationAttributeForm'

export interface HasResetState {
    hasReset: boolean
    setHasReset: Dispatch<SetStateAction<boolean>>
}
interface ICreateCiEntityForm {
    entityName: string
    generatedEntityId: CiCode
    constraintsData: (EnumType | undefined)[]
    ciTypeData: CiType | undefined
    uploadError: boolean
    onSubmit: (formData: FieldValues) => void
    unitsData: EnumType | undefined
    defaultItemAttributeValues?: ConfigurationItemUiAttributes | undefined
    updateCiItemId?: string
    relationSchema?: RelationshipType
    isProcessing?: boolean
    withRelation?: boolean
    selectedRole?: GidRoleData | null
    selectedOrg?: HierarchyRightsUi | null
    generatedRelCode?: RelationshipCode
    rolesForPO: GidRoleData[]
}

export const CreateCiEntityForm: React.FC<ICreateCiEntityForm> = ({
    entityName,
    ciTypeData,
    unitsData,
    constraintsData,
    generatedEntityId,
    onSubmit,
    defaultItemAttributeValues,
    updateCiItemId,
    relationSchema,
    isProcessing,
    withRelation,
    selectedRole,
    selectedOrg,
    generatedRelCode,
    rolesForPO,
}) => {
    const { t, i18n } = useTranslation()
    const [hasReset, setHasReset] = useState(false)
    const [sections, setSections] = useState<ISection[]>([])

    const ability = useAbilityContext()
    const canCreateRelationType = ability?.can(Actions.CREATE, `ci.create.newRelationType`)
    const isUpdate = !!updateCiItemId
    const isSubmitDisabled = (!selectedRole?.roleUuid && !updateCiItemId) || (withRelation ? !canCreateRelationType : false)

    const attProfiles = useMemo(() => ciTypeData?.attributeProfiles?.map((profile) => profile) ?? [], [ciTypeData?.attributeProfiles])
    const attProfileTechNames = useMemo(() => attProfiles.map((profile) => profile.technicalName), [attProfiles])
    const mappedProfileTechNames: Record<string, boolean> = useMemo(
        () =>
            attProfileTechNames.reduce<Record<string, boolean>>((accumulator, attributeName) => {
                if (attributeName != null) {
                    accumulator[attributeName] = false
                }
                return accumulator
            }, {}),
        [attProfileTechNames],
    )

    const attributes = useMemo(() => getValidAndVisibleAttributes(ciTypeData), [ciTypeData])
    const count = useFindByNameWithParamsCount({ name: '', system: 'All', group: 'All' })

    const roles = useFindByNameWithParams(
        1,
        count.data ?? BASE_PAGE_SIZE,
        { system: 'All', group: 'All', orderBy: 'name', direction: 'asc', name: '' },
        { query: { enabled: !!count.data } },
    )

    const getRoleNames = (roleTechNames: string[]): (string | undefined)[] => {
        return roleTechNames.map((roleTechName) => {
            return roles.data?.find((role) => role.name === roleTechName)?.description
        })
    }

    const defaultValuesFromSchema = useMemo(() => {
        return attributes.reduce((acc, att) => {
            if (att?.defaultValue) {
                return { ...acc, [att?.technicalName?.toString() ?? '']: att?.defaultValue }
            }
            return acc
        }, {})
    }, [attributes])

    const formDefaultValues = useMemo(
        () =>
            formatForFormDefaultValues(
                isUpdate ? defaultItemAttributeValues ?? {} : (defaultValuesFromSchema as ConfigurationItemUiAttributes) ?? {},
                attributes,
            ),
        [isUpdate, attributes, defaultItemAttributeValues, defaultValuesFromSchema],
    )

    const sectionErrorDefaultConfig: { [x: string]: boolean } = useMemo(
        () => ({
            [Gen_Profil]: false,
            ...mappedProfileTechNames,
        }),
        [mappedProfileTechNames],
    )
    const canMainInfoSection = ciTypeData?.roleList?.some((sectionRole) => rolesForPO?.map((role) => role.roleName).includes(sectionRole))
    const [sectionError, setSectionError] = useState<{ [x: string]: boolean }>(sectionErrorDefaultConfig)

    useEffect(() => {
        const result: ISection[] = [
            {
                title: t('ciInformationAccordion.basicInformation'),
                sectionWarning: (
                    <>
                        {!isProcessing && !roles.isLoading && !canMainInfoSection && (
                            <SectionWarning>{`${t('ciType.section.noRights', {
                                roles: getRoleNames(ciTypeData?.roleList ?? [])?.join(', '),
                            })}`}</SectionWarning>
                        )}
                    </>
                ),
                stepLabel: { label: '1', variant: 'circle' },
                error: sectionError[Gen_Profil] === true,
                id: Gen_Profil,
                isOpen: sections.find((item) => item.id === Gen_Profil)?.isOpen,
                attributes: ciTypeData?.attributes,
                content: (
                    <CreateEntitySection
                        entityName={entityName}
                        sectionId={Gen_Profil}
                        attributes={ciTypeData?.attributes?.sort((a, b) => (a.order ?? -1) - (b.order ?? -1)) ?? []}
                        constraintsData={constraintsData}
                        unitsData={unitsData}
                        generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                        defaultItemAttributeValues={defaultItemAttributeValues}
                        hasResetState={{ hasReset, setHasReset }}
                        updateCiItemId={updateCiItemId}
                        setSectionError={setSectionError}
                        sectionRoles={ciTypeData?.roleList ?? []}
                        selectedRole={selectedRole}
                        rolesForPO={rolesForPO}
                    />
                ),
            },
            ...attProfiles.map((profile, index) => {
                const canEditSection = profile.roleList?.some((sectionRole) => rolesForPO?.map((role) => role.roleName).includes(sectionRole))

                return {
                    title: (i18n.language === Languages.SLOVAK ? profile.description || profile.name : profile.engDescription || profile.name) || '',
                    sectionWarning: (
                        <>
                            {!isProcessing && !canEditSection && (
                                <SectionWarning>{`${t('ciType.section.noRights', {
                                    roles: getRoleNames(profile.roleList ?? [])?.join(', '),
                                })}`}</SectionWarning>
                            )}
                        </>
                    ),
                    stepLabel: { label: (index + 2).toString(), variant: 'circle' } as IStepLabel,
                    last: relationSchema ? false : attProfiles.length === index + 1 ? true : false,
                    id: `${profile.technicalName}`,
                    isOpen: sections.find((item) => item.id === `${profile.technicalName}`)?.isOpen,
                    attributes: profile.attributes,
                    error: sectionError[profile.technicalName ?? ''] === true,
                    content: (
                        <CreateEntitySection
                            entityName={entityName}
                            sectionId={profile.technicalName ?? ''}
                            attributes={profile.attributes?.sort((a, b) => (a.order ?? -1) - (b.order ?? -1)) ?? []}
                            constraintsData={constraintsData}
                            generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                            unitsData={unitsData}
                            setSectionError={setSectionError}
                            defaultItemAttributeValues={defaultItemAttributeValues}
                            hasResetState={{ hasReset, setHasReset }}
                            updateCiItemId={updateCiItemId}
                            sectionRoles={profile.roleList ?? []}
                            selectedRole={selectedRole}
                            rolesForPO={rolesForPO}
                        />
                    ),
                }
            }),
        ]

        if (relationSchema) {
            result.push({
                title: t('newRelation.relation'),
                last: true,
                id: 'newRelation',
                stepLabel: { label: (attProfiles.length + 2).toString(), variant: 'circle' } as IStepLabel,
                content: (
                    <RelationAttributeForm
                        entityName={entityName}
                        relationSchema={relationSchema}
                        hasResetState={{ hasReset, setHasReset }}
                        constraintsData={constraintsData}
                        unitsData={unitsData}
                        generatedRelCode={generatedRelCode}
                    />
                ),
            })
        }
        setSections(result)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        attProfiles,
        ciTypeData?.attributes,
        ciTypeData?.roleList,
        constraintsData,
        defaultItemAttributeValues,
        generatedEntityId,
        hasReset,
        i18n.language,
        mappedProfileTechNames,
        relationSchema,
        sectionError,
        selectedRole,
        t,
        entityName,
        unitsData,
        updateCiItemId,
    ])

    const handleSectionOpen = (id: string) => {
        setSections((prev) => prev.map((item) => (item.id === id ? { ...item, isOpen: !item.isOpen } : item)))
    }

    const openOrCloseAllSections = () => {
        setSections((prev) => {
            const allOpen = prev.every((item) => item.isOpen)
            return prev.map((item) => ({ ...item, isOpen: !allOpen }))
        })
    }

    const handleSectionBasedOnError = (errors: FieldErrors) => {
        setSections((prev) =>
            prev.map((section) => {
                const isSectionError = !!Object.keys(errors).find((item) => section.attributes?.find((attribute) => attribute.technicalName === item))
                if (isSectionError) {
                    return { ...section, isOpen: true, error: true }
                }
                return { ...section, error: false }
            }),
        )
    }

    return (
        <>
            <CiEntityFormBody
                relationSchemaAttributeProfiles={relationSchema?.attributeProfiles}
                entityName={entityName}
                generatedEntityId={generatedEntityId}
                ciTypeData={ciTypeData}
                onSubmit={onSubmit}
                onError={(errors) => handleSectionBasedOnError(errors)}
                isProcessing={isProcessing}
                selectedRole={selectedRole}
                stepperList={sections}
                formDefaultValues={formDefaultValues}
                isUpdate={isUpdate}
                handleSectionOpen={handleSectionOpen}
                openOrCloseAllSections={openOrCloseAllSections}
                setHasReset={setHasReset}
                isSubmitDisabled={isSubmitDisabled}
                selectedOrg={selectedOrg}
            />
        </>
    )
}
