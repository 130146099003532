import { i18n } from 'i18next'

import { CiWithRelsUi, RoleParticipantUI } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { RelationshipTypePreview } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { Languages } from '@isdd/metais-common/localization/languages'

type FormatRelationAttArgs = {
    ciWithRel: CiWithRelsUi
    relationTypes: RelationshipTypePreview[] | undefined
    owners: void | RoleParticipantUI[] | undefined
    lng: i18n
}

export const formatRelationAttributes = ({ ciWithRel, relationTypes, owners, lng }: FormatRelationAttArgs) => {
    const ci = ciWithRel?.ci
    let attributes = ci?.attributes

    if (Array.isArray(attributes)) {
        attributes = (ci?.attributes as unknown as { name: string; value: unknown }[])?.reduce(
            (acc: { [key: string]: unknown }, att: { name: string; value: unknown }) => ({ ...acc, [att.name]: att.value }),
            {},
        )
    }

    const owner = owners?.find((o) => o?.gid === ci?.metaAttributes?.owner)
    const relations = ciWithRel?.rels?.map((rel) => {
        const relationType = relationTypes?.find((et) => et?.technicalName === rel?.type)
        return {
            title: `${lng.language === Languages.SLOVAK ? relationType?.name : relationType?.engName}`,
            startUuid: rel.startUuid,
            endUuid: rel.endUuid,
            href: `/relation/${ci?.type}/${ci?.uuid}/${rel?.uuid}`,
            isValid: rel.metaAttributes?.state == 'DRAFT',
            attributes: rel.attributes,
        }
    })

    return {
        uuid: ci?.uuid,
        status: ci?.metaAttributes?.state,
        codeMetaIS: attributes?.Gen_Profil_kod_metais as string,
        label: attributes?.Gen_Profil_nazov as string,
        labelHref: `/ci/${ci?.type}/${ci?.uuid}`,
        name: attributes?.Gen_Profil_nazov as string,
        admin: owner?.configurationItemUi?.attributes?.Gen_Profil_nazov as string,
        relations,
    }
}
