import {
    CiWithRelsUi,
    ConfigurationItemUi,
    ConfigurationItemUiAttributes,
    useInvalidateSet,
    useReadCiNeighboursWithAllRels,
    useReadConfigurationItem,
    useReadRelationships,
    useStoreConfigurationItem,
    useStoreGraph,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useTranslation } from 'react-i18next'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useListRelatedCiTypesWrapper } from '@isdd/metais-common/hooks/useListRelatedCiTypes.hook'
import { useGetRelationshipTypeWrapper } from '@isdd/metais-common/hooks/useRelationshipType.hook'
import { useEffect, useState } from 'react'
import { useDetailData } from '@isdd/metais-common/hooks/useDetailData'
import { FieldValues } from 'react-hook-form'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { JOIN_OPERATOR } from '@isdd/metais-common/constants'
import { v4 as uuidV4 } from 'uuid'
import { TextHeading } from '@isdd/idsk-ui-kit/index'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { QueryFeedback, MutationFeedback, ATTRIBUTE_NAME, ZbierkaZakonov, RELATION_TYPE } from '@isdd/metais-common/index'
import {
    useInvalidateCiItemCache,
    useInvalidateCiListFilteredCache,
    useInvalidateRelationsCountCache,
    useInvalidateRelationsForCiCache,
} from '@isdd/metais-common/hooks/invalidate-cache'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { useGenerateCode } from '@isdd/metais-common/api/generated/types-repo-swagger'

import { RelationshipWithCiType } from '@/components/containers/ITVS-exceptions/ITVSExceptionsCreateContainer'
import { RoleState, PublicAuthorityState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { CreateEntityData } from '@/components/create-entity/CreateEntity'
import { EvidenceObjectAttributeCreateView } from '@/components/views/ci/evidence-object-attribute/EvidenceObjectAttributeCreateView'
import { filterRelatedList } from '@/componentHelpers/new-relation'
import { formatFormAttributeValue } from '@/components/create-entity/createEntityHelpers'

interface Props {
    entityName: string
    data: CreateEntityData
    ownerId: string
    isLoading: boolean
    isError: boolean
    updateCiItemId?: string
    roleState?: RoleState
    publicAuthorityState?: PublicAuthorityState
    ciItemData?: ConfigurationItemUi | undefined
}

const relationNodes = ['ObjektEvidencie']
const relationType = 'Objekt_evidencie_ma_atribut_evidencie'
const relationSuffix = 'RELATION'

export const EvidenceObjectAttributeCreateContainer: React.FC<Props> = ({
    entityName,
    data,
    ownerId,
    updateCiItemId,
    isLoading,
    isError,
    roleState,
    publicAuthorityState,
    ciItemData,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const OEParam = searchParams.get('OE')

    const { setIsActionSuccess } = useActionSuccess()

    const [formData, setFormData] = useState<FieldValues>({})

    const [configurationItemId, setConfigurationItemId] = useState<string>(updateCiItemId ? updateCiItemId : '')

    const [relationshipSet, setRelationshipSet] = useState<RelationshipWithCiType[]>([])

    const [isCreationLoading, setIsCreationLoading] = useState(false)
    const [creationError, setCreationError] = useState(false)

    const [presetCiWithRel, setPresetCiWithRel] = useState<CiWithRelsUi>()

    //load all relationship types for CI - /related
    const { data: relatedListData, isLoading: isRelatedListLoading, isError: isRelatedListError } = useListRelatedCiTypesWrapper(entityName)
    //build select options from this data
    const relatedListAsSources = filterRelatedList(relatedListData?.cisAsSources, relationNodes)
    const relatedListAsTargets = filterRelatedList(relatedListData?.cisAsTargets, relationNodes)

    const { data: rels } = useReadCiNeighboursWithAllRels(updateCiItemId ?? '')
    const allCIsInRelations: CiWithRelsUi[] = rels?.ciWithRels ?? []

    const lastIndex = data.generatedEntityId?.ciurl?.lastIndexOf('/')
    const urlString = data.generatedEntityId?.ciurl?.slice(0, lastIndex) + '/'
    const toPath = `/ci/${entityName}/${configurationItemId}`

    const invalidateCilistFilteredCache = useInvalidateCiListFilteredCache()
    const invalidateCiByUuidCache = useInvalidateCiItemCache()
    const invalidateRelationsForCiCache = useInvalidateRelationsForCiCache()
    const invalidateRelationsCountCache = useInvalidateRelationsCountCache()

    const {
        data: relationTypeData,
        isLoading: isRelationTypeDataLoading,
        isError: isRelationTypeDataError,
    } = useGetRelationshipTypeWrapper(relationType)

    const {
        data: readRelationShipsData,
        isLoading: isReadRelationshipsLoading,
        isError: isReadRelationshipsError,
        fetchStatus,
    } = useReadRelationships(configurationItemId ?? '')

    const { constraintsData, unitsData } = useDetailData({
        entityStructure: relationTypeData,
        isEntityStructureLoading: isRelationTypeDataLoading,
        isEntityStructureError: isRelationTypeDataError,
    })

    const onCreateSuccess = () => {
        invalidateCilistFilteredCache.invalidate({ ciType: entityName })
        invalidateCiByUuidCache.invalidate(configurationItemId)
        invalidateRelationsForCiCache.invalidate(configurationItemId)
        invalidateRelationsCountCache.invalidate(configurationItemId)
        setIsCreationLoading(false)
        setIsActionSuccess({ value: true, path: toPath, additionalInfo: { type: updateCiItemId ? 'edit' : 'create' } })
        navigate(toPath)
    }

    const { data: code } = useGenerateCode(RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie, { query: { enabled: !!OEParam } })

    const { data: oe } = useReadConfigurationItem(OEParam ?? '', {
        query: { enabled: !!OEParam },
    })

    useEffect(() => {
        if (oe) {
            const uuid = uuidV4()
            const ciWithRel = {
                ci: oe,
                rels: [
                    {
                        type: RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie,
                        attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: code?.code }],
                        uuid: uuid,
                        endUuid: oe?.uuid,
                    },
                ],
            } as CiWithRelsUi
            setPresetCiWithRel(ciWithRel)
            setRelationshipSet([
                {
                    ciType: oe?.type ?? '',
                    type: RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie,
                    endUuid: oe?.uuid,
                    startUuid: uuid,
                    uuid: uuid,
                    attributes: ciWithRel.rels?.[0].attributes,
                },
            ])
        }
    }, [code?.code, oe])

    useEffect(() => {
        setRelationshipSet(
            rels?.ciWithRels?.map((d) => ({
                ciType: d.ci?.type ?? '',
                type: d.rels?.[0].type,
                startUuid: d.rels?.[0].startUuid,
                endUuid: d.rels?.[0].endUuid,
                uuid: d.rels?.[0].uuid,
                attributes: d.rels?.[0].attributes,
            })) ?? [],
        )
    }, [rels?.ciWithRels])

    const { getRequestStatus, isLoading: isRedirectLoading, isError: isRedirectError, isTooManyFetchesError, isProcessedError } = useGetStatus()

    const storeGraph = useStoreGraph({
        mutation: {
            async onSuccess(successData) {
                if (successData.requestId) {
                    getRequestStatus(successData.requestId, onCreateSuccess)
                } else {
                    setIsCreationLoading(false)
                    setCreationError(true)
                }
            },
            onError() {
                setIsCreationLoading(false)
                setIsActionSuccess({
                    value: false,
                    path: toPath,
                    additionalInfo: { type: 'createRelationError' },
                })
                navigate(toPath)
            },
        },
    })
    const storeRelations = async () => {
        const formAttributesKeys = Object.keys(formData)

        const formattedAttributesToSend = formAttributesKeys
            .filter((key) => key.includes(relationSuffix))
            .map((key) => {
                const [name, id] = key.split(JOIN_OPERATOR)
                return {
                    name: name,
                    id: id,
                }
            })

        const relationRequestData = {
            storeSet: {
                relationshipSet: [
                    ...relationshipSet.map((rel) => {
                        const isRelatedEntityAsTarget = (rel.endUuid ?? configurationItemId) === configurationItemId
                        return {
                            ...rel,
                            type: rel.type,
                            startUuid: isRelatedEntityAsTarget ? rel.startUuid : configurationItemId,
                            endUuid: isRelatedEntityAsTarget ? configurationItemId : rel.endUuid,
                            owner: updateCiItemId ? ciItemData?.metaAttributes?.owner : ownerId,
                            attributes: [
                                ...formattedAttributesToSend
                                    .filter((key) => key.id == rel.endUuid)
                                    .map((key) => ({
                                        name: key.name,
                                        value: formData[key.name + JOIN_OPERATOR + key.id + JOIN_OPERATOR + relationSuffix],
                                    })),
                            ],
                        }
                    }),
                ],
            },
        }

        await storeGraph.mutateAsync({ data: relationRequestData })
    }

    const storeConfigurationItem = useStoreConfigurationItem({
        mutation: {
            onError() {
                setCreationError(true)
                setIsCreationLoading(false)
            },
            async onSuccess(successData) {
                if (successData.requestId) {
                    getRequestStatus(successData.requestId, () => storeRelations())
                } else {
                    setIsCreationLoading(false)
                    setCreationError(true)
                }
            },
        },
    })

    const storeCI = async (formAttributes: FieldValues) => {
        const formAttributesKeys = Object.keys(formAttributes)
        const formattedAttributesToSend = formAttributesKeys
            .filter((key) => !key.includes('RELATION'))
            .map((key) => ({
                name: key,
                value: formatFormAttributeValue(formAttributes, key, urlString),
            }))
        const type = entityName
        const uuid = updateCiItemId ? updateCiItemId : uuidV4()
        setConfigurationItemId(uuid)
        const isEuRefID = formAttributes[ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id] ? true : false
        const dataToUpdate = {
            uuid: uuid,
            type: type,
            attributes: [
                ...formattedAttributesToSend,
                { name: ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id, value: isEuRefID },
            ] as unknown as ConfigurationItemUiAttributes,
        }

        await storeConfigurationItem.mutateAsync({
            data: updateCiItemId
                ? dataToUpdate
                : {
                      ...dataToUpdate,
                      owner: ownerId,
                  },
        })
    }

    const invalidateSet = useInvalidateSet({
        mutation: {
            onSuccess: (successData) => {
                if (successData.requestId) {
                    getRequestStatus(successData.requestId, () => storeCI(formData))
                } else {
                    setIsCreationLoading(false)
                    setCreationError(true)
                }
            },
        },
    })

    const onSubmit = async (formAttributes: FieldValues, relationshipsToRemove: CiWithRelsUi[]) => {
        setFormData(formAttributes)
        setIsCreationLoading(true)
        if (relationshipsToRemove.length && relationshipsToRemove.length > 0) {
            await invalidateSet.mutateAsync({ data: { relationshipSet: relationshipsToRemove.map((rel) => rel.rels?.[0] ?? {}) } })
        } else {
            await storeCI(formAttributes)
        }
    }

    const isDataLoading = [
        isRelatedListLoading,
        isRelationTypeDataLoading,
        updateCiItemId ? isReadRelationshipsLoading && fetchStatus == 'fetching' : false,
    ].some((item) => item)
    const isDataError = [isReadRelationshipsError, isRelatedListError, isRelationTypeDataError].some((item) => item)

    useEffect(() => {
        if ([isRedirectError, isTooManyFetchesError, isProcessedError].some((item) => item)) {
            setIsActionSuccess({
                value: false,
                path: toPath,
                additionalInfo: { type: 'createRelationError' },
            })
            navigate(toPath)
        }
    }, [isRedirectError, isTooManyFetchesError, isProcessedError, setIsActionSuccess, toPath, navigate])

    return (
        <>
            <FlexColumnReverseWrapper>
                <TextHeading size="XL">
                    {!updateCiItemId
                        ? t('ciType.createEntity', { entityName: t('atributObjektuEvidencie') })
                        : t('ciType.editEntity', { entityName: t('atributObjektuEvidencie') })}
                </TextHeading>
                <QueryFeedback loading={false} error={isError} errorProps={{ errorMessage: t('feedback.failedFetch') }} />
            </FlexColumnReverseWrapper>
            <ElementToScrollTo trigger={storeConfigurationItem.isError || isProcessedError || isTooManyFetchesError || isRedirectError}>
                <MutationFeedback
                    error={storeConfigurationItem.isError}
                    mutationProcessingError={isProcessedError}
                    mutationTooLong={isTooManyFetchesError}
                />
            </ElementToScrollTo>
            <QueryFeedback
                loading={isRedirectLoading || isCreationLoading}
                error={isRedirectError}
                indicatorProps={{
                    label: updateCiItemId ? t('createEntity.redirectLoadingEdit') : t('createEntity.redirectLoading'),
                }}
                errorProps={{
                    errorMessage: updateCiItemId ? t('createEntity.redirectErrorEdit') : t('createEntity.redirectError'),
                }}
                withChildren
            >
                <EvidenceObjectAttributeCreateView
                    data={{ ...data, ownerId, generatedEntityId: data.generatedEntityId }}
                    relationData={{
                        relatedListAsSources,
                        relatedListAsTargets,
                        readRelationShipsData,
                        relationTypeData,
                        constraintsData,
                        unitsData,
                        ciTypeData: data.attributesData.ciTypeData,
                    }}
                    onSubmit={onSubmit}
                    relationshipSetState={{ relationshipSet, setRelationshipSet }}
                    isProcessing={false}
                    isLoading={isLoading || isDataLoading}
                    isError={isError || isDataError || isRedirectError}
                    uploadError={creationError}
                    updateCiItemId={updateCiItemId}
                    roleState={roleState}
                    publicAuthorityState={publicAuthorityState}
                    configurationItemId={configurationItemId}
                    allCIsInRelations={presetCiWithRel ? [presetCiWithRel] : allCIsInRelations ?? []}
                    defaultItemAttributeValues={{
                        ...ciItemData?.attributes,
                        [ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj]: ZbierkaZakonov,
                    }}
                />
            </QueryFeedback>
        </>
    )
}
