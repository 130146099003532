import React, { useEffect, useState } from 'react'
import { Filter, Input, TextHeading } from '@isdd/idsk-ui-kit'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { QueryFeedback } from '@isdd/metais-common/components/query-feedback/QueryFeedback'
import { AddItemsButtonGroup } from '@isdd/metais-common/components/add-items-button-group/AddItemsButtonGroup'
import { ActionsOverTable } from '@isdd/metais-common/components/actions-over-table'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { DEFAULT_PAGESIZE_OPTIONS, ENTITY_ISVS } from '@isdd/metais-common/constants'
import { useGetCiTypeConstraintsData } from '@isdd/metais-common/hooks/useGetCiTypeConstraintsData'
import { ConfigurationItemUi, IncidentRelationshipSetUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { IFilter } from '@isdd/idsk-ui-kit/types'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'
import { ATTRIBUTE_NAME, ModalButtons, RELATION_TYPE } from '@isdd/metais-common/index'
import { useCiListContainer } from '@isdd/metais-common/hooks/useCiListContainer'

import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'
import { CIFilterData } from '@/pages/ci/[entityName]/entity'
import { CiTable } from '@/components/ci-table/CiTable'

type Props = {
    ciType: string
    selectedItems: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null
    onSelectedSubmit: (val: ColumnsOutputDefinition[]) => void
    closeOnClick: () => void
    existingRelations: IncidentRelationshipSetUi | undefined
    relationType?: string
    ciItemData?: ConfigurationItemUi
}

export const CiListPageForModal: React.FC<Props> = ({
    ciType,
    selectedItems,
    onSelectedSubmit,
    closeOnClick,
    existingRelations,
    relationType,
    ciItemData,
}) => {
    const { t, i18n } = useTranslation()
    const defaultFilterValues: CIFilterData = {
        Gen_Profil_nazov: '',
        Gen_Profil_kod_metais: '',
    }

    const [filterValues, setFilterValues] = useState<CIFilterData>(defaultFilterValues)
    const rowSelection = useRowSelection<ColumnsOutputDefinition>()

    const {
        ciTypeData,
        attributes,
        attributeProfiles,
        constraintsData,
        unitsData,
        isError: isAttError,
        isLoading: isAttLoading,
    } = useAttributesHook(ciType)

    const {
        isError: isCiListError,
        isLoading: isCiListLoading,
        tableData,
        pagination,
        columnListData,
        storeUserSelectedColumns,
        resetUserSelectedColumns,
        sort,
        gestorsData,
    } = useCiListContainer<CIFilterData>({
        entityName: ciType,
        defaultFilterValues: filterValues,
        onlyValidItems: true,
        ciTypeData,
        relationType,
        ciItemData,
    })

    const {
        isError: isCiTypeConstraintsError,
        isLoading: isCiTypeConstraintsLoading,
        uuidsToMatchedCiItemsMap,
    } = useGetCiTypeConstraintsData(ciTypeData, tableData?.configurationItemSet ?? [])

    const isLoading = isAttLoading || isCiListLoading || isCiTypeConstraintsLoading
    const isError = isAttError || isCiListError || isCiTypeConstraintsError

    const [customDisabledUuids, setCustomDisabledUuids] = useState<string[]>([])

    useEffect(() => {
        if (selectedItems && Array.isArray(selectedItems)) {
            rowSelection.setRowSelection(
                selectedItems.reduce(
                    (acc: Record<string, ColumnsOutputDefinition>, item: ColumnsOutputDefinition) => ({
                        ...acc,
                        [item.uuid ?? '']: item,
                    }),
                    {},
                ),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems])

    const handleRelationItemsChange = () => {
        onSelectedSubmit(rowSelection.list)
        rowSelection.clearSelectedRows()
        closeOnClick()
    }

    const handleFilterChange = (filter: IFilter) => {
        setFilterValues((prevFilter) => {
            return { ...prevFilter, ...filter }
        })
    }

    const resetFilter = () => {
        setFilterValues(defaultFilterValues)
    }

    const handleOnSubmit = (filter: IFilter) => {
        setFilterValues((prevFilter) => {
            return { ...prevFilter, ...filter }
        })
    }

    useEffect(() => {
        if (relationType === RELATION_TYPE.ISVS_patri_pod_ISVS && ciItemData?.type === ENTITY_ISVS) {
            setCustomDisabledUuids(
                tableData?.configurationItemSet
                    ?.filter((ci: ConfigurationItemUi) => {
                        return (
                            ci.attributes?.[ATTRIBUTE_NAME.EA_Profil_ISVS_modul_isvs] ===
                            ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_ISVS_modul_isvs]
                        )
                    })
                    .map((ci: ConfigurationItemUi) => ci.uuid ?? '') ?? [],
            )
        }
    }, [ciItemData?.attributes, ciItemData?.type, relationType, tableData?.configurationItemSet])

    const itemUuidsWithoutCheckboxes = [
        ...(existingRelations?.endRelationshipSet?.map((rel) => rel.startUuid ?? '') ?? []),
        ...(existingRelations?.startRelationshipSet?.map((rel) => rel.endUuid ?? '') ?? []),
        ...customDisabledUuids,
    ]

    return (
        <QueryFeedback loading={isLoading} withChildren>
            <FlexColumnReverseWrapper>
                <TextHeading size="L">{i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName}</TextHeading>
                <QueryFeedback loading={false} error={isError} errorProps={{ errorMessage: t('feedback.failedFetch') }} />
            </FlexColumnReverseWrapper>
            <Filter<CIFilterData, ColumnsOutputDefinition>
                rowSelection={rowSelection}
                handleOnSubmit={handleOnSubmit}
                customReset={() => resetFilter()}
                defaultFilterValues={defaultFilterValues}
                form={({ register }) => {
                    return (
                        <div>
                            <Input
                                id="name"
                                label={t(`filter.${ciType}.name`)}
                                placeholder={t(`filter.namePlaceholder`)}
                                {...register('Gen_Profil_nazov')}
                            />
                            <Input
                                id="metais-code"
                                label={t('filter.metaisCode.label')}
                                placeholder={ciTypeData?.codePrefix}
                                {...register('Gen_Profil_kod_metais')}
                            />
                            {/* !!we have to fix this filter later */}
                            {/* <DynamicFilterAttributes
                                setValue={setValue}
                                defaults={defaultFilterValues}
                                filterData={{
                                    attributeFilters: filter.attributeFilters ?? {},
                                    metaAttributeFilters: filter.metaAttributeFilters ?? {},
                                }}
                                entityName={ciType}
                                attributes={attributes as ExtendedAttribute[]}
                                attributeProfiles={attributeProfiles}
                                constraintsData={constraintsData}
                                isFocusable={isOpen}
                            /> */}
                        </div>
                    )
                }}
            />

            <ActionsOverTable
                pagination={pagination}
                handleFilterChange={handleFilterChange}
                storeUserSelectedColumns={storeUserSelectedColumns}
                resetUserSelectedColumns={resetUserSelectedColumns}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                entityName={ciTypeData?.name ?? ''}
                attributeProfiles={attributeProfiles ?? []}
                attributes={attributes ?? []}
                columnListData={columnListData}
                ciTypeData={ciTypeData}
            >
                <AddItemsButtonGroup handleItemsChange={handleRelationItemsChange} onCancel={() => closeOnClick()} />
            </ActionsOverTable>
            <CiTable
                data={{ columnListData, tableData, constraintsData, unitsData, entityStructure: ciTypeData, gestorsData }}
                handleFilterChange={handleFilterChange}
                pagination={pagination}
                sort={sort}
                rowSelection={rowSelection}
                storeUserSelectedColumns={storeUserSelectedColumns}
                isLoading={isLoading || isCiTypeConstraintsLoading}
                isError={isError || isCiTypeConstraintsError}
                uuidsToMatchedCiItemsMap={uuidsToMatchedCiItemsMap}
                itemUuidsWithoutCheckboxes={itemUuidsWithoutCheckboxes}
                linkToNewTab
                entityName={ciType}
                baseHref={`/ci/${ciType}`}
            />

            <ModalButtons
                submitButtonLabel={t('newRelation.addItems')}
                onSubmit={handleRelationItemsChange}
                closeButtonLabel={t('newRelation.cancel')}
                onClose={closeOnClick}
            />
        </QueryFeedback>
    )
}
