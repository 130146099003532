import { GridRow, GridCol, Card, TextHeading } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useTranslation } from 'react-i18next'
import { QueryFeedback } from '@isdd/metais-common/index'
import { truncateWithEllipsis } from '@isdd/metais-common/componentHelpers/formatting/ellipsis'

import styles from './styles.module.scss'

import { DivWithShadow } from '@/components/div-with-shadow/DivWithShadow'
import { useGetLastEditedEntities } from '@/hooks/useGetLastEditedEntities'

export const LatestModifiedCiEntities = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const LATEST_COUNT = 3
    const { data, isError: isLatestError, isLoading: isLatestLoading, ciTypes } = useGetLastEditedEntities(LATEST_COUNT)

    return (
        <QueryFeedback withChildren loading={isLatestLoading} error={isLatestError} indicatorProps={{ label: t('entitySummary.loadingLatestCi') }}>
            <TextHeading size="L">
                <strong>{t('entitySummary.lastModifiedEntities')}</strong>
            </TextHeading>
            <DivWithShadow className={styles.marginBottom8}>
                <GridRow>
                    <ul className={styles.list}>
                        {data?.configurationItemSet?.map((item) => {
                            const matchedCiType = ciTypes?.results?.find((ci) => ci.technicalName === item.type)
                            return (
                                <li key={item?.uuid}>
                                    <GridCol setWidth="one-third">
                                        <Card
                                            title={item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                                            cardHref={`/ci/${item?.type}/${item?.uuid}`}
                                            description={truncateWithEllipsis(
                                                (item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_popis] as string) ?? '',
                                                100,
                                            )}
                                            date={
                                                <>
                                                    <span className="sr-only">
                                                        {t('entitySummary.dateAddedAria', {
                                                            date: t('dateTime'),
                                                        })}
                                                    </span>
                                                    {t('dateTime', { date: item?.metaAttributes?.lastModifiedAt })}
                                                </>
                                            }
                                            tag1={{
                                                title: language == Languages.SLOVAK ? matchedCiType?.name ?? '' : matchedCiType?.engName ?? '',
                                                href: `ci/${item?.type}`,
                                            }}
                                        />
                                    </GridCol>
                                </li>
                            )
                        })}
                    </ul>
                </GridRow>
            </DivWithShadow>
        </QueryFeedback>
    )
}
