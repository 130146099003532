import { ATTRIBUTE_NAME, RELATION_TYPE } from '@isdd/metais-common/api'
import {
    ConfigurationItemUi,
    ConfigurationItemUiAttributes,
    useReadListNeighboursConfigurationItems,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ENTITY_ZC } from '@isdd/metais-common/constants'
import { ciItemAttributesObjectToArray } from '@isdd/metais-common/utils/ciAttributeMapper'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { findItemInHierarchy, mapCiSetToHierarchyItems, setAreChildrenExpandable } from './publicAuthoritiesHierarchy.helper'

import { CodeListHierarchyView } from '@/components/views/codelist-hierarchy/CodeListHierarchyView'

export interface PublicAuthoritiesHierarchyItem {
    name: string
    uuid: string
    address: string
    children?: PublicAuthoritiesHierarchyItem[]
    canExpand?: boolean
}

export const CodeListHierarchyContainer: React.FC = () => {
    const [dataToShow, setDataToShow] = useState<PublicAuthoritiesHierarchyItem[] | undefined>()
    const [codeList, setCodeList] = useState<ConfigurationItemUi>()
    const [expandableRowIdLoading, setExpandableRowIdLoading] = useState<string | null>(null)
    const [expanded, setExpanded] = useState<Record<string, boolean>>({})

    const { mutateAsync: mutateNeighbors, isLoading } = useReadListNeighboursConfigurationItems()

    const defaultNeighborsFilter = useMemo(
        () => ({
            nodeType: ENTITY_ZC,
            relationshipType: RELATION_TYPE.Ciselnik_je_podriadeny_Ciselnik,
            includeInvalidated: false,
        }),
        [],
    )

    const loadNeighbors = useCallback(
        async (uuids: string[]) => {
            return await mutateNeighbors({
                data: {
                    ...defaultNeighborsFilter,
                    ciUuids: uuids,
                },
            })
        },
        [defaultNeighborsFilter, mutateNeighbors],
    )

    const handleExpandClick = async (row: PublicAuthoritiesHierarchyItem) => {
        const isItemExpanded = expanded[row.uuid]
        if (!isItemExpanded && row.children && row.children.length < 1) {
            setExpandableRowIdLoading(row.uuid)
            const neighbors = await loadNeighbors([row.uuid])
            const foundHierarchyItem: PublicAuthoritiesHierarchyItem = findItemInHierarchy(dataToShow ?? [], 'uuid', row.uuid)
            if (foundHierarchyItem) {
                foundHierarchyItem.children = mapCiSetToHierarchyItems(neighbors[row.uuid].toCiSet ?? [])
                const more = await loadNeighbors(neighbors[row.uuid].toCiSet?.map((ci) => ci.uuid ?? '') ?? [])
                setAreChildrenExpandable(more, foundHierarchyItem.children)
            }
            setDataToShow(dataToShow ? [...dataToShow] : [])
        }
        setExpanded((prev) => {
            const newState = { ...prev }
            newState[row.uuid] = !newState[row.uuid]
            return newState
        })
        setExpandableRowIdLoading(null)
    }

    useEffect(() => {
        const loadParents = async (
            parents: ConfigurationItemUi[],
            current: PublicAuthoritiesHierarchyItem,
            expandedIds: string[],
        ): Promise<PublicAuthoritiesHierarchyItem> => {
            if (parents.length === 0 || expandedIds.includes(parents[0].uuid ?? '')) {
                return current
            } else {
                const name = (parents[0]?.attributes as unknown as { value: string; name: string }[])?.find(
                    (attr: ConfigurationItemUiAttributes) => attr.name === ATTRIBUTE_NAME.Gen_Profil_nazov,
                )?.value

                const data = await loadNeighbors([parents[0].uuid ?? ''])
                const newItem: PublicAuthoritiesHierarchyItem = {
                    address: '',
                    name: name ?? 'name',
                    uuid: parents[0].uuid || '',
                    canExpand: true,
                    children: [current],
                }
                expandedIds.push(parents[0].uuid || '')
                return loadParents(data[parents[0].uuid || ''].fromCiSet || [], newItem, expandedIds)
            }
        }

        const loadNeighborsForCodeList = async (currentCodeList: ConfigurationItemUi) => {
            const expandedIds: string[] = []
            const selectedCodeListNeighbors = await loadNeighbors([currentCodeList.uuid ?? ''])
            const currentItem = mapCiSetToHierarchyItems([ciItemAttributesObjectToArray(currentCodeList)] as ConfigurationItemUi[])
            const selectedCodeListChildrenNeighbors: PublicAuthoritiesHierarchyItem[] = mapCiSetToHierarchyItems(
                selectedCodeListNeighbors[codeList?.uuid ?? '']?.toCiSet ?? [],
            )
            let children = selectedCodeListChildrenNeighbors.filter((item) => item.uuid !== currentCodeList.uuid)
            if (children.length > 0) {
                const childrenNeighborsData = await loadNeighbors(children.map((item) => item.uuid))
                children = children.map((item) => ({
                    ...item,
                    canExpand: (childrenNeighborsData[item.uuid]?.toCiSet?.length || 0) > 0 ? true : false,
                }))
            }

            currentItem[0].children = children
            currentItem[0].canExpand = (selectedCodeListNeighbors[codeList?.uuid ?? '']?.toCiSet?.length || 0) > 0

            if (currentItem[0].canExpand) {
                expandedIds.push(currentCodeList.uuid ?? '')
            }
            const resultWithParents = await loadParents(selectedCodeListNeighbors[codeList?.uuid ?? '']?.fromCiSet || [], currentItem[0], expandedIds)
            setDataToShow([resultWithParents])
            const idsObject = expandedIds.reduce<Record<string, boolean>>((acc, id) => {
                acc[id] = true
                return acc
            }, {})
            setExpanded(idsObject)
        }
        codeList && loadNeighborsForCodeList(codeList)
    }, [codeList, loadNeighbors])

    return (
        <CodeListHierarchyView
            hierarchy={dataToShow ?? []}
            onChangeCodeList={(value) => setCodeList(value)}
            handleExpandClick={handleExpandClick}
            isLoading={[isLoading].some((item) => item)}
            expandableRowIdLoading={expandableRowIdLoading}
            expanded={expanded}
        />
    )
}
