import { AccordionContainer } from '@isdd/idsk-ui-kit/accordion/Accordion'
import { SafeHtmlComponent } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'
import { ConfigurationItemUi, RoleParticipantUI, useReadCiNeighboursWithAllRels } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { getOwnerLabel } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { setLangForAttribute } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { DESCRIPTION, ENTITY_OBJEKT_EVIDENCIE, HTML_TYPE, PO_PO } from '@isdd/metais-common/constants'
import { useGetCiTypeConstraintsData } from '@isdd/metais-common/hooks/useGetCiTypeConstraintsData'
import { ATTRIBUTE_NAME, QueryFeedback, pairEnumsToEnumValues } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { filterAndSortAttributesByTechnicalName } from '@isdd/metais-common/utils/utils'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { useEvidenceObjectProviderAgendaData } from '@/components/views/ci/evidence-object/CreateEvidenceObjectView'

interface CiInformationData {
    data: {
        ciItemData: ConfigurationItemUi | undefined
        ciTypeData: CiType | undefined
        constraintsData: (EnumType | undefined)[]
        unitsData?: EnumType | undefined
        gestorData: RoleParticipantUI[] | undefined
    }
    isError: boolean
    isLoading: boolean
    additionalBasicInformation?: {
        top?: React.ReactNode
        bottom?: React.ReactNode
    }
    additionalProfileInformation?: [
        {
            technicalName: string
            node: ReactNode
        },
    ]
    withoutDescription?: boolean
}

export const AttributeObjektuEvidencieInformationAccordion: React.FC<CiInformationData> = ({
    data: { ciItemData, ciTypeData, constraintsData, unitsData, gestorData },
    isLoading,
    isError,
    additionalBasicInformation,
    additionalProfileInformation,
    withoutDescription = false,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { entityId, entityName } = useGetEntityParamsFromUrl()

    const {
        isLoading: isCiConstraintLoading,
        isError: isCiConstraintError,
        uuidsToMatchedCiItemsMap,
    } = useGetCiTypeConstraintsData(ciTypeData, [ciItemData ?? {}])

    const { isLoading: isAgendaLoading, isError: isAgendaError } = useEvidenceObjectProviderAgendaData(ciTypeData, entityId ?? '')

    const currentEntityCiTypeConstraintsData = uuidsToMatchedCiItemsMap[ciItemData?.uuid ?? '']
    const varAttributes = ciItemData?.attributes?.['Profil_AtributObjektuEvidencie_eu_ref_id']
        ? ['Profil_AtributObjektuEvidencie_jednotny_URI', 'Profil_AtributObjektuEvidencie_nazov']
        : [
              'Profil_AtributObjektuEvidencie_jednotny_URI',
              'Profil_AtributObjektuEvidencie_eu_ref_id',
              'Profil_AtributObjektuEvidencie_nazov',
              'Profil_AtributObjektuEvidencie_cislo',
              'Profil_AtributObjektuEvidencie_rok',
              'Profil_AtributObjektuEvidencie_zdroj',
              'Profil_AtributObjektuEvidencie_paragraf',
              'Profil_AtributObjektuEvidencie_odsek',
              'Profil_AtributObjektuEvidencie_pismeno',
              'Profil_AtributObjektuEvidencie_bod',
              'Profil_AtributObjektuEvidencie_eli_uri',
          ]

    const { data: OEData } = useReadCiNeighboursWithAllRels(ciItemData?.uuid ?? '', {
        ciTypes: ['ObjektEvidencie'],
        page: 1,
        perPage: 1,
    })

    const tabsFromApi =
        ciTypeData?.attributeProfiles
            ?.filter((p) => p.valid)
            .map((attributesProfile, index) => {
                return {
                    title:
                        (language === Languages.SLOVAK
                            ? attributesProfile.description || attributesProfile.name
                            : attributesProfile.engDescription || attributesProfile.name) || '',
                    content: (
                        <DefinitionList>
                            {filterAndSortAttributesByTechnicalName(
                                attributesProfile?.attributes ?? [],
                                index == 0 ? varAttributes : attributesProfile.attributes?.map((a) => a.technicalName ?? '') ?? [],
                            )
                                ?.filter((atr) => atr.valid === true && atr.invisible !== true)
                                .map((attribute) => {
                                    const formattedRowValue: string | JSX.Element = pairEnumsToEnumValues({
                                        attribute,
                                        ciItemData,
                                        constraintsData,
                                        t,
                                        unitsData,
                                        matchedAttributeNamesToCiItem: currentEntityCiTypeConstraintsData,
                                        withDescription: !withoutDescription,
                                    })
                                    let label = (language === Languages.SLOVAK ? attribute.name : attribute.engName) ?? ''
                                    if (
                                        attribute.technicalName == ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_nazov &&
                                        ciItemData?.attributes?.[ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id]
                                    ) {
                                        label = t('aoe.euRef')
                                    } else if (attribute.technicalName == ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI) {
                                        label = t('aoe.jednotnyURI')
                                    }

                                    const isHTML = attribute.type === HTML_TYPE
                                    return (
                                        !attribute?.invisible && (
                                            <InformationGridRow
                                                key={attribute?.technicalName}
                                                label={label}
                                                value={isHTML ? <SafeHtmlComponent dirtyHtml={formattedRowValue as string} /> : formattedRowValue}
                                                tooltip={attribute?.description}
                                                lang={setLangForAttribute({ language, technicalName: attribute.technicalName ?? '' })}
                                            />
                                        )
                                    )
                                })}
                            {additionalProfileInformation?.find((p) => p.technicalName == attributesProfile.technicalName)?.node}
                        </DefinitionList>
                    ),
                }
            }) ?? []

    return (
        <QueryFeedback
            loading={isLoading || isCiConstraintLoading || isAgendaLoading}
            error={isError || isCiConstraintError || isAgendaError}
            showSupportEmail
            errorProps={{ errorMessage: isCiConstraintError ? t('ciInformationAccordion.error') : undefined }}
            withChildren
        >
            <AccordionContainer
                sections={[
                    {
                        title: t('ciInformationAccordion.basicInformation'),
                        onLoadOpen: true,
                        content: (
                            <DefinitionList>
                                {additionalBasicInformation?.top}
                                {filterAndSortAttributesByTechnicalName(ciTypeData?.attributes ?? [], [
                                    'Gen_Profil_nazov',
                                    'Gen_Profil_kod_metais',
                                ])?.map((attribute) => {
                                    const rowValue = pairEnumsToEnumValues({
                                        attribute,
                                        ciItemData,
                                        constraintsData,
                                        t,
                                        unitsData,
                                        matchedAttributeNamesToCiItem: currentEntityCiTypeConstraintsData,
                                        withDescription: !withoutDescription,
                                    })
                                    const isHTML = attribute.type === HTML_TYPE || attribute.name == DESCRIPTION

                                    return (
                                        <InformationGridRow
                                            key={attribute?.technicalName}
                                            label={(language === Languages.SLOVAK ? attribute.name : attribute.engName) ?? ''}
                                            value={isHTML ? <SafeHtmlComponent dirtyHtml={(rowValue as string)?.replace(/\n/g, '<br>')} /> : rowValue}
                                            tooltip={attribute?.description}
                                            lang={setLangForAttribute({
                                                language,
                                                technicalName: attribute.technicalName ?? '',
                                                schemaAttribute: attribute,
                                            })}
                                        />
                                    )
                                })}
                                <InformationGridRow
                                    label={getOwnerLabel(t, entityName)}
                                    tooltip={getOwnerLabel(t, entityName)}
                                    value={
                                        <Link to={`/ci/${PO_PO}/${gestorData?.[0]?.configurationItemUi?.uuid}`} target="_blank">
                                            {gestorData?.[0]?.configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                                        </Link>
                                    }
                                />
                                {filterAndSortAttributesByTechnicalName(ciTypeData?.attributes ?? [], ['Gen_Profil_ref_id'])?.map((attribute) => {
                                    const rowValue = pairEnumsToEnumValues({
                                        attribute,
                                        ciItemData,
                                        constraintsData,
                                        t,
                                        unitsData,
                                        matchedAttributeNamesToCiItem: currentEntityCiTypeConstraintsData,
                                        withDescription: !withoutDescription,
                                    })
                                    const isHTML = attribute.type === HTML_TYPE || attribute.name == DESCRIPTION

                                    return (
                                        <InformationGridRow
                                            key={attribute?.technicalName}
                                            label={(language === Languages.SLOVAK ? attribute.name : attribute.engName) ?? ''}
                                            value={isHTML ? <SafeHtmlComponent dirtyHtml={(rowValue as string)?.replace(/\n/g, '<br>')} /> : rowValue}
                                            tooltip={attribute?.description}
                                            lang={setLangForAttribute({
                                                language,
                                                technicalName: attribute.technicalName ?? '',
                                                schemaAttribute: attribute,
                                            })}
                                        />
                                    )
                                })}
                                <InformationGridRow
                                    label={t('navMenu.lists.evidenceObjects')}
                                    tooltip={t('navMenu.lists.evidenceObjects')}
                                    value={
                                        <Link to={`/ci/${ENTITY_OBJEKT_EVIDENCIE}/${OEData?.ciWithRels?.at(0)?.ci?.uuid}`} target="_blank">
                                            {OEData?.ciWithRels?.at(0)?.ci?.attributes?.['Gen_Profil_nazov'] as string}
                                        </Link>
                                    }
                                />
                            </DefinitionList>
                        ),
                    },
                    ...tabsFromApi,
                ]}
            />
        </QueryFeedback>
    )
}
