import { PaginatorWrapper, Table } from '@isdd/idsk-ui-kit/index'
import { KrisToBeKs, KrisToBeRights, useGetProjects } from '@isdd/metais-common/api/generated/kris-swagger'
import { ActionsOverTable, BASE_PAGE_SIZE, QueryFeedback } from '@isdd/metais-common/index'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnDef, ExpandedState } from '@tanstack/react-table'
import { DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { Link } from 'react-router-dom'
import { useGetAttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'

import { ProjectEvaluationActivitieRow } from './ProjectEvaluationActivitieRow'

interface IProjectEvaluationAccordionProps {
    entityId: string
    dataRights?: KrisToBeRights
    isGlobalAllowed: boolean
}

export const ProjectEvaluationAccordion: React.FC<IProjectEvaluationAccordionProps> = ({ entityId, dataRights, isGlobalAllowed }) => {
    const { t } = useTranslation()
    const [pageSize, setPageSize] = useState<number>(BASE_PAGE_SIZE)
    const [currentPage, setCurrentPage] = useState(0)
    const [expandedState, setExpandedState] = useState<ExpandedState>({})

    const {
        data: projectData,
        isLoading: isLoadingProject,
        isError: isErrorProject,
        refetch,
        isRefetching,
    } = useGetProjects(entityId, {
        page: currentPage,
        count: pageSize,
        order: 'CODE',
        direction: 'DESC',
    })

    const {
        data: krisToBeIsvsData,
        isLoading: isLoadingKrisToBeIsvsData,
        isError: isErrorKrisToBeIsvsData,
    } = useGetAttributeProfile('Profil_KRIS_TO_BE_PROJECT')

    const handlePagingSelect = (value: string) => {
        setPageSize(Number(value))
        refetch()
    }

    const handlePageSelect = (page: number) => {
        setCurrentPage(page)
        refetch()
    }

    const columns: Array<ColumnDef<KrisToBeKs>> = [
        {
            accessorFn: (row) => row?.code,
            header: t('evaluation.projectTable.projectCode'),
            id: 'isvsCode',
            cell: (ctx) => (
                <Link to={`/ci/ks/${ctx.row?.original?.uuid}`} target="_blank" className="govuk-link">
                    {ctx.row?.original?.code}
                </Link>
            ),
            size: 200,
        },
        {
            accessorFn: (row) => row?.code,
            header: t('evaluation.projectTable.projectName'),
            id: 'name',
            cell: (ctx) => ctx.row?.original?.name,
            size: 200,
        },
    ]

    const isLoading = [isRefetching, isLoadingProject, isLoadingKrisToBeIsvsData].some((item) => item)
    const isError = [isErrorProject, isErrorKrisToBeIsvsData].some((item) => item)
    return (
        <QueryFeedback loading={isLoading} error={isError} withChildren>
            <ActionsOverTable
                pagination={{ pageNumber: currentPage, pageSize, dataLength: projectData?.totalElements ?? 0 }}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                handlePagingSelect={handlePagingSelect}
                hiddenButtons={{ SELECT_COLUMNS: true }}
                entityName={''}
            />
            <Table
                columns={columns}
                data={projectData?.content ?? []}
                expandedRowsState={expandedState}
                onExpandedChange={setExpandedState}
                getExpandedRow={(row) => (
                    <ProjectEvaluationActivitieRow
                        entityId={entityId}
                        uuid={row?.original?.uuid}
                        attributes={krisToBeIsvsData}
                        activities={row?.original?.activities}
                        dataRights={dataRights}
                        isGlobalAllowed={isGlobalAllowed}
                        project={row?.original}
                    />
                )}
            />
            <PaginatorWrapper
                pageSize={pageSize}
                pageNumber={currentPage + 1}
                dataLength={projectData?.totalElements ?? 0}
                totalPages={projectData?.totalPages}
                handlePageChange={(page) => handlePageSelect(page.pageNumber ?? -1)}
            />
        </QueryFeedback>
    )
}
