import { BreadCrumbs, ButtonLink, CheckBox, Filter, HomeIcon, Input, PaginatorWrapper, SimpleSelect, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Table } from '@isdd/idsk-ui-kit/table/Table'
import { TextLink } from '@isdd/idsk-ui-kit/typography/TextLink'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, DEFAULT_PAGESIZE_OPTIONS, PO } from '@isdd/metais-common/constants'
import { ActionsOverTable, BulkPopup, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes, RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { ColumnDef, Table as ITable, Row, CellContext } from '@tanstack/react-table'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { DynamicFilterAttributes, ExtendedAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { useAddFavorite } from '@isdd/metais-common/hooks/useAddFavorite'
import { FollowedItemItemType } from '@isdd/metais-common/api/generated/user-config-swagger'
import { NotificationBlackIcon } from '@isdd/metais-common/assets/images'
import actionsOverTableStyles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { AttributeAttributeTypeEnum } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { OPERATOR_OPTIONS_URL } from '@isdd/metais-common/hooks/useFilter'
import { SelectPOForFilter } from '@isdd/metais-common/components/select-po/SelectPOForFilter'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'

import {
    CodeListListFilterData,
    CodeListListViewProps,
    CodeListState,
    CodelistTableData,
    defaultFilterValues,
} from '@/components/containers/CodeListListContainer'
import { MainContentWrapper } from '@/components/MainContentWrapper'

export const reduceTableDataToObject = <T extends { id?: number }>(array: T[]): Record<string, T> => {
    return array.reduce<Record<string, T>>((result, item) => {
        if (item.id) {
            result[item.id] = item
        }
        return result
    }, {})
}

export const CodeListListView: React.FC<CodeListListViewProps> = ({
    data,
    filter,
    handleFilterChange,
    isOnlyPublishedPage = false,
    isError,
    isLoading,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const tableRef = useRef<HTMLTableElement>(null)
    const {
        isActionSuccess: { value: isExternalSuccess },
    } = useActionSuccess()
    const {
        addFavorite,
        error: errorAddToFavorite,
        isLoading: isLoadingAddToFavorite,
        isSuccess: isSuccessAddToFavorite,
        resetState,
        successMessage,
    } = useAddFavorite()
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    const {
        state: { user },
    } = useAuth()
    const isUserLogged = !!user
    const focusTriggerRef = useRef<() => void>()
    const handleAddToFavorite = async () => {
        await addFavorite(rowSelection.ids, FollowedItemItemType.CODELIST)
        focusTriggerRef.current?.()
    }

    useEffect(() => {
        scrollToMutationFeedback()
    }, [isError, isExternalSuccess, isSuccessAddToFavorite, errorAddToFavorite, scrollToMutationFeedback])

    const columns: Array<ColumnDef<CodelistTableData>> = [
        {
            header: ({ table }: { table: ITable<CodelistTableData> }) => {
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            name="checkbox"
                            id="checkbox-all"
                            value="checkbox-all"
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            checked={table.getIsAllRowsSelected()}
                            onClick={(event) => event.stopPropagation()}
                            aria-label={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            id: CHECKBOX_CELL,
            cell: ({ row }: { row: Row<CodelistTableData> }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        aria-label={t('table.selectItem', {
                            itemName: row.original.codelistNames,
                        })}
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        onClick={(event) => event.stopPropagation()}
                    />
                </div>
            ),
        },
        {
            id: 'codelistName',
            header: t('codeListList.table.name'),
            size: 150,
            accessorFn: (row) => row.codelistName,
            enableSorting: true,
            meta: {
                getCellContext: (ctx: CellContext<CodelistTableData, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => {
                return (
                    <TextLink to={`${NavigationSubRoutes.CODELIST}/${row.row.original.id}?orderBy=itemCode&sortDirection=ASC`}>
                        {row.getValue() as string}
                    </TextLink>
                )
            },
        },
        {
            id: 'code',
            header: t('codeListList.table.code'),
            accessorFn: (row) => row.code,
            meta: {
                getCellContext: (ctx: CellContext<CodelistTableData, unknown>) => ctx?.getValue?.(),
            },
            enableSorting: true,
        },
        {
            id: 'mainGestor',
            header: t('codeListList.table.mainGestor'),
            accessorFn: (row) => row.codelistGestors,
            size: 150,
            meta: {
                getCellContext: (ctx: CellContext<CodelistTableData, unknown>) => ctx?.getValue?.(),
            },
        },
        {
            id: 'effectiveFrom',
            header: t('codeListList.table.effectiveFrom'),
            accessorFn: (row) => row.effectiveFrom,
            enableSorting: true,
            meta: {
                getCellContext: (ctx: CellContext<CodelistTableData, unknown>) => t('date', { date: ctx.getValue() as string }),
            },
            cell: (row) => t('date', { date: row.getValue() as string }),
        },
        {
            id: 'base',
            header: t('codeListList.table.isBase'),
            accessorFn: (row) => row.base,
            enableSorting: true,
            cell: (row) => {
                return row.getValue() ? t('radioButton.yes') : t('radioButton.no')
            },
        },
    ]

    if (!isOnlyPublishedPage) {
        columns.push({
            id: 'codelistState',
            header: t('state'),
            accessorFn: (row) => row.codelistState,
            enableSorting: true,
            cell: (row) => {
                return t(`codeListList.state.${row.getValue()}`)
            },
        })
    }

    const filterAttributes = (): ExtendedAttribute[] | undefined => {
        const logged: ExtendedAttribute[] = !isOnlyPublishedPage
            ? [
                  {
                      name: t('codeListList.filter.state'),
                      engName: t('codeListList.filter.state'),
                      attributeTypeEnum: AttributeAttributeTypeEnum.STRING,
                      technicalName: 'wfState',
                      invisible: false,
                      valid: true,
                      customComponent: (value, onChange) => {
                          return (
                              <SimpleSelect
                                  name={'atributeValue'}
                                  label={t('customAttributeFilter.value.label')}
                                  options={Object.values(CodeListState).map((state) => ({
                                      value: state,
                                      label: t(`codeListList.state.${state}`),
                                  }))}
                                  defaultValue={value?.value}
                                  onChange={(val) => {
                                      onChange({ ...value, value: val })
                                  }}
                              />
                          )
                      },
                      customOperators: [OPERATOR_OPTIONS_URL.EQUAL],
                  },
              ]
            : []

        return [
            {
                name: t('codeListList.filter.code'),
                engName: t('codeListList.filter.code'),
                attributeTypeEnum: AttributeAttributeTypeEnum.STRING,
                technicalName: 'code',
                invisible: false,
                valid: true,
                customOperators: [OPERATOR_OPTIONS_URL.EQUAL],
            },
            {
                name: t('codeListList.filter.onlyBase.label'),
                engName: t('codeListList.filter.onlyBase.label'),
                attributeTypeEnum: 'BOOLEAN',
                technicalName: 'onlyBase',
                invisible: false,
                valid: true,
            },
            {
                name: t('codeListList.filter.toDate'),
                engName: t('codeListList.filter.toDate'),
                attributeTypeEnum: AttributeAttributeTypeEnum.DATE,
                technicalName: 'toDate',
                invisible: false,
                valid: true,
                customOperators: [OPERATOR_OPTIONS_URL.EQUAL_OR_LOWER],
            },
            {
                name: t('codeListList.filter.mainGestor'),
                engName: t('codeListList.filter.mainGestor'),
                attributeTypeEnum: AttributeAttributeTypeEnum.STRING,
                technicalName: 'mainGestorPoUuid',
                invisible: false,
                valid: true,
                customComponent: (value, onChange) => {
                    return (
                        <SelectPOForFilter
                            ciType={PO}
                            label={t('customAttributeFilter.value.label')}
                            name="atributeValue"
                            valuesAsUuids={Array.isArray(value) ? [value?.[0]?.value] ?? [] : value?.value ? [value?.value] : []}
                            onChange={(val) => {
                                if (val && val.length && val.every((v) => v && v.uuid != '')) {
                                    onChange({ ...value, value: val?.[0]?.uuid })
                                }
                            }}
                            isMulti={false}
                        />
                    )
                },
                customOperators: [OPERATOR_OPTIONS_URL.EQUAL],
            },
            ...logged,
        ]
    }

    const constraintsData: (EnumType | undefined)[] = [
        {
            code: 'state',
            enumItems: Object.values(CodeListState).map((state) => ({
                value: state,
                description: t(`codeListList.state.${state}`),
            })),
        },
    ]
    const columnsWithPermissions = isUserLogged ? columns : columns.slice(1)
    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('codeList.breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                    { label: t('codeList.breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS },
                    { label: t('codeList.breadcrumbs.codeLists'), href: RouteNames.HOW_TO_CODELIST },
                    {
                        label: isOnlyPublishedPage ? t('codeList.breadcrumbs.publicCodeListsList') : t('codeList.breadcrumbs.codeListsList'),
                        href: NavigationSubRoutes.CODELIST,
                    },
                ]}
            />
            <MainContentWrapper>
                <QueryFeedback loading={isLoading || isLoadingAddToFavorite} error={false} withChildren>
                    <FlexColumnReverseWrapper>
                        <TextHeading size="XL">{t('codeListList.title')}</TextHeading>
                        <div ref={wrapperRef}>
                            <MutationFeedback
                                success={isSuccessAddToFavorite}
                                successMessage={successMessage}
                                error={!!errorAddToFavorite}
                                errorMessage={t('userProfile.notifications.feedback.error')}
                                onMessageClose={() => resetState()}
                            />
                            <QueryFeedback error={isError} loading={false} />
                            <MutationFeedback success={isExternalSuccess} />
                        </div>
                    </FlexColumnReverseWrapper>
                    {isOnlyPublishedPage ? (
                        <TextHeading size="L">{t('codeListList.publicCodeListSubtitle')}</TextHeading>
                    ) : (
                        <TextHeading size="L">{t('codeListList.codeListSubtitle')}</TextHeading>
                    )}
                    <Filter<CodeListListFilterData, object>
                        rowSelection={rowSelection}
                        heading={t('codeList.filter.title')}
                        defaultFilterValues={defaultFilterValues}
                        form={({ filter: formFilter, register, setValue, isOpen }) => {
                            return (
                                <div>
                                    <Input {...register('name')} type="text" label={t('codeListList.filter.name')} />
                                    <DynamicFilterAttributes
                                        setValue={setValue}
                                        defaults={defaultFilterValues}
                                        attributes={filterAttributes()}
                                        attributeProfiles={[]}
                                        constraintsData={constraintsData}
                                        filterData={{
                                            attributeFilters: formFilter.attributeFilters ?? {},
                                            metaAttributeFilters: {},
                                        }}
                                        ignoreInputNames={['lastModifiedAt', 'createdAt', 'owner', 'state']}
                                        isFocusable={isOpen}
                                    />
                                </div>
                            )
                        }}
                    />
                    <ActionsOverTable
                        pagination={{
                            pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER,
                            pageSize: filter.pageSize ?? BASE_PAGE_SIZE,
                            dataLength: data?.dataLength ?? 0,
                        }}
                        selectedRowsCount={rowSelection.count}
                        bulkPopup={({ selectedRowsCount }) => (
                            <BulkPopup
                                checkedRowItems={selectedRowsCount}
                                manuallyHandledFocusAfterClose
                                onOpen={(focusTriggerEl) => {
                                    focusTriggerRef.current = focusTriggerEl
                                }}
                                onClose={() => {
                                    focusTriggerRef.current?.()
                                }}
                                items={() => [
                                    <ButtonLink
                                        inPopup
                                        key={'favorite'}
                                        label={t('codeListList.buttons.addToFavorites')}
                                        icon={NotificationBlackIcon}
                                        className={actionsOverTableStyles.buttonLinkWithIcon}
                                        onClick={() => {
                                            handleAddToFavorite()
                                            rowSelection.clearSelectedRows()
                                        }}
                                    />,
                                ]}
                            />
                        )}
                        entityName=""
                        handleFilterChange={handleFilterChange}
                        pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                        hiddenButtons={{ SELECT_COLUMNS: true }}
                    />
                    <Table<CodelistTableData>
                        tableRef={tableRef}
                        data={data?.list}
                        sort={filter.sort ?? []}
                        onSortingChange={(columnSort) => {
                            handleFilterChange({ sort: columnSort })
                            rowSelection.clearSelectedRows()
                        }}
                        rowSelection={rowSelection.rowSelection}
                        onRowSelectionChange={rowSelection.getOnRowSelectionChange()}
                        getRowId={(originalRow) => `${originalRow.id}`}
                        columns={columnsWithPermissions}
                    />
                    <PaginatorWrapper
                        pageNumber={filter.pageNumber || BASE_PAGE_NUMBER}
                        pageSize={filter.pageSize || BASE_PAGE_SIZE}
                        dataLength={data?.dataLength || 0}
                        handlePageChange={(filterValues) => {
                            handleFilterChange(filterValues)
                            tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                        }}
                    />
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}

export default CodeListListView
