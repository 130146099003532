import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonGroupRow } from '@isdd/idsk-ui-kit/index'
import {
    CiWithRelsUi,
    ConfigurationItemUi,
    ConfigurationItemUiAttributes,
    useReadConfigurationItem,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import { ENTITY_DATAVY_PRVOK } from '@isdd/metais-common/constants'
import { ATTRIBUTE_NAME, Gen_Profil, QueryFeedback, RELATION_TYPE, ZbierkaZakonov, attributesFilter } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { filterAndSortAttributesByTechnicalName } from '@isdd/metais-common/utils/utils'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MultiValue } from 'react-select'

import { getEliUri } from './EvidenceObjectAttributeUtils'
import styles from './styles.module.scss'
import { NON_EU_URI_ATTRIBUTES, useEvidenceObjectAttribute } from './useEvidenceObjectAttribute'

import { AttributesConfigTechNames } from '@/components/attribute-input/attributeDisplaySettings'
import { EvidenceObjectRelationSelect } from '@/components/containers/evidence-object-attribute/EvidenceObjectRelationSelect'
import { CreateEntityData } from '@/components/create-entity/CreateEntity'
import { CreateEntitySection } from '@/components/create-entity/CreateEntitySection'
import { SelectCiItem } from '@/components/select-ci-item/SelectCiItem'
import { IRelationshipSetState } from '@/components/views/ci/ITVSExceptions/ITVSExceptionsCreateView'
import { INewCiRelationData } from '@/hooks/useNewCiRelation.hook'
import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { useRolesForPO } from '@/hooks/useRolesForPO'

interface Props {
    data: CreateEntityData
    relationData: INewCiRelationData
    onSubmit: (formData: FieldValues, relationshipsToRemove: CiWithRelsUi[]) => void
    defaultItemAttributeValues?: ConfigurationItemUiAttributes | undefined
    updateCiItemId?: string
    isProcessing: boolean
    isLoading: boolean
    isError: boolean
    publicAuthorityState?: PublicAuthorityState
    roleState?: RoleState
    relationshipSetState: IRelationshipSetState
    uploadError: boolean
    allCIsInRelations: CiWithRelsUi[]
    configurationItemId: string
}

export const EvidenceObjectAttributeCreateView: React.FC<Props> = ({
    data,
    relationData,
    updateCiItemId,
    publicAuthorityState,
    roleState,
    isError,
    isLoading,
    isProcessing,
    defaultItemAttributeValues,
    onSubmit,
    relationshipSetState,
    allCIsInRelations,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { attributesData, generatedEntityId } = data
    const { constraintsData, ciTypeData, unitsData } = attributesData
    const { readRelationShipsData: existingRelations, relationTypeData: relationSchema } = relationData

    const genProfilTechName = Gen_Profil
    const [hasReset, setHasReset] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isEUUri, setIsEUURI] = useState(false)
    const [selectedUnifiedUri, setSelectedUnifiedUri] = useState<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null>(null)
    const [defaultRelations, setDefaultRelations] = useState<CiWithRelsUi[]>([])
    const [relationshipsToRemove, setRelationshipsToRemove] = useState<CiWithRelsUi[]>([])

    const referenceIdValue = generatedEntityId?.ciurl?.split('/').pop()
    const metaIsCodeValue = generatedEntityId?.cicode

    const { rolesForPO } = useRolesForPO(
        updateCiItemId ? data.ownerId ?? '' : publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '',
        ciTypeData?.roleList ?? [],
    )

    const zbierkaZakonov = constraintsData.find((constr) => constr?.code === 'ZDROJ_PRAVNEHO_ZAKLADU')?.enumItems?.[0]

    const { aoeAttrProfile, genProfileAttrs, defaultValues, schema } = useEvidenceObjectAttribute(
        ciTypeData?.attributeProfiles ?? [],
        ciTypeData?.attributes ?? [],
        isEUUri,
        updateCiItemId,
        defaultItemAttributeValues,
        referenceIdValue,
        metaIsCodeValue,
        roleState?.selectedRole,
        ciTypeData?.roleList,
    )

    const [relationSchemaCombinedAttributes, setRelationSchemaCombinedAttributest] = useState<(Attribute | undefined)[]>([])
    useEffect(() => {
        setRelationSchemaCombinedAttributest([
            ...(relationSchema?.attributes ?? []),
            ...(relationSchema?.attributeProfiles?.map((profile) => profile.attributes?.map((att) => att)).flat() ?? []),
        ])
    }, [relationSchema])

    const methods = useForm({
        defaultValues: defaultValues as { [x: string]: object | undefined },
        resolver: yupResolver(schema),
    })
    const { handleSubmit, setValue, watch, resetField } = methods
    const EURefId = watch(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id) as boolean

    const eliUriPreview = getEliUri(watch(), zbierkaZakonov)
    useEffect(() => {
        if (!isEUUri) {
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eli_uri, getEliUri(watch(), zbierkaZakonov, true))
        } else {
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eli_uri, '')
            NON_EU_URI_ATTRIBUTES.forEach((a) => setValue(a, null))
        }
    }, [eliUriPreview, isEUUri, setValue, watch, zbierkaZakonov])

    useEffect(() => {
        if (!isEUUri) {
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj, ZbierkaZakonov)
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_bod, '')
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_pismeno, '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEUUri])

    useEffect(() => {
        setIsEUURI(EURefId)
    }, [EURefId])

    useEffect(() => {
        setDefaultRelations(allCIsInRelations?.filter((ciRel) => ciRel.rels?.[0].type === RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie))
    }, [allCIsInRelations])

    useEffect(() => {
        setValue(AttributesConfigTechNames.REFERENCE_ID, referenceIdValue)
        setValue(AttributesConfigTechNames.METAIS_CODE, metaIsCodeValue)
    }, [metaIsCodeValue, referenceIdValue, setValue])

    const [objectEvidencieRef, setObjectEvidencieRef] = useState<string>()

    const { data: objektEvidencie, isError: isObjectEvidencieError } = useReadConfigurationItem(objectEvidencieRef ?? '', {
        query: { enabled: !!objectEvidencieRef },
    })

    useEffect(() => {
        if (objektEvidencie && objectEvidencieRef) {
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_nazov, objektEvidencie?.attributes?.[ATTRIBUTE_NAME.Profil_ObjektEvidencie_nazov])
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_cislo, objektEvidencie?.attributes?.[ATTRIBUTE_NAME.Profil_ObjektEvidencie_cislo])
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_rok, objektEvidencie?.attributes?.[ATTRIBUTE_NAME.Profil_ObjektEvidencie_rok])
            setValue(
                ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj,
                objektEvidencie?.attributes?.[ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj],
            )
            setValue(
                ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_paragraf,
                objektEvidencie?.attributes?.[ATTRIBUTE_NAME.Profil_ObjektEvidencie_paragraf],
            )
        } else {
            resetField(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_nazov)
            resetField(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_cislo)
            resetField(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_rok)
            resetField(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_paragraf)
        }
    }, [defaultItemAttributeValues, objectEvidencieRef, objektEvidencie, EURefId, setValue, resetField])

    const [attributesShort, setAttributesShort] = useState<Attribute[]>()

    useEffect(() => {
        if (!attributesShort && aoeAttrProfile?.attributes) {
            const toChange = {
                ...aoeAttrProfile?.attributes?.find((a) => a.technicalName == ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_nazov),
            } as Attribute
            const checkBox = {
                ...aoeAttrProfile?.attributes?.find((a) => a.technicalName == ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id),
            } as Attribute
            if (toChange) {
                toChange.name = t('aoe.addEuRef')
                toChange.description = t('aoe.euRefExample')
                setAttributesShort([checkBox, toChange])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aoeAttrProfile?.attributes])
    const attrOrder = [
        'Profil_AtributObjektuEvidencie_eu_ref_id',
        'Profil_AtributObjektuEvidencie_nazov',
        'Profil_AtributObjektuEvidencie_cislo',
        'Profil_AtributObjektuEvidencie_rok',
        'Profil_AtributObjektuEvidencie_zdroj',
        'Profil_AtributObjektuEvidencie_paragraf',
        'Profil_AtributObjektuEvidencie_odsek',
        'Profil_AtributObjektuEvidencie_pismeno',
        'Profil_AtributObjektuEvidencie_bod',
    ]

    return (
        <>
            <QueryFeedback loading={isLoading || isProcessing} error={isError || isObjectEvidencieError}>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={handleSubmit((formData) => onSubmit(formData, relationshipsToRemove))}>
                        <fieldset>
                            <legend className="govuk-heading-m">{t('ciInformationAccordion.basicInformation')}</legend>
                            <CreateEntitySection
                                sectionId={genProfilTechName}
                                attributes={genProfileAttrs ?? []}
                                constraintsData={constraintsData}
                                generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                                unitsData={unitsData}
                                defaultItemAttributeValues={defaultItemAttributeValues}
                                hasResetState={{ hasReset, setHasReset }}
                                updateCiItemId={updateCiItemId}
                                sectionRoles={ciTypeData?.roleList ?? []}
                                selectedRole={roleState?.selectedRole ?? null}
                                rolesForPO={rolesForPO ?? []}
                                maxTextInputLength={2000}
                            />
                            {publicAuthorityState && roleState && (
                                <SelectPublicAuthorityAndRole
                                    selectedRole={roleState.selectedRole ?? {}}
                                    onChangeAuthority={publicAuthorityState.setSelectedPublicAuthority}
                                    onChangeRole={roleState.setSelectedRole}
                                    selectedOrg={publicAuthorityState.selectedPublicAuthority}
                                    ciRoles={ciTypeData?.roleList ?? []}
                                    hideRoleSelect
                                    publicAuthorityLabel={t('atributObjektuEvidencie.provider')}
                                    disablePOSelect={!!updateCiItemId}
                                    publicAuthorityRequired
                                />
                            )}
                            <EvidenceObjectRelationSelect
                                setObjectEvidencieRef={setObjectEvidencieRef}
                                ciType="ObjektEvidencie"
                                relationSchemaCombinedAttributes={relationSchemaCombinedAttributes}
                                methods={methods}
                                hasResetState={{ hasReset, setHasReset }}
                                constraintsData={relationData?.constraintsData ?? []}
                                unitsData={unitsData}
                                relationType="Objekt_evidencie_ma_atribut_evidencie"
                                relationshipSetState={relationshipSetState}
                                label={t('atributObjektuEvidencie.ObjektEvidencie')}
                                existingRelations={existingRelations}
                                defaultData={defaultRelations}
                                setRelationshipsToRemove={setRelationshipsToRemove}
                            />
                        </fieldset>
                        <fieldset>
                            <legend className="govuk-heading-m">{t('atributObjektuEvidencie.jednotnyUri')}</legend>
                            <SelectCiItem
                                ciType={ENTITY_DATAVY_PRVOK}
                                onChangeSelectedCiItem={(val) => {
                                    setSelectedUnifiedUri(val)
                                    setValue(
                                        ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI,
                                        Array.isArray(val)
                                            ? val[0].attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id]
                                            : (val as ConfigurationItemUi)?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id],
                                    )
                                }}
                                onCloseModal={() => setIsOpen(false)}
                                onOpenModal={() => setIsOpen(true)}
                                existingRelations={existingRelations}
                                label={t('refIdentifiers.type.DatovyPrvok')}
                                isOpen={isOpen}
                                selectedItems={selectedUnifiedUri}
                                isMulti={false}
                                labelDescriptionAttr={ATTRIBUTE_NAME.Gen_Profil_ref_id}
                                attributesFilter={attributesFilter}
                            />
                            <span className="govuk-hint">
                                <u>{`${
                                    watch(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI)
                                        ? watch(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI)
                                        : defaultValues[ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI] ?? ''
                                }`}</u>
                            </span>
                        </fieldset>
                        <fieldset className={styles.uriFieldset}>
                            <legend className="govuk-heading-m">{t('atributObjektuEvidencie.pravnyPredpis')}</legend>
                            <CreateEntitySection
                                sectionId={aoeAttrProfile?.technicalName ?? ''}
                                attributes={filterAndSortAttributesByTechnicalName(
                                    EURefId ? attributesShort ?? [] : aoeAttrProfile?.attributes ?? [],
                                    attrOrder,
                                )}
                                constraintsData={constraintsData}
                                generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                                unitsData={unitsData}
                                defaultItemAttributeValues={defaultItemAttributeValues}
                                hasResetState={{ hasReset, setHasReset }}
                                updateCiItemId={updateCiItemId}
                                sectionRoles={ciTypeData?.roleList ?? []}
                                selectedRole={roleState?.selectedRole ?? null}
                                rolesForPO={rolesForPO ?? []}
                                maxTextInputLength={2000}
                            />
                            {!EURefId && (
                                <div className={classNames(['govuk-hint', styles.wrapAnywhere])}>
                                    <u>{`${t('atributObjektuEvidencie.uriInsight')}: ${eliUriPreview}`}</u>
                                </div>
                            )}
                        </fieldset>

                        <ButtonGroupRow>
                            <Button
                                label={t('button.cancel')}
                                type="reset"
                                variant="secondary"
                                onClick={() => {
                                    navigate(`${NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE}`)
                                }}
                            />
                            <Button type="submit" label={t('button.saveChanges')} />
                        </ButtonGroupRow>
                    </form>
                </FormProvider>
            </QueryFeedback>
        </>
    )
}
