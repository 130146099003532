import { BaseModal, Button, SelectLazyLoading, TextBody } from '@isdd/idsk-ui-kit'
import { MetaAttributesState, SortBy, SortType } from '@isdd/idsk-ui-kit/types'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MultiValue, OptionProps } from 'react-select'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import {
    ConfigurationItemUi,
    FilterAttributesUi,
    IncidentRelationshipSetUi,
    useReadCiList1Hook,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Option } from '@isdd/idsk-ui-kit/common/SelectCommon'

import styles from './selectCiItem.module.scss'

import { CiListPageForModal } from '@/components/ciListPageForModal/CiListPageForModal'

interface Props {
    onChangeSelectedCiItem: (val: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null) => void
    selectedItems: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null
    perPage?: number
    onCloseModal: () => void
    onOpenModal: () => void
    existingRelations: IncidentRelationshipSetUi | undefined
    label?: string
    isOpen: boolean
    ciType: string
    error?: string
    isMulti?: boolean
    labelDescriptionAttr?: string
    attributesFilter?: FilterAttributesUi[]
    relationType?: string
    ciItemData?: ConfigurationItemUi
}

export const SelectCiItem: React.FC<Props> = ({
    onChangeSelectedCiItem,
    perPage = 20,
    onCloseModal,
    onOpenModal,
    existingRelations,
    label,
    isOpen,
    selectedItems,
    ciType,
    error,
    isMulti = true,
    labelDescriptionAttr,
    attributesFilter = [],
    relationType,
    ciItemData,
}) => {
    const { t } = useTranslation()
    const readCiListFetch = useReadCiList1Hook()

    const loadOptions = async (searchQuery: string, additional: { page: number } | undefined) => {
        const page = !additional?.page ? 1 : (additional?.page || 0) + 1

        const response = await readCiListFetch({
            filter: {
                searchFields: [ATTRIBUTE_NAME.Gen_Profil_nazov, ATTRIBUTE_NAME.Gen_Profil_kod_metais],
                fullTextSearch: searchQuery,
                type: [ciType],
                uuid: [],
                metaAttributes: {
                    state: [MetaAttributesState.DRAFT],
                },
                attributes: attributesFilter,
            },
            page: page,
            perpage: perPage,
            sortBy: SortBy.GEN_PROFIL_NAZOV,
            sortType: SortType.ASC,
        })

        const filteredCiListData = response.configurationItemSet?.filter((item) => {
            const startUuids = existingRelations?.endRelationshipSet?.map((rel) => rel.startUuid) || []
            const endUuids = existingRelations?.startRelationshipSet?.map((rel) => rel.endUuid) || []
            return !startUuids.includes(item.uuid) && !endUuids.includes(item.uuid)
        })

        const options = filteredCiListData

        return {
            options: options || [],
            hasMore: options?.length ? true : false,
            additional: {
                page: page,
            },
        }
    }

    const formatOption = (props: OptionProps<ConfigurationItemUi>) => {
        const { attributes } = props.data

        return (
            <Option {...props}>
                <div>{attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}</div>
                {labelDescriptionAttr && (
                    <span>
                        <small>{attributes?.[labelDescriptionAttr] as string}</small>
                    </span>
                )}
            </Option>
        )
    }

    return (
        <>
            <div className={styles.rowDiv}>
                <div className={styles.selectDiv}>
                    <SelectLazyLoading
                        error={error}
                        isMulti={isMulti}
                        getOptionLabel={(item) => item.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                        getOptionValue={(item) => item.uuid ?? ''}
                        loadOptions={(searchTerm, _, additional) => loadOptions(searchTerm, additional)}
                        label={label ? label : t('selectCiItem.label')}
                        name="select-configuration-item"
                        onChange={(val: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null) => onChangeSelectedCiItem(val)}
                        value={selectedItems}
                        option={formatOption}
                    />
                </div>

                {isMulti && (
                    <>
                        <TextBody className={classNames(styles.marginTop, styles.italic, styles.text)}>{t('newRelation.or')}</TextBody>
                        <Button className={styles.marginTop} variant="secondary" label={t('newRelation.pickItems')} onClick={onOpenModal} />
                    </>
                )}
            </div>

            <BaseModal isOpen={isOpen} close={onCloseModal}>
                <CiListPageForModal
                    ciType={ciType}
                    selectedItems={selectedItems}
                    existingRelations={existingRelations}
                    onSelectedSubmit={(val) => onChangeSelectedCiItem(val)}
                    closeOnClick={onCloseModal}
                    relationType={relationType}
                    ciItemData={ciItemData}
                />
            </BaseModal>
        </>
    )
}
