import { Button, ButtonGroupRow, CheckBox, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Stepper } from '@isdd/idsk-ui-kit/stepper/Stepper'
import { ISection, IStepLabel } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import { ConfigurationItemUiAttributes, HistoryVersionUiConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute, AttributeConstraintEnumAllOf } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { useNavigate, useParams } from 'react-router-dom'

import styles from './historyCompare.module.scss'
import { HistoryCompareItemView } from './HistoryCompareItemView'
import { RelationCompareItemView } from './RelationCompareItemView'

import { IHistoryItemsCompareContainerView } from '@/components/containers/HistoryItemsCompareContainer'
import { getIsDiff } from '@/componentHelpers/ci/relationsCompare'

enum AttributeType {
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    STRING_PAIR = 'STRING_PAIR',
    DATE = 'DATE',
    ENUM = 'enum',
}

export const HistoryCompareView: React.FC<IHistoryItemsCompareContainerView> = ({
    ciTypeData,
    dataFirst,
    dataSec,
    constraintsData,
    dataRelationFirst,
    dataRelationSecond,
    isSimple,
}) => {
    const { entityId } = useParams()
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [showOnlyChanges, setShowOnlyChanges] = useState<boolean>(false)
    const [sections, setSections] = useState<ISection[]>([])

    const languageEn = 'en'
    const attProfiles = useMemo(() => ciTypeData?.attributeProfiles?.map((profile) => profile) ?? [], [ciTypeData?.attributeProfiles])

    const heading = t('historyTab.comparingHistory', {
        itemName: (dataFirst?.item?.attributes as unknown as { name: string; value: string }[])?.find(
            (att) => att?.name == ATTRIBUTE_NAME.Gen_Profil_nazov,
        )?.value,
    })
    setDocumentTitle(heading)

    const getEnumValue = useCallback(
        (enumAttribute: AttributeConstraintEnumAllOf, value: string): string => {
            if (!enumAttribute) {
                return value ?? ''
            }

            const numValue = constraintsData.find((i) => i?.code === enumAttribute.enumCode)?.enumItems?.find((i) => i?.code === value)
            if (i18n.language === languageEn) {
                return numValue?.engValue ?? ''
            }

            return numValue?.value ?? value
        },
        [constraintsData, i18n.language],
    )

    const getAttributeValue = useCallback(
        (attribute: Attribute, data?: HistoryVersionUiConfigurationItemUi): string => {
            if (!attribute.technicalName) return ''
            const item = (data?.item?.attributes as unknown as { name: string; value: string }[])?.find((i) => i.name === attribute.technicalName)
            if (item) {
                if (attribute.array) {
                    return item?.value?.length
                        ? ((item?.value as unknown as string[]).map(
                              (i: string) =>
                                  getEnumValue(attribute.constraints?.find((o) => o.type === AttributeType.ENUM) as AttributeConstraintEnumAllOf, i) +
                                  ', ',
                          ) as unknown as string)
                        : ''
                }
                if (attribute.type === AttributeType.DATE) {
                    return item?.value && new Date(item?.value).toLocaleString(i18n.language)
                }
                if (attribute.type === AttributeType.BOOLEAN) {
                    return item?.value ? t('radioButton.yes') : t('radioButton.no')
                }
                if (attribute.type === AttributeType.STRING_PAIR) {
                    return item?.value?.length ? item?.value?.split('|')[0] ?? '' : ''
                }

                return item?.value && attribute.constraints?.length && attribute.constraints?.find((i) => i.type === AttributeType.ENUM)
                    ? getEnumValue(attribute.constraints?.find((i) => i.type === AttributeType.ENUM) as AttributeConstraintEnumAllOf, item?.value)
                    : item?.value ?? ''
            }
            return ''
        },
        [getEnumValue, i18n.language, t],
    )

    const haveDiff = useCallback(
        (attributes: Attribute[]): boolean => {
            let diff = false
            attributes.forEach((attribute) => {
                if (!attribute.valid || attribute.invisible) {
                    return
                }
                const itemFirst = (dataFirst?.item?.attributes as unknown as ConfigurationItemUiAttributes[])?.find(
                    (i: ConfigurationItemUiAttributes) => i.name === attribute.technicalName,
                )?.value as string | string[]
                const itemSec = (dataSec?.item?.attributes as unknown as ConfigurationItemUiAttributes[])?.find(
                    (i: ConfigurationItemUiAttributes) => i.name === attribute.technicalName,
                )?.value as string | string[]
                if (itemFirst !== itemSec) {
                    if (Array.isArray(itemFirst)) {
                        if (itemFirst.length !== itemSec?.length) {
                            diff = true
                            return
                        }
                        itemFirst.forEach((item, index) => {
                            if (item !== itemSec?.[index]) {
                                diff = true
                                return
                            }
                        })
                        return
                    }
                    diff = true
                    return
                }
            })
            return diff
        },
        [dataFirst?.item?.attributes, dataSec?.item?.attributes],
    )

    let firstIsOpen = false
    const setOpenProfile = () => {
        if (!firstIsOpen && !isSimple && haveDiff(ciTypeData?.attributes || [])) {
            firstIsOpen = true
            return true
        }
        return false
    }

    const getActionLabel = (historyData: HistoryVersionUiConfigurationItemUi | undefined) => {
        const actionTime = (historyData?.actionTime && new Date(historyData?.actionTime).toLocaleString(i18n.language)) ?? ''

        const getLabelFormatted = (label: string, time: string) => {
            return `${label}: ${time}`
        }

        if (historyData?.actions && historyData.actions.length > 1) {
            const actionLabel = historyData.actions.map((act) => t('history.ACTIONS.' + (act ?? ''))).join(', ')
            return getLabelFormatted(actionLabel, actionTime)
        } else {
            const actionLabel = t('history.ACTIONS.' + (historyData?.actions?.[0] ?? ''))
            return getLabelFormatted(actionLabel, actionTime)
        }
    }

    useEffect(() => {
        const isGenProfilHidden = showOnlyChanges && !isSimple && !haveDiff(ciTypeData?.attributes || [])
        const isRelSectionHidden = showOnlyChanges && !isSimple && !getIsDiff(dataRelationFirst ?? [], dataRelationSecond ?? [], entityId ?? '')
        const attProfilesHiddenObject = attProfiles.reduce<Record<string, boolean>>(
            (acc, obj) => ({
                ...acc,
                [obj.technicalName ?? '']: showOnlyChanges && !isSimple && !haveDiff(obj.attributes || []),
            }),
            {},
        )

        setSections([
            {
                title: t('ciInformationAccordion.basicInformation'),
                change: !isSimple && haveDiff(ciTypeData?.attributes || []),
                isOpen: setOpenProfile(),
                hide: isGenProfilHidden,
                stepLabel: { label: '1', variant: 'circle' },
                id: 'attribute.technicalName',
                last: isRelSectionHidden && !isGenProfilHidden && Object.keys(attProfilesHiddenObject).every((p) => attProfilesHiddenObject[p]),
                content: (
                    <DefinitionList>
                        <HistoryCompareItemView
                            key={'attribute.technicalName'}
                            label={''}
                            tooltip={''}
                            isSimple={isSimple}
                            valueFirst={getActionLabel(dataFirst)}
                            valueSec={getActionLabel(dataSec)}
                            withoutCompare
                        />
                        {ciTypeData?.attributes
                            ?.filter((a) => a.valid && !a.invisible)
                            ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                            .map((attribute) => {
                                return (
                                    <HistoryCompareItemView
                                        key={attribute.technicalName}
                                        label={attribute.name || ''}
                                        tooltip={attribute.description || ''}
                                        isSimple={isSimple}
                                        showOnlyChanges={showOnlyChanges}
                                        valueFirst={getAttributeValue(attribute, dataFirst)}
                                        valueSec={getAttributeValue(attribute, dataSec)}
                                    />
                                )
                            })}
                    </DefinitionList>
                ),
            },
            ...attProfiles
                .filter((profile) => profile.attributes && profile.attributes.length > 0)
                .map((profile, index) => {
                    return {
                        title: profile.description ?? '',
                        id: profile.technicalName ?? '',
                        change: !isSimple && haveDiff(profile.attributes || []),
                        isOpen: setOpenProfile(),
                        hide: attProfilesHiddenObject[profile.technicalName ?? ''],
                        stepLabel: { label: (index + 2).toString(), variant: 'circle' } as IStepLabel,
                        last:
                            isRelSectionHidden &&
                            !!Object.keys(attProfilesHiddenObject)
                                .reverse()
                                .find((key) => attProfilesHiddenObject[key]),
                        content: (
                            <DefinitionList>
                                {profile.attributes &&
                                    profile.attributes
                                        .filter((a) => a.valid && !a.invisible)
                                        .map((attribute) => {
                                            return (
                                                <HistoryCompareItemView
                                                    key={attribute.technicalName}
                                                    label={attribute.name || ''}
                                                    tooltip={attribute.description || ''}
                                                    isSimple={isSimple}
                                                    showOnlyChanges={showOnlyChanges}
                                                    valueFirst={getAttributeValue(attribute, dataFirst)}
                                                    valueSec={getAttributeValue(attribute, dataSec)}
                                                />
                                            )
                                        })}
                            </DefinitionList>
                        ),
                    }
                }),
            {
                title: t('ciInformationAccordion.relations'),
                id: 'ciInformationAccordion.relations',
                change: !isSimple && getIsDiff(dataRelationFirst ?? [], dataRelationSecond ?? [], entityId ?? ''),
                isOpen: setOpenProfile(),
                hide: isRelSectionHidden,
                stepLabel: {
                    label: (attProfiles.filter((p) => p.attributes && p.attributes.length > 0).length + 2).toString(),
                    variant: 'circle',
                },
                last: true,
                content: (
                    <RelationCompareItemView
                        label={t('ciInformationAccordion.relations')}
                        tooltip={''}
                        dataRelationFirst={dataRelationFirst}
                        dataRelationSecond={dataRelationSecond}
                        isSimple={isSimple}
                        showOnlyChanges={showOnlyChanges}
                    />
                ),
            },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        attProfiles,
        ciTypeData?.attributes,
        dataFirst,
        dataRelationFirst,
        dataRelationSecond,
        dataSec,
        getAttributeValue,
        haveDiff,
        i18n.language,
        isSimple,
        showOnlyChanges,
        t,
    ])

    const handleSectionOpen = (id: string) => {
        setSections((prev) => prev.map((item) => (item.id === id ? { ...item, isOpen: !item.isOpen } : item)))
    }

    const openOrCloseAllSections = () => {
        setSections((prev) => {
            const allOpen = prev.every((item) => item.isOpen)
            return prev.map((item) => ({ ...item, isOpen: !allOpen }))
        })
    }

    return (
        <>
            <TextHeading size="XL">{heading}</TextHeading>
            {!isSimple && (
                <CheckBox
                    label={t('historyTab.hideCheckButtonLabel')}
                    name={'hideCheckButtonLabel'}
                    id={'hideCheckButtonLabel'}
                    onChange={() => setShowOnlyChanges(!showOnlyChanges)}
                />
            )}
            <Stepper subtitleTitle="" stepperList={sections} handleSectionOpen={handleSectionOpen} openOrCloseAllSections={openOrCloseAllSections} />
            <ButtonGroupRow>
                <Button label={t('form.back')} type="reset" variant="secondary" onClick={() => navigate(-1)} className={styles.marginLeftAuto} />
            </ButtonGroupRow>
        </>
    )
}
