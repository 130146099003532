import { GridCol } from '@isdd/idsk-ui-kit/grid/GridCol'
import { GridRow } from '@isdd/idsk-ui-kit/grid/GridRow'
import { TextBody } from '@isdd/idsk-ui-kit/typography/TextBody'
import { TextLinkExternal } from '@isdd/idsk-ui-kit/typography/TextLinkExternal'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { AttributeUi, ConfigurationItemUi, RelationshipListUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { setLangForAttribute } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import { DateRangeObject, findCurrentDateObjects } from '@isdd/metais-common/utils/utils'
import classNames from 'classnames'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RelationAttribute } from './RelationAttribute'
import styles from './relationCard.module.scss'

interface IRelationCardProps extends PropsWithChildren {
    label: string
    labelHref: string
    status?: string
    codeMetaIS: string
    name: string
    uuid?: string
    admin: React.ReactNode
    relations?: { title: string; href: string; isValid?: boolean; attributes?: AttributeUi[]; endUuid?: string; startUuid?: string }[]
    showRelAttributes?: boolean
    attributesToDisplay?: string[]
    entityName?: string
    entityId?: string
    isDerived: boolean
    derivedRelationItems?: ConfigurationItemUi[]
    loadPhases?: () => Promise<RelationshipListUi | undefined>
}

export const RelationCard: React.FC<IRelationCardProps> = ({
    codeMetaIS,
    status,
    label,
    labelHref,
    name,
    admin,
    relations,
    uuid,
    showRelAttributes = false,
    attributesToDisplay,
    entityName,
    entityId,
    isDerived,
    derivedRelationItems,
    loadPhases,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [currentKsPhase, setCurrentKsPhase] = useState<DateRangeObject>()

    useEffect(() => {
        if (loadPhases) {
            loadPhases().then((resp) => {
                if (resp) {
                    const dateRangeObjects =
                        resp.relationshipList?.map((r) => ({
                            name: r.endName ?? '',
                            startDate: r.attributes?.find((i) => i.name === 'Profil_Rel_FazaZivotnehoCyklu_datum_zacatia')?.value as
                                | string
                                | undefined,
                            endDate: r.attributes?.find((i) => i.name === 'Profil_Rel_FazaZivotnehoCyklu_datum_ukoncenia')?.value as
                                | string
                                | undefined,
                        })) ?? []
                    const currentEvents = findCurrentDateObjects(dateRangeObjects)
                    setCurrentKsPhase(currentEvents)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={classNames([styles.itemBox], { [styles.errorItemBox]: status === 'INVALIDATED' })}>
                <GridRow className={styles.heading}>
                    <GridCol setWidth="one-third">
                        <h3 className={classNames('govuk-heading-s', [styles.fontWeightNormal])}>
                            <TextLinkExternal
                                title={`${t('relationDetail.item')}: ${label}`}
                                href={labelHref}
                                textLink={`${t('relationDetail.item')}: ${label}`}
                                newTab
                            />
                        </h3>
                    </GridCol>
                    <GridCol setWidth="two-thirds">
                        <dl className={styles.itemContent}>
                            <TextBody size="S" className={styles.headingItem}>
                                <dt className="govuk-!-font-weight-bold">{t('relationCard.codeMetaIS') + ': '}</dt>
                                <dd>{codeMetaIS}</dd>
                            </TextBody>

                            <TextBody size="S" className={styles.headingItem}>
                                <dt className="govuk-!-font-weight-bold">{t('relationCard.status') + ' '}</dt>
                                <dd className={classNames({ [styles.errorItemText]: status === 'INVALIDATED' })}>
                                    {t(`metaAttributes.state.${status}`)}
                                </dd>
                            </TextBody>
                        </dl>
                    </GridCol>
                </GridRow>
                <DefinitionList>
                    <RelationAttribute
                        name={`${t('relationDetail.item')}: ${t('relationCard.name')}`}
                        value={name}
                        lang={setLangForAttribute({ language, technicalName: ATTRIBUTE_NAME.Gen_Profil_nazov })}
                    />
                    <RelationAttribute name={t('relationCard.codeMetaIS')} value={codeMetaIS} />
                    <RelationAttribute
                        name={t('relationCard.admin')}
                        value={admin}
                        lang={setLangForAttribute({ language, technicalName: ATTRIBUTE_NAME.Gen_Profil_nazov })}
                    />
                    {relations?.map((relation, index) => {
                        let sourceName: string | undefined = ''
                        let targetName: string | undefined = ''
                        if (isDerived) {
                            sourceName = derivedRelationItems?.find((item) => item.uuid === relation.startUuid)?.attributes?.[
                                ATTRIBUTE_NAME.Gen_Profil_nazov
                            ] as string
                            targetName = derivedRelationItems?.find((item) => item.uuid === relation.endUuid)?.attributes?.[
                                ATTRIBUTE_NAME.Gen_Profil_nazov
                            ] as string
                        } else {
                            sourceName = relation.startUuid === uuid ? label : relation.startUuid === entityId ? entityName : ''
                            targetName = relation.endUuid === uuid ? label : relation.endUuid === entityId ? entityName : ''
                        }

                        return (
                            <React.Fragment key={relation.title + index}>
                                <RelationAttribute
                                    name={index === 0 ? t('relationCard.relations') : ''}
                                    className={index === 0 ? '' : styles.multiRelationsMargin}
                                    value={
                                        <>
                                            {sourceName && (
                                                <TextBody size="S" className="marginBottom0">
                                                    {t('relationDetail.source')}: {sourceName}
                                                </TextBody>
                                            )}

                                            <TextLinkExternal
                                                title={`${t('relationDetail.relation')}: ${relation.title}`}
                                                href={relation.href}
                                                textLink={`${t('relationDetail.relation')}: ${relation.title}`}
                                                isInvalid={!relation.isValid}
                                                newTab
                                            />
                                            {targetName && (
                                                <TextBody size="S" className="marginBottom0">
                                                    {t('relationDetail.target')}: {targetName}
                                                </TextBody>
                                            )}
                                        </>
                                    }
                                />
                                {showRelAttributes &&
                                    relation.attributes
                                        ?.filter((attr) => attr.name && attributesToDisplay?.includes(attr.name))
                                        .map((attr) => {
                                            return (
                                                <RelationAttribute
                                                    key={attr.name}
                                                    name={t(`relAttributes.${attr.name}`)}
                                                    value={t('date', { date: attr.value as unknown as string })}
                                                />
                                            )
                                        })}
                                {currentKsPhase && (
                                    <RelationAttribute
                                        name={t('evaluation.projectTable.projectPhases')}
                                        className={index === 0 ? '' : styles.multiRelationsMargin}
                                        value={
                                            <>
                                                {currentKsPhase.name && (
                                                    <TextBody size="S" className="marginBottom0">
                                                        {t('currentPhase.name')}: {currentKsPhase.name}
                                                    </TextBody>
                                                )}
                                                {currentKsPhase.startDate && (
                                                    <TextBody size="S" className="marginBottom0">
                                                        {t('currentPhase.startDate')}:{' '}
                                                        {t('date', { date: currentKsPhase.startDate as unknown as string })}
                                                    </TextBody>
                                                )}
                                                {currentKsPhase.endDate && (
                                                    <TextBody size="S" className="marginBottom0">
                                                        {t('currentPhase.endDate')}:{' '}
                                                        {t('date', { date: currentKsPhase.endDate as unknown as string })}
                                                    </TextBody>
                                                )}
                                            </>
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )
                    })}
                </DefinitionList>
            </div>
        </>
    )
}
