import { BaseModal, SelectLazyLoading, TextHeading, TextWarning } from '@isdd/idsk-ui-kit'
import { CiListFilterContainerUi, ConfigurationItemUi, useReadCiList1Hook } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { DRAFT, ENTITY_ISVS, PROJECT } from '@isdd/metais-common/constants'
import { ATTRIBUTE_NAME, ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'

export interface IGeneratePdfFormData {
    project?: ConfigurationItemUi[]
    isvs?: ConfigurationItemUi[]
}
export interface IGeneratePdfModalProps {
    open: boolean
    orgId?: string
    onClose: () => void
    onSend: (formData: IGeneratePdfFormData) => void
}
export const GeneratePdfModal: React.FC<IGeneratePdfModalProps> = ({ open, orgId, onClose, onSend }) => {
    const { t } = useTranslation()
    const maxItemsToGenerate = 200
    const readCiList = useReadCiList1Hook()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [totalIsvsCount, setTotalIsvsCount] = useState<number>(0)
    const [totalProjktCount, setTotalProjktCount] = useState<number>(0)
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const { register, handleSubmit, reset, setValue } = useForm<IGeneratePdfFormData>()

    const loadOptionsIsvs = useCallback(
        async (additional: { page: number } | undefined, searchQuery: string) => {
            const defaultFilterIsvs = {
                type: [ENTITY_ISVS],
                metaAttributes: { liableEntityByHierarchy: true, liableEntity: [orgId], state: [DRAFT] },
            }
            setIsLoading(true)
            const page = !additional?.page ? 1 : (additional?.page || 0) + 1
            const options = await readCiList({
                filter: { ...defaultFilterIsvs, fullTextSearch: searchQuery },
                page: page,
                perpage: 50,
            } as CiListFilterContainerUi)
            setTotalIsvsCount(options.pagination?.totaltems || 0)
            setIsLoading(false)
            return {
                options: options.configurationItemSet || [],
                hasMore: options.configurationItemSet?.length ? true : false,
                additional: {
                    page: page,
                },
            }
        },
        [orgId, readCiList],
    )

    const loadOptionsProjekt = useCallback(
        async (additional: { page: number } | undefined, searchQuery: string) => {
            const defaultFilterProject = {
                type: [PROJECT],
                metaAttributes: { liableEntityByHierarchy: true, liableEntity: [orgId], state: [DRAFT] },
            }
            setIsLoading(true)
            const page = !additional?.page ? 1 : (additional?.page || 0) + 1
            const options = await readCiList({
                filter: { ...defaultFilterProject, fullTextSearch: searchQuery },
                page: page,
                perpage: 50,
            } as CiListFilterContainerUi)
            setTotalProjktCount(options.pagination?.totaltems || 0)
            setIsLoading(false)

            return {
                options: options.configurationItemSet || [],
                hasMore: options.configurationItemSet?.length ? true : false,
                additional: {
                    page: page,
                },
            }
        },
        [orgId, readCiList],
    )

    const generate = (form: IGeneratePdfFormData) => {
        if ((form?.project && form?.project?.length > maxItemsToGenerate) || (form?.isvs && form?.isvs?.length > maxItemsToGenerate)) {
            setShowWarning(true)
            return
        }
        if ((!form?.project && totalProjktCount > maxItemsToGenerate) || (!form?.isvs && totalIsvsCount > maxItemsToGenerate)) {
            setShowWarning(true)
            return
        }

        setShowWarning(false)
        onSend(form)
    }

    return (
        <BaseModal isOpen={open} close={onClose}>
            <QueryFeedback loading={isLoading} withChildren>
                <form onSubmit={handleSubmit(generate)} noValidate>
                    <TextHeading size="L">{t('ciType.pdfGenerateFuture')}</TextHeading>
                    {showWarning && <TextWarning>{t('modalKris.generatePdf.tooBigToGenerate')}</TextWarning>}
                    <SelectLazyLoading<ConfigurationItemUi>
                        isMulti
                        {...register('project')}
                        label={t('modalKris.generatePdf.project')}
                        placeholder={t('modalKris.generatePdf.allProjects')}
                        loadOptions={(searchQuery, _, additional) => loadOptionsProjekt(additional, searchQuery)}
                        getOptionLabel={(item) =>
                            `${item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? ''} - ${
                                item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? ''
                            }`
                        }
                        getOptionValue={(item) => (item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string) ?? ''}
                        onChange={(val: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null) =>
                            setValue('project', val as ConfigurationItemUi[])
                        }
                    />

                    <SelectLazyLoading<ConfigurationItemUi>
                        isMulti
                        {...register('isvs')}
                        label={t('modalKris.generatePdf.isvs')}
                        placeholder={t('modalKris.generatePdf.allISVS')}
                        loadOptions={(searchQuery, _, additional) => loadOptionsIsvs(additional, searchQuery)}
                        getOptionLabel={(item) =>
                            `${item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? ''} - ${
                                item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? ''
                            }`
                        }
                        getOptionValue={(item) => (item?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string) ?? ''}
                        onChange={(val: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null) =>
                            setValue('isvs', val as ConfigurationItemUi[])
                        }
                    />

                    <ModalButtons
                        submitButtonLabel={t('ciType.pdfGenerateFuture')}
                        closeButtonLabel={t('evaluation.cancelBtn')}
                        onClose={() => {
                            reset()
                            onClose()
                        }}
                    />
                </form>
            </QueryFeedback>
        </BaseModal>
    )
}
