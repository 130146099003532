import { ATTRIBUTE_NAME, RELATION_TYPE } from '@isdd/metais-common/api'
import {
    AttributeUiValue,
    RelationshipInvalidateUi,
    RelationshipUi,
    RequestIdUi,
    useInvalidateRelationshipHook,
    useReadCiNeighboursWithAllRelsHook,
    useRecycleInvalidatedRelsHook,
    useStoreRelationship,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute, useGenerateCodeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { latinizeString, removeInvalidURLSymbols } from '@isdd/metais-common/componentHelpers/filter/feFilters'
import { INVALIDATED, MAX_NUMBER_INPUT, MAX_NUMBER_INPUT_SPLIT } from '@isdd/metais-common/constants'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { AnyObject, boolean, BooleanSchema, number, NumberSchema, object, ObjectSchema, string, StringSchema } from 'yup'

export type EvidenceObjectForm = {
    Gen_Profil_nazov?: string
    Gen_Profil_kod_metais?: string
    Gen_Profil_ref_id?: string
    agenda: string | null
    Profil_ObjektEvidencie_otvorenost_udajov?: string
    Profil_ObjektEvidencie_dovod?: string
    Profil_ObjektEvidencie_eu_ref_id?: boolean
    Profil_ObjektEvidencie_jednotny_URI?: string
    Profil_ObjektEvidencie_nazov?: string
    Profil_ObjektEvidencie_rok?: number | null
    Profil_ObjektEvidencie_cislo?: number | null
    Profil_ObjektEvidencie_paragraf?: number | null
    Profil_ObjektEvidencie_odsek?: number | null
    Profil_ObjektEvidencie_pismeno?: string | null
    Profil_ObjektEvidencie_bod?: string | null
    Profil_ObjektEvidencie_zdroj?: string | null
    Profil_ObjektEvidencie_popis?: string | null
}

export const getAttribute = (attributes: (Attribute | undefined)[], techName: string) => {
    return attributes.find((att) => att?.technicalName == techName)
}

export const useGetEvidenceObjectSchema = () => {
    const { t } = useTranslation()
    const [isEU, setIsEU] = useState(false)
    const maxLengthMessage = t('validation.maxValue') + ' ' + MAX_NUMBER_INPUT_SPLIT
    const schemaObj: Record<
        keyof EvidenceObjectForm,
        | StringSchema<string | null | undefined, AnyObject, undefined, ''>
        | BooleanSchema<boolean | null | undefined, AnyObject, undefined, ''>
        | NumberSchema<number | null | undefined, AnyObject, undefined, ''>
    > = {
        Gen_Profil_nazov: string().required(t('validation.required')),
        Gen_Profil_kod_metais: string().required(t('validation.required')),
        Gen_Profil_ref_id: string().required(t('validation.required')),
        agenda: string().nullable(),
        Profil_ObjektEvidencie_otvorenost_udajov: string().nullable(),
        Profil_ObjektEvidencie_dovod: string().nullable(),
        Profil_ObjektEvidencie_eu_ref_id: boolean().nullable(),
        Profil_ObjektEvidencie_jednotny_URI: string().nullable(),
        Profil_ObjektEvidencie_nazov: string().required(t('validation.required')),
        Profil_ObjektEvidencie_rok: number()
            .max(MAX_NUMBER_INPUT, maxLengthMessage)
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when('isRequired', (_, current) => {
                if (!isEU) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
        Profil_ObjektEvidencie_cislo: number()
            .max(MAX_NUMBER_INPUT, maxLengthMessage)
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when('isRequired', (_, current) => {
                if (!isEU) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
        Profil_ObjektEvidencie_paragraf: number()
            .max(MAX_NUMBER_INPUT, maxLengthMessage)
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when('isRequired', (_, current) => {
                if (!isEU) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
        Profil_ObjektEvidencie_odsek: number()
            .max(MAX_NUMBER_INPUT, maxLengthMessage)
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
        Profil_ObjektEvidencie_pismeno: string().nullable(),
        Profil_ObjektEvidencie_bod: string().nullable(),
        Profil_ObjektEvidencie_zdroj: string()
            .when('isRequired', (_, current) => {
                if (!isEU) {
                    return current.required(t('validation.required'))
                }
                return current
            })
            .nullable(),
        Profil_ObjektEvidencie_popis: string().nullable(),
    }

    const schema = object().shape(schemaObj) as ObjectSchema<EvidenceObjectForm>

    return {
        schema,
        setIsEU,
    }
}

type StoreObjectEvidenceRelsProps = {
    configurationItemId: string
    agendaId: string
    owner: string
    setError: Dispatch<SetStateAction<boolean>>
    onSuccess: () => void
}

const resolveRequestPromises = async (
    requestIdArray: RequestIdUi[],
    getRequestStatus: (requestId: string, onSuccess?: (() => void) | undefined, onError?: (() => void) | undefined) => Promise<void>,
) => {
    const statusPromises: Promise<void>[] = []
    requestIdArray.forEach((req) => {
        const statusProm = getRequestStatus(req?.requestId ?? '')
        statusPromises.push(statusProm)
    })
    await Promise.all(statusPromises)
}

export const useStoreObjectEvidenceRels = () => {
    const { mutateAsync: storeRel, isLoading: isStoreRelationLoading, isError: isStoreRelationError } = useStoreRelationship()
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()

    const generateRelCode = useGenerateCodeHook()
    const getRelWithCi = useReadCiNeighboursWithAllRelsHook()
    const invalidateRel = useInvalidateRelationshipHook()
    const recycleRelsHook = useRecycleInvalidatedRelsHook()

    const [isLoading, setIsLoading] = useState(false)

    const storeObjectEvidenceRels = async ({ configurationItemId, agendaId, owner, setError, onSuccess }: StoreObjectEvidenceRelsProps) => {
        setIsLoading(true)
        try {
            const relWithCiList = await getRelWithCi(configurationItemId, {
                relTypes: [RELATION_TYPE.OE_patri_Agenda],
                page: 1,
                perPage: 999,
            })

            const agendaCi = relWithCiList.ciWithRels?.find((i) => i.ci?.uuid === agendaId)
            const agendaRel = agendaCi?.rels?.find((rel) => rel.endUuid == agendaId && rel.type == RELATION_TYPE.OE_patri_Agenda)

            const promiseArray: Promise<RequestIdUi>[] = []

            const invalidateRels = (rels: RelationshipUi[] | undefined, promiseArr: Promise<RequestIdUi>[], reason: string) => {
                rels?.forEach((rel) => {
                    const param: RelationshipInvalidateUi = {
                        type: rel?.type,
                        uuid: rel?.uuid,
                        startUuid: rel?.startUuid,
                        endUuid: rel?.endUuid,
                        attributes: rel?.attributes,
                        invalidateReason: {
                            comment: reason,
                        },
                    }
                    const request = invalidateRel(param, { newState: [INVALIDATED] })
                    promiseArr.push(request)
                })
            }
            const recycleRels = (rels: RelationshipUi[] | undefined, promiseArr: Promise<RequestIdUi>[]) => {
                const requestId = recycleRelsHook({ relIdList: rels?.map((rel) => rel?.uuid ?? '') ?? [] })
                promiseArr.push(requestId)
            }

            if (agendaRel == undefined) {
                const agendaRelCode = await generateRelCode(RELATION_TYPE.OE_patri_Agenda)
                const promiseAgenda = storeRel({
                    data: {
                        type: RELATION_TYPE.OE_patri_Agenda,
                        uuid: v4(),
                        startUuid: configurationItemId,
                        endUuid: agendaId,
                        owner,
                        attributes: [
                            {
                                name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais,
                                value: agendaRelCode.code as unknown as AttributeUiValue,
                            },
                        ],
                    },
                })
                promiseArray.push(promiseAgenda)
                const otherAgendaCiRels = agendaCi?.rels?.filter((rel) => rel.endUuid != agendaId)
                invalidateRels(otherAgendaCiRels, promiseArray, 'editacia agendy')
            } else {
                if (agendaRel.metaAttributes?.state == INVALIDATED) {
                    recycleRels([agendaRel], promiseArray)
                    const otherAgendaCiRels = agendaCi?.rels?.filter((rel) => rel.uuid != agendaRel.uuid)
                    invalidateRels(otherAgendaCiRels, promiseArray, 'editacia agendy')
                }
            }

            const requestIdArray = await Promise.all(promiseArray)
            await resolveRequestPromises(requestIdArray, getRequestStatus)
            onSuccess()
        } catch {
            setError(true)
        } finally {
            setIsLoading(false)
        }
    }
    return {
        storeObjectEvidenceRels,
        isError: isRedirectError || isTooManyFetchesError || isProcessedError || isStoreRelationError,
        isLoading: isRedirectLoading || isStoreRelationLoading || isLoading,
    }
}

export const getEliUri = (
    formData: EvidenceObjectForm,
    zdrojPravnehoZakladuOptions: { value: string; label: string }[],
    withoutDefaults?: boolean,
) => {
    const getStringWithSlash = (str: string | number | null, withSlash = true) => {
        if (str) {
            return `${str}${withSlash ? '/' : ''}`
        }
        return ''
    }

    return (
        `http://data.gov.sk/id/eli/sk/` +
        (formData.Profil_ObjektEvidencie_zdroj
            ? getStringWithSlash(
                  encodeURIComponent(zdrojPravnehoZakladuOptions.find((z) => z.value === formData.Profil_ObjektEvidencie_zdroj)?.label ?? '') || '',
              )
            : '') +
        (formData.Profil_ObjektEvidencie_rok ? getStringWithSlash(formData.Profil_ObjektEvidencie_rok || (withoutDefaults ? '' : 'rok')) : '') +
        (formData.Profil_ObjektEvidencie_cislo ? getStringWithSlash(formData.Profil_ObjektEvidencie_cislo || (withoutDefaults ? '' : 'cislo')) : '') +
        (formData.Profil_ObjektEvidencie_paragraf
            ? getStringWithSlash(formData.Profil_ObjektEvidencie_paragraf || (withoutDefaults ? '' : 'paragraf'))
            : '') +
        (formData.Profil_ObjektEvidencie_odsek ? getStringWithSlash(formData.Profil_ObjektEvidencie_odsek || (withoutDefaults ? '' : 'odsek')) : '') +
        (formData.Profil_ObjektEvidencie_pismeno
            ? getStringWithSlash(formData.Profil_ObjektEvidencie_pismeno || (withoutDefaults ? '' : 'pismeno'))
            : '') +
        (formData.Profil_ObjektEvidencie_bod ? getStringWithSlash(formData.Profil_ObjektEvidencie_bod || (withoutDefaults ? '' : 'bod')) : '') +
        (formData.Gen_Profil_nazov
            ? getStringWithSlash(removeInvalidURLSymbols(latinizeString(formData.Gen_Profil_nazov ?? '', true)).replace(/\s/g, '') || 'nazov', false)
            : '')
    )
}
