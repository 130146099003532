import { ButtonLink } from '@isdd/idsk-ui-kit/button-link/ButtonLink'
import { Filter } from '@isdd/idsk-ui-kit/filter'
import { Button, Input, LoadingIndicator, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { getReadCiList1QueryKey } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ChangeIcon, CheckInACircleIcon, CrossInACircleIcon, NotificationBlackIcon } from '@isdd/metais-common/assets/images'
import { getChangeOwnerLabel, getCiDefaultMetaAttributes } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import {
    BulkPopup,
    ChangeOwnerBulkModal,
    CreateEntityButton,
    InvalidateBulkModal,
    ReInvalidateBulkModal,
} from '@isdd/metais-common/components/actions-over-table'
import { ActionsOverTable } from '@isdd/metais-common/components/actions-over-table/ActionsOverTable'
import { ExportButton } from '@isdd/metais-common/components/actions-over-table/actions-default/ExportButton'
import { ImportButton } from '@isdd/metais-common/components/actions-over-table/actions-default/ImportButton'
import styles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { DynamicFilterAttributes, ExtendedAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { DEFAULT_PAGESIZE_OPTIONS, ENTITY_KS, ENTITY_PROJECT, ENTITY_TRAINING, ENTITY_ZC, ROLES } from '@isdd/metais-common/constants'
import { IBulkActionResult, useBulkAction } from '@isdd/metais-common/hooks/useBulkAction'
import { useGetCiTypeConstraintsData } from '@isdd/metais-common/hooks/useGetCiTypeConstraintsData'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { FollowedItemItemType } from '@isdd/metais-common/api/generated/user-config-swagger'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { getSearchFields } from '@isdd/metais-common/componentHelpers/ci/filter'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { ICiListContainerView } from '@/components/containers/CiListContainer'
import { CiTable } from '@/components/ci-table/CiTable'
import { CIFilterData } from '@/pages/ci/[entityName]/entity'
import { useCiListPageHeading } from '@/componentHelpers/ci'
import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'

export type CustomFilterProps = {
    defaultFilterValues: CIFilterData
    entityName: string
    codePrefix: string
    constraintsData: (EnumType | undefined)[] | undefined
    attributes: ExtendedAttribute[] | undefined
    attributeProfiles: AttributeProfile[] | undefined
    rowSelection: RowSelection<object>
}

interface IListWrapper extends ICiListContainerView<CIFilterData> {
    isNewRelationModal?: boolean
    hideRowSelection?: boolean
    customFilter?: (props: CustomFilterProps) => React.ReactNode
}

export const ListWrapper: React.FC<IListWrapper> = ({
    defaultFilterValues,
    entityName,
    columnListData,
    handleFilterChange,
    storeUserSelectedColumns,
    resetUserSelectedColumns,
    ciTypeData,
    attributeProfiles,
    attributes,
    tableData,
    constraintsData,
    unitsData,
    pagination,
    sort,
    refetch,
    gestorsData,
    isLoading,
    isError,
    isNewRelationModal,
    rowSelection,
    hiddenButtons,
    hideRowSelection,
    customFilter,
    relationAttributes,
    hideHeader = false,
    baseHref,
    linkToNewTab,
    queryParamForCreate,
}) => {
    const { t, i18n } = useTranslation()

    const {
        isError: isCiTypeConstraintsError,
        isLoading: isCiTypeConstraintsLoading,
        uuidsToMatchedCiItemsMap,
    } = useGetCiTypeConstraintsData(ciTypeData, tableData?.configurationItemSet ?? [])
    const {
        state: { user },
    } = useAuth()
    const { errorMessage, isBulkLoading, handleInvalidate, handleReInvalidate, handleChangeOwner, handleAddToFavorite } = useBulkAction()
    const queryKey = getReadCiList1QueryKey({})
    const navigate = useNavigate()
    const location = useLocation()
    const focusTriggerRef = useRef<() => void>()

    const [showInvalidate, setShowInvalidate] = useState<boolean>(false)
    const [showReInvalidate, setShowReInvalidate] = useState<boolean>(false)
    const [showChangeOwner, setShowChangeOwner] = useState<boolean>(false)

    const [bulkActionResult, setBulkActionResult] = useState<IBulkActionResult>()

    const checkedItemList = rowSelection.list

    const queryClient = useQueryClient()
    const typeTraining = entityName === ENTITY_TRAINING
    const typeProject = entityName === ENTITY_PROJECT
    const typeZC = entityName === ENTITY_ZC
    const isUserTrainer = user?.roles?.includes(ROLES.SKOLITEL)
    const isUserAdminEgov = user?.roles?.includes(ROLES.R_EGOV)
    const isUserAdmin = user?.roles?.includes(ROLES.R_ADMIN)

    const handleCloseBulkModal = (actionResult: IBulkActionResult, closeFunction: (value: React.SetStateAction<boolean>) => void) => {
        closeFunction(false)
        queryClient.invalidateQueries([queryKey[0]])
        refetch()
        setBulkActionResult(actionResult)
        rowSelection.clearSelectedRows()
    }
    const showCreateEntityButton = useMemo(() => {
        if (typeZC) return false
        if (typeTraining) {
            return isUserTrainer ? true : false
        }
        if (typeProject) {
            return isUserAdminEgov || isUserAdmin ? true : false
        } else return true
    }, [isUserAdmin, isUserAdminEgov, isUserTrainer, typeProject, typeTraining, typeZC])

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        scrollToMutationFeedback()
    }, [bulkActionResult, scrollToMutationFeedback])

    const { getHeading } = useCiListPageHeading(ciTypeData?.name ?? '', t, ciTypeData?.technicalName)
    const ciName = getHeading()
    setDocumentTitle(getHeading())

    return (
        <QueryFeedback loading={isLoading} error={false} withChildren>
            <FlexColumnReverseWrapper>
                {!hideHeader && <TextHeading size="XL">{ciName}</TextHeading>}
                <QueryFeedback loading={false} error={isError || isCiTypeConstraintsError} errorProps={{ errorMessage: t('feedback.failedFetch') }} />
                <div ref={wrapperRef}>
                    <MutationFeedback
                        success={bulkActionResult?.isSuccess}
                        successMessage={bulkActionResult?.successMessage}
                        error={bulkActionResult?.isError}
                        errorMessage={bulkActionResult?.errorMessage}
                        onMessageClose={() => setBulkActionResult(undefined)}
                    />
                </div>
            </FlexColumnReverseWrapper>
            {customFilter &&
                customFilter({
                    entityName,
                    defaultFilterValues,
                    attributes: attributes as ExtendedAttribute[],
                    attributeProfiles,
                    constraintsData,
                    codePrefix: ciTypeData?.codePrefix ?? '',
                    rowSelection: rowSelection,
                })}
            {!customFilter && (
                <Filter<CIFilterData, ColumnsOutputDefinition>
                    defaultFilterValues={defaultFilterValues}
                    rowSelection={rowSelection}
                    form={({ register, filter, setValue, isOpen }) => {
                        return (
                            <div>
                                <Input
                                    id="name"
                                    label={t(`filter.${entityName}.name`)}
                                    placeholder={t(`filter.namePlaceholder`)}
                                    {...register('Gen_Profil_nazov')}
                                />
                                <Input
                                    id="metais-code"
                                    label={t('filter.metaisCode.label')}
                                    placeholder={ciTypeData?.codePrefix}
                                    {...register('Gen_Profil_kod_metais')}
                                />
                                <DynamicFilterAttributes
                                    setValue={setValue}
                                    defaults={defaultFilterValues}
                                    filterData={{
                                        attributeFilters: filter.attributeFilters ?? {},
                                        metaAttributeFilters: filter.metaAttributeFilters ?? {},
                                    }}
                                    ciName={ciName}
                                    entityName={entityName}
                                    attributes={attributes as ExtendedAttribute[]}
                                    attributeProfiles={attributeProfiles}
                                    constraintsData={constraintsData}
                                    isFocusable={isOpen}
                                />
                            </div>
                        )
                    }}
                />
            )}
            {!isNewRelationModal && (
                <ActionsOverTable
                    pagination={pagination}
                    metaAttributesColumnSection={getCiDefaultMetaAttributes({ t, entityName })}
                    handleFilterChange={handleFilterChange}
                    storeUserSelectedColumns={storeUserSelectedColumns}
                    resetUserSelectedColumns={resetUserSelectedColumns}
                    pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                    entityName={ciTypeData?.name ?? ''}
                    attributeProfiles={attributeProfiles ?? []}
                    attributes={attributes ?? []}
                    columnListData={columnListData}
                    ciTypeData={ciTypeData}
                    hiddenButtons={hiddenButtons}
                    relationAttributes={relationAttributes}
                    createButton={
                        showCreateEntityButton && (
                            <CreateEntityButton
                                ciTypeName={i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName}
                                onClick={() => navigate(`/ci/${entityName}/create${queryParamForCreate ?? ''}`, { state: { from: location } })}
                            />
                        )
                    }
                    publishButton={
                        entityName == ENTITY_KS && (
                            <Button
                                label={t('KSPublishHelper.button')}
                                className={styles.bottomMargin0}
                                onClick={() => navigate(RouterRoutes.KS_PUBLISH_PAGE, { state: { from: location } })}
                            />
                        )
                    }
                    importButton={
                        <ImportButton
                            checkRequestId
                            doCleanUp
                            ciType={entityName ?? ''}
                            ciTypeName={i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName}
                        />
                    }
                    exportButton={
                        <ExportButton
                            ciTypeName={i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName}
                            defaultFilterValues={defaultFilterValues}
                            checkedItemsUuids={rowSelection.ids}
                            pagination={pagination}
                            ciType={ciTypeData?.technicalName ?? ''}
                            selectedColumns={getSearchFields(columnListData, ciTypeData ?? {})}
                        />
                    }
                    selectedRowsCount={rowSelection.count}
                    bulkPopup={({ selectedRowsCount: selectedCount }) => (
                        <Tooltip
                            descriptionElement={errorMessage}
                            on={'click'}
                            position={'center center'}
                            tooltipContent={(open) => (
                                <BulkPopup
                                    limitWidthForMobile={false}
                                    checkedRowItems={selectedCount}
                                    manuallyHandledFocusAfterClose
                                    onClose={() => {
                                        if (![showInvalidate, showReInvalidate, showChangeOwner].includes(true)) {
                                            focusTriggerRef.current?.()
                                        }
                                    }}
                                    onOpen={(focusTriggerEl) => {
                                        focusTriggerRef.current = focusTriggerEl
                                    }}
                                    items={(closePopup) => [
                                        <ButtonLink
                                            key={'invalidate'}
                                            className={styles.buttonLinkWithIcon}
                                            onClick={() => {
                                                handleInvalidate(checkedItemList, () => setShowInvalidate(true), open)
                                            }}
                                            icon={CrossInACircleIcon}
                                            label={t('actionOverTable.invalidateItems')}
                                            aria={{ 'aria-haspopup': 'dialog' }}
                                        />,
                                        <ButtonLink
                                            key={'reInvalidate'}
                                            className={styles.buttonLinkWithIcon}
                                            onClick={() => {
                                                handleReInvalidate(checkedItemList, () => setShowReInvalidate(true), open)
                                            }}
                                            icon={CheckInACircleIcon}
                                            label={t('actionOverTable.validateItems')}
                                            aria={{ 'aria-haspopup': 'dialog' }}
                                        />,
                                        <ButtonLink
                                            key={'changeOwner'}
                                            className={styles.buttonLinkWithIcon}
                                            onClick={() => {
                                                handleChangeOwner(checkedItemList, () => setShowChangeOwner(true), open)
                                            }}
                                            icon={ChangeIcon}
                                            label={getChangeOwnerLabel(t, entityName)}
                                            aria={{ 'aria-haspopup': 'dialog' }}
                                        />,
                                        <ButtonLink
                                            key={'favorite'}
                                            className={styles.buttonLinkWithIcon}
                                            onClick={() => {
                                                const ids = checkedItemList.map((item) => item.uuid ?? '')
                                                handleAddToFavorite(ids, FollowedItemItemType.CI, (actionResponse) =>
                                                    handleCloseBulkModal(actionResponse, () => {
                                                        return
                                                    }),
                                                )
                                                closePopup()
                                            }}
                                            icon={NotificationBlackIcon}
                                            label={t('userProfile.notifications.table.add')}
                                        />,
                                    ]}
                                />
                            )}
                        />
                    )}
                />
            )}
            {isBulkLoading && <LoadingIndicator fullscreen />}
            <ReInvalidateBulkModal
                items={checkedItemList}
                open={showReInvalidate}
                multiple
                onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowReInvalidate)}
                onClose={() => setShowReInvalidate(false)}
            />
            <InvalidateBulkModal
                items={checkedItemList}
                open={showInvalidate}
                multiple
                onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowInvalidate)}
                onClose={() => setShowInvalidate(false)}
            />
            <ChangeOwnerBulkModal
                entityType={entityName}
                items={checkedItemList}
                open={showChangeOwner}
                multiple
                onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowChangeOwner)}
                onClose={() => setShowChangeOwner(false)}
                ciRoles={ciTypeData?.roleList ?? []}
            />
            <CiTable
                data={{ columnListData, tableData, constraintsData, unitsData, entityStructure: ciTypeData, gestorsData }}
                handleFilterChange={handleFilterChange}
                pagination={pagination}
                sort={sort}
                rowSelection={hideRowSelection ? undefined : rowSelection}
                storeUserSelectedColumns={storeUserSelectedColumns}
                isLoading={isLoading || isCiTypeConstraintsLoading}
                isError={isError || isCiTypeConstraintsError}
                uuidsToMatchedCiItemsMap={uuidsToMatchedCiItemsMap}
                entityName={entityName}
                baseHref={baseHref}
                linkToNewTab={linkToNewTab}
            />
        </QueryFeedback>
    )
}
