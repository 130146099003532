import { AccordionContainer, BaseModal, ButtonGroupRow, ButtonLink, IAccordionSection } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME, RELATION_TYPE } from '@isdd/metais-common/api'
import { CiWithRelsUi, ConfigurationItemUi, IncidentRelationshipSetUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Attribute, useGenerateCodeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { JOIN_OPERATOR } from '@isdd/metais-common/constants'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { v4 as uuidV4 } from 'uuid'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { ModalButtons } from '@isdd/metais-common/index'
import { differenceWith, find, uniq } from 'lodash'

import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'
import { AttributeInput } from '@/components/attribute-input/AttributeInput'
import { HasResetState } from '@/components/create-entity/CreateCiEntityForm'
import { findAttributeConstraint, getAttributeInputErrorMessage, getAttributeUnits } from '@/components/create-entity/createEntityHelpers'
import { SelectCiItem } from '@/components/select-ci-item/SelectCiItem'
import { IRelationshipSetState } from '@/components/views/ci/ITVSExceptions/ITVSExceptionsCreateView'
import styles from '@/components/views/new-relation/newRelationView.module.scss'
import { CiInformationAccordion } from '@/components/entities/accordion/CiInformationAccordion'

interface Props {
    ciType: string
    relationSchemaCombinedAttributes: (Attribute | undefined)[]
    methods: UseFormReturn
    hasResetState: HasResetState
    constraintsData: (EnumType | undefined)[]
    unitsData: EnumType | undefined
    relationType: string
    relationshipSetState: IRelationshipSetState
    label: string
    existingRelations: IncidentRelationshipSetUi | undefined
    defaultData?: CiWithRelsUi[]
    setRelationshipsToRemove: React.Dispatch<React.SetStateAction<CiWithRelsUi[]>>
    setObjectEvidencieRef: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const EvidenceObjectRelationSelect: React.FC<Props> = ({
    ciType,
    relationSchemaCombinedAttributes,
    methods,
    hasResetState,
    constraintsData,
    unitsData,
    relationType,
    relationshipSetState,
    label,
    existingRelations,
    defaultData,
    setRelationshipsToRemove,
    setObjectEvidencieRef: setObjectEvidancieRef,
}) => {
    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const [selectedItems, setSelectedItems] = useState<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null>(null)

    const [itemDetail, setItemDetail] = useState<ConfigurationItemUi | null | undefined>(null)

    const { ciItemData, gestorData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(itemDetail?.uuid)
    const {
        constraintsData: itemConstraintsData,
        ciTypeData: itemCiTypeData,
        unitsData: itemUnitsData,
        isLoading: isAttLoading,
        isError: isAttError,
    } = useAttributesHook(itemDetail?.type)

    const { register, clearErrors, trigger, setValue, formState, getValues } = methods

    const { relationshipSet, setRelationshipSet } = relationshipSetState

    const handleItemSelection = (items: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null) => {
        const selectedCIs = Array.isArray(items) ? items : items ? [items] : []
        setObjectEvidancieRef(selectedCIs?.at(0)?.uuid ?? undefined)
        setSelectedItems(selectedCIs)
        const reducedSet = relationshipSet.filter((rel) => rel.ciType !== ciType)
        const selectedCIsFiltred = selectedCIs.filter((ci) => !defaultData?.find((d) => d.ci?.uuid === ci?.uuid))

        setRelationshipSet([
            ...reducedSet,
            ...selectedCIsFiltred.map((ci) => ({ ciType: ciType, type: relationType, startUuid: ci?.uuid, uuid: uuidV4() })),
        ])
    }

    useEffect(() => {
        setSelectedItems((prev) =>
            uniq([...(Array.isArray(prev) ? prev : prev ? [prev] : []), ...(defaultData?.map((d) => d.ci as ConfigurationItemUi) ?? [])]),
        )
    }, [defaultData])

    const generateRelCode = useGenerateCodeHook()
    const setRelMetaisCode = async (val: ConfigurationItemUi[]) => {
        const relTechName = RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie
        const formValues = getValues()

        val.forEach(async (item) => {
            const itemRelCodeInForm = `${ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}${JOIN_OPERATOR}${item?.uuid}${JOIN_OPERATOR}RELATION`
            if (!formValues[itemRelCodeInForm]) {
                const code = await generateRelCode(relTechName)
                setValue(itemRelCodeInForm, code.code)
            }
        })
    }

    const getDefaultDataForAttribute = (uuid: string, technicalName: string): string => {
        const ciWithRel = find(defaultData, (val: CiWithRelsUi) => {
            return val.ci?.uuid === uuid
        })

        return find(ciWithRel?.rels?.[0].attributes, (attr) => attr.name === technicalName)?.value?.toString() ?? ''
    }

    const selectItemSections: IAccordionSection[] =
        selectedItems && Array.isArray(selectedItems)
            ? selectedItems.map((item: ConfigurationItemUi) => ({
                  title: item.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
                  summary: (
                      <ButtonGroupRow key={item.uuid} className={''}>
                          <ButtonLink
                              label={t('newRelation.detailButton')}
                              className={classNames(styles.buttonLink, styles.blue)}
                              onClick={(e) => {
                                  e.preventDefault()
                                  setItemDetail(item)
                              }}
                          />
                          <ButtonLink
                              type="button"
                              label={t('newRelation.deleteButton')}
                              className={classNames(styles.buttonLink, styles.red)}
                              onClick={() => {
                                  setSelectedItems((prev: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | ColumnsOutputDefinition | null) => {
                                      const newSelectedItems = Array.isArray(prev)
                                          ? prev.filter((prevItem: ConfigurationItemUi) => prevItem.uuid !== item.uuid)
                                          : prev
                                      handleItemSelection(newSelectedItems)
                                      return newSelectedItems
                                  })

                                  const defDataFound = defaultData?.find((d) => d.ci?.uuid === item.uuid)
                                  if (defDataFound) {
                                      setRelationshipsToRemove((prev: CiWithRelsUi[]) => [...prev, defDataFound])
                                  }
                              }}
                          />
                      </ButtonGroupRow>
                  ),
                  content: relationSchemaCombinedAttributes.map(
                      (attribute) =>
                          attribute?.valid &&
                          !attribute.invisible && (
                              <AttributeInput
                                  key={`${attribute?.id}+${item.uuid}`}
                                  control={methods.control}
                                  attribute={attribute ?? {}}
                                  register={register}
                                  setValue={setValue}
                                  clearErrors={clearErrors}
                                  trigger={trigger}
                                  isSubmitted={formState.isSubmitted}
                                  error={getAttributeInputErrorMessage(attribute ?? {}, formState.errors)}
                                  nameSuffix={JOIN_OPERATOR + item.uuid + JOIN_OPERATOR + 'RELATION'}
                                  hint={attribute?.description}
                                  hasResetState={hasResetState}
                                  constraints={findAttributeConstraint(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      //@ts-ignore
                                      attribute?.constraints?.map((att: AttributeConstraintEnumAllOf) => att.enumCode ?? '') ?? [],
                                      constraintsData ?? [],
                                  )}
                                  disabled={attribute.technicalName == ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}
                                  unitsData={attribute?.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                  defaultValueFromCiItem={getDefaultDataForAttribute(item.uuid ?? '', attribute.technicalName ?? '')}
                              />
                          ),
                  ),
              }))
            : []

    const showAccordion = (selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0) || (defaultData && defaultData?.length > 0)

    return (
        <>
            <SelectCiItem
                ciType={ciType}
                onChangeSelectedCiItem={async (val) => {
                    await setRelMetaisCode(Array.isArray(val) ? val : [val])
                    const removedDefault = differenceWith(
                        defaultData,
                        (Array.isArray(val) ? val : [val]) as ConfigurationItemUi[],
                        (a: CiWithRelsUi, b: ConfigurationItemUi) => {
                            return a.ci?.uuid === b?.uuid
                        },
                    )
                    setRelationshipsToRemove((prev) => [...prev, ...removedDefault])
                    handleItemSelection(val)
                }}
                onCloseModal={() => setIsOpen(false)}
                onOpenModal={() => setIsOpen(true)}
                existingRelations={existingRelations}
                label={label}
                isOpen={isOpen}
                selectedItems={selectedItems}
                isMulti={false}
            />
            {showAccordion && <AccordionContainer sections={selectItemSections} />}

            <BaseModal isOpen={itemDetail !== null} close={() => setItemDetail(null)}>
                <div className={styles.modalContent}>
                    {itemDetail && (
                        <CiInformationAccordion
                            data={{
                                ciItemData,
                                gestorData,
                                constraintsData: itemConstraintsData,
                                ciTypeData: itemCiTypeData,
                                unitsData: itemUnitsData,
                            }}
                            isError={isAttError || isCiItemError}
                            isLoading={isAttLoading || isCiItemLoading}
                        />
                    )}
                </div>
                <ModalButtons onClose={() => setItemDetail(null)} />
            </BaseModal>
        </>
    )
}
