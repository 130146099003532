import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { MOBILE_MENU_BUTTON_ID } from '../navbar-main/NavBarMain'

import { NavMenuList } from './NavMenuList'
import { closeOnEscapeKey } from './navMenuUtils'

import { NewItemButtonPopup } from '@isdd/metais-common/components/navbar/navbar-main/NewItemButtonPopup'
import { NavLogin } from '@isdd/metais-common/components/navbar/navbar-main/NavLogin'
import styles from '@isdd/metais-common/components/navbar/navbar.module.scss'
import { NavigationItem, RegistrationRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { PORTAL_URL } from '@isdd/metais-common/constants'
import { Spacer } from '@isdd/metais-common/components/spacer/Spacer'

interface INavMenu {
    id: string
    isMenuExpanded: boolean
    setIsMenuExpanded: React.Dispatch<SetStateAction<boolean>>
    navItems: NavigationItem[]
    isAdmin?: boolean
}

export const NavMenu: React.FC<INavMenu> = ({ id, isMenuExpanded, setIsMenuExpanded, navItems, isAdmin }) => {
    const { t } = useTranslation()
    const menuRef = useRef<HTMLDivElement>(null)
    const [expandedSubmenu, setExpandedSubmenu] = useState<string | null>(null)

    const {
        state: { user },
    } = useAuth()
    const location = useLocation()

    const handleClickOutside = (event: MouseEvent) => {
        if (!menuRef.current?.contains(event.target as Node) && !document.getElementById(MOBILE_MENU_BUTTON_ID)?.contains(event.target as Node)) {
            if (isMenuExpanded) {
                setIsMenuExpanded(false)
            }
            setExpandedSubmenu(null)
        }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.stopPropagation()
            setIsMenuExpanded(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuExpanded])

    return (
        <div
            ref={menuRef}
            id={id}
            onKeyDown={(event) => closeOnEscapeKey<boolean>(event, setIsMenuExpanded, false)}
            className={classNames({ 'idsk-header-web__nav': true, 'idsk-header-web__nav--mobile': !isMenuExpanded })}
        >
            <div className="govuk-width-container">
                <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full" />
                    <div className="govuk-grid-column-full">
                        <nav className="idsk-header-web__nav-bar--buttons" aria-label={t('navMenu.mainNav')}>
                            <NavMenuList
                                menuItems={navItems}
                                closeMenu={() => {
                                    setIsMenuExpanded(false)
                                    setExpandedSubmenu(null)
                                }}
                                isAdmin={isAdmin}
                                expandedSubmenu={expandedSubmenu}
                                setExpandedSubmenu={setExpandedSubmenu}
                            />
                        </nav>
                    </div>
                </div>
                <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                        <div className="idsk-header-web__main--buttons">
                            {user ? (
                                !isAdmin && <NewItemButtonPopup />
                            ) : (
                                <div className={classNames(styles.registerLink, styles.fullWidth)}>
                                    <Link
                                        onClick={() => setIsMenuExpanded(false)}
                                        className="govuk-link"
                                        to={PORTAL_URL + RegistrationRoutes.REGISTRATION}
                                        state={{ from: location }}
                                    >
                                        {t('navbar.registration')}
                                    </Link>
                                </div>
                            )}
                            <NavLogin isAdmin={isAdmin} />
                            <Spacer vertical />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
