import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import { ConfigurationItemUi, RoleParticipantUI } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useGetEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { setLangForAttribute } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import { GET_ENUM, QueryFeedback } from '@isdd/metais-common/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getOwnerLabel } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'

import { RelationAttribute } from '@/components/entities/cards/RelationAttribute'

interface ConfigurationItemHistoryDetail {
    data?: ConfigurationItemUi
    roleParticipant?: RoleParticipantUI
    isLoading: boolean
    isError: boolean
}

export const ConfigurationItemHistoryDetailView: React.FC<ConfigurationItemHistoryDetail> = ({ data, roleParticipant, isLoading, isError }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { data: sources, isLoading: isLoadingSources, isError: isErrorSources } = useGetEnum(GET_ENUM.ZDROJ)

    const metaAtributes = data?.metaAttributes
    return (
        <QueryFeedback loading={isLoading || isLoadingSources} error={isError || isErrorSources} withChildren>
            <DefinitionList>
                <RelationAttribute
                    name={t('historyTab.configurationItemView.state')}
                    value={t(`metaAttributes.state.${metaAtributes?.state ?? ''}`)}
                />
                <RelationAttribute
                    name={getOwnerLabel(t, data?.type ?? '')}
                    value={roleParticipant?.configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                    lang={setLangForAttribute({ language, technicalName: ATTRIBUTE_NAME.Gen_Profil_nazov })}
                />
                <RelationAttribute name={t('historyTab.configurationItemView.createdBy')} value={metaAtributes?.createdBy} />
                <RelationAttribute name={t('historyTab.configurationItemView.createdAt')} value={t('dateTime', { date: metaAtributes?.createdAt })} />
                <RelationAttribute name={t('historyTab.configurationItemView.lastModifiedBy')} value={metaAtributes?.lastModifiedBy} />
                <RelationAttribute
                    name={t('historyTab.configurationItemView.lastModifiedAt')}
                    value={t('dateTime', { date: metaAtributes?.lastModifiedAt })}
                />
                <RelationAttribute
                    name={t('historyTab.configurationItemView.source')}
                    value={sources?.enumItems?.find((en) => en.code === data?.attributes?.Gen_Profil_zdroj)?.value}
                />
            </DefinitionList>
        </QueryFeedback>
    )
}
