import { useGetEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { STAV_PROJEKTU, FAZA_PROJEKTU } from '@isdd/metais-common/constants'

export const useGetProjectPhaseAndStatus = () => {
    const { data: stavProjektu, isLoading: isStavLoading, isError: isStavError } = useGetEnum(STAV_PROJEKTU)
    const { data: fazaProjektu, isLoading: isFazaLoading, isError: isFazaError } = useGetEnum(FAZA_PROJEKTU)

    return {
        stavProjektu,
        fazaProjektu,
        isLoading: isStavLoading || isFazaLoading,
        isError: isStavError || isFazaError,
    }
}
