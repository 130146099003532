import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { getOwnerLabel } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { PO_PO } from '@isdd/metais-common/constants'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { ISVSCiInformationAccordion } from '@/components/entities/accordion/ISVSCiInformationAccordion'

const IsvsInformation = () => {
    const { entityId, entityName } = useGetEntityParamsFromUrl()

    const { t } = useTranslation()
    const { ciItemData, gestorData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(entityId)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(entityName)
    return (
        <ISVSCiInformationAccordion
            data={{ ciItemData, gestorData, constraintsData, ciTypeData, unitsData }}
            isError={[isCiItemError, isAttError].some((item) => item)}
            isLoading={[isCiItemLoading, isAttLoading].some((item) => item)}
            additionalBasicInformation={{
                top: (
                    <InformationGridRow
                        label={getOwnerLabel(t, entityName)}
                        tooltip={getOwnerLabel(t, entityName)}
                        value={
                            <Link to={`/ci/${PO_PO}/${gestorData?.[0]?.configurationItemUi?.uuid}`} target="_blank">
                                {gestorData?.[0]?.configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                            </Link>
                        }
                    />
                ),
            }}
        />
    )
}

export default IsvsInformation
