import { IAccordionSection, ButtonGroupRow, ButtonLink, AccordionContainer } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi, useReadCiNeighboursWithAllRels } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ColumnsOutputDefinition } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import {
    DRAFT,
    ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE,
    ENTITY_OBJEKT_EVIDENCIE,
    Referencny_register_ma_objekt_evidencie,
    Referencny_register_ma_referencny_udaj,
} from '@isdd/metais-common/constants'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import classNames from 'classnames'

import styles from './styles.module.scss'
import { EvidenceObjectAttributes } from './EvidenceObjectAttributes'

import { EvidenceObjectsRefRegForm } from '@/components/views/refregisters/createView/RefRegisterCreateView'
import { useRelationData } from '@/hooks/useRelationData.hook'
import { SelectCiItem } from '@/components/select-ci-item/SelectCiItem'

type EvidenceObjectsRefRegProps = {
    configurationItemId: string
}

export const EvidenceObjectsRefReg = ({ configurationItemId }: EvidenceObjectsRefRegProps) => {
    const {
        data: { readRelationShipsData },
    } = useRelationData({ configurationItemId })
    const { t } = useTranslation()
    const { setValue, getValues, reset } = useFormContext<EvidenceObjectsRefRegForm>()
    const {
        data: refRegCiWithRels,
        isLoading,
        fetchStatus,
    } = useReadCiNeighboursWithAllRels(
        configurationItemId,
        {
            ciTypes: [ENTITY_OBJEKT_EVIDENCIE, ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE],
            relTypes: [Referencny_register_ma_objekt_evidencie, Referencny_register_ma_referencny_udaj],
            state: [DRAFT],
        },
        { query: { enabled: !!configurationItemId } },
    )
    const areCiWithRelsLoading = isLoading && fetchStatus != 'idle'
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItems, setSelectedItems] = useState<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | ColumnsOutputDefinition | null>(null)

    useEffect(() => {
        setSelectedItems(
            refRegCiWithRels?.ciWithRels
                ?.filter((i) => i.ci?.type === ENTITY_OBJEKT_EVIDENCIE)
                ?.sort((a, b) =>
                    (a.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string).localeCompare(
                        b.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
                    ),
                )
                .map((item) => item?.ci ?? {}) ?? [],
        )
    }, [refRegCiWithRels?.ciWithRels, setValue])

    useEffect(() => {
        const updateFormValues = () => {
            const values = getValues()
            const newForm: EvidenceObjectsRefRegForm = {}
            if (Array.isArray(selectedItems) && selectedItems.length > 0) {
                selectedItems.forEach((item) => {
                    newForm[item.uuid] = values?.[item.uuid]
                })
            }
            reset(newForm)
        }
        updateFormValues()
    }, [getValues, reset, selectedItems])

    const sectionsNew: IAccordionSection[] =
        selectedItems && Array.isArray(selectedItems)
            ? selectedItems.map((item: ConfigurationItemUi, index) => {
                  const parentIndex: number = index + 1
                  return {
                      title: `${parentIndex}. ${item.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}`,
                      summary: (
                          <ButtonGroupRow key={item.uuid} className={styles.accordionButtonDiv}>
                              <ButtonLink
                                  type="button"
                                  label={t('newRelation.detailButton')}
                                  className={classNames(styles.buttonLink, styles.blue)}
                                  onClick={() => {
                                      window.open(`/ci/${ENTITY_OBJEKT_EVIDENCIE}/${item.uuid}`, '_blank')
                                  }}
                              />
                              <ButtonLink
                                  label={t('newRelation.deleteButton')}
                                  className={classNames(styles.buttonLink, styles.red)}
                                  onClick={() => {
                                      setSelectedItems(
                                          (prev: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | ColumnsOutputDefinition | null) =>
                                              Array.isArray(prev)
                                                  ? prev.filter((prevItem: ConfigurationItemUi) => prevItem.uuid !== item.uuid)
                                                  : prev,
                                      )
                                  }}
                              />
                          </ButtonGroupRow>
                      ),
                      content: (
                          <>
                              {item.uuid && (
                                  <EvidenceObjectAttributes
                                      parentIndex={parentIndex}
                                      evidenceObjectId={item.uuid}
                                      parentUuid={item.uuid}
                                      refRegDirectList={refRegCiWithRels ?? {}}
                                  />
                              )}
                          </>
                      ),
                  }
              })
            : []

    return (
        <>
            <SelectCiItem
                label={t('evidenceObject.selectLabel')}
                ciType={ENTITY_OBJEKT_EVIDENCIE}
                isOpen={isOpen}
                selectedItems={selectedItems}
                onChangeSelectedCiItem={(val) => {
                    setSelectedItems(val)
                }}
                onCloseModal={() => setIsOpen(false)}
                onOpenModal={() => setIsOpen(true)}
                perPage={200}
                existingRelations={readRelationShipsData}
            />
            {selectedItems && !areCiWithRelsLoading && Array.isArray(selectedItems) && selectedItems.length > 0 && (
                <AccordionContainer shouldNotUnMountContent sections={sectionsNew} />
            )}
        </>
    )
}
