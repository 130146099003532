import { yupResolver } from '@hookform/resolvers/yup'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import {
    BaseModal,
    ButtonLink,
    Filter,
    LoadingIndicator,
    PaginatorWrapper,
    SimpleSelect,
    Table,
    TextBody,
    TextWarning,
} from '@isdd/idsk-ui-kit/index'
import { TextHeading } from '@isdd/idsk-ui-kit/typography/TextHeading'
import { ApiCodelistItem } from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions, Subjects } from '@isdd/metais-common/hooks/permissions/useCodeListPermissions'
import {
    ActionsOverTable,
    BASE_PAGE_NUMBER,
    BASE_PAGE_SIZE,
    BulkPopup,
    CreateEntityButton,
    ModalButtons,
    MutationFeedback,
    QueryFeedback,
} from '@isdd/metais-common/index'
import { CellContext } from '@tanstack/react-table'
import { useRef, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CODE_LISTS, DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'

import { CodeListDetailItemsTable, TableCols } from './CodeListDetailItemsTable'
import { selectBasedOnLanguageAndDate } from './CodeListDetailUtils'
import { ItemFormModal } from './components/modals/ItemFormModal/ItemFormModal'
import { useSetDatesSchema } from './useCodeListSchemas'

import { CodeListItemState } from '@/componentHelpers/codeList'
import {
    CodeListDetailItemsViewProps,
    CodeListDetailFilterData,
    defaultFilterValues,
    CodeListFilterEffective,
} from '@/components/containers/CodeListDetailContainer'

export const CodeListDetailItemsWrapper: React.FC<CodeListDetailItemsViewProps> = ({
    code,
    itemsData,
    attributeProfile,
    isLoadingItemsTable,
    isLoadingItemAction,
    isErrorItemsTable,
    itemActionErrors,
    isSuccessItemActionMutation,
    workingLanguage,
    itemsFilter,
    invalidateCodeListDetailItemsCache,
    onItemsModalOpen,
    handleItemsFilterChange,
    handleMarkForPublish,
    handleSetDates,
    isCodelistAutoincrementValid,
    codelistPrefix,
    rowSelection,
    codelistId,
}) => {
    const { t } = useTranslation()

    const [isMarkForPublishDialogOpened, setIsMarkForPublishDialogOpened] = useState<boolean>(false)
    const [isSetDatesDialogOpened, setIsSetDatesDialogOpened] = useState<boolean>(false)
    const [isItemEditOpen, setIsItemEditOpen] = useState(false)
    const [editingCodeListItem, setEditingCodeListItem] = useState<ApiCodelistItem>()
    const [codeListItemSaveOrUpdateSuccess, setCodeListItemSaveOrUpdateSuccess] = useState(false)
    const [codeListItemSaveOrUpdateError, setCodeListItemSaveOrUpdateError] = useState(false)
    const [codeListItemSaveOrUpdateErrorMessage, setCodeListItemSaveOrUpdateErrorMessage] = useState<string | undefined>('')
    const focusTriggerRef = useRef<() => void>()

    const { schema } = useSetDatesSchema()

    const {
        register,
        formState,
        handleSubmit,
        control,
        setValue: setFormValue,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    })

    const handleDateChange = (date: Date | null, name: string) => {
        setFormValue(name as 'validFrom' | 'effectiveFrom', date ?? new Date())
    }

    const onHandleMarkForPublish = () => {
        handleMarkForPublish(
            rowSelection.list.map((item) => item.itemCode || ''),
            () => {
                rowSelection.clearSelectedRows()
                setIsMarkForPublishDialogOpened(false)
            },
        )
    }

    const onSetDatesSubmit = (formValues: FieldValues) => {
        const { effectiveFrom, validFrom } = formValues
        const selectedItemCodes = rowSelection.list.map((item) => item.itemCode || '')
        handleSetDates(selectedItemCodes, effectiveFrom, validFrom, () => {
            rowSelection.clearSelectedRows()
            setIsSetDatesDialogOpened(false)
        })
    }

    const handleOpenEditItem = async (item: ApiCodelistItem) => {
        onItemsModalOpen()
        setEditingCodeListItem(item)
        setIsItemEditOpen(true)
    }

    const handleOpenCreateItem = () => {
        onItemsModalOpen()
        setEditingCodeListItem(undefined)
        setIsItemEditOpen(true)
    }

    const handleCloseItemEditModal = () => {
        invalidateCodeListDetailItemsCache()
        setIsItemEditOpen(false)
    }

    const selectedItemsTable = (
        <Table
            data={rowSelection.list}
            columns={[
                {
                    id: 'name',
                    header: t('codeListDetail.table.name'),
                    meta: { getCellContext: (ctx: CellContext<TableCols, unknown>) => ctx?.getValue?.() },
                    accessorFn: (row) => selectBasedOnLanguageAndDate(row.codelistItemNames, workingLanguage),
                },
                {
                    id: 'code',
                    header: t('codeListDetail.table.code'),
                    accessorFn: (row) => row.itemCode,
                },
            ]}
        />
    )

    return (
        <QueryFeedback loading={isLoadingItemsTable} error={false} withChildren>
            <QueryFeedback error={isErrorItemsTable} loading={false} />
            <TextHeading size="L">{t('codeListDetail.title.items')}</TextHeading>
            <Filter<CodeListDetailFilterData, TableCols>
                heading={t('codeList.filter.title')}
                defaultFilterValues={defaultFilterValues}
                rowSelection={rowSelection}
                form={({ filter: formFilter, setValue, isOpen }) => (
                    <div>
                        <SimpleSelect
                            id="state"
                            name="state"
                            label={t('codeListDetail.filter.state')}
                            options={Object.values(CodeListItemState).map((state) => ({
                                value: state,
                                label: t(`codeListDetail.state.${state}`),
                            }))}
                            setValue={setValue}
                            defaultValue={formFilter.state || defaultFilterValues.state}
                            tabIndex={isOpen ? undefined : -1}
                        />
                        <SimpleSelect
                            id="effective"
                            name="effective"
                            label={t('codeListDetail.filter.effective.label')}
                            options={[
                                { value: CodeListFilterEffective.TRUE, label: t('codeListDetail.filter.effective.true') },
                                { value: CodeListFilterEffective.FALSE, label: t('codeListDetail.filter.effective.false') },
                            ]}
                            setValue={setValue}
                            defaultValue={formFilter.effective}
                            tabIndex={isOpen ? undefined : -1}
                        />
                    </div>
                )}
            />
            <ElementToScrollTo trigger={isSuccessItemActionMutation} manualScroll>
                <MutationFeedback
                    success={isSuccessItemActionMutation}
                    successMessage={t('codeListDetail.feedback.editCodeListItems')}
                    onMessageClose={() => onItemsModalOpen()}
                />
            </ElementToScrollTo>
            <ActionsOverTable
                pagination={{
                    pageNumber: itemsFilter.pageNumber || BASE_PAGE_NUMBER,
                    pageSize: itemsFilter.pageSize || BASE_PAGE_SIZE,
                    dataLength: itemsData?.codelistsItemCount || 0,
                }}
                entityName={CODE_LISTS}
                handleFilterChange={handleItemsFilterChange}
                hiddenButtons={{ SELECT_COLUMNS: true }}
                createButton={
                    <Can I={Actions.CREATE} a={Subjects.ITEM}>
                        <CreateEntityButton label={t('codeListDetail.button.addNewItem')} onClick={() => handleOpenCreateItem()} />
                    </Can>
                }
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                selectedRowsCount={rowSelection.count}
                bulkPopup={({ selectedRowsCount }) => (
                    <Can I={Actions.BULK_ACTIONS} a={Subjects.ITEM}>
                        <BulkPopup
                            checkedRowItems={selectedRowsCount}
                            manuallyHandledFocusAfterClose
                            onClose={() => {
                                if (![isMarkForPublishDialogOpened, isSetDatesDialogOpened].includes(true)) {
                                    focusTriggerRef.current?.()
                                }
                            }}
                            onOpen={(focusTriggerEl) => {
                                focusTriggerRef.current = focusTriggerEl
                            }}
                            items={() => [
                                <ButtonLink
                                    inPopup
                                    key={'markReadyForPublishing'}
                                    label={t('codeListDetail.button.markReadyForPublishingBulk')}
                                    onClick={() => {
                                        onItemsModalOpen()
                                        setIsMarkForPublishDialogOpened(true)
                                    }}
                                    aria={{ 'aria-haspopup': 'dialog' }}
                                />,
                                <ButtonLink
                                    inPopup
                                    key={'setDates'}
                                    label={t('codeListDetail.button.setDatesBulk')}
                                    onClick={() => {
                                        onItemsModalOpen()
                                        setIsSetDatesDialogOpened(true)
                                    }}
                                    aria={{ 'aria-haspopup': 'dialog' }}
                                />,
                            ]}
                        />
                    </Can>
                )}
            />
            <ElementToScrollTo trigger={codeListItemSaveOrUpdateSuccess || codeListItemSaveOrUpdateError} manualScroll>
                <MutationFeedback
                    success={codeListItemSaveOrUpdateSuccess}
                    successMessage={
                        editingCodeListItem?.id ? t('codeListDetail.feedback.editItemSuccess') : t('codeListDetail.feedback.createItemSuccess')
                    }
                    error={codeListItemSaveOrUpdateError}
                    errorMessage={codeListItemSaveOrUpdateErrorMessage ? t(codeListItemSaveOrUpdateErrorMessage) : undefined}
                />
            </ElementToScrollTo>
            {itemsData && (
                <CodeListDetailItemsTable
                    items={itemsData}
                    attributeProfile={attributeProfile}
                    filter={itemsFilter}
                    workingLanguage={workingLanguage}
                    handleOpenEditItem={handleOpenEditItem}
                    handleFilterChange={handleItemsFilterChange}
                    handleMarkForPublish={handleMarkForPublish}
                    rowSelection={rowSelection}
                />
            )}
            <PaginatorWrapper
                pageNumber={itemsFilter.pageNumber || BASE_PAGE_NUMBER}
                pageSize={itemsFilter.pageSize || BASE_PAGE_SIZE}
                dataLength={itemsData?.codelistsItemCount || 0}
                handlePageChange={handleItemsFilterChange}
            />
            <BaseModal isOpen={isSetDatesDialogOpened} close={() => setIsSetDatesDialogOpened(false)}>
                {isLoadingItemAction && <LoadingIndicator label={t('feedback.saving')} />}
                <TextHeading size="M">{t(`codeListDetail.modal.title.setDates`)}</TextHeading>
                {rowSelection.count > 0 ? (
                    <form onSubmit={handleSubmit(onSetDatesSubmit)} noValidate>
                        <TextBody>{t('codeListDetail.modal.text.willBeChanged')}</TextBody>
                        {selectedItemsTable}
                        <DateInput
                            label={t('codeListDetail.modal.form.validFrom')}
                            id="validFrom"
                            {...register(`validFrom`)}
                            error={formState.errors.validFrom?.message}
                            control={control}
                            handleDateChange={handleDateChange}
                            setValue={setFormValue}
                        />
                        <DateInput
                            label={t('codeListDetail.modal.form.effectiveFrom')}
                            id="effectiveFrom"
                            {...register(`effectiveFrom`)}
                            error={formState.errors.validFrom?.message}
                            control={control}
                            handleDateChange={handleDateChange}
                            setValue={setFormValue}
                        />
                        <ModalButtons
                            submitButtonLabel={t('codeListDetail.modal.button.confirm')}
                            onClose={() => setIsSetDatesDialogOpened(false)}
                            disabled={!watch('effectiveFrom') || !watch('validFrom')}
                        />
                    </form>
                ) : (
                    <>
                        <TextWarning>{t('codeListDetail.modal.text.nothingSelected')}</TextWarning>
                        <ModalButtons onClose={() => setIsSetDatesDialogOpened(false)} />
                    </>
                )}
                {itemActionErrors.map((error, index) => (
                    <QueryFeedback
                        key={index}
                        error
                        loading={false}
                        errorProps={{
                            errorMessage: `${error.itemCode}: ${t([`errors.${error.message}`, 'feedback.mutationErrorTitle'])}`,
                        }}
                    />
                ))}
            </BaseModal>
            <BaseModal isOpen={isMarkForPublishDialogOpened} close={() => setIsMarkForPublishDialogOpened(false)}>
                {isLoadingItemAction && <LoadingIndicator label={t('feedback.saving')} />}
                <TextHeading size="M">{t(`codeListDetail.modal.title.markForPublish`)}</TextHeading>
                {rowSelection.count > 0 ? (
                    <>
                        <TextBody>{t('codeListDetail.modal.text.willBeChanged')}</TextBody>
                        {selectedItemsTable}
                        <ModalButtons
                            submitButtonLabel={t('codeListDetail.modal.button.confirm')}
                            onSubmit={onHandleMarkForPublish}
                            onClose={() => setIsMarkForPublishDialogOpened(false)}
                        />
                    </>
                ) : (
                    <>
                        <TextWarning>{t('codeListDetail.modal.text.nothingSelected')}</TextWarning>*
                        <ModalButtons onClose={() => setIsMarkForPublishDialogOpened(false)} />
                    </>
                )}
                {itemActionErrors.map((error, index) => (
                    <QueryFeedback
                        key={index}
                        error
                        loading={false}
                        errorProps={{
                            errorMessage: `${error.itemCode}: ${t([`errors.${error.message}`, 'feedback.mutationErrorTitle'])}`,
                        }}
                    />
                ))}
            </BaseModal>
            {isItemEditOpen && (
                <ItemFormModal
                    isOpen={isItemEditOpen}
                    close={handleCloseItemEditModal}
                    codeListCode={code}
                    codelistId={codelistId}
                    item={editingCodeListItem}
                    attributeProfile={attributeProfile}
                    workingLanguage={workingLanguage}
                    defaultOrderValue={itemsData?.codelistsItemCount ?? 1}
                    isCodelistAutoincrementValid={!!isCodelistAutoincrementValid}
                    codelistPrefix={codelistPrefix}
                    setIsSuccess={setCodeListItemSaveOrUpdateSuccess}
                    setIsError={setCodeListItemSaveOrUpdateError}
                    setErrorMessage={setCodeListItemSaveOrUpdateErrorMessage}
                />
            )}
        </QueryFeedback>
    )
}
