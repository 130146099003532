import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useTranslation } from 'react-i18next'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { useEffect } from 'react'
import { CiCode } from '@isdd/metais-common/api/generated/types-repo-swagger'

import { getCiHowToBreadCrumb, useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { usePublicAuthorityAndRoleHook } from '@/hooks/usePublicAuthorityAndRole.hook'
import { EvidenceObjectAttributeCreateContainer } from '@/components/containers/evidence-object-attribute/EvidenceObjectAttributeCreateContainer'

const AtributObjektuEvidencieEditPage: React.FC = () => {
    const { t, i18n } = useTranslation()
    const { entityId, entityName } = useGetEntityParamsFromUrl()

    const { ciItemData, isError: isCiError, isLoading: isCiLoading, gestorData } = useCiHook(entityId ?? '')
    const { ciTypeData, constraintsData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(entityName ?? '')
    const ciTypeName = i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName
    const {
        groupData,
        publicAuthorityState,
        roleState,
        isError: publicAuthAndRoleError,
        isLoading: publicAuthAndRoleLoading,
    } = usePublicAuthorityAndRoleHook()

    useEffect(() => {
        publicAuthorityState.setSelectedPublicAuthority({
            poUUID: gestorData?.[0].owner,
            poName: gestorData?.[0].configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestorData, groupData])

    const currentName =
        i18n.language == Languages.SLOVAK
            ? ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]
            : ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]
    const entityIdToUpdate = {
        cicode: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_kod_metais],
        ciurl: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id],
    }

    setDocumentTitle(
        t('titles.ciEdit', {
            ci: ciTypeName,
            itemName: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
        }),
    )

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(entityName ?? '', t),
                    { label: t('titles.ciList', { ci: ciTypeName }), href: `/ci/${entityName}` },
                    { label: currentName ? (currentName as string) : t('breadcrumbs.noName'), href: `/ci/${entityName}/${entityId}` },
                    { label: t('breadcrumbs.ciEdit', { itemName: currentName }), href: `/ci/${entityName}/${entityId}/edit` },
                ]}
            />
            <MainContentWrapper>
                <CiPermissionsWrapper entityName={entityName ?? ''} entityId={ciItemData?.uuid ?? ''}>
                    <EvidenceObjectAttributeCreateContainer
                        entityName={entityName ?? ''}
                        data={{ attributesData: { ciTypeData, constraintsData, unitsData }, generatedEntityId: entityIdToUpdate as CiCode }}
                        ownerId={entityId ? gestorData?.[0].owner ?? '' : groupData?.gid ?? ''}
                        isLoading={[isAttLoading, publicAuthAndRoleLoading, isCiLoading].some((item) => item)}
                        isError={[isAttError, publicAuthAndRoleError, isCiError].some((item) => item)}
                        roleState={roleState}
                        publicAuthorityState={publicAuthorityState}
                        updateCiItemId={ciItemData?.uuid}
                        ciItemData={ciItemData}
                    />
                </CiPermissionsWrapper>
            </MainContentWrapper>
        </>
    )
}

export default AtributObjektuEvidencieEditPage
