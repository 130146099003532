import React, { ReactNode, RefObject, useEffect, useRef, useState, useId, cloneElement, ReactElement, AriaAttributes } from 'react'
import { Popup } from 'reactjs-popup'
import { PopupActions } from 'reactjs-popup/dist/types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './buttonPopup.module.scss'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { ArrowDownIcon } from '@isdd/idsk-ui-kit/assets/images'
import { Button } from '@isdd/idsk-ui-kit/button/Button'

interface IButtonPopupProps {
    popupHorizontalPosition?: 'left' | 'right' | 'center'
    popupVerticalPosition?: 'top' | 'bottom'
    buttonLabel?: string
    popupContent: (closePopup: () => void, focusTrigger: () => void) => ReactNode
    buttonClassName?: string
    contentClassNameReplacement?: string
    disabled?: boolean
    disabledTooltip?: ReactNode
    customTrigger?: ({ isExpanded }: { isExpanded: boolean }) => ReactElement
    triggerAria?: AriaAttributes
    contentStyle?: React.CSSProperties
    keepTooltipInside?: string | boolean
    manuallyHandledFocusAfterClose?: boolean
    onClose?: () => void
    onOpen?: (focusTrigger: () => void) => void
    limitWidthForMobile?: boolean
}

const focusableElementsQuery =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'

export const useTabbing = (contentRef: RefObject<HTMLElement>, active = true) => {
    // copied and modified from 'reactjs-popup/src/hooks'

    useEffect(() => {
        if (!active) return
        const listener = (event: KeyboardEvent) => {
            if (event.key === 'Tab') {
                const els = contentRef?.current?.querySelectorAll(focusableElementsQuery)
                if (!els) return

                const focusableEls = Array.prototype.slice.call(els)
                const firstFocusableEl = focusableEls[0]
                const lastFocusableEl = focusableEls[focusableEls.length - 1]

                if (event.shiftKey && document.activeElement === firstFocusableEl) {
                    event.preventDefault()
                    lastFocusableEl.focus()
                } else if (!event.shiftKey && document.activeElement === lastFocusableEl) {
                    // condition modified from old source code, `!event.shiftKey` added
                    event.preventDefault()
                    firstFocusableEl.focus()
                }
            }
        }

        document.addEventListener('keydown', listener)

        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [contentRef, active])
}

export const focusContent = (contentRef: RefObject<HTMLElement>) => {
    // copied and modified from 'reactjs-popup'
    const focusableEls = contentRef.current?.querySelectorAll(focusableElementsQuery)
    const firstEl = Array.prototype.slice.call(focusableEls)[0]
    firstEl?.focus()
}

export const ButtonPopup: React.FC<IButtonPopupProps> = ({
    buttonLabel,
    popupContent,
    disabled,
    disabledTooltip,
    popupHorizontalPosition = 'left',
    popupVerticalPosition = 'bottom',
    buttonClassName,
    contentClassNameReplacement,
    customTrigger,
    triggerAria,
    contentStyle,
    keepTooltipInside = true,
    manuallyHandledFocusAfterClose = false,
    onClose,
    onOpen,
    limitWidthForMobile = true,
}) => {
    const { t } = useTranslation()
    const popupRef = useRef<PopupActions>(null)
    const contentRef = useRef(null)
    const triggerId = useId()
    const contentId = useId()
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const label = (
        <div className={styles.buttonLabel}>
            {buttonLabel}
            <img src={ArrowDownIcon} alt="" className={styles.downArrow} />
        </div>
    )

    useTabbing(contentRef, isFocused)

    const focusTrigger = () => {
        setIsExpanded(false)
        document.getElementById(triggerId)?.focus()
    }

    // popup library was automatically focusing content, desired behavior is:
    // - trigger opens popup, focus is kept on trigger
    // - pressing TAB while popup is opened focuses content and locks TAB inside of content
    // if it does not behave like this, check if patch is applied (Windows problem)
    // -> patches/reactjs-popup+2.0.5.patch
    const handleTriggerKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.code === 'Tab' && isExpanded) {
            event.preventDefault()
            focusContent(contentRef)
            setIsFocused(true)
        }
    }

    const trigger = customTrigger ? (
        cloneElement(customTrigger({ isExpanded }), {
            id: triggerId,
            onKeyDown: handleTriggerKeyDown,
            'aria-expanded': isExpanded,
            'aria-controls': contentId,
            'aria-haspopup': true,
        })
    ) : (
        <Button
            id={triggerId}
            label={label}
            variant="secondary"
            className={classNames(buttonClassName, styles.button)}
            aria-expanded={isExpanded}
            aria-haspopup
            aria-controls={contentId}
            onKeyDown={handleTriggerKeyDown}
            {...triggerAria}
        />
    )

    if (disabled && disabledTooltip) {
        return (
            <div className={styles.fullWidth}>
                <Tooltip
                    defaultOpen={false}
                    descriptionElement={disabledTooltip}
                    triggerElement={trigger}
                    altText={t('tooltip', { tooltip: buttonLabel })}
                />
            </div>
        )
    }

    // popup library was forcing aria-describedby tag on trigger/content
    // -> we can now set aria-describedby as we need on trigger/content element
    // -> patches/reactjs-popup+2.0.5.patch
    return (
        <Popup
            trigger={trigger}
            position={`${popupVerticalPosition} ${popupHorizontalPosition}`}
            arrow={false}
            disabled={disabled}
            ref={popupRef}
            offsetY={2} //button box shadow
            onOpen={() => {
                setIsExpanded(true)
                onOpen?.(focusTrigger)
            }}
            keepTooltipInside={keepTooltipInside}
            onClose={() => {
                setIsExpanded(false)
                if (!manuallyHandledFocusAfterClose) {
                    focusTrigger()
                }
                onClose?.()
            }}
            contentStyle={contentStyle}
        >
            <div
                ref={contentRef}
                id={contentId}
                className={classNames(contentClassNameReplacement ?? styles.whiteBackground, { [styles.limitWidthForMobile]: limitWidthForMobile })}
                role="menu"
            >
                {popupContent(() => popupRef.current?.close(), focusTrigger)}
            </div>
        </Popup>
    )
}
