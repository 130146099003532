import { SimpleSelect, Table } from '@isdd/idsk-ui-kit/index'
import { RisPreviewBudgetsForEcoList, useGetRisExpenses } from '@isdd/metais-common/api/generated/tco-swagger'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { FINANCES_TYPES, FINANCE_TYPE, QueryFeedback, RIS_EXPENSES_GAP } from '@isdd/metais-common/index'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumberWithSpaces } from '@isdd/metais-common/utils/utils'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'

const ISVSFinancesPage: React.FC = () => {
    const { entityId } = useGetEntityParamsFromUrl()
    const { t } = useTranslation()

    const { ciItemData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(entityId)

    const currentYear: number = new Date().getFullYear()
    const {
        data: budgets,
        isLoading,
        isError,
    } = useGetRisExpenses({
        elements: ciItemData?.attributes?.EA_Profil_ISVS_prvok_rozpoctu as string[],
        yearFrom: currentYear - RIS_EXPENSES_GAP,
        yearTo: currentYear + RIS_EXPENSES_GAP,
    })

    const selectOptions = [
        { value: FINANCES_TYPES.REAL, label: t('isvs.finances.real') },
        { value: FINANCES_TYPES.CORRECTED, label: t('isvs.finances.corrected') },
        { value: FINANCES_TYPES.ACCEPTED, label: t('isvs.finances.accepted') },
    ]

    const [selectedType, setSelectedType] = useState<FINANCE_TYPE>(FINANCES_TYPES.ACCEPTED)

    const columns: ColumnDef<object>[] = [
        {
            header: t('isvs.finances.eko'),
            accessorKey: 'ekoCodeName',
            size: 250,
            id: 'ekoCodeName',
            cell: (ctx: CellContext<object, unknown>) =>
                ctx?.row.index != (budgets?.count ?? 0) ? <span>{ctx?.getValue?.() as string}</span> : <b>{ctx?.getValue?.() as string}</b>,
        },
        ...(budgets?.summaryPerYearList
            ?.sort((a, b) => (a.summaryBudget?.year ?? 0) - (b.summaryBudget?.year ?? 0))
            .map((value) => ({
                header: value.summaryBudget?.year + '',
                accessorKey: value.summaryBudget?.year + '',
                size: 150,
                id: value.summaryBudget?.year + '',
                cell: (ctx: CellContext<object, unknown>) =>
                    ctx?.row.index != (budgets.count ?? 0) ? (
                        <span>{formatNumberWithSpaces(ctx?.getValue?.() as string)}</span>
                    ) : (
                        <b>{formatNumberWithSpaces(ctx?.getValue?.() as string)}</b>
                    ),
            })) ?? []),
    ]

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transformData = (input: RisPreviewBudgetsForEcoList | undefined, type: FINANCE_TYPE): any[] => {
        if (!input) {
            return []
        }
        const { ecoBudgetsList, summaryPerYearList } = input
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = []

        const uniqueYears = new Set<number>()

        ecoBudgetsList?.forEach((ecoBudget) => {
            ecoBudget.annualBudgets?.forEach((budget) => uniqueYears.add(budget.year ?? 0))
        })

        summaryPerYearList?.forEach(({ summaryBudget }) => {
            uniqueYears.add(summaryBudget?.year ?? 0)
        })

        const yearsArray = Array.from(uniqueYears).sort((a, b) => a - b)

        ecoBudgetsList?.forEach((ecoBudget) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const entry: any = { ekoCodeName: `${ecoBudget.ekoCodeName} (${ecoBudget.ekoCode})` }

            yearsArray.forEach((year) => {
                entry[year] = 0
            })

            ecoBudget.annualBudgets?.forEach((budget) => {
                entry[budget.year ?? ''] = budget[type]
            })

            result.push(entry)
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const totalEntry: any = { ekoCodeName: t('isvs.finances.total') }

        yearsArray.forEach((year) => {
            totalEntry[year] = 0
        })

        summaryPerYearList?.forEach(({ summaryBudget }) => {
            totalEntry[summaryBudget?.year ?? ''] = summaryBudget?.[type]
        })

        result.push(totalEntry)

        return result
    }

    return (
        <QueryFeedback loading={isLoading || isCiItemLoading} error={isCiItemError || isError} withChildren>
            <SimpleSelect
                label={t('isvs.finances.budget')}
                name="budget"
                options={selectOptions}
                defaultValue={FINANCES_TYPES.ACCEPTED}
                value={selectedType}
                onChange={(value) => {
                    if (value) {
                        setSelectedType(value as FINANCE_TYPE)
                    }
                }}
            />
            <Table columns={columns} data={(budgets?.count ?? 0) > 0 ? transformData(budgets, selectedType) : []} />
        </QueryFeedback>
    )
}

export default ISVSFinancesPage
