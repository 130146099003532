import { AccordionContainer, CheckBox, IAccordionSection } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME, ATTRIBUTE_PROFILE_NAME } from '@isdd/metais-common/api'
import { CiWithRelsResultUi, useReadCiNeighboursWithAllRels } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { DRAFT, ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE } from '@isdd/metais-common/constants'
import React, { useEffect, useState } from 'react'
import { useFormContext, UseFormTrigger } from 'react-hook-form'
import { QueryFeedback } from '@isdd/metais-common/index'
import { useTranslation } from 'react-i18next'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'

import { EvidenceObjectsRefRegForm } from '@/components/views/refregisters/createView/RefRegisterCreateView'
import { AttributeInput } from '@/components/attribute-input/AttributeInput'
import { findAttributeConstraint, getAttributeUnits } from '@/components/create-entity/createEntityHelpers'

type Props = {
    evidenceObjectId: string
    parentIndex: number
    parentUuid: string
    refRegDirectList: CiWithRelsResultUi
}

export const EvidenceObjectAttributes: React.FC<Props> = ({ evidenceObjectId, parentIndex, parentUuid, refRegDirectList }) => {
    const { t } = useTranslation()
    const [hasReset, setHasReset] = useState(false)
    const {
        data: directList,
        isError: isDirectListError,
        isLoading: isDirectListLoading,
    } = useReadCiNeighboursWithAllRels(evidenceObjectId, {
        state: [DRAFT],
    })
    const { ciTypeData, constraintsData, unitsData, isError, isLoading } = useAttributesHook(ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE)
    const aoeRefProfile = ciTypeData?.attributeProfiles?.find((prof) => prof.technicalName == ATTRIBUTE_PROFILE_NAME.Profil_ReferencnyAtribut)

    const {
        register,
        formState: { errors, isSubmitted },
        control,
        clearErrors,
        setValue,
        trigger,
    } = useFormContext<EvidenceObjectsRefRegForm>()

    useEffect(() => {
        const objEvidenceAOERels = directList?.ciWithRels?.filter((item) => item.ci?.type === ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE)

        refRegDirectList.ciWithRels
            ?.filter((i) => i.ci?.type == ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE)
            .forEach((i) => {
                const isInObjEviAOERels = !!objEvidenceAOERels?.find((aoe) => aoe.ci?.uuid == i.ci?.uuid)
                if (isInObjEviAOERels) {
                    setValue(`${parentUuid}.aoeRels.${i.ci?.uuid}`, { attributes: i.ci?.attributes ?? {}, isChecked: true })
                }
            })
    }, [directList?.ciWithRels, parentUuid, refRegDirectList.ciWithRels, setValue])

    const sections: IAccordionSection[] =
        directList?.ciWithRels
            ?.filter((item) => item.ci?.type === ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE)
            ?.sort((a, b) =>
                (a.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string).localeCompare(
                    b.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
                ),
            )
            .map((item, index) => {
                const childIndex = index + 1

                return {
                    title: `${parentIndex}.${childIndex}. ${item.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}`,
                    content: aoeRefProfile?.attributes?.map((attribute) => {
                        return (
                            <React.Fragment key={attribute.technicalName}>
                                {!attribute.invisible && attribute.valid && (
                                    <AttributeInput
                                        control={control}
                                        trigger={
                                            trigger as UseFormTrigger<{
                                                [x: string]: unknown
                                            }>
                                        }
                                        setValue={setValue}
                                        attribute={attribute}
                                        constraints={findAttributeConstraint(
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            attribute?.constraints?.map((i: AttributeConstraintEnumAllOf) => i.enumCode ?? '') ?? [],
                                            constraintsData,
                                        )}
                                        namePrefix={`${parentUuid}.aoeRels.${item.ci?.uuid}.attributes.`}
                                        clearErrors={clearErrors}
                                        register={register}
                                        error={errors[parentUuid]?.aoeRels?.[item.ci?.uuid ?? '']?.attributes?.[attribute.technicalName ?? '']}
                                        isSubmitted={isSubmitted}
                                        unitsData={attribute.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                        defaultValueFromCiItem={item.ci?.attributes?.[attribute.technicalName ?? ''] as string}
                                        hasResetState={{ setHasReset, hasReset }}
                                    />
                                )}
                            </React.Fragment>
                        )
                    }),
                    summary: (
                        <CheckBox
                            containerClassName="govuk-checkboxes--small"
                            label={t('refRegisters.isReferentialItem')}
                            {...register(`${parentUuid}.aoeRels.${item.ci?.uuid}.isChecked`)}
                        />
                    ),
                }
            }) ?? []

    return (
        <QueryFeedback loading={isDirectListLoading || isLoading} error={isDirectListError || isError}>
            {sections && <AccordionContainer sections={sections} isSmall />}
        </QueryFeedback>
    )
}
