//@ts-nocheck
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * MetaIS CMDB
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useCmdbSwaggerClient } from '../hooks/useCmdbSwaggerClient'
import { useClientForGetRoleParticipantBulkUsingPOST } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForreadCiNeighboursUsingPOST } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForReadCiListUsingPOST } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForGetRoleParticipantUsingGET } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForReadCiNeighboursWithAllRelsUsingGET } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForReadConfigurationItemUsingGET } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForReadCiListNeighboursUsingGET } from '../hooks/useCmdbSwaggerClientWithTransform'
import { useClientForRefRegistersHistory } from '../hooks/useCmdbSwaggerClientWithTransform'
export type ReadAllCiHistoryVersionsParams = {
    page: number
    perPage: number
    action?: string[]
    lastModifiedBy?: string[]
    fromDate?: string
    toDate?: string
}

export type ReadCiHistoryVersionsParams = {
    page: number
    perPage: number
    action?: string[]
    lastModifiedBy?: string[]
    fromDate?: string
    toDate?: string
}

export type ReadCiHistoryVersionsIncidentRelsParams = {
    versionId: string
    page: number
    perPage: number
    includeCis?: boolean
    'excludeStates[]'?: string[]
}

export type ReadCiHistoryVersionParams = {
    timestamp?: string
    versionId?: string
}

export type GdprHistoryParams = {
    page: number
    perPage: number
    lastModifiedBy: string
}

export type ReadRelHistoryVersionsParams = {
    page: number
    perPage: number
}

export type ReadRelHistoryVersionParams = {
    timestamp?: string
    versionId?: string
}

export type GetMetricsParams = {
    pretty?: boolean
}

export type GetCountCiTypesByOwnerParams = {
    liableEntities?: string[]
}

export type ReadCiDerivedRelTypesParams = {
    states?: string[]
    page?: number
    perPage?: number
    'usageType.whiteList'?: string[]
    'usageType.blackList'?: string[]
}

export type ReadCiDerivedRelTypesCountParams = {
    'usageType.whiteList[]'?: string[]
    'usageType.blackList[]'?: string[]
    includeInvalidated?: boolean
}

export type ReadNeighboursConfigurationItemsParams = {
    nodeType?: string
    relationshipType?: string
    'nodeUsageType.whiteList[]'?: string[]
    'nodeUsageType.blackList[]'?: string[]
    'relationshipUsageType.whiteList[]'?: string[]
    'relationshipUsageType.blackList[]'?: string[]
    includeInvalidated?: boolean
}

export type ReadNeighboursConfigurationItemsCount200 = { [key: string]: number }

export type ReadNeighboursConfigurationItemsCountStatesItem =
    (typeof ReadNeighboursConfigurationItemsCountStatesItem)[keyof typeof ReadNeighboursConfigurationItemsCountStatesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadNeighboursConfigurationItemsCountStatesItem = {
    DRAFT: 'DRAFT',
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    APPROVED_BY_OWNER: 'APPROVED_BY_OWNER',
    INVALIDATED: 'INVALIDATED',
    DELETED: 'DELETED',
} as const

export type ReadNeighboursConfigurationItemsCountParams = {
    'usageType.whiteList[]'?: string[]
    'usageType.blackList[]'?: string[]
    includeInvalidated?: boolean
    includeDeleted?: boolean
    states?: ReadNeighboursConfigurationItemsCountStatesItem[]
}

export type ReadRelationshipsParams = {
    'usageType.whiteList[]'?: string[]
    'usageType.blackList[]'?: string[]
    'types[]'?: string[]
}

export type ReadCiList2Params = {
    page?: number
    perPage?: number
    type?: string[]
    state?: string[]
    'usageType.whiteList[]'?: string[]
    'usageType.blackList[]'?: string[]
    owner?: string
    sortBy?: string
    sortType?: string
}

export type ReadCiNeighboursWithAllRelsParams = {
    relTypes?: string[]
    ciTypes?: string[]
    page?: number
    perPage?: number
    state?: string[]
    'usageType.whiteList[]'?: string[]
    'usageType.blackList[]'?: string[]
    sortBy?: string
    sortType?: string
}

export type ReadReportsWithFilterParams = {
    fulltextSearch?: string
    reportType?: string
    category_id?: number
    sortBy?: string
    sortType?: string
    page?: number
    perPage?: number
    published?: boolean
    language?: string
}

export type CiRelTypesIntegrityCheck200 = { [key: string]: string[] }

export type GetProperties200 = { [key: string]: string }

export type ValidateCIsByTypesAndOwnerParams = {
    poUuid: string
    typeNames: string[]
    validationTypes?: string[]
}

export type InvalidateSetBody = ItemSetUi | WriteSetUi

export type InvalidateRelationshipNewStateItem = (typeof InvalidateRelationshipNewStateItem)[keyof typeof InvalidateRelationshipNewStateItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvalidateRelationshipNewStateItem = {
    DRAFT: 'DRAFT',
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    APPROVED_BY_OWNER: 'APPROVED_BY_OWNER',
    INVALIDATED: 'INVALIDATED',
    DELETED: 'DELETED',
} as const

export type InvalidateRelationshipParams = {
    newState: InvalidateRelationshipNewStateItem[]
}

export type InvalidateSendEmail200 = { [key: string]: unknown }

export type ReadListNeighboursConfigurationItems200 = { [key: string]: ConfigurationItemNeighbourSetUi }

export type ReadPoSuperiorPoRelationship200 = { [key: string]: RelationshipUi }

export type ReadConfigurationItemByRefID200 = { [key: string]: unknown }

export type ReadGroupItems200 = ItemSetUi | WriteSetUi

export type ReadMeetingRequestsFromStandardParams = {
    fulltextSearch?: string
    sortBy?: string
    ascending?: boolean
    page?: number
    perPage?: number
}

export type ReadStandardRequestsFromStandardParams = {
    fulltextSearch?: string
    sortBy?: string
    ascending?: boolean
    page?: number
    perPage?: number
}

export type ReadVotesFromStandardParams = {
    fulltextSearch?: string
    sortBy?: string
    ascending?: boolean
    page?: number
    perPage?: number
}

export type ConfluenceDocumentResultsParams = {
    spaceKey: string
    title: string
}

export type PartialReindexParams = {
    limit?: number
    timestamp: string
}

export type AsyncReindexParams = {
    perPage?: number
    ciPageFrom?: number
    relPageFrom?: number
}

export type ReindexRelationshipsParams = {
    limit?: number
    page?: number
    deleteIndex?: boolean
    isReindex?: boolean
}

export type ReadNotificationsWithFilterParams = {
    fulltextSearch?: string
    userId?: string
    eventType?: string
    sortBy?: string
    ascending?: boolean
    page?: number
    perPage?: number
    unread?: boolean
}

export interface GroupSetUi {
    groupSet?: GroupUi[]
}

export interface HistoryVersionUiConfigurationItemUi {
    actionBy?: string
    actions?: string[]
    actionTime?: string
    item?: ConfigurationItemUi
    versionId?: string
}

export interface HistoryVersionsListUiConfigurationItemUi {
    historyVersions?: HistoryVersionUiConfigurationItemUi[]
    pagination?: PaginationUi
}

export interface HistoryVersionUiRelationshipUi {
    actionBy?: string
    actions?: string[]
    actionTime?: string
    item?: RelationshipUi
    versionId?: string
}

export interface CiHistoryVersionsIncidentRelationshipsUi {
    historyVersions?: HistoryVersionUiRelationshipUi[]
    incidentCis?: HistoryVersionUiConfigurationItemUi[]
    pagination?: PaginationUi
}

export interface HistoryVersionsListUiRelationshipUi {
    historyVersions?: HistoryVersionUiRelationshipUi[]
    pagination?: PaginationUi
}

export interface PaginationData {
    page?: number
    perPage?: number
    totalItems?: number
    totalPages?: number
    totalUnreadedItems?: number
}

export interface NotificationsList {
    notifications?: Notification[]
    pagination?: PaginationData
}

export interface CountTypes {
    count?: number
    type?: string
}

export interface DerivedRelationshipCount {
    count?: number
    technicalName?: string
}

export interface DerivedCiTypeCountSummaryUi {
    derivedRelationshipCounts?: DerivedRelationshipCount[]
}

export interface IncidentRelationshipSetUi {
    endRelationshipSet?: RelationshipUi[]
    startRelationshipSet?: RelationshipUi[]
}

export interface CiWithRelsUi {
    ci?: ConfigurationItemUi
    rels?: RelationshipUi[]
}

export interface CiWithRelsResultUi {
    ciWithRels?: CiWithRelsUi[]
    pagination?: PaginationUi
}

export interface ReportHolderUi {
    pagination?: PaginationUi
    reports?: ReportUi[]
}

export interface ReportCategoryUi {
    id?: number
    name?: string
    nameEn?: string
}

export interface ReportUi {
    category?: ReportCategoryUi
    description?: string
    id?: number
    language?: string
    lastUpdatedTime?: string
    lookupKey?: string
    name?: string
    publikovany?: boolean
    reportType?: string
}

export type RequestStatusResultUiNotificationReady =
    (typeof RequestStatusResultUiNotificationReady)[keyof typeof RequestStatusResultUiNotificationReady]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestStatusResultUiNotificationReady = {
    FAILED: 'FAILED',
    READY: 'READY',
} as const

export type RequestStatusResultUiIndexReady = (typeof RequestStatusResultUiIndexReady)[keyof typeof RequestStatusResultUiIndexReady]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestStatusResultUiIndexReady = {
    FAILED: 'FAILED',
    READY: 'READY',
} as const

export type RequestStatusResultUiIamReady = (typeof RequestStatusResultUiIamReady)[keyof typeof RequestStatusResultUiIamReady]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestStatusResultUiIamReady = {
    FAILED: 'FAILED',
    READY: 'READY',
} as const

export type RequestStatusResultUiHistoryReady = (typeof RequestStatusResultUiHistoryReady)[keyof typeof RequestStatusResultUiHistoryReady]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestStatusResultUiHistoryReady = {
    FAILED: 'FAILED',
    READY: 'READY',
} as const

export interface RequestStatusResultUi {
    historyReady?: RequestStatusResultUiHistoryReady
    iamReady?: RequestStatusResultUiIamReady
    indexReady?: RequestStatusResultUiIndexReady
    notificationReady?: RequestStatusResultUiNotificationReady
    processed?: boolean
    processedAt?: string
    readyAt?: string
    status?: string
}

export interface IntegrityCheckObject {
    elasticValue?: number
    neo4jValue?: number
    type?: string
}

export interface IntegrityCheckHolder {
    configurationItems?: IntegrityCheckObject[]
    configurationItemsDifferences?: string[]
    relationships?: IntegrityCheckObject[]
    relationshipsDifferences?: string[]
}

export interface GetResponseWrapper {
    isExist?: boolean
    sourceAsString?: string
}

export type ReindexStageState = (typeof ReindexStageState)[keyof typeof ReindexStageState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReindexStageState = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
} as const

export type ReindexStageStage = (typeof ReindexStageStage)[keyof typeof ReindexStageStage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReindexStageStage = {
    CI: 'CI',
    RELATIONS: 'RELATIONS',
    PO: 'PO',
} as const

export interface ReindexStage {
    elasticIndexTotal?: number
    exception?: string
    finishedAt?: string
    lastElasticCallAt?: string
    stage?: ReindexStageStage
    startAt?: string
    state?: ReindexStageState
}

export interface ReindexTask {
    stages?: ReindexStage[]
    uuid?: string
}

export type ReindexStageSimpleDTOState = (typeof ReindexStageSimpleDTOState)[keyof typeof ReindexStageSimpleDTOState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReindexStageSimpleDTOState = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
} as const

export type ReindexStageSimpleDTOStage = (typeof ReindexStageSimpleDTOStage)[keyof typeof ReindexStageSimpleDTOStage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReindexStageSimpleDTOStage = {
    CI: 'CI',
    RELATIONS: 'RELATIONS',
    PO: 'PO',
} as const

export interface ReindexStageSimpleDTO {
    finishedAt?: string
    stage?: ReindexStageSimpleDTOStage
    startAt?: string
    state?: ReindexStageSimpleDTOState
}

export interface ReindexTaskSimpleDTO {
    stages?: ReindexStageSimpleDTO[]
    uuid?: string
}

export interface RelationshipsProblemMessageUi {
    problemType?: string
    relationsUuids?: string[]
    relOrientation?: string
    relTypeName?: string
}

export interface RelationshipsProblemHolderUi {
    lastModification?: string
    relationshipsProblemMessages?: RelationshipsProblemMessageUi[]
}

export interface MissingAttributesHolderUi {
    lastModification?: string
    missingAttributes?: string[]
}

export interface CiProblemMessageUi {
    ciUuid?: string
    missingAttributesHolder?: MissingAttributesHolderUi
    relationshipsProblemHolder?: RelationshipsProblemHolderUi
}

export interface CiProblemMessageHolderUi {
    ciProblemMessages?: CiProblemMessageUi[]
    ciTypeName?: string
}

export interface CiProblemMessageHolderListUi {
    ciProblemMessageHolders?: CiProblemMessageHolderUi[]
}

export interface ChangeHierarchyUi {
    newHierarchy?: RelationshipUi
    oldHierarchy?: RelationshipUi
}

export interface RequestId {
    id?: string
}

export interface UserFeedbackErrorReportUi {
    data?: string
    description?: string
    title?: string
}

export type FillRefAttributesTaskStatus = (typeof FillRefAttributesTaskStatus)[keyof typeof FillRefAttributesTaskStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FillRefAttributesTaskStatus = {
    CREATED: 'CREATED',
    IN_PROGRESS_PREPARE_DATA: 'IN_PROGRESS_PREPARE_DATA',
    IN_PROGRESS_UPDATE_METADATA_IN_DMS: 'IN_PROGRESS_UPDATE_METADATA_IN_DMS',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED',
} as const

export interface FillRefAttributesTask {
    endedAt?: string
    failedCount?: number
    failedCountError?: number
    failedCountInconsistent?: number
    failedCountNotFound?: number
    failedCountSoftDeleted?: number
    failedCountStateMissing?: number
    failedUuids?: DocumentInfo[]
    id?: string
    startedAt?: string
    status?: FillRefAttributesTaskStatus
    successCount?: number
    successUuids?: string[]
    taskBriefInfo?: string
    totalCount?: number
}

export type DocumentInfoState = (typeof DocumentInfoState)[keyof typeof DocumentInfoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentInfoState = {
    ERROR: 'ERROR',
    INCONSISTENT: 'INCONSISTENT',
    NOT_FOUND: 'NOT_FOUND',
    SOFT_DELETED: 'SOFT_DELETED',
    STATE_MISSING: 'STATE_MISSING',
    UPDATED: 'UPDATED',
} as const

export interface DocumentInfo {
    errorMessage?: string
    id?: string
    state?: DocumentInfoState
}

export interface ConfigurationItemInvalidateUi {
    attributes?: AttributeUi[]
    invalidateReason?: InvalidateReason
    metaAttributes?: MetaAttributesUi
    owner?: string
    type?: string
    uuid?: string
}

export interface RelationshipInvalidateUi {
    attributes?: AttributeUi[]
    endKodMetaIS?: string
    endName?: string
    endType?: string
    endTypeName?: string
    endUuid?: string
    invalidateReason?: InvalidateReason
    metaAttributes?: MetaAttributesUi
    owner?: string
    startKodMetaIS?: string
    startName?: string
    startType?: string
    startTypeName?: string
    startUuid?: string
    type?: string
    uuid?: string
}

export interface Notification {
    createdAt?: string
    deleted?: boolean
    eventType?: string
    id?: number
    message?: string
    messagePerex?: string
    notifType?: string
    readedAt?: string
    sendToUserIdSet?: string[]
    targetUri?: string
    userId?: string
}

export interface ConfigurationItemNeighbourSetUi {
    fromCiSet?: ConfigurationItemUi[]
    toCiSet?: ConfigurationItemUi[]
}

export interface CiListNeighboursFilterUi {
    ciUuids?: string[]
    includeInvalidated?: boolean
    nodeType?: string
    nodeUsageTypeBlackList?: string[]
    nodeUsageTypeWhiteList?: string[]
    relationshipType?: string
    relUsageTypeBlackList?: string[]
    relUsageTypeWhiteList?: string[]
}

export interface ConfigurationItemSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    incidentRelationshipSet?: RelationshipUi[]
    pagination?: PaginationUi
}

export interface CiListFilterScrollUi {
    filter: CiFilterUi
    getIncidentRelations?: boolean
    page: number
    relFilter?: RelFilterUi
    scrollInterval: string
    size: number
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    usageTypeFilter?: UsageTypeFilterUi
}

export interface ConfigurationItemsWithScrollIdUi {
    configurationItemSet?: ConfigurationItemUi[]
    scrollId?: string
}

export interface CiRelationshipCiPreviewHolderListUi {
    ciRelCiList?: CiRelationshipCiPreviewHolderUi[]
    pagination?: PaginationUi
}

export interface CiPreviewUi {
    genName?: string
    metaIsCode?: string
    uuid?: string
}

export interface CiRelationshipCiPreviewHolderUi {
    ciEnd?: CiPreviewUi
    ciStart?: CiPreviewUi
    rel?: RelationshipUi
}

export interface CiUuidSetUi {
    ciUuids?: string[]
    pagination?: PaginationUi
}

export interface RelTypeFilterUi {
    byHierarchy?: boolean
    onlyValidRel?: boolean
    relCiUuids?: string[]
    relType?: string
}

export interface CiFilterUi {
    attributes?: FilterAttributesUi[]
    fullTextSearch?: string
    metaAttributes?: FilterMetaAttributesUi
    mustExistAttributes?: string[]
    mustNotExistAttributes?: string[]
    poUuid?: string
    relTypeFilters?: RelTypeFilterUi[]
    searchFields?: string[]
    type?: string[]
    usageType?: string[]
    uuid?: string[]
}

export interface CiListFilterContainerUi {
    filter?: CiFilterUi
    getIncidentRelations?: boolean
    page?: number
    perpage?: number
    relFilter?: RelFilterUi
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    usageTypeFilter?: UsageTypeFilterUi
}

export interface RelationshipSetUi {
    pagination?: PaginationUi
    relationshipSet?: RelationshipUi[]
}

export interface IncidentRelationshipsFilterUi {
    ciUuids?: string[]
    page?: number
    perPage?: number
}

export type QueryResultTableUiTypes = { [key: string]: 'node' | 'rel' | 'prop' }

export type QueryResultTableUiDataItem = { [key: string]: { [key: string]: unknown } }

export interface QueryResultTableUi {
    data?: QueryResultTableUiDataItem[]
    types?: QueryResultTableUiTypes
}

export type QueryUiParams = { [key: string]: { [key: string]: unknown } }

export interface QueryUi {
    params?: QueryUiParams
    query?: string
}

export interface NeighbourSetUi {
    fromNodes?: NeighboursResultUi
    toNodes?: NeighboursResultUi
}

export interface NeighbourPairUi {
    configurationItem?: ConfigurationItemUi
    relationship?: RelationshipUi
}

export interface NeighboursResultUi {
    neighbourPairs?: NeighbourPairUi[]
    pagination?: PaginationUi
}

export interface NeighboursFilterUi {
    ciType?: string[]
    excludedCiUuids?: string[]
    excludedRelTypes?: string[]
    filterType?: string
    fullTextSearch?: string
    metaAttributes?: FilterMetaAttributesUi
    relType?: string[]
    searchFields?: string[]
    usageType?: string[]
}

export interface NeighboursFilterContainerUi {
    neighboursFilter?: NeighboursFilterUi
    page?: number
    perpage?: number
    sortBy?: string
    sortSource?: string
    sortType?: string
}

export interface RelationshipListUi {
    pagination?: PaginationUi
    relationshipList?: RelationshipUi[]
}

export interface RelListFilterContainerUi {
    getIncidentRelations?: boolean
    page?: number
    perpage?: number
    relFilter?: RelFilterUi
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    usageTypeFilter?: UsageTypeFilterUi
}

export interface UuidSetUi {
    uuids?: string[]
}

export interface RelationshipElasticPreviewUi {
    endCiName?: string
    endCiType?: string
    endCiUuid?: string
    relState?: string
    relType?: string
    relUuid?: string
    startCiName?: string
    startCiType?: string
    startCiUuid?: string
    usageType?: string
}

export interface RelationshipElasticPreviewHolderUi {
    ciRelCi?: RelationshipElasticPreviewUi[]
    pagination?: PaginationUi
}

export interface RelListFilterScrollUi {
    getIncidentRelations?: boolean
    page: number
    relFilter?: RelFilterUi
    scrollInterval: string
    size: number
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    usageTypeFilter?: UsageTypeFilterUi
}

export interface FilterValueUi {
    equality?: string
    value?: string
}

export interface FilterMetaAttributesUi {
    createdAtFrom?: string
    createdAtTo?: string
    createdBy?: string[]
    lastAction?: string[]
    lastModifiedAtFrom?: string
    lastModifiedAtTo?: string
    lastModifiedBy?: string[]
    liableEntity?: string[]
    liableEntityByHierarchy?: boolean
    owner?: string[]
    state?: string[]
}

export interface RelFilterSmallUi {
    ciTypes?: string[]
    ciUuids?: string[]
    metaAttributes?: FilterMetaAttributesUi
    page?: number
    perpage?: number
    relTypes?: string[]
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    state?: string[]
    text?: string
}

export interface FilterAttributesUi {
    filterValue?: FilterValueUi[]
    name?: string
}

export interface RelFilterUi {
    attributes?: FilterAttributesUi[]
    endCiTypeName?: string[]
    endCiUuid?: string[]
    fullTextSearch?: string
    metaAttributes?: FilterMetaAttributesUi
    mustExistAttributes?: string[]
    mustNotExistAttributes?: string[]
    poUuid?: string
    searchFields?: string[]
    startCiTypeName?: string[]
    startCiUuid?: string[]
    startOrEndCiUuid?: string[]
    type?: string[]
    usageType?: string[]
    uuid?: string[]
}

export interface RelationsWithScrollIdUi {
    relations?: RelationshipUi[]
    scrollId?: string
}

export interface CmdbItemListScrollIdUi {
    scrollId: string
    scrollInterval: string
}

export type RoleType = (typeof RoleType)[keyof typeof RoleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
    SYSTEM: 'SYSTEM',
    NON_SYSTEM: 'NON_SYSTEM',
} as const

export interface Role {
    assignedGroup?: string[]
    category?: string
    description?: string
    name?: string
    type?: RoleType
    uuid?: string
    weight?: number
}

export interface RoleParticipantUI {
    configurationItemUi?: ConfigurationItemUi
    gid?: string
    owner?: string
    role?: Role
}

export interface GidSetUi {
    gids?: string[]
}

export type StandardRequestElasticItemAllOf = {
    createdAt?: string
    createdBy?: string
    id?: number
    lastModifiedAt?: string
    name?: string
    standardRequestState?: string
}

export type StandardRequestElasticItem = ElasticItem & StandardRequestElasticItemAllOf

export type RelationshipElasticItemAllOf = {
    endName?: string
    endType?: string
    endUuid?: string
    lastModifiedAt?: string
    owner?: string
    poUuid?: string
    roleUuid?: string
    startName?: string
    startType?: string
    startUuid?: string
    state?: string
    type?: string
    typeName?: string
    uuid?: string
}

export type MeetingRequestElasticItemAllOf = {
    beginDate?: string
    createdAt?: string
    createdBy?: string
    endDate?: string
    id?: number
    lastModifiedAt?: string
    name?: string
    state?: string
}

export interface HighlightResult {
    field?: string
    highlightText?: string[]
}

export interface HighLightResultHolder {
    attributes?: HighlightResult[]
    enumAttributes?: HighlightResult[]
    metaAttributes?: HighlightResult[]
    otherAttributes?: HighlightResult[]
}

export type ElasticItemHolderElasticItemsItem =
    | ConfigurationItemElasticItem
    | DmsDocumentElasticItem
    | MeetingRequestElasticItem
    | RelationshipElasticItem
    | StandardRequestElasticItem

export interface ElasticItemHolder {
    elasticItems?: ElasticItemHolderElasticItemsItem[]
    pagination?: PaginationUi
}

export type ElasticItemElasticItemType = (typeof ElasticItemElasticItemType)[keyof typeof ElasticItemElasticItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ElasticItemElasticItemType = {
    CONFIGURATION_ITEM: 'CONFIGURATION_ITEM',
    RELATIONSHIP: 'RELATIONSHIP',
    DMS_DOCUMENT: 'DMS_DOCUMENT',
    STANDARD_REQUEST: 'STANDARD_REQUEST',
    MEETING_REQUEST: 'MEETING_REQUEST',
} as const

export interface ElasticItem {
    elasticItemType?: ElasticItemElasticItemType
    highlight?: HighLightResultHolder
}

export type RelationshipElasticItem = ElasticItem & RelationshipElasticItemAllOf

export type MeetingRequestElasticItem = ElasticItem & MeetingRequestElasticItemAllOf

export type DmsDocumentElasticItemAllOfRefType = (typeof DmsDocumentElasticItemAllOfRefType)[keyof typeof DmsDocumentElasticItemAllOfRefType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DmsDocumentElasticItemAllOfRefType = {
    CI: 'CI',
    STANDARD_REQUEST: 'STANDARD_REQUEST',
    MEETING_REQUEST: 'MEETING_REQUEST',
    VOTE: 'VOTE',
    DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',
    UNKNOWN: 'UNKNOWN',
} as const

export type DmsDocumentElasticItemAllOf = {
    contentLength?: number
    extension?: string
    fileName?: string
    lastModifiedAt?: string
    refCiId?: string
    refCiMetaisCode?: string
    refCiOwner?: string
    refCiPoUuid?: string
    refCiRoleUuid?: string
    refCiTechnicalName?: string
    refDocumentTemplateId?: number
    refMeetingRequestId?: number
    refStandardRequestId?: number
    refType?: DmsDocumentElasticItemAllOfRefType
    refVoteId?: number
    type?: string
    uuid?: string
}

export type DmsDocumentElasticItem = ElasticItem & DmsDocumentElasticItemAllOf

export type ConfigurationItemElasticItemAllOf = {
    code?: string
    lastModifiedAt?: string
    name?: string
    owner?: string
    poUuid?: string
    roleUuid?: string
    state?: string
    type?: string
    typeName?: string
    uuid?: string
}

export type ConfigurationItemElasticItem = ElasticItem & ConfigurationItemElasticItemAllOf

export type UsageTypeFilterWhiteListItem = (typeof UsageTypeFilterWhiteListItem)[keyof typeof UsageTypeFilterWhiteListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterWhiteListItem = {
    system: 'system',
    application: 'application',
} as const

export type UsageTypeFilterBlackListItem = (typeof UsageTypeFilterBlackListItem)[keyof typeof UsageTypeFilterBlackListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterBlackListItem = {
    system: 'system',
    application: 'application',
} as const

export interface UsageTypeFilter {
    blackList?: UsageTypeFilterBlackListItem[]
    filterAsPositiveStrings?: string[]
    whiteList?: UsageTypeFilterWhiteListItem[]
}

export type PortalSearchSectionsItem = (typeof PortalSearchSectionsItem)[keyof typeof PortalSearchSectionsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalSearchSectionsItem = {
    EGOV_COMPONENT: 'EGOV_COMPONENT',
    STANDARDIZATION: 'STANDARDIZATION',
    DATA_OBJECTS: 'DATA_OBJECTS',
    SLA_TCO_EKO: 'SLA_TCO_EKO',
} as const

export type PortalSearchResultTypesItem = (typeof PortalSearchResultTypesItem)[keyof typeof PortalSearchResultTypesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalSearchResultTypesItem = {
    ATTRIBUTE: 'ATTRIBUTE',
    DOCUMENT: 'DOCUMENT',
    RELATIONSHIP: 'RELATIONSHIP',
} as const

export type PortalSearchDmsDocumentExtensionsItem = (typeof PortalSearchDmsDocumentExtensionsItem)[keyof typeof PortalSearchDmsDocumentExtensionsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalSearchDmsDocumentExtensionsItem = {
    PDF: 'PDF',
    DOCX: 'DOCX',
    RTF: 'RTF',
    ODT: 'ODT',
    XLSX: 'XLSX',
    XML: 'XML',
    PPTX: 'PPTX',
    CSV: 'CSV',
} as const

export interface Pagination {
    fetchTrueCount?: boolean
    page?: number
    perPage?: number
    totalItems?: number
    totalPages?: number
}

export interface PortalSearch {
    dmsDocumentExtensions?: PortalSearchDmsDocumentExtensionsItem[]
    lastModifiedAtFrom: string
    lastModifiedAtTo: string
    pagination: Pagination
    poUuid?: string
    resultTypes: PortalSearchResultTypesItem[]
    sections: PortalSearchSectionsItem[]
    states?: string[]
    text: string
    usageTypeFilter?: UsageTypeFilter
}

export interface ConfigurationItemsListUi {
    configurationItemSet?: ConfigurationItemUi[]
}

export interface MetaIsCodesListUi {
    metaIsCodes?: string[]
}

export interface ItemSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    invalidateReason?: InvalidateReason
    relationshipSet?: RelationshipUi[]
}

export type WriteSetUi = ItemSetUi

export type UsageTypeFilterUiWhiteListItem = (typeof UsageTypeFilterUiWhiteListItem)[keyof typeof UsageTypeFilterUiWhiteListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterUiWhiteListItem = {
    system: 'system',
    application: 'application',
} as const

export type UsageTypeFilterUiBlackListItem = (typeof UsageTypeFilterUiBlackListItem)[keyof typeof UsageTypeFilterUiBlackListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterUiBlackListItem = {
    system: 'system',
    application: 'application',
} as const

export interface UsageTypeFilterUi {
    blackList?: UsageTypeFilterUiBlackListItem[]
    whiteList?: UsageTypeFilterUiWhiteListItem[]
}

export interface GroupMembersFilterUi {
    asIfInGroup?: string[]
    newComtool?: string
    nodeUsageTypeFilter?: UsageTypeFilterUi
    relationshipUsageTypeFilter?: UsageTypeFilterUi
    relTypesEndingInGroup?: string[]
    relTypesStartingInGroup?: string[]
}

export interface RecycleCisUi {
    ciIdList?: string[]
}

export interface PoWithHierarchyUi {
    hierarchy?: RelationshipUi
    po?: ConfigurationItemUi
}

export interface RecycleRelsUi {
    relIdList?: string[]
}

export interface HierarchyPOFilterUi {
    fullTextSearch?: string
    page?: number
    perpage?: number
    poUUID?: string
    rights?: HierarchyRightsUi[]
    sortBy?: string
    sortType?: string
}

export interface AddressObjectUi {
    number?: string
    street?: string
    village?: string
    zipCode?: string
}

export interface HierarchyRightsUi {
    address?: AddressObjectUi
    HIERARCHY_FROM_ROOT?: number
    path?: string
    poName?: string
    poUUID?: string
    roles?: string[]
}

export interface MeetingRequestUi {
    beginDate?: string
    createdAt?: string
    createdBy?: string
    endDate?: string
    id?: number
    lastModifiedAt?: string
    name?: string
    state?: string
}

export interface StandardRequestUi {
    createdAt?: string
    createdBy?: string
    id?: number
    lastModifiedAt?: string
    name?: string
    standardRequestState?: string
}

export interface VoteUi {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    name?: string
    voteState?: string
}

export interface PaginationUi {
    page?: number
    perPage?: number
    totalPages?: number
    totaltems?: number
}

export interface HierarchyRightsResultUi {
    pagination?: PaginationUi
    rights?: HierarchyRightsUi[]
}

export interface MeetingRequestListUi {
    meetingRequests?: MeetingRequestUi[]
    pagination?: PaginationUi
}

export interface StandardRequestListUi {
    pagination?: PaginationUi
    standardRequests?: StandardRequestUi[]
}

export interface VoteListUi {
    pagination?: PaginationUi
    votes?: VoteUi[]
}

export interface GroupUi {
    name?: string
    newComtool?: string
    uuid?: string
}

export interface StoreGroupMembersSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    doNotInvalidateIncomming?: boolean
    doNotInvalidateOutgoing?: boolean
    invalidateReason?: InvalidateReason
    newComtool?: string
    processedRelTypesEndingInGroup?: string[]
    processedRelTypesStartingInGroup?: string[]
    relationshipSet?: RelationshipUi[]
}

export interface Links {
    edit?: string
    self?: string
    tinyui?: string
    webui?: string
}

export interface Extensions {
    position?: string
}

export interface Expandable {
    ancestors?: string
    body?: string
    children?: string
    container?: string
    descendants?: string
    history?: string
    metadata?: string
    operations?: string
    restrictions?: string
    space?: string
}

export interface RequestIdUi {
    requestId?: string
}

export interface ProfilePicture {
    height?: number
    isDefault?: boolean
    path?: string
    width?: number
}

export interface Links {
    self?: string
}

export interface Links {
    self?: string
}

export interface Expandable {
    content?: string
}

export interface Expandable {
    status?: string
}

export interface By {
    _expandable?: Expandable
    _links?: Links
    displayName?: string
    profilePicture?: ProfilePicture
    type?: string
    userKey?: string
    username?: string
}

export interface Version {
    _expandable?: Expandable
    _links?: Links
    by?: By
    hidden?: boolean
    message?: string
    minorEdit?: boolean
    number?: number
    when?: string
}

export interface Result {
    _expandable?: Expandable
    _links?: Links
    extensions?: Extensions
    id?: string
    status?: string
    title?: string
    type?: string
    version?: Version
}

export type ErrorMessageUiItemType = (typeof ErrorMessageUiItemType)[keyof typeof ErrorMessageUiItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorMessageUiItemType = {
    RELATIONSHIP: 'RELATIONSHIP',
    CONFIGURATION_ITEM: 'CONFIGURATION_ITEM',
} as const

export type ErrorMessageUiErrorType = (typeof ErrorMessageUiErrorType)[keyof typeof ErrorMessageUiErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorMessageUiErrorType = {
    OWNER: 'OWNER',
    OWNER_CREATE_ROLE: 'OWNER_CREATE_ROLE',
    CREATE_CI: 'CREATE_CI',
    UPDATE_CI: 'UPDATE_CI',
    CREATE_REL: 'CREATE_REL',
    UPDATE_REL: 'UPDATE_REL',
    UPDATE_INVALIDATE_CI: 'UPDATE_INVALIDATE_CI',
    CHANGE_ATTRIBUTE: 'CHANGE_ATTRIBUTE',
    LIMIT: 'LIMIT',
    ENUM: 'ENUM',
    EXPRESION: 'EXPRESION',
    ATTRIBUTE: 'ATTRIBUTE',
    MANDATORY: 'MANDATORY',
    REGEX: 'REGEX',
    NUMBER_FORMAT: 'NUMBER_FORMAT',
    MAX_STRING_SIZE: 'MAX_STRING_SIZE',
    INVALID_TYPE: 'INVALID_TYPE',
    GROUP_RIGHT: 'GROUP_RIGHT',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    PROCESSING_ERROR: 'PROCESSING_ERROR',
} as const

export interface ErrorMessageUi {
    attributeName?: string
    errorDetail?: ErrorDetailUi
    errorType?: ErrorMessageUiErrorType
    itemId?: string
    itemType?: ErrorMessageUiItemType
}

export interface ErrorMessageHolderUi {
    messages?: ErrorMessageUi[]
}

export interface ErrorDetailUi {
    description?: string
    enumConstrain?: string
    invalidType?: string
    itemTechnicalTypeName?: string
    maxConstrain?: number
    maxStringSize?: number
    minConstrain?: number
    notAllowedExpression?: string
    notAuthorizedGid?: string
    notAuthorizedRole?: string
    notExistAttribute?: string
    notFilledAttribute?: string
    notNumber?: string
    regex?: string
}

export interface RecycleSetUi {
    ciIdSet?: string[]
    relIdSet?: string[]
}

export interface MetaAttributesUi {
    createdAt?: string
    createdBy?: string
    lastModifiedAt?: string
    lastModifiedBy?: string
    owner?: string
    state?: string
}

export interface RelationshipUi {
    attributes?: AttributeUi[]
    endKodMetaIS?: string
    endName?: string
    endType?: string
    endTypeName?: string
    endUuid?: string
    metaAttributes?: MetaAttributesUi
    owner?: string
    startKodMetaIS?: string
    startName?: string
    startType?: string
    startTypeName?: string
    startUuid?: string
    type?: string
    uuid?: string
}

export interface InvalidateReason {
    comment?: string
}

export interface StoreSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    invalidateReason?: InvalidateReason
    relationshipSet?: RelationshipUi[]
}

export interface InvalidateSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    invalidateReason?: InvalidateReason
    relationshipSet?: RelationshipUi[]
}

export interface CustomMessageUi {
    failPlaceholderValues?: string[]
    messageFailType?: string
    messageSuccessType?: string
    successPlaceholderValues?: string[]
}

export interface GraphRequestUi {
    changeOwnerSet?: ChangeOwnerSetUi
    customMessage?: CustomMessageUi
    invalidateSet?: InvalidateSetUi
    recycleSet?: RecycleSetUi
    storeSet?: StoreSetUi
}

export type ConfigurationItemUiAttributes = { [key: string]: unknown }

export interface ConfigurationItemUi {
    attributes?: ConfigurationItemUiAttributes
    metaAttributes?: MetaAttributesUi
    owner?: string
    type?: string
    uuid?: string
}

export type ChangeOwnerDataUiChangeType = (typeof ChangeOwnerDataUiChangeType)[keyof typeof ChangeOwnerDataUiChangeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeOwnerDataUiChangeType = {
    changeCmdbItemAndRelatedCmdbItems: 'changeCmdbItemAndRelatedCmdbItems',
    changeCmdbItemAndRelatedCmdbItemsWithSameOwner: 'changeCmdbItemAndRelatedCmdbItemsWithSameOwner',
    changeCmdbItem: 'changeCmdbItem',
} as const

export interface ChangeOwnerDataUi {
    changeDescription?: string
    changeReason?: string
    changeType?: ChangeOwnerDataUiChangeType
    newOwner?: string
}

export interface ChangeOwnerSetUi {
    changeOwnerData?: ChangeOwnerDataUi
    configurationItemSet?: ConfigurationItemUi[]
    invalidateReason?: InvalidateReason
    relationshipSet?: RelationshipUi[]
}

export type AttributeUiValue = { [key: string]: unknown }

export interface AttributeUi {
    name?: string
    value?: AttributeUiValue
}

export type NotificationUpdateHolderUiTypeOfAction =
    (typeof NotificationUpdateHolderUiTypeOfAction)[keyof typeof NotificationUpdateHolderUiTypeOfAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationUpdateHolderUiTypeOfAction = {
    READ: 'READ',
    REMOVE: 'REMOVE',
    REMOVE_UNREAD: 'REMOVE_UNREAD',
} as const

export interface NotificationUpdateHolderUi {
    ids?: number[]
    typeOfAction?: NotificationUpdateHolderUiTypeOfAction
    userId?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useReadNotificationsWithFilterHook = () => {
    const readNotificationsWithFilter = useCmdbSwaggerClient<NotificationsList>()

    return useCallback(
        (params?: ReadNotificationsWithFilterParams, signal?: AbortSignal) => {
            return readNotificationsWithFilter({ url: `/notification`, method: 'GET', params, signal })
        },
        [readNotificationsWithFilter],
    )
}

export const getReadNotificationsWithFilterQueryKey = (params?: ReadNotificationsWithFilterParams) => {
    return [`/notification`, ...(params ? [params] : [])] as const
}

export const useReadNotificationsWithFilterQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>,
    TError = ApiError,
>(
    params?: ReadNotificationsWithFilterParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadNotificationsWithFilterQueryKey(params)

    const readNotificationsWithFilter = useReadNotificationsWithFilterHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>> = ({ signal }) =>
        readNotificationsWithFilter(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadNotificationsWithFilterQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>>
export type ReadNotificationsWithFilterQueryError = ApiError

export const useReadNotificationsWithFilter = <TData = Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>, TError = ApiError>(
    params?: ReadNotificationsWithFilterParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadNotificationsWithFilterHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadNotificationsWithFilterQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateNotificationsHook = () => {
    const updateNotifications = useCmdbSwaggerClient<void>()

    return useCallback(
        (notificationUpdateHolderUi: NotificationUpdateHolderUi) => {
            return updateNotifications({
                url: `/notification`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: notificationUpdateHolderUi,
            })
        },
        [updateNotifications],
    )
}

export const useUpdateNotificationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateNotificationsHook>>>,
        TError,
        { data: NotificationUpdateHolderUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateNotificationsHook>>>,
    TError,
    { data: NotificationUpdateHolderUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateNotifications = useUpdateNotificationsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateNotificationsHook>>>, { data: NotificationUpdateHolderUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return updateNotifications(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateNotificationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateNotificationsHook>>>>
export type UpdateNotificationsMutationBody = NotificationUpdateHolderUi
export type UpdateNotificationsMutationError = ApiError

export const useUpdateNotifications = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateNotificationsHook>>>,
        TError,
        { data: NotificationUpdateHolderUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateNotificationsHook>>>, TError, { data: NotificationUpdateHolderUi }, TContext> => {
    const mutationOptions = useUpdateNotificationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNotificationsHook = () => {
    const storeNotifications = useCmdbSwaggerClient<boolean>()

    return useCallback(
        (notification: Notification[]) => {
            return storeNotifications({ url: `/notification`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: notification })
        },
        [storeNotifications],
    )
}

export const useStoreNotificationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNotificationsHook>>>, TError, { data: Notification[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNotificationsHook>>>, TError, { data: Notification[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNotifications = useStoreNotificationsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNotificationsHook>>>, { data: Notification[] }> = (props) => {
        const { data } = props ?? {}

        return storeNotifications(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNotificationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNotificationsHook>>>>
export type StoreNotificationsMutationBody = Notification[]
export type StoreNotificationsMutationError = ApiError

export const useStoreNotifications = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNotificationsHook>>>, TError, { data: Notification[] }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreNotificationsHook>>>, TError, { data: Notification[] }, TContext> => {
    const mutationOptions = useStoreNotificationsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary validateStoreGraph
 */
export const useValidateStoreGraphHook = () => {
    const validateStoreGraph = useCmdbSwaggerClient<ErrorMessageHolderUi>()

    return useCallback(
        (graphRequestUi: GraphRequestUi) => {
            return validateStoreGraph({
                url: `/validate/graph`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: graphRequestUi,
            })
        },
        [validateStoreGraph],
    )
}

export const useValidateStoreGraphMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidateStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidateStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const validateStoreGraph = useValidateStoreGraphHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useValidateStoreGraphHook>>>, { data: GraphRequestUi }> = (props) => {
        const { data } = props ?? {}

        return validateStoreGraph(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidateStoreGraphMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidateStoreGraphHook>>>>
export type ValidateStoreGraphMutationBody = GraphRequestUi
export type ValidateStoreGraphMutationError = ApiError

/**
 * @summary validateStoreGraph
 */
export const useValidateStoreGraph = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidateStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useValidateStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const mutationOptions = useValidateStoreGraphMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateConfluenceHook = () => {
    const updateConfluence = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (docUuid: string, version: Version) => {
            return updateConfluence({
                url: `/util/update/docConfluence/${docUuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: version,
            })
        },
        [updateConfluence],
    )
}

export const useUpdateConfluenceMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateConfluenceHook>>>,
        TError,
        { docUuid: string; data: Version },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateConfluenceHook>>>, TError, { docUuid: string; data: Version }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateConfluence = useUpdateConfluenceHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateConfluenceHook>>>, { docUuid: string; data: Version }> = (
        props,
    ) => {
        const { docUuid, data } = props ?? {}

        return updateConfluence(docUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateConfluenceMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateConfluenceHook>>>>
export type UpdateConfluenceMutationBody = Version
export type UpdateConfluenceMutationError = ApiError

export const useUpdateConfluence = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateConfluenceHook>>>,
        TError,
        { docUuid: string; data: Version },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateConfluenceHook>>>, TError, { docUuid: string; data: Version }, TContext> => {
    const mutationOptions = useUpdateConfluenceMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexRelationshipsHook = () => {
    const reindexRelationships = useCmdbSwaggerClient<string>()

    return useCallback(
        (params?: ReindexRelationshipsParams) => {
            return reindexRelationships({ url: `/util/reindexrelationships`, method: 'POST', params })
        },
        [reindexRelationships],
    )
}

export const useReindexRelationshipsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReindexRelationshipsHook>>>,
        TError,
        { params?: ReindexRelationshipsParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReindexRelationshipsHook>>>,
    TError,
    { params?: ReindexRelationshipsParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindexRelationships = useReindexRelationshipsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useReindexRelationshipsHook>>>,
        { params?: ReindexRelationshipsParams }
    > = (props) => {
        const { params } = props ?? {}

        return reindexRelationships(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexRelationshipsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipsHook>>>>

export type ReindexRelationshipsMutationError = ApiError

export const useReindexRelationships = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReindexRelationshipsHook>>>,
        TError,
        { params?: ReindexRelationshipsParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useReindexRelationshipsHook>>>,
    TError,
    { params?: ReindexRelationshipsParams },
    TContext
> => {
    const mutationOptions = useReindexRelationshipsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexRelationshipHook = () => {
    const reindexRelationship = useCmdbSwaggerClient<void>()

    return useCallback(
        (relationshipUuid: string) => {
            return reindexRelationship({ url: `/util/reindexrelationship/${relationshipUuid}`, method: 'POST' })
        },
        [reindexRelationship],
    )
}

export const useReindexRelationshipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipHook>>>, TError, { relationshipUuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipHook>>>, TError, { relationshipUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindexRelationship = useReindexRelationshipHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipHook>>>, { relationshipUuid: string }> = (
        props,
    ) => {
        const { relationshipUuid } = props ?? {}

        return reindexRelationship(relationshipUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexRelationshipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipHook>>>>

export type ReindexRelationshipMutationError = ApiError

export const useReindexRelationship = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipHook>>>, TError, { relationshipUuid: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindexRelationshipHook>>>, TError, { relationshipUuid: string }, TContext> => {
    const mutationOptions = useReindexRelationshipMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAsyncReindexHook = () => {
    const asyncReindex = useCmdbSwaggerClient<string>()

    return useCallback(
        (params?: AsyncReindexParams) => {
            return asyncReindex({ url: `/util/reindexelastic`, method: 'POST', params })
        },
        [asyncReindex],
    )
}

export const useAsyncReindexMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAsyncReindexHook>>>, TError, { params?: AsyncReindexParams }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAsyncReindexHook>>>, TError, { params?: AsyncReindexParams }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const asyncReindex = useAsyncReindexHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAsyncReindexHook>>>, { params?: AsyncReindexParams }> = (props) => {
        const { params } = props ?? {}

        return asyncReindex(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type AsyncReindexMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAsyncReindexHook>>>>

export type AsyncReindexMutationError = ApiError

export const useAsyncReindex = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAsyncReindexHook>>>, TError, { params?: AsyncReindexParams }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useAsyncReindexHook>>>, TError, { params?: AsyncReindexParams }, TContext> => {
    const mutationOptions = useAsyncReindexMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexCiHook = () => {
    const reindexCi = useCmdbSwaggerClient<void>()

    return useCallback(
        (ciUuid: string) => {
            return reindexCi({ url: `/util/reindexci/${ciUuid}`, method: 'POST' })
        },
        [reindexCi],
    )
}

export const useReindexCiMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexCiHook>>>, TError, { ciUuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexCiHook>>>, TError, { ciUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindexCi = useReindexCiHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindexCiHook>>>, { ciUuid: string }> = (props) => {
        const { ciUuid } = props ?? {}

        return reindexCi(ciUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexCiMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexCiHook>>>>

export type ReindexCiMutationError = ApiError

export const useReindexCi = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexCiHook>>>, TError, { ciUuid: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindexCiHook>>>, TError, { ciUuid: string }, TContext> => {
    const mutationOptions = useReindexCiMutationOptions(options)

    return useMutation(mutationOptions)
}

export const usePartialReindexHook = () => {
    const partialReindex = useCmdbSwaggerClient<string>()

    return useCallback(
        (params: PartialReindexParams) => {
            return partialReindex({ url: `/util/indexelastic`, method: 'POST', params })
        },
        [partialReindex],
    )
}

export const usePartialReindexMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialReindexHook>>>, TError, { params: PartialReindexParams }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialReindexHook>>>, TError, { params: PartialReindexParams }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const partialReindex = usePartialReindexHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePartialReindexHook>>>, { params: PartialReindexParams }> = (props) => {
        const { params } = props ?? {}

        return partialReindex(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type PartialReindexMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePartialReindexHook>>>>

export type PartialReindexMutationError = ApiError

export const usePartialReindex = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePartialReindexHook>>>, TError, { params: PartialReindexParams }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof usePartialReindexHook>>>, TError, { params: PartialReindexParams }, TContext> => {
    const mutationOptions = usePartialReindexMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexHierarchyHook = () => {
    const reindexHierarchy = useCmdbSwaggerClient<string>()

    return useCallback(() => {
        return reindexHierarchy({ url: `/util/hierarchypo`, method: 'POST' })
    }, [reindexHierarchy])
}

export const useReindexHierarchyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHierarchyHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHierarchyHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindexHierarchy = useReindexHierarchyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindexHierarchyHook>>>, void> = () => {
        return reindexHierarchy()
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexHierarchyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexHierarchyHook>>>>

export type ReindexHierarchyMutationError = ApiError

export const useReindexHierarchy = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHierarchyHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindexHierarchyHook>>>, TError, void, TContext> => {
    const mutationOptions = useReindexHierarchyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary confluenceDocumentResults
 */
export const useConfluenceDocumentResultsHook = () => {
    const confluenceDocumentResults = useCmdbSwaggerClient<Result>()

    return useCallback(
        (params: ConfluenceDocumentResultsParams) => {
            return confluenceDocumentResults({ url: `/util/conf`, method: 'POST', params })
        },
        [confluenceDocumentResults],
    )
}

export const useConfluenceDocumentResultsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useConfluenceDocumentResultsHook>>>,
        TError,
        { params: ConfluenceDocumentResultsParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConfluenceDocumentResultsHook>>>,
    TError,
    { params: ConfluenceDocumentResultsParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const confluenceDocumentResults = useConfluenceDocumentResultsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useConfluenceDocumentResultsHook>>>,
        { params: ConfluenceDocumentResultsParams }
    > = (props) => {
        const { params } = props ?? {}

        return confluenceDocumentResults(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ConfluenceDocumentResultsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useConfluenceDocumentResultsHook>>>>

export type ConfluenceDocumentResultsMutationError = ApiError

/**
 * @summary confluenceDocumentResults
 */
export const useConfluenceDocumentResults = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useConfluenceDocumentResultsHook>>>,
        TError,
        { params: ConfluenceDocumentResultsParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useConfluenceDocumentResultsHook>>>,
    TError,
    { params: ConfluenceDocumentResultsParams },
    TContext
> => {
    const mutationOptions = useConfluenceDocumentResultsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreGroupItemsHook = () => {
    const storeGroupItems = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (uuid: string, storeGroupMembersSetUi: StoreGroupMembersSetUi) => {
            return storeGroupItems({
                url: `/storemembers/group/${uuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: storeGroupMembersSetUi,
            })
        },
        [storeGroupItems],
    )
}

export const useStoreGroupItemsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreGroupItemsHook>>>,
        TError,
        { uuid: string; data: StoreGroupMembersSetUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreGroupItemsHook>>>,
    TError,
    { uuid: string; data: StoreGroupMembersSetUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeGroupItems = useStoreGroupItemsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreGroupItemsHook>>>,
        { uuid: string; data: StoreGroupMembersSetUi }
    > = (props) => {
        const { uuid, data } = props ?? {}

        return storeGroupItems(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreGroupItemsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreGroupItemsHook>>>>
export type StoreGroupItemsMutationBody = StoreGroupMembersSetUi
export type StoreGroupItemsMutationError = ApiError

export const useStoreGroupItems = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreGroupItemsHook>>>,
        TError,
        { uuid: string; data: StoreGroupMembersSetUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreGroupItemsHook>>>,
    TError,
    { uuid: string; data: StoreGroupMembersSetUi },
    TContext
> => {
    const mutationOptions = useStoreGroupItemsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreRelationshipHook = () => {
    const storeRelationship = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (relationshipUi: RelationshipUi) => {
            return storeRelationship({
                url: `/store/relation`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipUi,
            })
        },
        [storeRelationship],
    )
}

export const useStoreRelationshipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRelationshipHook>>>, TError, { data: RelationshipUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRelationshipHook>>>, TError, { data: RelationshipUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeRelationship = useStoreRelationshipHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreRelationshipHook>>>, { data: RelationshipUi }> = (props) => {
        const { data } = props ?? {}

        return storeRelationship(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreRelationshipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreRelationshipHook>>>>
export type StoreRelationshipMutationBody = RelationshipUi
export type StoreRelationshipMutationError = ApiError

export const useStoreRelationship = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRelationshipHook>>>, TError, { data: RelationshipUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreRelationshipHook>>>, TError, { data: RelationshipUi }, TContext> => {
    const mutationOptions = useStoreRelationshipMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreGroupHook = () => {
    const storeGroup = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (groupUi: GroupUi) => {
            return storeGroup({ url: `/store/group`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: groupUi })
        },
        [storeGroup],
    )
}

export const useStoreGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGroupHook>>>, TError, { data: GroupUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGroupHook>>>, TError, { data: GroupUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeGroup = useStoreGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreGroupHook>>>, { data: GroupUi }> = (props) => {
        const { data } = props ?? {}

        return storeGroup(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreGroupHook>>>>
export type StoreGroupMutationBody = GroupUi
export type StoreGroupMutationError = ApiError

export const useStoreGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGroupHook>>>, TError, { data: GroupUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreGroupHook>>>, TError, { data: GroupUi }, TContext> => {
    const mutationOptions = useStoreGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveManagerFromGroupHook = () => {
    const removeManagerFromGroup = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (group: string, uuid: string) => {
            return removeManagerFromGroup({ url: `/store/group/${group}/remove/${uuid}`, method: 'POST' })
        },
        [removeManagerFromGroup],
    )
}

export const useRemoveManagerFromGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveManagerFromGroupHook>>>,
        TError,
        { group: string; uuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveManagerFromGroupHook>>>, TError, { group: string; uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeManagerFromGroup = useRemoveManagerFromGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveManagerFromGroupHook>>>, { group: string; uuid: string }> = (
        props,
    ) => {
        const { group, uuid } = props ?? {}

        return removeManagerFromGroup(group, uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveManagerFromGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveManagerFromGroupHook>>>>

export type RemoveManagerFromGroupMutationError = ApiError

export const useRemoveManagerFromGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveManagerFromGroupHook>>>,
        TError,
        { group: string; uuid: string },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRemoveManagerFromGroupHook>>>, TError, { group: string; uuid: string }, TContext> => {
    const mutationOptions = useRemoveManagerFromGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreManagerToGroupHook = () => {
    const storeManagerToGroup = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (group: string, uuid: string) => {
            return storeManagerToGroup({ url: `/store/group/${group}/add/${uuid}`, method: 'POST' })
        },
        [storeManagerToGroup],
    )
}

export const useStoreManagerToGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreManagerToGroupHook>>>,
        TError,
        { group: string; uuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreManagerToGroupHook>>>, TError, { group: string; uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeManagerToGroup = useStoreManagerToGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreManagerToGroupHook>>>, { group: string; uuid: string }> = (
        props,
    ) => {
        const { group, uuid } = props ?? {}

        return storeManagerToGroup(group, uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreManagerToGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreManagerToGroupHook>>>>

export type StoreManagerToGroupMutationError = ApiError

export const useStoreManagerToGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreManagerToGroupHook>>>,
        TError,
        { group: string; uuid: string },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreManagerToGroupHook>>>, TError, { group: string; uuid: string }, TContext> => {
    const mutationOptions = useStoreManagerToGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreGraphHook = () => {
    const storeGraph = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (graphRequestUi: GraphRequestUi) => {
            return storeGraph({ url: `/store/graph`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: graphRequestUi })
        },
        [storeGraph],
    )
}

export const useStoreGraphMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeGraph = useStoreGraphHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreGraphHook>>>, { data: GraphRequestUi }> = (props) => {
        const { data } = props ?? {}

        return storeGraph(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreGraphMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreGraphHook>>>>
export type StoreGraphMutationBody = GraphRequestUi
export type StoreGraphMutationError = ApiError

export const useStoreGraph = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreGraphHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const mutationOptions = useStoreGraphMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreGraphBiznisHook = () => {
    const storeGraphBiznis = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (graphRequestUi: GraphRequestUi) => {
            return storeGraphBiznis({
                url: `/store/graph/biznis`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: graphRequestUi,
            })
        },
        [storeGraphBiznis],
    )
}

export const useStoreGraphBiznisMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGraphBiznisHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGraphBiznisHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeGraphBiznis = useStoreGraphBiznisHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreGraphBiznisHook>>>, { data: GraphRequestUi }> = (props) => {
        const { data } = props ?? {}

        return storeGraphBiznis(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreGraphBiznisMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreGraphBiznisHook>>>>
export type StoreGraphBiznisMutationBody = GraphRequestUi
export type StoreGraphBiznisMutationError = ApiError

export const useStoreGraphBiznis = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreGraphBiznisHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreGraphBiznisHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const mutationOptions = useStoreGraphBiznisMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreConfigurationItemHook = () => {
    const storeConfigurationItem = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (configurationItemUi: ConfigurationItemUi) => {
            return storeConfigurationItem({
                url: `/store/ci`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: configurationItemUi,
            })
        },
        [storeConfigurationItem],
    )
}

export const useStoreConfigurationItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemHook>>>,
        TError,
        { data: ConfigurationItemUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemHook>>>, TError, { data: ConfigurationItemUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeConfigurationItem = useStoreConfigurationItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemHook>>>, { data: ConfigurationItemUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeConfigurationItem(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreConfigurationItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemHook>>>>
export type StoreConfigurationItemMutationBody = ConfigurationItemUi
export type StoreConfigurationItemMutationError = ApiError

export const useStoreConfigurationItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemHook>>>,
        TError,
        { data: ConfigurationItemUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemHook>>>, TError, { data: ConfigurationItemUi }, TContext> => {
    const mutationOptions = useStoreConfigurationItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreConfigurationItemBiznisHook = () => {
    const storeConfigurationItemBiznis = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (configurationItemUi: ConfigurationItemUi) => {
            return storeConfigurationItemBiznis({
                url: `/store/ci/biznis`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: configurationItemUi,
            })
        },
        [storeConfigurationItemBiznis],
    )
}

export const useStoreConfigurationItemBiznisMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemBiznisHook>>>,
        TError,
        { data: ConfigurationItemUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemBiznisHook>>>,
    TError,
    { data: ConfigurationItemUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeConfigurationItemBiznis = useStoreConfigurationItemBiznisHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemBiznisHook>>>, { data: ConfigurationItemUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeConfigurationItemBiznis(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreConfigurationItemBiznisMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemBiznisHook>>>>
export type StoreConfigurationItemBiznisMutationBody = ConfigurationItemUi
export type StoreConfigurationItemBiznisMutationError = ApiError

export const useStoreConfigurationItemBiznis = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemBiznisHook>>>,
        TError,
        { data: ConfigurationItemUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreConfigurationItemBiznisHook>>>,
    TError,
    { data: ConfigurationItemUi },
    TContext
> => {
    const mutationOptions = useStoreConfigurationItemBiznisMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadVotesFromStandardHook = () => {
    const readVotesFromStandard = useCmdbSwaggerClient<VoteListUi>()

    return useCallback(
        (params?: ReadVotesFromStandardParams, signal?: AbortSignal) => {
            return readVotesFromStandard({ url: `/standard/votes`, method: 'GET', params, signal })
        },
        [readVotesFromStandard],
    )
}

export const getReadVotesFromStandardQueryKey = (params?: ReadVotesFromStandardParams) => {
    return [`/standard/votes`, ...(params ? [params] : [])] as const
}

export const useReadVotesFromStandardQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>, TError = ApiError>(
    params?: ReadVotesFromStandardParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadVotesFromStandardQueryKey(params)

    const readVotesFromStandard = useReadVotesFromStandardHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>> = ({ signal }) =>
        readVotesFromStandard(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadVotesFromStandardQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>>
export type ReadVotesFromStandardQueryError = ApiError

export const useReadVotesFromStandard = <TData = Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>, TError = ApiError>(
    params?: ReadVotesFromStandardParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadVotesFromStandardHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadVotesFromStandardQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStoreStandardVotesHook = () => {
    const storeStandardVotes = useCmdbSwaggerClient<void>()

    return useCallback(
        (voteListUi: VoteListUi) => {
            return storeStandardVotes({ url: `/standard/votes`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: voteListUi })
        },
        [storeStandardVotes],
    )
}

export const useStoreStandardVotesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreStandardVotesHook>>>, TError, { data: VoteListUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreStandardVotesHook>>>, TError, { data: VoteListUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeStandardVotes = useStoreStandardVotesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreStandardVotesHook>>>, { data: VoteListUi }> = (props) => {
        const { data } = props ?? {}

        return storeStandardVotes(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreStandardVotesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreStandardVotesHook>>>>
export type StoreStandardVotesMutationBody = VoteListUi
export type StoreStandardVotesMutationError = ApiError

export const useStoreStandardVotes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreStandardVotesHook>>>, TError, { data: VoteListUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreStandardVotesHook>>>, TError, { data: VoteListUi }, TContext> => {
    const mutationOptions = useStoreStandardVotesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardVotes1Hook = () => {
    const deleteStandardVotes1 = useCmdbSwaggerClient<void>()

    return useCallback(() => {
        return deleteStandardVotes1({ url: `/standard/votes`, method: 'DELETE' })
    }, [deleteStandardVotes1])
}

export const useDeleteStandardVotes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotes1Hook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotes1Hook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandardVotes1 = useDeleteStandardVotes1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotes1Hook>>>, void> = () => {
        return deleteStandardVotes1()
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardVotes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotes1Hook>>>>

export type DeleteStandardVotes1MutationError = ApiError

export const useDeleteStandardVotes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotes1Hook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotes1Hook>>>, TError, void, TContext> => {
    const mutationOptions = useDeleteStandardVotes1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardVotesHook = () => {
    const deleteStandardVotes = useCmdbSwaggerClient<void>()

    return useCallback(
        (deleteStandardVotesBody: number[]) => {
            return deleteStandardVotes({
                url: `/standard/votes/delete`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: deleteStandardVotesBody,
            })
        },
        [deleteStandardVotes],
    )
}

export const useDeleteStandardVotesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotesHook>>>, TError, { data: number[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotesHook>>>, TError, { data: number[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandardVotes = useDeleteStandardVotesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotesHook>>>, { data: number[] }> = (props) => {
        const { data } = props ?? {}

        return deleteStandardVotes(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardVotesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotesHook>>>>
export type DeleteStandardVotesMutationBody = number[]
export type DeleteStandardVotesMutationError = ApiError

export const useDeleteStandardVotes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotesHook>>>, TError, { data: number[] }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardVotesHook>>>, TError, { data: number[] }, TContext> => {
    const mutationOptions = useDeleteStandardVotesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadStandardRequestsFromStandardHook = () => {
    const readStandardRequestsFromStandard = useCmdbSwaggerClient<StandardRequestListUi>()

    return useCallback(
        (params?: ReadStandardRequestsFromStandardParams, signal?: AbortSignal) => {
            return readStandardRequestsFromStandard({ url: `/standard/standardRequests`, method: 'GET', params, signal })
        },
        [readStandardRequestsFromStandard],
    )
}

export const getReadStandardRequestsFromStandardQueryKey = (params?: ReadStandardRequestsFromStandardParams) => {
    return [`/standard/standardRequests`, ...(params ? [params] : [])] as const
}

export const useReadStandardRequestsFromStandardQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>,
    TError = ApiError,
>(
    params?: ReadStandardRequestsFromStandardParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadStandardRequestsFromStandardQueryKey(params)

    const readStandardRequestsFromStandard = useReadStandardRequestsFromStandardHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>> = ({ signal }) =>
        readStandardRequestsFromStandard(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadStandardRequestsFromStandardQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>>
export type ReadStandardRequestsFromStandardQueryError = ApiError

export const useReadStandardRequestsFromStandard = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>,
    TError = ApiError,
>(
    params?: ReadStandardRequestsFromStandardParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadStandardRequestsFromStandardHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadStandardRequestsFromStandardQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStoreStandardStandardRequestsHook = () => {
    const storeStandardStandardRequests = useCmdbSwaggerClient<void>()

    return useCallback(
        (standardRequestListUi: StandardRequestListUi) => {
            return storeStandardStandardRequests({
                url: `/standard/standardRequests`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: standardRequestListUi,
            })
        },
        [storeStandardStandardRequests],
    )
}

export const useStoreStandardStandardRequestsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreStandardStandardRequestsHook>>>,
        TError,
        { data: StandardRequestListUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreStandardStandardRequestsHook>>>,
    TError,
    { data: StandardRequestListUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeStandardStandardRequests = useStoreStandardStandardRequestsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreStandardStandardRequestsHook>>>,
        { data: StandardRequestListUi }
    > = (props) => {
        const { data } = props ?? {}

        return storeStandardStandardRequests(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreStandardStandardRequestsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreStandardStandardRequestsHook>>>>
export type StoreStandardStandardRequestsMutationBody = StandardRequestListUi
export type StoreStandardStandardRequestsMutationError = ApiError

export const useStoreStandardStandardRequests = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreStandardStandardRequestsHook>>>,
        TError,
        { data: StandardRequestListUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreStandardStandardRequestsHook>>>,
    TError,
    { data: StandardRequestListUi },
    TContext
> => {
    const mutationOptions = useStoreStandardStandardRequestsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardStandardHook = () => {
    const deleteStandardStandard = useCmdbSwaggerClient<void>()

    return useCallback(() => {
        return deleteStandardStandard({ url: `/standard/standardRequests`, method: 'DELETE' })
    }, [deleteStandardStandard])
}

export const useDeleteStandardStandardMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandardStandard = useDeleteStandardStandardHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardHook>>>, void> = () => {
        return deleteStandardStandard()
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardStandardMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardHook>>>>

export type DeleteStandardStandardMutationError = ApiError

export const useDeleteStandardStandard = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardHook>>>, TError, void, TContext> => {
    const mutationOptions = useDeleteStandardStandardMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardStandardRequestsHook = () => {
    const deleteStandardStandardRequests = useCmdbSwaggerClient<void>()

    return useCallback(
        (deleteStandardStandardRequestsBody: number[]) => {
            return deleteStandardStandardRequests({
                url: `/standard/standardRequests/delete`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: deleteStandardStandardRequestsBody,
            })
        },
        [deleteStandardStandardRequests],
    )
}

export const useDeleteStandardStandardRequestsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardRequestsHook>>>, TError, { data: number[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardRequestsHook>>>, TError, { data: number[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandardStandardRequests = useDeleteStandardStandardRequestsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardRequestsHook>>>, { data: number[] }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return deleteStandardStandardRequests(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardStandardRequestsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardRequestsHook>>>>
export type DeleteStandardStandardRequestsMutationBody = number[]
export type DeleteStandardStandardRequestsMutationError = ApiError

export const useDeleteStandardStandardRequests = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardRequestsHook>>>, TError, { data: number[] }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardStandardRequestsHook>>>, TError, { data: number[] }, TContext> => {
    const mutationOptions = useDeleteStandardStandardRequestsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadMeetingRequestsFromStandardHook = () => {
    const readMeetingRequestsFromStandard = useCmdbSwaggerClient<MeetingRequestListUi>()

    return useCallback(
        (params?: ReadMeetingRequestsFromStandardParams, signal?: AbortSignal) => {
            return readMeetingRequestsFromStandard({ url: `/standard/meetingRequests`, method: 'GET', params, signal })
        },
        [readMeetingRequestsFromStandard],
    )
}

export const getReadMeetingRequestsFromStandardQueryKey = (params?: ReadMeetingRequestsFromStandardParams) => {
    return [`/standard/meetingRequests`, ...(params ? [params] : [])] as const
}

export const useReadMeetingRequestsFromStandardQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>,
    TError = ApiError,
>(
    params?: ReadMeetingRequestsFromStandardParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadMeetingRequestsFromStandardQueryKey(params)

    const readMeetingRequestsFromStandard = useReadMeetingRequestsFromStandardHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>> = ({ signal }) =>
        readMeetingRequestsFromStandard(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadMeetingRequestsFromStandardQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>>
export type ReadMeetingRequestsFromStandardQueryError = ApiError

export const useReadMeetingRequestsFromStandard = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>,
    TError = ApiError,
>(
    params?: ReadMeetingRequestsFromStandardParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadMeetingRequestsFromStandardHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadMeetingRequestsFromStandardQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStoreStandardMeetingRequestsHook = () => {
    const storeStandardMeetingRequests = useCmdbSwaggerClient<void>()

    return useCallback(
        (meetingRequestListUi: MeetingRequestListUi) => {
            return storeStandardMeetingRequests({
                url: `/standard/meetingRequests`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: meetingRequestListUi,
            })
        },
        [storeStandardMeetingRequests],
    )
}

export const useStoreStandardMeetingRequestsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreStandardMeetingRequestsHook>>>,
        TError,
        { data: MeetingRequestListUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreStandardMeetingRequestsHook>>>,
    TError,
    { data: MeetingRequestListUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeStandardMeetingRequests = useStoreStandardMeetingRequestsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreStandardMeetingRequestsHook>>>,
        { data: MeetingRequestListUi }
    > = (props) => {
        const { data } = props ?? {}

        return storeStandardMeetingRequests(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreStandardMeetingRequestsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreStandardMeetingRequestsHook>>>>
export type StoreStandardMeetingRequestsMutationBody = MeetingRequestListUi
export type StoreStandardMeetingRequestsMutationError = ApiError

export const useStoreStandardMeetingRequests = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreStandardMeetingRequestsHook>>>,
        TError,
        { data: MeetingRequestListUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreStandardMeetingRequestsHook>>>,
    TError,
    { data: MeetingRequestListUi },
    TContext
> => {
    const mutationOptions = useStoreStandardMeetingRequestsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardMeetingRequests1Hook = () => {
    const deleteStandardMeetingRequests1 = useCmdbSwaggerClient<void>()

    return useCallback(() => {
        return deleteStandardMeetingRequests1({ url: `/standard/meetingRequests`, method: 'DELETE' })
    }, [deleteStandardMeetingRequests1])
}

export const useDeleteStandardMeetingRequests1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequests1Hook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequests1Hook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandardMeetingRequests1 = useDeleteStandardMeetingRequests1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequests1Hook>>>, void> = () => {
        return deleteStandardMeetingRequests1()
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardMeetingRequests1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequests1Hook>>>>

export type DeleteStandardMeetingRequests1MutationError = ApiError

export const useDeleteStandardMeetingRequests1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequests1Hook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequests1Hook>>>, TError, void, TContext> => {
    const mutationOptions = useDeleteStandardMeetingRequests1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardMeetingRequestsHook = () => {
    const deleteStandardMeetingRequests = useCmdbSwaggerClient<void>()

    return useCallback(
        (deleteStandardMeetingRequestsBody: number[]) => {
            return deleteStandardMeetingRequests({
                url: `/standard/meetingRequests/delete`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: deleteStandardMeetingRequestsBody,
            })
        },
        [deleteStandardMeetingRequests],
    )
}

export const useDeleteStandardMeetingRequestsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequestsHook>>>, TError, { data: number[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequestsHook>>>, TError, { data: number[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandardMeetingRequests = useDeleteStandardMeetingRequestsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequestsHook>>>, { data: number[] }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return deleteStandardMeetingRequests(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardMeetingRequestsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequestsHook>>>>
export type DeleteStandardMeetingRequestsMutationBody = number[]
export type DeleteStandardMeetingRequestsMutationError = ApiError

export const useDeleteStandardMeetingRequests = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequestsHook>>>, TError, { data: number[] }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardMeetingRequestsHook>>>, TError, { data: number[] }, TContext> => {
    const mutationOptions = useDeleteStandardMeetingRequestsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readCiList
 */
export const useReadCiListHook = () => {
    const readCiList = useCmdbSwaggerClient<HierarchyRightsResultUi>()

    return useCallback(
        (hierarchyPOFilterUi: HierarchyPOFilterUi) => {
            return readCiList({
                url: `/rights/implicitHierarchy`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: hierarchyPOFilterUi,
            })
        },
        [readCiList],
    )
}

export const useReadCiListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiListHook>>>, TError, { data: HierarchyPOFilterUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiListHook>>>, TError, { data: HierarchyPOFilterUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readCiList = useReadCiListHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadCiListHook>>>, { data: HierarchyPOFilterUi }> = (props) => {
        const { data } = props ?? {}

        return readCiList(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadCiListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiListHook>>>>
export type ReadCiListMutationBody = HierarchyPOFilterUi
export type ReadCiListMutationError = ApiError

/**
 * @summary readCiList
 */
export const useReadCiList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiListHook>>>, TError, { data: HierarchyPOFilterUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadCiListHook>>>, TError, { data: HierarchyPOFilterUi }, TContext> => {
    const mutationOptions = useReadCiListMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveCiFromGroupHook = () => {
    const removeCiFromGroup = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (uuid: string, removeCiFromGroupBody: string[]) => {
            return removeCiFromGroup({
                url: `/removemembers/group/${uuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: removeCiFromGroupBody,
            })
        },
        [removeCiFromGroup],
    )
}

export const useRemoveCiFromGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveCiFromGroupHook>>>,
        TError,
        { uuid: string; data: string[] },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveCiFromGroupHook>>>, TError, { uuid: string; data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeCiFromGroup = useRemoveCiFromGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveCiFromGroupHook>>>, { uuid: string; data: string[] }> = (
        props,
    ) => {
        const { uuid, data } = props ?? {}

        return removeCiFromGroup(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveCiFromGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveCiFromGroupHook>>>>
export type RemoveCiFromGroupMutationBody = string[]
export type RemoveCiFromGroupMutationError = ApiError

export const useRemoveCiFromGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveCiFromGroupHook>>>,
        TError,
        { uuid: string; data: string[] },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRemoveCiFromGroupHook>>>, TError, { uuid: string; data: string[] }, TContext> => {
    const mutationOptions = useRemoveCiFromGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveGroupHook = () => {
    const removeGroup = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (uuid: string) => {
            return removeGroup({ url: `/remove/group/${uuid}`, method: 'POST' })
        },
        [removeGroup],
    )
}

export const useRemoveGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveGroupHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveGroupHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeGroup = useRemoveGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveGroupHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return removeGroup(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveGroupHook>>>>

export type RemoveGroupMutationError = ApiError

export const useRemoveGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveGroupHook>>>, TError, { uuid: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRemoveGroupHook>>>, TError, { uuid: string }, TContext> => {
    const mutationOptions = useRemoveGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRecycleInvalidatedRelsHook = () => {
    const recycleInvalidatedRels = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (recycleRelsUi: RecycleRelsUi) => {
            return recycleInvalidatedRels({
                url: `/recycle/rels`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: recycleRelsUi,
            })
        },
        [recycleInvalidatedRels],
    )
}

export const useRecycleInvalidatedRelsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsHook>>>, TError, { data: RecycleRelsUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsHook>>>, TError, { data: RecycleRelsUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const recycleInvalidatedRels = useRecycleInvalidatedRelsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsHook>>>, { data: RecycleRelsUi }> = (props) => {
        const { data } = props ?? {}

        return recycleInvalidatedRels(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RecycleInvalidatedRelsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsHook>>>>
export type RecycleInvalidatedRelsMutationBody = RecycleRelsUi
export type RecycleInvalidatedRelsMutationError = ApiError

export const useRecycleInvalidatedRels = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsHook>>>, TError, { data: RecycleRelsUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsHook>>>, TError, { data: RecycleRelsUi }, TContext> => {
    const mutationOptions = useRecycleInvalidatedRelsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRecycleInvalidatedRelsBiznisHook = () => {
    const recycleInvalidatedRelsBiznis = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (recycleRelsUi: RecycleRelsUi) => {
            return recycleInvalidatedRelsBiznis({
                url: `/recycle/rels/biznis`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: recycleRelsUi,
            })
        },
        [recycleInvalidatedRelsBiznis],
    )
}

export const useRecycleInvalidatedRelsBiznisMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsBiznisHook>>>,
        TError,
        { data: RecycleRelsUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsBiznisHook>>>, TError, { data: RecycleRelsUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const recycleInvalidatedRelsBiznis = useRecycleInvalidatedRelsBiznisHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsBiznisHook>>>, { data: RecycleRelsUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return recycleInvalidatedRelsBiznis(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RecycleInvalidatedRelsBiznisMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsBiznisHook>>>>
export type RecycleInvalidatedRelsBiznisMutationBody = RecycleRelsUi
export type RecycleInvalidatedRelsBiznisMutationError = ApiError

export const useRecycleInvalidatedRelsBiznis = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsBiznisHook>>>,
        TError,
        { data: RecycleRelsUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedRelsBiznisHook>>>, TError, { data: RecycleRelsUi }, TContext> => {
    const mutationOptions = useRecycleInvalidatedRelsBiznisMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRecyclePoWithHierarchyHook = () => {
    const recyclePoWithHierarchy = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (poId: string, relId: string, poWithHierarchyUi: PoWithHierarchyUi) => {
            return recyclePoWithHierarchy({
                url: `/recycle/poWithHierarchy/${poId}/${relId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: poWithHierarchyUi,
            })
        },
        [recyclePoWithHierarchy],
    )
}

export const useRecyclePoWithHierarchyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecyclePoWithHierarchyHook>>>,
        TError,
        { poId: string; relId: string; data: PoWithHierarchyUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRecyclePoWithHierarchyHook>>>,
    TError,
    { poId: string; relId: string; data: PoWithHierarchyUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const recyclePoWithHierarchy = useRecyclePoWithHierarchyHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRecyclePoWithHierarchyHook>>>,
        { poId: string; relId: string; data: PoWithHierarchyUi }
    > = (props) => {
        const { poId, relId, data } = props ?? {}

        return recyclePoWithHierarchy(poId, relId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RecyclePoWithHierarchyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRecyclePoWithHierarchyHook>>>>
export type RecyclePoWithHierarchyMutationBody = PoWithHierarchyUi
export type RecyclePoWithHierarchyMutationError = ApiError

export const useRecyclePoWithHierarchy = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecyclePoWithHierarchyHook>>>,
        TError,
        { poId: string; relId: string; data: PoWithHierarchyUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useRecyclePoWithHierarchyHook>>>,
    TError,
    { poId: string; relId: string; data: PoWithHierarchyUi },
    TContext
> => {
    const mutationOptions = useRecyclePoWithHierarchyMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRecycleInvalidatedCisHook = () => {
    const recycleInvalidatedCis = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (recycleCisUi: RecycleCisUi) => {
            return recycleInvalidatedCis({ url: `/recycle/cis`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: recycleCisUi })
        },
        [recycleInvalidatedCis],
    )
}

export const useRecycleInvalidatedCisMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisHook>>>, TError, { data: RecycleCisUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisHook>>>, TError, { data: RecycleCisUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const recycleInvalidatedCis = useRecycleInvalidatedCisHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisHook>>>, { data: RecycleCisUi }> = (props) => {
        const { data } = props ?? {}

        return recycleInvalidatedCis(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RecycleInvalidatedCisMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisHook>>>>
export type RecycleInvalidatedCisMutationBody = RecycleCisUi
export type RecycleInvalidatedCisMutationError = ApiError

export const useRecycleInvalidatedCis = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisHook>>>, TError, { data: RecycleCisUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisHook>>>, TError, { data: RecycleCisUi }, TContext> => {
    const mutationOptions = useRecycleInvalidatedCisMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRecycleInvalidatedCisBiznisHook = () => {
    const recycleInvalidatedCisBiznis = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (recycleCisUi: RecycleCisUi) => {
            return recycleInvalidatedCisBiznis({
                url: `/recycle/cis/biznis`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: recycleCisUi,
            })
        },
        [recycleInvalidatedCisBiznis],
    )
}

export const useRecycleInvalidatedCisBiznisMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisBiznisHook>>>,
        TError,
        { data: RecycleCisUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisBiznisHook>>>, TError, { data: RecycleCisUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const recycleInvalidatedCisBiznis = useRecycleInvalidatedCisBiznisHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisBiznisHook>>>, { data: RecycleCisUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return recycleInvalidatedCisBiznis(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RecycleInvalidatedCisBiznisMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisBiznisHook>>>>
export type RecycleInvalidatedCisBiznisMutationBody = RecycleCisUi
export type RecycleInvalidatedCisBiznisMutationError = ApiError

export const useRecycleInvalidatedCisBiznis = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisBiznisHook>>>,
        TError,
        { data: RecycleCisUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRecycleInvalidatedCisBiznisHook>>>, TError, { data: RecycleCisUi }, TContext> => {
    const mutationOptions = useRecycleInvalidatedCisBiznisMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readGroupItems
 */
export const useReadGroupItemsHook = () => {
    const readGroupItems = useCmdbSwaggerClient<ReadGroupItems200>()

    return useCallback(
        (uuid: string, groupMembersFilterUi: GroupMembersFilterUi) => {
            return readGroupItems({
                url: `/readmembers/group/${uuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: groupMembersFilterUi,
            })
        },
        [readGroupItems],
    )
}

export const useReadGroupItemsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadGroupItemsHook>>>,
        TError,
        { uuid: string; data: GroupMembersFilterUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadGroupItemsHook>>>,
    TError,
    { uuid: string; data: GroupMembersFilterUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readGroupItems = useReadGroupItemsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useReadGroupItemsHook>>>,
        { uuid: string; data: GroupMembersFilterUi }
    > = (props) => {
        const { uuid, data } = props ?? {}

        return readGroupItems(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadGroupItemsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadGroupItemsHook>>>>
export type ReadGroupItemsMutationBody = GroupMembersFilterUi
export type ReadGroupItemsMutationError = ApiError

/**
 * @summary readGroupItems
 */
export const useReadGroupItems = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadGroupItemsHook>>>,
        TError,
        { uuid: string; data: GroupMembersFilterUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useReadGroupItemsHook>>>,
    TError,
    { uuid: string; data: GroupMembersFilterUi },
    TContext
> => {
    const mutationOptions = useReadGroupItemsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readConfigurationItemByRefID
 */
export const useReadConfigurationItemByRefIDHook = () => {
    const readConfigurationItemByRefID = useCmdbSwaggerClient<ReadConfigurationItemByRefID200>()

    return useCallback(
        (readConfigurationItemByRefIDBody: string) => {
            return readConfigurationItemByRefID({
                url: `/readByRefID/ci`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: readConfigurationItemByRefIDBody,
            })
        },
        [readConfigurationItemByRefID],
    )
}

export const useReadConfigurationItemByRefIDMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByRefIDHook>>>, TError, { data: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByRefIDHook>>>, TError, { data: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readConfigurationItemByRefID = useReadConfigurationItemByRefIDHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByRefIDHook>>>, { data: string }> = (props) => {
        const { data } = props ?? {}

        return readConfigurationItemByRefID(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadConfigurationItemByRefIDMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByRefIDHook>>>>
export type ReadConfigurationItemByRefIDMutationBody = string
export type ReadConfigurationItemByRefIDMutationError = ApiError

/**
 * @summary readConfigurationItemByRefID
 */
export const useReadConfigurationItemByRefID = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByRefIDHook>>>, TError, { data: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByRefIDHook>>>, TError, { data: string }, TContext> => {
    const mutationOptions = useReadConfigurationItemByRefIDMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readConfigurationItemsByMetaIsCodes
 */
export const useReadConfigurationItemsByMetaIsCodesHook = () => {
    const readConfigurationItemsByMetaIsCodes = useCmdbSwaggerClient<ConfigurationItemsListUi>()

    return useCallback(
        (metaIsCodesListUi: MetaIsCodesListUi) => {
            return readConfigurationItemsByMetaIsCodes({
                url: `/readByMetaIsCode/ci`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: metaIsCodesListUi,
            })
        },
        [readConfigurationItemsByMetaIsCodes],
    )
}

export const useReadConfigurationItemsByMetaIsCodesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemsByMetaIsCodesHook>>>,
        TError,
        { data: MetaIsCodesListUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemsByMetaIsCodesHook>>>,
    TError,
    { data: MetaIsCodesListUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readConfigurationItemsByMetaIsCodes = useReadConfigurationItemsByMetaIsCodesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemsByMetaIsCodesHook>>>,
        { data: MetaIsCodesListUi }
    > = (props) => {
        const { data } = props ?? {}

        return readConfigurationItemsByMetaIsCodes(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadConfigurationItemsByMetaIsCodesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemsByMetaIsCodesHook>>>
>
export type ReadConfigurationItemsByMetaIsCodesMutationBody = MetaIsCodesListUi
export type ReadConfigurationItemsByMetaIsCodesMutationError = ApiError

/**
 * @summary readConfigurationItemsByMetaIsCodes
 */
export const useReadConfigurationItemsByMetaIsCodes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemsByMetaIsCodesHook>>>,
        TError,
        { data: MetaIsCodesListUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemsByMetaIsCodesHook>>>,
    TError,
    { data: MetaIsCodesListUi },
    TContext
> => {
    const mutationOptions = useReadConfigurationItemsByMetaIsCodesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSearchAllHook = () => {
    const searchAll = useCmdbSwaggerClient<ElasticItemHolder>()

    return useCallback(
        (portalSearch: PortalSearch) => {
            return searchAll({ url: `/read/search`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: portalSearch })
        },
        [searchAll],
    )
}

export const getSearchAllQueryKey = (portalSearch: PortalSearch) => {
    return [`/read/search`, portalSearch] as const
}

export const useSearchAllQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>, TError = ApiError>(
    portalSearch: PortalSearch,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getSearchAllQueryKey(portalSearch)

    const searchAll = useSearchAllHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>> = ({ signal }) => searchAll(portalSearch, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type SearchAllQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>>
export type SearchAllQueryError = ApiError

export const useSearchAll = <TData = Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>, TError = ApiError>(
    portalSearch: PortalSearch,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSearchAllHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useSearchAllQueryOptions(portalSearch, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary getRoleParticipantBulk
 */
export const useGetRoleParticipantBulkHook = () => {
    const getRoleParticipantBulk = useClientForGetRoleParticipantBulkUsingPOST<RoleParticipantUI[]>()

    return useCallback(
        (gidSetUi: GidSetUi) => {
            return getRoleParticipantBulk({
                url: `/read/roleParticipant`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: gidSetUi,
            })
        },
        [getRoleParticipantBulk],
    )
}

export const getGetRoleParticipantBulkQueryKey = (gidSetUi: GidSetUi) => {
    return [`/read/roleParticipant`, gidSetUi] as const
}

export const useGetRoleParticipantBulkQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>,
    TError = ApiError,
>(
    gidSetUi: GidSetUi,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRoleParticipantBulkQueryKey(gidSetUi)

    const getRoleParticipantBulk = useGetRoleParticipantBulkHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>> = ({ signal }) =>
        getRoleParticipantBulk(gidSetUi, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetRoleParticipantBulkQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>>
export type GetRoleParticipantBulkQueryError = ApiError

/**
 * @summary getRoleParticipantBulk
 */
export const useGetRoleParticipantBulk = <TData = Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>, TError = ApiError>(
    gidSetUi: GidSetUi,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantBulkHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRoleParticipantBulkQueryOptions(gidSetUi, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadRelListWithScrollIdHook = () => {
    const readRelListWithScrollId = useCmdbSwaggerClient<RelationsWithScrollIdUi>()

    return useCallback(
        (cmdbItemListScrollIdUi: CmdbItemListScrollIdUi) => {
            return readRelListWithScrollId({
                url: `/read/rellistscrollid`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: cmdbItemListScrollIdUi,
            })
        },
        [readRelListWithScrollId],
    )
}

export const useReadRelListWithScrollIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelListWithScrollIdHook>>>,
        TError,
        { data: CmdbItemListScrollIdUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadRelListWithScrollIdHook>>>,
    TError,
    { data: CmdbItemListScrollIdUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readRelListWithScrollId = useReadRelListWithScrollIdHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadRelListWithScrollIdHook>>>, { data: CmdbItemListScrollIdUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return readRelListWithScrollId(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadRelListWithScrollIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelListWithScrollIdHook>>>>
export type ReadRelListWithScrollIdMutationBody = CmdbItemListScrollIdUi
export type ReadRelListWithScrollIdMutationError = ApiError

export const useReadRelListWithScrollId = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelListWithScrollIdHook>>>,
        TError,
        { data: CmdbItemListScrollIdUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadRelListWithScrollIdHook>>>, TError, { data: CmdbItemListScrollIdUi }, TContext> => {
    const mutationOptions = useReadRelListWithScrollIdMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadRelListAndInitScrollHook = () => {
    const readRelListAndInitScroll = useCmdbSwaggerClient<RelationsWithScrollIdUi>()

    return useCallback(
        (relListFilterScrollUi: RelListFilterScrollUi) => {
            return readRelListAndInitScroll({
                url: `/read/rellistinitscroll`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relListFilterScrollUi,
            })
        },
        [readRelListAndInitScroll],
    )
}

export const useReadRelListAndInitScrollMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelListAndInitScrollHook>>>,
        TError,
        { data: RelListFilterScrollUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadRelListAndInitScrollHook>>>,
    TError,
    { data: RelListFilterScrollUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readRelListAndInitScroll = useReadRelListAndInitScrollHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadRelListAndInitScrollHook>>>, { data: RelListFilterScrollUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return readRelListAndInitScroll(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadRelListAndInitScrollMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelListAndInitScrollHook>>>>
export type ReadRelListAndInitScrollMutationBody = RelListFilterScrollUi
export type ReadRelListAndInitScrollMutationError = ApiError

export const useReadRelListAndInitScroll = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelListAndInitScrollHook>>>,
        TError,
        { data: RelListFilterScrollUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadRelListAndInitScrollHook>>>, TError, { data: RelListFilterScrollUi }, TContext> => {
    const mutationOptions = useReadRelListAndInitScrollMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readRelList
 */
export const useReadRelListHook = () => {
    const readRelList = useCmdbSwaggerClient<RelationshipElasticPreviewHolderUi>()

    return useCallback(
        (relFilterSmallUi: RelFilterSmallUi) => {
            return readRelList({
                url: `/read/rellistfiltered`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relFilterSmallUi,
            })
        },
        [readRelList],
    )
}

export const useReadRelListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadRelListHook>>>, TError, { data: RelFilterSmallUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadRelListHook>>>, TError, { data: RelFilterSmallUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readRelList = useReadRelListHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadRelListHook>>>, { data: RelFilterSmallUi }> = (props) => {
        const { data } = props ?? {}

        return readRelList(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadRelListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelListHook>>>>
export type ReadRelListMutationBody = RelFilterSmallUi
export type ReadRelListMutationError = ApiError

/**
 * @summary readRelList
 */
export const useReadRelList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadRelListHook>>>, TError, { data: RelFilterSmallUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadRelListHook>>>, TError, { data: RelFilterSmallUi }, TContext> => {
    const mutationOptions = useReadRelListMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readPoSuperiorPoRelationship
 */
export const useReadPoSuperiorPoRelationshipHook = () => {
    const readPoSuperiorPoRelationship = useCmdbSwaggerClient<ReadPoSuperiorPoRelationship200>()

    return useCallback(
        (uuidSetUi: UuidSetUi) => {
            return readPoSuperiorPoRelationship({
                url: `/read/relationspo`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: uuidSetUi,
            })
        },
        [readPoSuperiorPoRelationship],
    )
}

export const useReadPoSuperiorPoRelationshipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadPoSuperiorPoRelationshipHook>>>, TError, { data: UuidSetUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadPoSuperiorPoRelationshipHook>>>, TError, { data: UuidSetUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readPoSuperiorPoRelationship = useReadPoSuperiorPoRelationshipHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadPoSuperiorPoRelationshipHook>>>, { data: UuidSetUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return readPoSuperiorPoRelationship(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadPoSuperiorPoRelationshipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadPoSuperiorPoRelationshipHook>>>>
export type ReadPoSuperiorPoRelationshipMutationBody = UuidSetUi
export type ReadPoSuperiorPoRelationshipMutationError = ApiError

/**
 * @summary readPoSuperiorPoRelationship
 */
export const useReadPoSuperiorPoRelationship = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadPoSuperiorPoRelationshipHook>>>, TError, { data: UuidSetUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadPoSuperiorPoRelationshipHook>>>, TError, { data: UuidSetUi }, TContext> => {
    const mutationOptions = useReadPoSuperiorPoRelationshipMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readRelationshipList
 */
export const useReadRelationshipListHook = () => {
    const readRelationshipList = useCmdbSwaggerClient<RelationshipListUi>()

    return useCallback(
        (relListFilterContainerUi: RelListFilterContainerUi) => {
            return readRelationshipList({
                url: `/read/relationshiplistfiltered`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relListFilterContainerUi,
            })
        },
        [readRelationshipList],
    )
}

export const useReadRelationshipListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelationshipListHook>>>,
        TError,
        { data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadRelationshipListHook>>>, TError, { data: RelListFilterContainerUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readRelationshipList = useReadRelationshipListHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadRelationshipListHook>>>, { data: RelListFilterContainerUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return readRelationshipList(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadRelationshipListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelationshipListHook>>>>
export type ReadRelationshipListMutationBody = RelListFilterContainerUi
export type ReadRelationshipListMutationError = ApiError

/**
 * @summary readRelationshipList
 */
export const useReadRelationshipList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelationshipListHook>>>,
        TError,
        { data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadRelationshipListHook>>>, TError, { data: RelListFilterContainerUi }, TContext> => {
    const mutationOptions = useReadRelationshipListMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readCiNeighbours
 */
export const useReadCiNeighboursHook = () => {
    const readCiNeighbours = useClientForreadCiNeighboursUsingPOST<NeighbourSetUi>()

    return useCallback(
        (uuid: string, neighboursFilterContainerUi: NeighboursFilterContainerUi) => {
            return readCiNeighbours({
                url: `/read/relations/neighbours/${uuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: neighboursFilterContainerUi,
            })
        },
        [readCiNeighbours],
    )
}

export const getReadCiNeighboursQueryKey = (uuid: string, neighboursFilterContainerUi: NeighboursFilterContainerUi) => {
    return [`/read/relations/neighbours/${uuid}`, neighboursFilterContainerUi] as const
}

export const useReadCiNeighboursQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>, TError = ApiError>(
    uuid: string,
    neighboursFilterContainerUi: NeighboursFilterContainerUi,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiNeighboursQueryKey(uuid, neighboursFilterContainerUi)

    const readCiNeighbours = useReadCiNeighboursHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>> = ({ signal }) =>
        readCiNeighbours(uuid, neighboursFilterContainerUi, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiNeighboursQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>>
export type ReadCiNeighboursQueryError = ApiError

/**
 * @summary readCiNeighbours
 */
export const useReadCiNeighbours = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>, TError = ApiError>(
    uuid: string,
    neighboursFilterContainerUi: NeighboursFilterContainerUi,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiNeighboursQueryOptions(uuid, neighboursFilterContainerUi, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary readQuery
 */
export const useReadQueryHook = () => {
    const readQuery = useCmdbSwaggerClient<QueryResultTableUi>()

    return useCallback(
        (queryUi: QueryUi) => {
            return readQuery({ url: `/read/query`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: queryUi })
        },
        [readQuery],
    )
}

export const useReadQueryMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadQueryHook>>>, TError, { data: QueryUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadQueryHook>>>, TError, { data: QueryUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readQuery = useReadQueryHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadQueryHook>>>, { data: QueryUi }> = (props) => {
        const { data } = props ?? {}

        return readQuery(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadQueryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadQueryHook>>>>
export type ReadQueryMutationBody = QueryUi
export type ReadQueryMutationError = ApiError

/**
 * @summary readQuery
 */
export const useReadQuery = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadQueryHook>>>, TError, { data: QueryUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadQueryHook>>>, TError, { data: QueryUi }, TContext> => {
    const mutationOptions = useReadQueryMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readIncidentRelationships
 */
export const useReadIncidentRelationshipsHook = () => {
    const readIncidentRelationships = useCmdbSwaggerClient<RelationshipSetUi>()

    return useCallback(
        (incidentRelationshipsFilterUi: IncidentRelationshipsFilterUi) => {
            return readIncidentRelationships({
                url: `/read/incidentrelationships`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: incidentRelationshipsFilterUi,
            })
        },
        [readIncidentRelationships],
    )
}

export const useReadIncidentRelationshipsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadIncidentRelationshipsHook>>>,
        TError,
        { data: IncidentRelationshipsFilterUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadIncidentRelationshipsHook>>>,
    TError,
    { data: IncidentRelationshipsFilterUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readIncidentRelationships = useReadIncidentRelationshipsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useReadIncidentRelationshipsHook>>>,
        { data: IncidentRelationshipsFilterUi }
    > = (props) => {
        const { data } = props ?? {}

        return readIncidentRelationships(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadIncidentRelationshipsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadIncidentRelationshipsHook>>>>
export type ReadIncidentRelationshipsMutationBody = IncidentRelationshipsFilterUi
export type ReadIncidentRelationshipsMutationError = ApiError

/**
 * @summary readIncidentRelationships
 */
export const useReadIncidentRelationships = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadIncidentRelationshipsHook>>>,
        TError,
        { data: IncidentRelationshipsFilterUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useReadIncidentRelationshipsHook>>>,
    TError,
    { data: IncidentRelationshipsFilterUi },
    TContext
> => {
    const mutationOptions = useReadIncidentRelationshipsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readCiUuidSet
 */
export const useReadCiUuidSetHook = () => {
    const readCiUuidSet = useCmdbSwaggerClient<CiUuidSetUi>()

    return useCallback(
        (ciListFilterContainerUi: CiListFilterContainerUi) => {
            return readCiUuidSet({
                url: `/read/ciuuidsetfiltered`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciListFilterContainerUi,
            })
        },
        [readCiUuidSet],
    )
}

export const useReadCiUuidSetMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiUuidSetHook>>>, TError, { data: CiListFilterContainerUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiUuidSetHook>>>, TError, { data: CiListFilterContainerUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readCiUuidSet = useReadCiUuidSetHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadCiUuidSetHook>>>, { data: CiListFilterContainerUi }> = (props) => {
        const { data } = props ?? {}

        return readCiUuidSet(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadCiUuidSetMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiUuidSetHook>>>>
export type ReadCiUuidSetMutationBody = CiListFilterContainerUi
export type ReadCiUuidSetMutationError = ApiError

/**
 * @summary readCiUuidSet
 */
export const useReadCiUuidSet = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiUuidSetHook>>>, TError, { data: CiListFilterContainerUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadCiUuidSetHook>>>, TError, { data: CiListFilterContainerUi }, TContext> => {
    const mutationOptions = useReadCiUuidSetMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readCiRelationshipCiList
 */
export const useReadCiRelationshipCiListHook = () => {
    const readCiRelationshipCiList = useCmdbSwaggerClient<CiRelationshipCiPreviewHolderListUi>()

    return useCallback(
        (relListFilterContainerUi: RelListFilterContainerUi) => {
            return readCiRelationshipCiList({
                url: `/read/cirelationshipcilistfiltered`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relListFilterContainerUi,
            })
        },
        [readCiRelationshipCiList],
    )
}

export const useReadCiRelationshipCiListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiRelationshipCiListHook>>>,
        TError,
        { data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadCiRelationshipCiListHook>>>,
    TError,
    { data: RelListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readCiRelationshipCiList = useReadCiRelationshipCiListHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useReadCiRelationshipCiListHook>>>,
        { data: RelListFilterContainerUi }
    > = (props) => {
        const { data } = props ?? {}

        return readCiRelationshipCiList(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadCiRelationshipCiListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiRelationshipCiListHook>>>>
export type ReadCiRelationshipCiListMutationBody = RelListFilterContainerUi
export type ReadCiRelationshipCiListMutationError = ApiError

/**
 * @summary readCiRelationshipCiList
 */
export const useReadCiRelationshipCiList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiRelationshipCiListHook>>>,
        TError,
        { data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useReadCiRelationshipCiListHook>>>,
    TError,
    { data: RelListFilterContainerUi },
    TContext
> => {
    const mutationOptions = useReadCiRelationshipCiListMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadCiListWithScrollIdHook = () => {
    const readCiListWithScrollId = useCmdbSwaggerClient<ConfigurationItemsWithScrollIdUi>()

    return useCallback(
        (cmdbItemListScrollIdUi: CmdbItemListScrollIdUi) => {
            return readCiListWithScrollId({
                url: `/read/cilistscrollid`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: cmdbItemListScrollIdUi,
            })
        },
        [readCiListWithScrollId],
    )
}

export const useReadCiListWithScrollIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiListWithScrollIdHook>>>,
        TError,
        { data: CmdbItemListScrollIdUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiListWithScrollIdHook>>>, TError, { data: CmdbItemListScrollIdUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readCiListWithScrollId = useReadCiListWithScrollIdHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadCiListWithScrollIdHook>>>, { data: CmdbItemListScrollIdUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return readCiListWithScrollId(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadCiListWithScrollIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiListWithScrollIdHook>>>>
export type ReadCiListWithScrollIdMutationBody = CmdbItemListScrollIdUi
export type ReadCiListWithScrollIdMutationError = ApiError

export const useReadCiListWithScrollId = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiListWithScrollIdHook>>>,
        TError,
        { data: CmdbItemListScrollIdUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadCiListWithScrollIdHook>>>, TError, { data: CmdbItemListScrollIdUi }, TContext> => {
    const mutationOptions = useReadCiListWithScrollIdMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadCiListAndInitScrollHook = () => {
    const readCiListAndInitScroll = useCmdbSwaggerClient<ConfigurationItemsWithScrollIdUi>()

    return useCallback(
        (ciListFilterScrollUi: CiListFilterScrollUi) => {
            return readCiListAndInitScroll({
                url: `/read/cilistinitscroll`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciListFilterScrollUi,
            })
        },
        [readCiListAndInitScroll],
    )
}

export const useReadCiListAndInitScrollMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiListAndInitScrollHook>>>,
        TError,
        { data: CiListFilterScrollUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadCiListAndInitScrollHook>>>, TError, { data: CiListFilterScrollUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readCiListAndInitScroll = useReadCiListAndInitScrollHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadCiListAndInitScrollHook>>>, { data: CiListFilterScrollUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return readCiListAndInitScroll(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadCiListAndInitScrollMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiListAndInitScrollHook>>>>
export type ReadCiListAndInitScrollMutationBody = CiListFilterScrollUi
export type ReadCiListAndInitScrollMutationError = ApiError

export const useReadCiListAndInitScroll = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiListAndInitScrollHook>>>,
        TError,
        { data: CiListFilterScrollUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadCiListAndInitScrollHook>>>, TError, { data: CiListFilterScrollUi }, TContext> => {
    const mutationOptions = useReadCiListAndInitScrollMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadCiList1Hook = () => {
    const readCiList1 = useClientForReadCiListUsingPOST<ConfigurationItemSetUi>()

    return useCallback(
        (ciListFilterContainerUi: CiListFilterContainerUi) => {
            return readCiList1({
                url: `/read/cilistfiltered`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciListFilterContainerUi,
            })
        },
        [readCiList1],
    )
}

export const getReadCiList1QueryKey = (ciListFilterContainerUi: CiListFilterContainerUi) => {
    return [`/read/cilistfiltered`, ciListFilterContainerUi] as const
}

export const useReadCiList1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>, TError = ApiError>(
    ciListFilterContainerUi: CiListFilterContainerUi,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiList1QueryKey(ciListFilterContainerUi)

    const readCiList1 = useReadCiList1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>> = ({ signal }) =>
        readCiList1(ciListFilterContainerUi, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type ReadCiList1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>>
export type ReadCiList1QueryError = ApiError

export const useReadCiList1 = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>, TError = ApiError>(
    ciListFilterContainerUi: CiListFilterContainerUi,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiList1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiList1QueryOptions(ciListFilterContainerUi, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadListNeighboursConfigurationItemsHook = () => {
    const readListNeighboursConfigurationItems = useCmdbSwaggerClient<ReadListNeighboursConfigurationItems200>()

    return useCallback(
        (ciListNeighboursFilterUi: CiListNeighboursFilterUi) => {
            return readListNeighboursConfigurationItems({
                url: `/read/ci/neighbours`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciListNeighboursFilterUi,
            })
        },
        [readListNeighboursConfigurationItems],
    )
}

export const useReadListNeighboursConfigurationItemsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadListNeighboursConfigurationItemsHook>>>,
        TError,
        { data: CiListNeighboursFilterUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReadListNeighboursConfigurationItemsHook>>>,
    TError,
    { data: CiListNeighboursFilterUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const readListNeighboursConfigurationItems = useReadListNeighboursConfigurationItemsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useReadListNeighboursConfigurationItemsHook>>>,
        { data: CiListNeighboursFilterUi }
    > = (props) => {
        const { data } = props ?? {}

        return readListNeighboursConfigurationItems(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadListNeighboursConfigurationItemsMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useReadListNeighboursConfigurationItemsHook>>>
>
export type ReadListNeighboursConfigurationItemsMutationBody = CiListNeighboursFilterUi
export type ReadListNeighboursConfigurationItemsMutationError = ApiError

export const useReadListNeighboursConfigurationItems = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReadListNeighboursConfigurationItemsHook>>>,
        TError,
        { data: CiListNeighboursFilterUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useReadListNeighboursConfigurationItemsHook>>>,
    TError,
    { data: CiListNeighboursFilterUi },
    TContext
> => {
    const mutationOptions = useReadListNeighboursConfigurationItemsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStorePoWithHierarchyRelHook = () => {
    const storePoWithHierarchyRel = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (poId: string, relId: string, poWithHierarchyUi: PoWithHierarchyUi) => {
            return storePoWithHierarchyRel({
                url: `/poWithHierarchy/${poId}/${relId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: poWithHierarchyUi,
            })
        },
        [storePoWithHierarchyRel],
    )
}

export const useStorePoWithHierarchyRelMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStorePoWithHierarchyRelHook>>>,
        TError,
        { poId: string; relId: string; data: PoWithHierarchyUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStorePoWithHierarchyRelHook>>>,
    TError,
    { poId: string; relId: string; data: PoWithHierarchyUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storePoWithHierarchyRel = useStorePoWithHierarchyRelHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStorePoWithHierarchyRelHook>>>,
        { poId: string; relId: string; data: PoWithHierarchyUi }
    > = (props) => {
        const { poId, relId, data } = props ?? {}

        return storePoWithHierarchyRel(poId, relId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StorePoWithHierarchyRelMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStorePoWithHierarchyRelHook>>>>
export type StorePoWithHierarchyRelMutationBody = PoWithHierarchyUi
export type StorePoWithHierarchyRelMutationError = ApiError

export const useStorePoWithHierarchyRel = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStorePoWithHierarchyRelHook>>>,
        TError,
        { poId: string; relId: string; data: PoWithHierarchyUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStorePoWithHierarchyRelHook>>>,
    TError,
    { poId: string; relId: string; data: PoWithHierarchyUi },
    TContext
> => {
    const mutationOptions = useStorePoWithHierarchyRelMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStorePoHook = () => {
    const storePo = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (poId: string, configurationItemUi: ConfigurationItemUi) => {
            return storePo({ url: `/po/${poId}`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: configurationItemUi })
        },
        [storePo],
    )
}

export const useStorePoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStorePoHook>>>,
        TError,
        { poId: string; data: ConfigurationItemUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStorePoHook>>>, TError, { poId: string; data: ConfigurationItemUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storePo = useStorePoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStorePoHook>>>, { poId: string; data: ConfigurationItemUi }> = (
        props,
    ) => {
        const { poId, data } = props ?? {}

        return storePo(poId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StorePoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStorePoHook>>>>
export type StorePoMutationBody = ConfigurationItemUi
export type StorePoMutationError = ApiError

export const useStorePo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStorePoHook>>>,
        TError,
        { poId: string; data: ConfigurationItemUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStorePoHook>>>, TError, { poId: string; data: ConfigurationItemUi }, TContext> => {
    const mutationOptions = useStorePoMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInvalidateSendEmailHook = () => {
    const invalidateSendEmail = useCmdbSwaggerClient<InvalidateSendEmail200>()

    return useCallback(
        (isvsUuid: string[], isvsName: string[]) => {
            return invalidateSendEmail({ url: `/invalidate/sendEmail/isvsUuid/${isvsUuid}/isvsName/${isvsName}`, method: 'POST' })
        },
        [invalidateSendEmail],
    )
}

export const useInvalidateSendEmailMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateSendEmailHook>>>,
        TError,
        { isvsUuid: string[]; isvsName: string[] },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInvalidateSendEmailHook>>>,
    TError,
    { isvsUuid: string[]; isvsName: string[] },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const invalidateSendEmail = useInvalidateSendEmailHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInvalidateSendEmailHook>>>,
        { isvsUuid: string[]; isvsName: string[] }
    > = (props) => {
        const { isvsUuid, isvsName } = props ?? {}

        return invalidateSendEmail(isvsUuid, isvsName)
    }

    return { mutationFn, ...mutationOptions }
}

export type InvalidateSendEmailMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInvalidateSendEmailHook>>>>

export type InvalidateSendEmailMutationError = ApiError

export const useInvalidateSendEmail = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateSendEmailHook>>>,
        TError,
        { isvsUuid: string[]; isvsName: string[] },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInvalidateSendEmailHook>>>,
    TError,
    { isvsUuid: string[]; isvsName: string[] },
    TContext
> => {
    const mutationOptions = useInvalidateSendEmailMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInvalidateRelationshipHook = () => {
    const invalidateRelationship = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (relationshipInvalidateUi: RelationshipInvalidateUi, params: InvalidateRelationshipParams) => {
            return invalidateRelationship({
                url: `/invalidate/relation`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipInvalidateUi,
                params,
            })
        },
        [invalidateRelationship],
    )
}

export const useInvalidateRelationshipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateRelationshipHook>>>,
        TError,
        { data: RelationshipInvalidateUi; params: InvalidateRelationshipParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInvalidateRelationshipHook>>>,
    TError,
    { data: RelationshipInvalidateUi; params: InvalidateRelationshipParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const invalidateRelationship = useInvalidateRelationshipHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInvalidateRelationshipHook>>>,
        { data: RelationshipInvalidateUi; params: InvalidateRelationshipParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return invalidateRelationship(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type InvalidateRelationshipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInvalidateRelationshipHook>>>>
export type InvalidateRelationshipMutationBody = RelationshipInvalidateUi
export type InvalidateRelationshipMutationError = ApiError

export const useInvalidateRelationship = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateRelationshipHook>>>,
        TError,
        { data: RelationshipInvalidateUi; params: InvalidateRelationshipParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInvalidateRelationshipHook>>>,
    TError,
    { data: RelationshipInvalidateUi; params: InvalidateRelationshipParams },
    TContext
> => {
    const mutationOptions = useInvalidateRelationshipMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInvalidateSetHook = () => {
    const invalidateSet = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (invalidateSetBody: InvalidateSetBody) => {
            return invalidateSet({
                url: `/invalidate/list`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: invalidateSetBody,
            })
        },
        [invalidateSet],
    )
}

export const useInvalidateSetMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInvalidateSetHook>>>, TError, { data: InvalidateSetBody }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInvalidateSetHook>>>, TError, { data: InvalidateSetBody }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const invalidateSet = useInvalidateSetHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useInvalidateSetHook>>>, { data: InvalidateSetBody }> = (props) => {
        const { data } = props ?? {}

        return invalidateSet(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InvalidateSetMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInvalidateSetHook>>>>
export type InvalidateSetMutationBody = InvalidateSetBody
export type InvalidateSetMutationError = ApiError

export const useInvalidateSet = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInvalidateSetHook>>>, TError, { data: InvalidateSetBody }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useInvalidateSetHook>>>, TError, { data: InvalidateSetBody }, TContext> => {
    const mutationOptions = useInvalidateSetMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInvalidateConfigurationItemHook = () => {
    const invalidateConfigurationItem = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (configurationItemInvalidateUi: ConfigurationItemInvalidateUi) => {
            return invalidateConfigurationItem({
                url: `/invalidate/ci`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: configurationItemInvalidateUi,
            })
        },
        [invalidateConfigurationItem],
    )
}

export const useInvalidateConfigurationItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemHook>>>,
        TError,
        { data: ConfigurationItemInvalidateUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemHook>>>,
    TError,
    { data: ConfigurationItemInvalidateUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const invalidateConfigurationItem = useInvalidateConfigurationItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemHook>>>,
        { data: ConfigurationItemInvalidateUi }
    > = (props) => {
        const { data } = props ?? {}

        return invalidateConfigurationItem(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InvalidateConfigurationItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemHook>>>>
export type InvalidateConfigurationItemMutationBody = ConfigurationItemInvalidateUi
export type InvalidateConfigurationItemMutationError = ApiError

export const useInvalidateConfigurationItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemHook>>>,
        TError,
        { data: ConfigurationItemInvalidateUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemHook>>>,
    TError,
    { data: ConfigurationItemInvalidateUi },
    TContext
> => {
    const mutationOptions = useInvalidateConfigurationItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInvalidateConfigurationItemBiznisMdulesHook = () => {
    const invalidateConfigurationItemBiznisMdules = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (configurationItemInvalidateUi: ConfigurationItemInvalidateUi) => {
            return invalidateConfigurationItemBiznisMdules({
                url: `/invalidate/ci/biznis`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: configurationItemInvalidateUi,
            })
        },
        [invalidateConfigurationItemBiznisMdules],
    )
}

export const useInvalidateConfigurationItemBiznisMdulesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
        TError,
        { data: ConfigurationItemInvalidateUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
    TError,
    { data: ConfigurationItemInvalidateUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const invalidateConfigurationItemBiznisMdules = useInvalidateConfigurationItemBiznisMdulesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
        { data: ConfigurationItemInvalidateUi }
    > = (props) => {
        const { data } = props ?? {}

        return invalidateConfigurationItemBiznisMdules(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InvalidateConfigurationItemBiznisMdulesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>
>
export type InvalidateConfigurationItemBiznisMdulesMutationBody = ConfigurationItemInvalidateUi
export type InvalidateConfigurationItemBiznisMdulesMutationError = ApiError

export const useInvalidateConfigurationItemBiznisMdules = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
        TError,
        { data: ConfigurationItemInvalidateUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
    TError,
    { data: ConfigurationItemInvalidateUi },
    TContext
> => {
    const mutationOptions = useInvalidateConfigurationItemBiznisMdulesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStorePoHierarchyRelHook = () => {
    const storePoHierarchyRel = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (relId: string, relationshipUi: RelationshipUi) => {
            return storePoHierarchyRel({
                url: `/hierarchy/${relId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipUi,
            })
        },
        [storePoHierarchyRel],
    )
}

export const useStorePoHierarchyRelMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStorePoHierarchyRelHook>>>,
        TError,
        { relId: string; data: RelationshipUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStorePoHierarchyRelHook>>>,
    TError,
    { relId: string; data: RelationshipUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storePoHierarchyRel = useStorePoHierarchyRelHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStorePoHierarchyRelHook>>>,
        { relId: string; data: RelationshipUi }
    > = (props) => {
        const { relId, data } = props ?? {}

        return storePoHierarchyRel(relId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StorePoHierarchyRelMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStorePoHierarchyRelHook>>>>
export type StorePoHierarchyRelMutationBody = RelationshipUi
export type StorePoHierarchyRelMutationError = ApiError

export const useStorePoHierarchyRel = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStorePoHierarchyRelHook>>>,
        TError,
        { relId: string; data: RelationshipUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStorePoHierarchyRelHook>>>,
    TError,
    { relId: string; data: RelationshipUi },
    TContext
> => {
    const mutationOptions = useStorePoHierarchyRelMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReportErrorHook = () => {
    const reportError = useCmdbSwaggerClient<RequestId>()

    return useCallback(
        (userFeedbackErrorReportUi: UserFeedbackErrorReportUi) => {
            return reportError({
                url: `/feedback/error`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: userFeedbackErrorReportUi,
            })
        },
        [reportError],
    )
}

export const useReportErrorMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReportErrorHook>>>, TError, { data: UserFeedbackErrorReportUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReportErrorHook>>>, TError, { data: UserFeedbackErrorReportUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reportError = useReportErrorHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReportErrorHook>>>, { data: UserFeedbackErrorReportUi }> = (props) => {
        const { data } = props ?? {}

        return reportError(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReportErrorMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReportErrorHook>>>>
export type ReportErrorMutationBody = UserFeedbackErrorReportUi
export type ReportErrorMutationError = ApiError

export const useReportError = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReportErrorHook>>>, TError, { data: UserFeedbackErrorReportUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReportErrorHook>>>, TError, { data: UserFeedbackErrorReportUi }, TContext> => {
    const mutationOptions = useReportErrorMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readQuery
 */
export const useReadQuery1Hook = () => {
    const readQuery1 = useCmdbSwaggerClient<QueryResultTableUi>()

    return useCallback(
        (queryUi: QueryUi) => {
            return readQuery1({ url: `/external/read/query`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: queryUi })
        },
        [readQuery1],
    )
}

export const useReadQuery1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadQuery1Hook>>>, TError, { data: QueryUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadQuery1Hook>>>, TError, { data: QueryUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readQuery1 = useReadQuery1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadQuery1Hook>>>, { data: QueryUi }> = (props) => {
        const { data } = props ?? {}

        return readQuery1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadQuery1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadQuery1Hook>>>>
export type ReadQuery1MutationBody = QueryUi
export type ReadQuery1MutationError = ApiError

/**
 * @summary readQuery
 */
export const useReadQuery1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadQuery1Hook>>>, TError, { data: QueryUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadQuery1Hook>>>, TError, { data: QueryUi }, TContext> => {
    const mutationOptions = useReadQuery1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteRelationshipHook = () => {
    const deleteRelationship = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (relationshipInvalidateUi: RelationshipInvalidateUi) => {
            return deleteRelationship({
                url: `/delete/relation`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipInvalidateUi,
            })
        },
        [deleteRelationship],
    )
}

export const useDeleteRelationshipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelationshipHook>>>,
        TError,
        { data: RelationshipInvalidateUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteRelationshipHook>>>, TError, { data: RelationshipInvalidateUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteRelationship = useDeleteRelationshipHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteRelationshipHook>>>, { data: RelationshipInvalidateUi }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return deleteRelationship(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRelationshipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteRelationshipHook>>>>
export type DeleteRelationshipMutationBody = RelationshipInvalidateUi
export type DeleteRelationshipMutationError = ApiError

export const useDeleteRelationship = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelationshipHook>>>,
        TError,
        { data: RelationshipInvalidateUi },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteRelationshipHook>>>, TError, { data: RelationshipInvalidateUi }, TContext> => {
    const mutationOptions = useDeleteRelationshipMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangeOwnerSetHook = () => {
    const changeOwnerSet = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (changeOwnerSetUi: ChangeOwnerSetUi) => {
            return changeOwnerSet({
                url: `/changeOwner/list`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: changeOwnerSetUi,
            })
        },
        [changeOwnerSet],
    )
}

export const useChangeOwnerSetMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeOwnerSetHook>>>, TError, { data: ChangeOwnerSetUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeOwnerSetHook>>>, TError, { data: ChangeOwnerSetUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const changeOwnerSet = useChangeOwnerSetHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangeOwnerSetHook>>>, { data: ChangeOwnerSetUi }> = (props) => {
        const { data } = props ?? {}

        return changeOwnerSet(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangeOwnerSetMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeOwnerSetHook>>>>
export type ChangeOwnerSetMutationBody = ChangeOwnerSetUi
export type ChangeOwnerSetMutationError = ApiError

export const useChangeOwnerSet = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeOwnerSetHook>>>, TError, { data: ChangeOwnerSetUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useChangeOwnerSetHook>>>, TError, { data: ChangeOwnerSetUi }, TContext> => {
    const mutationOptions = useChangeOwnerSetMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangePoHierarchyHook = () => {
    const changePoHierarchy = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (oldRelId: string, newRelId: string, changeHierarchyUi: ChangeHierarchyUi) => {
            return changePoHierarchy({
                url: `/changeHierarchy/${oldRelId}/${newRelId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: changeHierarchyUi,
            })
        },
        [changePoHierarchy],
    )
}

export const useChangePoHierarchyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useChangePoHierarchyHook>>>,
        TError,
        { oldRelId: string; newRelId: string; data: ChangeHierarchyUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChangePoHierarchyHook>>>,
    TError,
    { oldRelId: string; newRelId: string; data: ChangeHierarchyUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const changePoHierarchy = useChangePoHierarchyHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useChangePoHierarchyHook>>>,
        { oldRelId: string; newRelId: string; data: ChangeHierarchyUi }
    > = (props) => {
        const { oldRelId, newRelId, data } = props ?? {}

        return changePoHierarchy(oldRelId, newRelId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangePoHierarchyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangePoHierarchyHook>>>>
export type ChangePoHierarchyMutationBody = ChangeHierarchyUi
export type ChangePoHierarchyMutationError = ApiError

export const useChangePoHierarchy = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useChangePoHierarchyHook>>>,
        TError,
        { oldRelId: string; newRelId: string; data: ChangeHierarchyUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useChangePoHierarchyHook>>>,
    TError,
    { oldRelId: string; newRelId: string; data: ChangeHierarchyUi },
    TContext
> => {
    const mutationOptions = useChangePoHierarchyMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddCiToGroupHook = () => {
    const addCiToGroup = useCmdbSwaggerClient<RequestIdUi>()

    return useCallback(
        (uuid: string, addCiToGroupBody: string[]) => {
            return addCiToGroup({
                url: `/addmembers/group/${uuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: addCiToGroupBody,
            })
        },
        [addCiToGroup],
    )
}

export const useAddCiToGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddCiToGroupHook>>>, TError, { uuid: string; data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddCiToGroupHook>>>, TError, { uuid: string; data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addCiToGroup = useAddCiToGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddCiToGroupHook>>>, { uuid: string; data: string[] }> = (props) => {
        const { uuid, data } = props ?? {}

        return addCiToGroup(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddCiToGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddCiToGroupHook>>>>
export type AddCiToGroupMutationBody = string[]
export type AddCiToGroupMutationError = ApiError

export const useAddCiToGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddCiToGroupHook>>>, TError, { uuid: string; data: string[] }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useAddCiToGroupHook>>>, TError, { uuid: string; data: string[] }, TContext> => {
    const mutationOptions = useAddCiToGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidateCIsByTypesAndOwnerHook = () => {
    const validateCIsByTypesAndOwner = useCmdbSwaggerClient<CiProblemMessageHolderListUi>()

    return useCallback(
        (params: ValidateCIsByTypesAndOwnerParams, signal?: AbortSignal) => {
            return validateCIsByTypesAndOwner({ url: `/validateCIsByTypesAndOwner`, method: 'GET', params, signal })
        },
        [validateCIsByTypesAndOwner],
    )
}

export const getValidateCIsByTypesAndOwnerQueryKey = (params: ValidateCIsByTypesAndOwnerParams) => {
    return [`/validateCIsByTypesAndOwner`, ...(params ? [params] : [])] as const
}

export const useValidateCIsByTypesAndOwnerQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>,
    TError = ApiError,
>(
    params: ValidateCIsByTypesAndOwnerParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getValidateCIsByTypesAndOwnerQueryKey(params)

    const validateCIsByTypesAndOwner = useValidateCIsByTypesAndOwnerHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>> = ({ signal }) =>
        validateCIsByTypesAndOwner(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ValidateCIsByTypesAndOwnerQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>>
export type ValidateCIsByTypesAndOwnerQueryError = ApiError

export const useValidateCIsByTypesAndOwner = <TData = Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>, TError = ApiError>(
    params: ValidateCIsByTypesAndOwnerParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useValidateCIsByTypesAndOwnerHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useValidateCIsByTypesAndOwnerQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListAllReindexTasksHook = () => {
    const listAllReindexTasks = useCmdbSwaggerClient<ReindexTaskSimpleDTO[]>()

    return useCallback(
        (signal?: AbortSignal) => {
            return listAllReindexTasks({ url: `/util/reindexelastic/tasks`, method: 'GET', signal })
        },
        [listAllReindexTasks],
    )
}

export const getListAllReindexTasksQueryKey = () => {
    return [`/util/reindexelastic/tasks`] as const
}

export const useListAllReindexTasksQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAllReindexTasksQueryKey()

    const listAllReindexTasks = useListAllReindexTasksHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>> = ({ signal }) => listAllReindexTasks(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListAllReindexTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>>
export type ListAllReindexTasksQueryError = ApiError

export const useListAllReindexTasks = <TData = Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListAllReindexTasksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReindexTaskStateByUuidHook = () => {
    const getReindexTaskStateByUuid = useCmdbSwaggerClient<ReindexTask>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return getReindexTaskStateByUuid({ url: `/util/reindexelastic/tasks/${uuid}`, method: 'GET', signal })
        },
        [getReindexTaskStateByUuid],
    )
}

export const getGetReindexTaskStateByUuidQueryKey = (uuid: string) => {
    return [`/util/reindexelastic/tasks/${uuid}`] as const
}

export const useGetReindexTaskStateByUuidQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReindexTaskStateByUuidQueryKey(uuid)

    const getReindexTaskStateByUuid = useGetReindexTaskStateByUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>> = ({ signal }) =>
        getReindexTaskStateByUuid(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReindexTaskStateByUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>>
export type GetReindexTaskStateByUuidQueryError = ApiError

export const useGetReindexTaskStateByUuid = <TData = Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetReindexTaskStateByUuidQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCancelReindexTaskByUuidHook = () => {
    const cancelReindexTaskByUuid = useCmdbSwaggerClient<string>()

    return useCallback(
        (uuid: string) => {
            return cancelReindexTaskByUuid({ url: `/util/reindexelastic/tasks/${uuid}`, method: 'DELETE' })
        },
        [cancelReindexTaskByUuid],
    )
}

export const useCancelReindexTaskByUuidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelReindexTaskByUuid = useCancelReindexTaskByUuidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return cancelReindexTaskByUuid(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelReindexTaskByUuidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>>

export type CancelReindexTaskByUuidMutationError = ApiError

export const useCancelReindexTaskByUuid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext> => {
    const mutationOptions = useCancelReindexTaskByUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetPropertiesHook = () => {
    const getProperties = useCmdbSwaggerClient<GetProperties200>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getProperties({ url: `/util/properties`, method: 'GET', signal })
        },
        [getProperties],
    )
}

export const getGetPropertiesQueryKey = () => {
    return [`/util/properties`] as const
}

export const useGetPropertiesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPropertiesQueryKey()

    const getProperties = useGetPropertiesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>> = ({ signal }) => getProperties(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetPropertiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>>
export type GetPropertiesQueryError = ApiError

export const useGetProperties = <TData = Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPropertiesHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPropertiesQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetDocumentHook = () => {
    const getDocument = useCmdbSwaggerClient<GetResponseWrapper>()

    return useCallback(
        (ciUuid: string, signal?: AbortSignal) => {
            return getDocument({ url: `/util/getDocument/${ciUuid}`, method: 'GET', signal })
        },
        [getDocument],
    )
}

export const getGetDocumentQueryKey = (ciUuid: string) => {
    return [`/util/getDocument/${ciUuid}`] as const
}

export const useGetDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError = ApiError>(
    ciUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetDocumentQueryKey(ciUuid)

    const getDocument = useGetDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>> = ({ signal }) => getDocument(ciUuid, signal)

    return { queryKey, queryFn, enabled: !!ciUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>>
export type GetDocumentQueryError = ApiError

export const useGetDocument = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError = ApiError>(
    ciUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetDocumentQueryOptions(ciUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useIntegrityCheckHook = () => {
    const integrityCheck = useCmdbSwaggerClient<IntegrityCheckHolder>()

    return useCallback(
        (signal?: AbortSignal) => {
            return integrityCheck({ url: `/util/databaseIntegrityCheck`, method: 'GET', signal })
        },
        [integrityCheck],
    )
}

export const getIntegrityCheckQueryKey = () => {
    return [`/util/databaseIntegrityCheck`] as const
}

export const useIntegrityCheckQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIntegrityCheckQueryKey()

    const integrityCheck = useIntegrityCheckHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>> = ({ signal }) => integrityCheck(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type IntegrityCheckQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>>
export type IntegrityCheckQueryError = ApiError

export const useIntegrityCheck = <TData = Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIntegrityCheckHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIntegrityCheckQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCiRelTypesIntegrityCheckHook = () => {
    const ciRelTypesIntegrityCheck = useCmdbSwaggerClient<CiRelTypesIntegrityCheck200>()

    return useCallback(
        (signal?: AbortSignal) => {
            return ciRelTypesIntegrityCheck({ url: `/util/ciRelTypesIntegrityCheck`, method: 'GET', signal })
        },
        [ciRelTypesIntegrityCheck],
    )
}

export const getCiRelTypesIntegrityCheckQueryKey = () => {
    return [`/util/ciRelTypesIntegrityCheck`] as const
}

export const useCiRelTypesIntegrityCheckQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCiRelTypesIntegrityCheckQueryKey()

    const ciRelTypesIntegrityCheck = useCiRelTypesIntegrityCheckHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>> = ({ signal }) =>
        ciRelTypesIntegrityCheck(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type CiRelTypesIntegrityCheckQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>>
export type CiRelTypesIntegrityCheckQueryError = ApiError

export const useCiRelTypesIntegrityCheck = <
    TData = Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCiRelTypesIntegrityCheckHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useCiRelTypesIntegrityCheckQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRequestStatusHook = () => {
    const getRequestStatus = useCmdbSwaggerClient<RequestStatusResultUi>()

    return useCallback(
        (requestId: string, signal?: AbortSignal) => {
            return getRequestStatus({ url: `/request-tracking/${requestId}`, method: 'GET', signal })
        },
        [getRequestStatus],
    )
}

export const getGetRequestStatusQueryKey = (requestId: string) => {
    return [`/request-tracking/${requestId}`] as const
}

export const useGetRequestStatusQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>, TError = ApiError>(
    requestId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRequestStatusQueryKey(requestId)

    const getRequestStatus = useGetRequestStatusHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>> = ({ signal }) =>
        getRequestStatus(requestId, signal)

    return { queryKey, queryFn, enabled: !!requestId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetRequestStatusQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>>
export type GetRequestStatusQueryError = ApiError

export const useGetRequestStatus = <TData = Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>, TError = ApiError>(
    requestId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestStatusHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRequestStatusQueryOptions(requestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadReportsWithFilterHook = () => {
    const readReportsWithFilter = useCmdbSwaggerClient<ReportHolderUi>()

    return useCallback(
        (params?: ReadReportsWithFilterParams, signal?: AbortSignal) => {
            return readReportsWithFilter({ url: `/reports`, method: 'GET', params, signal })
        },
        [readReportsWithFilter],
    )
}

export const getReadReportsWithFilterQueryKey = (params?: ReadReportsWithFilterParams) => {
    return [`/reports`, ...(params ? [params] : [])] as const
}

export const useReadReportsWithFilterQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>, TError = ApiError>(
    params?: ReadReportsWithFilterParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadReportsWithFilterQueryKey(params)

    const readReportsWithFilter = useReadReportsWithFilterHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>> = ({ signal }) =>
        readReportsWithFilter(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadReportsWithFilterQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>>
export type ReadReportsWithFilterQueryError = ApiError

export const useReadReportsWithFilter = <TData = Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>, TError = ApiError>(
    params?: ReadReportsWithFilterParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadReportsWithFilterHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadReportsWithFilterQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadConfigurationItemByMetaIsCodeHook = () => {
    const readConfigurationItemByMetaIsCode = useCmdbSwaggerClient<ConfigurationItemUi>()

    return useCallback(
        (metaIsCode: string, signal?: AbortSignal) => {
            return readConfigurationItemByMetaIsCode({ url: `/readByMetaIsCode/ci/${metaIsCode}`, method: 'GET', signal })
        },
        [readConfigurationItemByMetaIsCode],
    )
}

export const getReadConfigurationItemByMetaIsCodeQueryKey = (metaIsCode: string) => {
    return [`/readByMetaIsCode/ci/${metaIsCode}`] as const
}

export const useReadConfigurationItemByMetaIsCodeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>,
    TError = ApiError,
>(
    metaIsCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadConfigurationItemByMetaIsCodeQueryKey(metaIsCode)

    const readConfigurationItemByMetaIsCode = useReadConfigurationItemByMetaIsCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>> = ({ signal }) =>
        readConfigurationItemByMetaIsCode(metaIsCode, signal)

    return { queryKey, queryFn, enabled: !!metaIsCode, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadConfigurationItemByMetaIsCodeQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>
>
export type ReadConfigurationItemByMetaIsCodeQueryError = ApiError

export const useReadConfigurationItemByMetaIsCode = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>,
    TError = ApiError,
>(
    metaIsCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemByMetaIsCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadConfigurationItemByMetaIsCodeQueryOptions(metaIsCode, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRoleParticipantHook = () => {
    const getRoleParticipant = useClientForGetRoleParticipantUsingGET<RoleParticipantUI>()

    return useCallback(
        (gid: string, signal?: AbortSignal) => {
            return getRoleParticipant({ url: `/read/roleParticipant/${gid}`, method: 'GET', signal })
        },
        [getRoleParticipant],
    )
}

export const getGetRoleParticipantQueryKey = (gid: string) => {
    return [`/read/roleParticipant/${gid}`] as const
}

export const useGetRoleParticipantQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>, TError = ApiError>(
    gid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRoleParticipantQueryKey(gid)

    const getRoleParticipant = useGetRoleParticipantHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>> = ({ signal }) => getRoleParticipant(gid, signal)

    return { queryKey, queryFn, enabled: !!gid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetRoleParticipantQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>>
export type GetRoleParticipantQueryError = ApiError

export const useGetRoleParticipant = <TData = Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>, TError = ApiError>(
    gid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRoleParticipantHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRoleParticipantQueryOptions(gid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiNeighboursWithAllRelsHook = () => {
    const readCiNeighboursWithAllRels = useClientForReadCiNeighboursWithAllRelsUsingGET<CiWithRelsResultUi>()

    return useCallback(
        (uuid: string, params?: ReadCiNeighboursWithAllRelsParams, signal?: AbortSignal) => {
            return readCiNeighboursWithAllRels({ url: `/read/relations/neighbourswithallrels/${uuid}`, method: 'GET', params, signal })
        },
        [readCiNeighboursWithAllRels],
    )
}

export const getReadCiNeighboursWithAllRelsQueryKey = (uuid: string, params?: ReadCiNeighboursWithAllRelsParams) => {
    return [`/read/relations/neighbourswithallrels/${uuid}`, ...(params ? [params] : [])] as const
}

export const useReadCiNeighboursWithAllRelsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: ReadCiNeighboursWithAllRelsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiNeighboursWithAllRelsQueryKey(uuid, params)

    const readCiNeighboursWithAllRels = useReadCiNeighboursWithAllRelsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>> = ({ signal }) =>
        readCiNeighboursWithAllRels(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiNeighboursWithAllRelsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>>
export type ReadCiNeighboursWithAllRelsQueryError = ApiError

export const useReadCiNeighboursWithAllRels = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadCiNeighboursWithAllRelsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiNeighboursWithAllRelsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiNeighboursWithAllRelsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadRelationshipHook = () => {
    const readRelationship = useCmdbSwaggerClient<RelationshipUi>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return readRelationship({ url: `/read/relation/${uuid}`, method: 'GET', signal })
        },
        [readRelationship],
    )
}

export const getReadRelationshipQueryKey = (uuid: string) => {
    return [`/read/relation/${uuid}`] as const
}

export const useReadRelationshipQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadRelationshipQueryKey(uuid)

    const readRelationship = useReadRelationshipHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>> = ({ signal }) => readRelationship(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadRelationshipQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>>
export type ReadRelationshipQueryError = ApiError

export const useReadRelationship = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelationshipHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadRelationshipQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetKSSpravcaVerejnaMocAktivnaFZCHook = () => {
    const getKSSpravcaVerejnaMocAktivnaFZC = useCmdbSwaggerClient<QueryResultTableUi>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getKSSpravcaVerejnaMocAktivnaFZC({ url: `/read/ksSpVmFazaZivCyklu`, method: 'GET', signal })
        },
        [getKSSpravcaVerejnaMocAktivnaFZC],
    )
}

export const getGetKSSpravcaVerejnaMocAktivnaFZCQueryKey = () => {
    return [`/read/ksSpVmFazaZivCyklu`] as const
}

export const useGetKSSpravcaVerejnaMocAktivnaFZCQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetKSSpravcaVerejnaMocAktivnaFZCQueryKey()

    const getKSSpravcaVerejnaMocAktivnaFZC = useGetKSSpravcaVerejnaMocAktivnaFZCHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>> = ({ signal }) =>
        getKSSpravcaVerejnaMocAktivnaFZC(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetKSSpravcaVerejnaMocAktivnaFZCQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>>
export type GetKSSpravcaVerejnaMocAktivnaFZCQueryError = ApiError

export const useGetKSSpravcaVerejnaMocAktivnaFZC = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKSSpravcaVerejnaMocAktivnaFZCHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetKSSpravcaVerejnaMocAktivnaFZCQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary readCiList
 */
export const useReadCiList2Hook = () => {
    const readCiList2 = useCmdbSwaggerClient<ConfigurationItemSetUi>()

    return useCallback(
        (params?: ReadCiList2Params, signal?: AbortSignal) => {
            return readCiList2({ url: `/read/cilist`, method: 'GET', params, signal })
        },
        [readCiList2],
    )
}

export const getReadCiList2QueryKey = (params?: ReadCiList2Params) => {
    return [`/read/cilist`, ...(params ? [params] : [])] as const
}

export const useReadCiList2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>, TError = ApiError>(
    params?: ReadCiList2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiList2QueryKey(params)

    const readCiList2 = useReadCiList2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>> = ({ signal }) => readCiList2(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type ReadCiList2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>>
export type ReadCiList2QueryError = ApiError

/**
 * @summary readCiList
 */
export const useReadCiList2 = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>, TError = ApiError>(
    params?: ReadCiList2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiList2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiList2QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadConfigurationItemHook = () => {
    const readConfigurationItem = useClientForReadConfigurationItemUsingGET<ConfigurationItemUi>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return readConfigurationItem({ url: `/read/ci/${uuid}`, method: 'GET', signal })
        },
        [readConfigurationItem],
    )
}

export const getReadConfigurationItemQueryKey = (uuid: string) => {
    return [`/read/ci/${uuid}`] as const
}

export const useReadConfigurationItemQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadConfigurationItemQueryKey(uuid)

    const readConfigurationItem = useReadConfigurationItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>> = ({ signal }) =>
        readConfigurationItem(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadConfigurationItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>>
export type ReadConfigurationItemQueryError = ApiError

export const useReadConfigurationItem = <TData = Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadConfigurationItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadConfigurationItemQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadRelationshipsHook = () => {
    const readRelationships = useCmdbSwaggerClient<IncidentRelationshipSetUi>()

    return useCallback(
        (uuid: string, params?: ReadRelationshipsParams, signal?: AbortSignal) => {
            return readRelationships({ url: `/read/ci/${uuid}/relations`, method: 'GET', params, signal })
        },
        [readRelationships],
    )
}

export const getReadRelationshipsQueryKey = (uuid: string, params?: ReadRelationshipsParams) => {
    return [`/read/ci/${uuid}/relations`, ...(params ? [params] : [])] as const
}

export const useReadRelationshipsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadRelationshipsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadRelationshipsQueryKey(uuid, params)

    const readRelationships = useReadRelationshipsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>> = ({ signal }) =>
        readRelationships(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadRelationshipsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>>
export type ReadRelationshipsQueryError = ApiError

export const useReadRelationships = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadRelationshipsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelationshipsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadRelationshipsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadNeighboursConfigurationItemsCountHook = () => {
    const readNeighboursConfigurationItemsCount = useCmdbSwaggerClient<ReadNeighboursConfigurationItemsCount200>()

    return useCallback(
        (uuid: string, params?: ReadNeighboursConfigurationItemsCountParams, signal?: AbortSignal) => {
            return readNeighboursConfigurationItemsCount({ url: `/read/ci/${uuid}/neighbourscount`, method: 'GET', params, signal })
        },
        [readNeighboursConfigurationItemsCount],
    )
}

export const getReadNeighboursConfigurationItemsCountQueryKey = (uuid: string, params?: ReadNeighboursConfigurationItemsCountParams) => {
    return [`/read/ci/${uuid}/neighbourscount`, ...(params ? [params] : [])] as const
}

export const useReadNeighboursConfigurationItemsCountQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: ReadNeighboursConfigurationItemsCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadNeighboursConfigurationItemsCountQueryKey(uuid, params)

    const readNeighboursConfigurationItemsCount = useReadNeighboursConfigurationItemsCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>> = ({ signal }) =>
        readNeighboursConfigurationItemsCount(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadNeighboursConfigurationItemsCountQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>
>
export type ReadNeighboursConfigurationItemsCountQueryError = ApiError

export const useReadNeighboursConfigurationItemsCount = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: ReadNeighboursConfigurationItemsCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadNeighboursConfigurationItemsCountQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadNeighboursConfigurationItemsHook = () => {
    const readNeighboursConfigurationItems = useClientForReadCiListNeighboursUsingGET<ConfigurationItemNeighbourSetUi>()

    return useCallback(
        (uuid: string, params?: ReadNeighboursConfigurationItemsParams, signal?: AbortSignal) => {
            return readNeighboursConfigurationItems({ url: `/read/ci/${uuid}/neighbours`, method: 'GET', params, signal })
        },
        [readNeighboursConfigurationItems],
    )
}

export const getReadNeighboursConfigurationItemsQueryKey = (uuid: string, params?: ReadNeighboursConfigurationItemsParams) => {
    return [`/read/ci/${uuid}/neighbours`, ...(params ? [params] : [])] as const
}

export const useReadNeighboursConfigurationItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: ReadNeighboursConfigurationItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadNeighboursConfigurationItemsQueryKey(uuid, params)

    const readNeighboursConfigurationItems = useReadNeighboursConfigurationItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>> = ({ signal }) =>
        readNeighboursConfigurationItems(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadNeighboursConfigurationItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>>
export type ReadNeighboursConfigurationItemsQueryError = ApiError

export const useReadNeighboursConfigurationItems = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: ReadNeighboursConfigurationItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadNeighboursConfigurationItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadNeighboursConfigurationItemsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiDerivedRelTypesCountHook = () => {
    const readCiDerivedRelTypesCount = useCmdbSwaggerClient<DerivedCiTypeCountSummaryUi>()

    return useCallback(
        (ciUUID: string, params?: ReadCiDerivedRelTypesCountParams, signal?: AbortSignal) => {
            return readCiDerivedRelTypesCount({ url: `/read/ci/${ciUUID}/neighbourscount/derived`, method: 'GET', params, signal })
        },
        [readCiDerivedRelTypesCount],
    )
}

export const getReadCiDerivedRelTypesCountQueryKey = (ciUUID: string, params?: ReadCiDerivedRelTypesCountParams) => {
    return [`/read/ci/${ciUUID}/neighbourscount/derived`, ...(params ? [params] : [])] as const
}

export const useReadCiDerivedRelTypesCountQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>,
    TError = ApiError,
>(
    ciUUID: string,
    params?: ReadCiDerivedRelTypesCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiDerivedRelTypesCountQueryKey(ciUUID, params)

    const readCiDerivedRelTypesCount = useReadCiDerivedRelTypesCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>> = ({ signal }) =>
        readCiDerivedRelTypesCount(ciUUID, params, signal)

    return { queryKey, queryFn, enabled: !!ciUUID, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiDerivedRelTypesCountQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>>
export type ReadCiDerivedRelTypesCountQueryError = ApiError

export const useReadCiDerivedRelTypesCount = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>, TError = ApiError>(
    ciUUID: string,
    params?: ReadCiDerivedRelTypesCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiDerivedRelTypesCountQueryOptions(ciUUID, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiDerivedRelTypesHook = () => {
    const readCiDerivedRelTypes = useCmdbSwaggerClient<CiWithRelsResultUi>()

    return useCallback(
        (ciUUID: string, derivedTechnicalName: string, params?: ReadCiDerivedRelTypesParams, signal?: AbortSignal) => {
            return readCiDerivedRelTypes({ url: `/read/ci/${ciUUID}/neighbours/derived/${derivedTechnicalName}`, method: 'GET', params, signal })
        },
        [readCiDerivedRelTypes],
    )
}

export const getReadCiDerivedRelTypesQueryKey = (ciUUID: string, derivedTechnicalName: string, params?: ReadCiDerivedRelTypesParams) => {
    return [`/read/ci/${ciUUID}/neighbours/derived/${derivedTechnicalName}`, ...(params ? [params] : [])] as const
}

export const useReadCiDerivedRelTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>, TError = ApiError>(
    ciUUID: string,
    derivedTechnicalName: string,
    params?: ReadCiDerivedRelTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiDerivedRelTypesQueryKey(ciUUID, derivedTechnicalName, params)

    const readCiDerivedRelTypes = useReadCiDerivedRelTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>> = ({ signal }) =>
        readCiDerivedRelTypes(ciUUID, derivedTechnicalName, params, signal)

    return { queryKey, queryFn, enabled: !!(ciUUID && derivedTechnicalName), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiDerivedRelTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>>
export type ReadCiDerivedRelTypesQueryError = ApiError

export const useReadCiDerivedRelTypes = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>, TError = ApiError>(
    ciUUID: string,
    derivedTechnicalName: string,
    params?: ReadCiDerivedRelTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiDerivedRelTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiDerivedRelTypesQueryOptions(ciUUID, derivedTechnicalName, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCountCiTypesByOwnerHook = () => {
    const getCountCiTypesByOwner = useCmdbSwaggerClient<CountTypes[]>()

    return useCallback(
        (params?: GetCountCiTypesByOwnerParams, signal?: AbortSignal) => {
            return getCountCiTypesByOwner({ url: `/read/ci/counttypes`, method: 'GET', params, signal })
        },
        [getCountCiTypesByOwner],
    )
}

export const getGetCountCiTypesByOwnerQueryKey = (params?: GetCountCiTypesByOwnerParams) => {
    return [`/read/ci/counttypes`, ...(params ? [params] : [])] as const
}

export const useGetCountCiTypesByOwnerQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>,
    TError = ApiError,
>(
    params?: GetCountCiTypesByOwnerParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCountCiTypesByOwnerQueryKey(params)

    const getCountCiTypesByOwner = useGetCountCiTypesByOwnerHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>> = ({ signal }) =>
        getCountCiTypesByOwner(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCountCiTypesByOwnerQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>>
export type GetCountCiTypesByOwnerQueryError = ApiError

export const useGetCountCiTypesByOwner = <TData = Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>, TError = ApiError>(
    params?: GetCountCiTypesByOwnerParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCountCiTypesByOwnerHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCountCiTypesByOwnerQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetMetricsHook = () => {
    const getMetrics = useCmdbSwaggerClient<void>()

    return useCallback(
        (params?: GetMetricsParams, signal?: AbortSignal) => {
            return getMetrics({ url: `/metrics`, method: 'GET', params, signal })
        },
        [getMetrics],
    )
}

export const getGetMetricsQueryKey = (params?: GetMetricsParams) => {
    return [`/metrics`, ...(params ? [params] : [])] as const
}

export const useGetMetricsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError = ApiError>(
    params?: GetMetricsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMetricsQueryKey(params)

    const getMetrics = useGetMetricsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>> = ({ signal }) => getMetrics(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetMetricsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>>
export type GetMetricsQueryError = ApiError

export const useGetMetrics = <TData = Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError = ApiError>(
    params?: GetMetricsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetMetricsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadRelHistoryVersionHook = () => {
    const readRelHistoryVersion = useCmdbSwaggerClient<HistoryVersionUiRelationshipUi>()

    return useCallback(
        (uuid: string, params?: ReadRelHistoryVersionParams, signal?: AbortSignal) => {
            return readRelHistoryVersion({ url: `/history/read/rel/${uuid}`, method: 'GET', params, signal })
        },
        [readRelHistoryVersion],
    )
}

export const getReadRelHistoryVersionQueryKey = (uuid: string, params?: ReadRelHistoryVersionParams) => {
    return [`/history/read/rel/${uuid}`, ...(params ? [params] : [])] as const
}

export const useReadRelHistoryVersionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadRelHistoryVersionParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadRelHistoryVersionQueryKey(uuid, params)

    const readRelHistoryVersion = useReadRelHistoryVersionHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>> = ({ signal }) =>
        readRelHistoryVersion(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadRelHistoryVersionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>>
export type ReadRelHistoryVersionQueryError = ApiError

export const useReadRelHistoryVersion = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadRelHistoryVersionParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadRelHistoryVersionQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadRelHistoryVersionsHook = () => {
    const readRelHistoryVersions = useCmdbSwaggerClient<HistoryVersionsListUiRelationshipUi>()

    return useCallback(
        (uuid: string, params: ReadRelHistoryVersionsParams, signal?: AbortSignal) => {
            return readRelHistoryVersions({ url: `/history/read/rel/${uuid}/list`, method: 'GET', params, signal })
        },
        [readRelHistoryVersions],
    )
}

export const getReadRelHistoryVersionsQueryKey = (uuid: string, params: ReadRelHistoryVersionsParams) => {
    return [`/history/read/rel/${uuid}/list`, ...(params ? [params] : [])] as const
}

export const useReadRelHistoryVersionsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params: ReadRelHistoryVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadRelHistoryVersionsQueryKey(uuid, params)

    const readRelHistoryVersions = useReadRelHistoryVersionsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>> = ({ signal }) =>
        readRelHistoryVersions(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadRelHistoryVersionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>>
export type ReadRelHistoryVersionsQueryError = ApiError

export const useReadRelHistoryVersions = <TData = Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>, TError = ApiError>(
    uuid: string,
    params: ReadRelHistoryVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadRelHistoryVersionsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadRelHistoryVersionsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGdprHistoryHook = () => {
    const gdprHistory = useCmdbSwaggerClient<ConfigurationItemSetUi>()

    return useCallback(
        (params: GdprHistoryParams, signal?: AbortSignal) => {
            return gdprHistory({ url: `/history/read/gdpr/list`, method: 'GET', params, signal })
        },
        [gdprHistory],
    )
}

export const getGdprHistoryQueryKey = (params: GdprHistoryParams) => {
    return [`/history/read/gdpr/list`, ...(params ? [params] : [])] as const
}

export const useGdprHistoryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>, TError = ApiError>(
    params: GdprHistoryParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGdprHistoryQueryKey(params)

    const gdprHistory = useGdprHistoryHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>> = ({ signal }) => gdprHistory(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GdprHistoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>>
export type GdprHistoryQueryError = ApiError

export const useGdprHistory = <TData = Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>, TError = ApiError>(
    params: GdprHistoryParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGdprHistoryHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGdprHistoryQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiHistoryVersionHook = () => {
    const readCiHistoryVersion = useCmdbSwaggerClient<HistoryVersionUiConfigurationItemUi>()

    return useCallback(
        (uuid: string, params?: ReadCiHistoryVersionParams, signal?: AbortSignal) => {
            return readCiHistoryVersion({ url: `/history/read/ci/${uuid}`, method: 'GET', params, signal })
        },
        [readCiHistoryVersion],
    )
}

export const getReadCiHistoryVersionQueryKey = (uuid: string, params?: ReadCiHistoryVersionParams) => {
    return [`/history/read/ci/${uuid}`, ...(params ? [params] : [])] as const
}

export const useReadCiHistoryVersionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadCiHistoryVersionParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiHistoryVersionQueryKey(uuid, params)

    const readCiHistoryVersion = useReadCiHistoryVersionHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>> = ({ signal }) =>
        readCiHistoryVersion(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiHistoryVersionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>>
export type ReadCiHistoryVersionQueryError = ApiError

export const useReadCiHistoryVersion = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>, TError = ApiError>(
    uuid: string,
    params?: ReadCiHistoryVersionParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiHistoryVersionQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiHistoryVersionsIncidentRelsHook = () => {
    const readCiHistoryVersionsIncidentRels = useCmdbSwaggerClient<CiHistoryVersionsIncidentRelationshipsUi>()

    return useCallback(
        (uuid: string, params: ReadCiHistoryVersionsIncidentRelsParams, signal?: AbortSignal) => {
            return readCiHistoryVersionsIncidentRels({ url: `/history/read/ci/${uuid}/rels`, method: 'GET', params, signal })
        },
        [readCiHistoryVersionsIncidentRels],
    )
}

export const getReadCiHistoryVersionsIncidentRelsQueryKey = (uuid: string, params: ReadCiHistoryVersionsIncidentRelsParams) => {
    return [`/history/read/ci/${uuid}/rels`, ...(params ? [params] : [])] as const
}

export const useReadCiHistoryVersionsIncidentRelsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params: ReadCiHistoryVersionsIncidentRelsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiHistoryVersionsIncidentRelsQueryKey(uuid, params)

    const readCiHistoryVersionsIncidentRels = useReadCiHistoryVersionsIncidentRelsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>> = ({ signal }) =>
        readCiHistoryVersionsIncidentRels(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiHistoryVersionsIncidentRelsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>
>
export type ReadCiHistoryVersionsIncidentRelsQueryError = ApiError

export const useReadCiHistoryVersionsIncidentRels = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params: ReadCiHistoryVersionsIncidentRelsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsIncidentRelsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiHistoryVersionsIncidentRelsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiHistoryVersionsHook = () => {
    const readCiHistoryVersions = useClientForRefRegistersHistory<HistoryVersionsListUiConfigurationItemUi>()

    return useCallback(
        (uuid: string, params: ReadCiHistoryVersionsParams, signal?: AbortSignal) => {
            return readCiHistoryVersions({ url: `/history/read/ci/${uuid}/list`, method: 'GET', params, signal })
        },
        [readCiHistoryVersions],
    )
}

export const getReadCiHistoryVersionsQueryKey = (uuid: string, params: ReadCiHistoryVersionsParams) => {
    return [`/history/read/ci/${uuid}/list`, ...(params ? [params] : [])] as const
}

export const useReadCiHistoryVersionsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>, TError = ApiError>(
    uuid: string,
    params: ReadCiHistoryVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiHistoryVersionsQueryKey(uuid, params)

    const readCiHistoryVersions = useReadCiHistoryVersionsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>> = ({ signal }) =>
        readCiHistoryVersions(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiHistoryVersionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>>
export type ReadCiHistoryVersionsQueryError = ApiError

export const useReadCiHistoryVersions = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>, TError = ApiError>(
    uuid: string,
    params: ReadCiHistoryVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiHistoryVersionsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiHistoryModifiedByListHook = () => {
    const readCiHistoryModifiedByList = useCmdbSwaggerClient<string[]>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return readCiHistoryModifiedByList({ url: `/history/read/ci/${uuid}/list/modifiedBy`, method: 'GET', signal })
        },
        [readCiHistoryModifiedByList],
    )
}

export const getReadCiHistoryModifiedByListQueryKey = (uuid: string) => {
    return [`/history/read/ci/${uuid}/list/modifiedBy`] as const
}

export const useReadCiHistoryModifiedByListQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiHistoryModifiedByListQueryKey(uuid)

    const readCiHistoryModifiedByList = useReadCiHistoryModifiedByListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>> = ({ signal }) =>
        readCiHistoryModifiedByList(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiHistoryModifiedByListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>>
export type ReadCiHistoryModifiedByListQueryError = ApiError

export const useReadCiHistoryModifiedByList = <TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryModifiedByListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiHistoryModifiedByListQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadCiHistoryVersionsActionsListHook = () => {
    const readCiHistoryVersionsActionsList = useCmdbSwaggerClient<string[]>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return readCiHistoryVersionsActionsList({ url: `/history/read/ci/${uuid}/list/actions`, method: 'GET', signal })
        },
        [readCiHistoryVersionsActionsList],
    )
}

export const getReadCiHistoryVersionsActionsListQueryKey = (uuid: string) => {
    return [`/history/read/ci/${uuid}/list/actions`] as const
}

export const useReadCiHistoryVersionsActionsListQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadCiHistoryVersionsActionsListQueryKey(uuid)

    const readCiHistoryVersionsActionsList = useReadCiHistoryVersionsActionsListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>> = ({ signal }) =>
        readCiHistoryVersionsActionsList(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadCiHistoryVersionsActionsListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>>
export type ReadCiHistoryVersionsActionsListQueryError = ApiError

export const useReadCiHistoryVersionsActionsList = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadCiHistoryVersionsActionsListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadCiHistoryVersionsActionsListQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadAllCiHistoryVersionsHook = () => {
    const readAllCiHistoryVersions = useCmdbSwaggerClient<HistoryVersionsListUiConfigurationItemUi>()

    return useCallback(
        (params: ReadAllCiHistoryVersionsParams, signal?: AbortSignal) => {
            return readAllCiHistoryVersions({ url: `/history/read/ci/list`, method: 'GET', params, signal })
        },
        [readAllCiHistoryVersions],
    )
}

export const getReadAllCiHistoryVersionsQueryKey = (params: ReadAllCiHistoryVersionsParams) => {
    return [`/history/read/ci/list`, ...(params ? [params] : [])] as const
}

export const useReadAllCiHistoryVersionsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>,
    TError = ApiError,
>(
    params: ReadAllCiHistoryVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadAllCiHistoryVersionsQueryKey(params)

    const readAllCiHistoryVersions = useReadAllCiHistoryVersionsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>> = ({ signal }) =>
        readAllCiHistoryVersions(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadAllCiHistoryVersionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>>
export type ReadAllCiHistoryVersionsQueryError = ApiError

export const useReadAllCiHistoryVersions = <TData = Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>, TError = ApiError>(
    params: ReadAllCiHistoryVersionsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadAllCiHistoryVersionsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadAllCiHistoryVersionsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetGroupListHook = () => {
    const getGroupList = useCmdbSwaggerClient<GroupSetUi>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getGroupList({ url: `/grouplist`, method: 'GET', signal })
        },
        [getGroupList],
    )
}

export const getGetGroupListQueryKey = () => {
    return [`/grouplist`] as const
}

export const useGetGroupListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetGroupListQueryKey()

    const getGroupList = useGetGroupListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>> = ({ signal }) => getGroupList(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetGroupListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>>
export type GetGroupListQueryError = ApiError

export const useGetGroupList = <TData = Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetGroupListQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetConfigurationItemsGroupsHook = () => {
    const getConfigurationItemsGroups = useCmdbSwaggerClient<GroupSetUi>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return getConfigurationItemsGroups({ url: `/grouplist/ci/${uuid}`, method: 'GET', signal })
        },
        [getConfigurationItemsGroups],
    )
}

export const getGetConfigurationItemsGroupsQueryKey = (uuid: string) => {
    return [`/grouplist/ci/${uuid}`] as const
}

export const useGetConfigurationItemsGroupsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetConfigurationItemsGroupsQueryKey(uuid)

    const getConfigurationItemsGroups = useGetConfigurationItemsGroupsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>> = ({ signal }) =>
        getConfigurationItemsGroups(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetConfigurationItemsGroupsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>>
export type GetConfigurationItemsGroupsQueryError = ApiError

export const useGetConfigurationItemsGroups = <TData = Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConfigurationItemsGroupsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetConfigurationItemsGroupsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetUuidHook = () => {
    const getUuid = useCmdbSwaggerClient<string>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getUuid({ url: `/generate/uuid`, method: 'GET', signal })
        },
        [getUuid],
    )
}

export const getGetUuidQueryKey = () => {
    return [`/generate/uuid`] as const
}

export const useGetUuidQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetUuidQueryKey()

    const getUuid = useGetUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>> = ({ signal }) => getUuid(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>>
export type GetUuidQueryError = ApiError

export const useGetUuid = <TData = Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUuidHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetUuidQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReindexReportsHook = () => {
    const reindexReports = useCmdbSwaggerClient<void>()

    return useCallback(() => {
        return reindexReports({ url: `/util/reports`, method: 'DELETE' })
    }, [reindexReports])
}

export const useReindexReportsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexReportsHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexReportsHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindexReports = useReindexReportsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindexReportsHook>>>, void> = () => {
        return reindexReports()
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexReportsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexReportsHook>>>>

export type ReindexReportsMutationError = ApiError

export const useReindexReports = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexReportsHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindexReportsHook>>>, TError, void, TContext> => {
    const mutationOptions = useReindexReportsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteStandardHook = () => {
    const deleteStandard = useCmdbSwaggerClient<void>()

    return useCallback(() => {
        return deleteStandard({ url: `/standard`, method: 'DELETE' })
    }, [deleteStandard])
}

export const useDeleteStandardMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteStandard = useDeleteStandardHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteStandardHook>>>, void> = () => {
        return deleteStandard()
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteStandardMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteStandardHook>>>>

export type DeleteStandardMutationError = ApiError

export const useDeleteStandard = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteStandardHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteStandardHook>>>, TError, void, TContext> => {
    const mutationOptions = useDeleteStandardMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearCacheAllHook = () => {
    const clearCacheAll = useCmdbSwaggerClient<string>()

    return useCallback(() => {
        return clearCacheAll({ url: `/cache`, method: 'DELETE' })
    }, [clearCacheAll])
}

export const useClearCacheAllMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCacheAllHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCacheAllHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearCacheAll = useClearCacheAllHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearCacheAllHook>>>, void> = () => {
        return clearCacheAll()
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearCacheAllMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearCacheAllHook>>>>

export type ClearCacheAllMutationError = ApiError

export const useClearCacheAll = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCacheAllHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearCacheAllHook>>>, TError, void, TContext> => {
    const mutationOptions = useClearCacheAllMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearCacheHook = () => {
    const clearCache = useCmdbSwaggerClient<string>()

    return useCallback(() => {
        return clearCache({ url: `/cache/schema`, method: 'DELETE' })
    }, [clearCache])
}

export const useClearCacheMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCacheHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCacheHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearCache = useClearCacheHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearCacheHook>>>, void> = () => {
        return clearCache()
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearCacheMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearCacheHook>>>>

export type ClearCacheMutationError = ApiError

export const useClearCache = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCacheHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearCacheHook>>>, TError, void, TContext> => {
    const mutationOptions = useClearCacheMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearRelCacheHook = () => {
    const clearRelCache = useCmdbSwaggerClient<string>()

    return useCallback(() => {
        return clearRelCache({ url: `/cache/schema/rel`, method: 'DELETE' })
    }, [clearRelCache])
}

export const useClearRelCacheMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearRelCacheHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearRelCacheHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearRelCache = useClearRelCacheHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearRelCacheHook>>>, void> = () => {
        return clearRelCache()
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearRelCacheMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearRelCacheHook>>>>

export type ClearRelCacheMutationError = ApiError

export const useClearRelCache = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearRelCacheHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearRelCacheHook>>>, TError, void, TContext> => {
    const mutationOptions = useClearRelCacheMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearRelCache1Hook = () => {
    const clearRelCache1 = useCmdbSwaggerClient<string>()

    return useCallback(
        (relType: string) => {
            return clearRelCache1({ url: `/cache/schema/rel/${relType}`, method: 'DELETE' })
        },
        [clearRelCache1],
    )
}

export const useClearRelCache1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearRelCache1Hook>>>, TError, { relType: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearRelCache1Hook>>>, TError, { relType: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearRelCache1 = useClearRelCache1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearRelCache1Hook>>>, { relType: string }> = (props) => {
        const { relType } = props ?? {}

        return clearRelCache1(relType)
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearRelCache1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearRelCache1Hook>>>>

export type ClearRelCache1MutationError = ApiError

export const useClearRelCache1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearRelCache1Hook>>>, TError, { relType: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearRelCache1Hook>>>, TError, { relType: string }, TContext> => {
    const mutationOptions = useClearRelCache1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearEnumCacheHook = () => {
    const clearEnumCache = useCmdbSwaggerClient<string>()

    return useCallback(() => {
        return clearEnumCache({ url: `/cache/schema/enum`, method: 'DELETE' })
    }, [clearEnumCache])
}

export const useClearEnumCacheMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearEnumCacheHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearEnumCacheHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearEnumCache = useClearEnumCacheHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearEnumCacheHook>>>, void> = () => {
        return clearEnumCache()
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearEnumCacheMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearEnumCacheHook>>>>

export type ClearEnumCacheMutationError = ApiError

export const useClearEnumCache = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearEnumCacheHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearEnumCacheHook>>>, TError, void, TContext> => {
    const mutationOptions = useClearEnumCacheMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearEnumCache1Hook = () => {
    const clearEnumCache1 = useCmdbSwaggerClient<string>()

    return useCallback(
        (enumType: string) => {
            return clearEnumCache1({ url: `/cache/schema/enum/${enumType}`, method: 'DELETE' })
        },
        [clearEnumCache1],
    )
}

export const useClearEnumCache1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearEnumCache1Hook>>>, TError, { enumType: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearEnumCache1Hook>>>, TError, { enumType: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearEnumCache1 = useClearEnumCache1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearEnumCache1Hook>>>, { enumType: string }> = (props) => {
        const { enumType } = props ?? {}

        return clearEnumCache1(enumType)
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearEnumCache1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearEnumCache1Hook>>>>

export type ClearEnumCache1MutationError = ApiError

export const useClearEnumCache1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearEnumCache1Hook>>>, TError, { enumType: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearEnumCache1Hook>>>, TError, { enumType: string }, TContext> => {
    const mutationOptions = useClearEnumCache1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearCiCacheHook = () => {
    const clearCiCache = useCmdbSwaggerClient<string>()

    return useCallback(() => {
        return clearCiCache({ url: `/cache/schema/ci`, method: 'DELETE' })
    }, [clearCiCache])
}

export const useClearCiCacheMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCiCacheHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCiCacheHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearCiCache = useClearCiCacheHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearCiCacheHook>>>, void> = () => {
        return clearCiCache()
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearCiCacheMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearCiCacheHook>>>>

export type ClearCiCacheMutationError = ApiError

export const useClearCiCache = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCiCacheHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearCiCacheHook>>>, TError, void, TContext> => {
    const mutationOptions = useClearCiCacheMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearCiCache1Hook = () => {
    const clearCiCache1 = useCmdbSwaggerClient<string>()

    return useCallback(
        (ciType: string) => {
            return clearCiCache1({ url: `/cache/schema/ci/${ciType}`, method: 'DELETE' })
        },
        [clearCiCache1],
    )
}

export const useClearCiCache1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCiCache1Hook>>>, TError, { ciType: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCiCache1Hook>>>, TError, { ciType: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearCiCache1 = useClearCiCache1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearCiCache1Hook>>>, { ciType: string }> = (props) => {
        const { ciType } = props ?? {}

        return clearCiCache1(ciType)
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearCiCache1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearCiCache1Hook>>>>

export type ClearCiCache1MutationError = ApiError

export const useClearCiCache1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCiCache1Hook>>>, TError, { ciType: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useClearCiCache1Hook>>>, TError, { ciType: string }, TContext> => {
    const mutationOptions = useClearCiCache1MutationOptions(options)

    return useMutation(mutationOptions)
}
