import { BreadCrumbs, Button, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { Tab, Tabs } from '@isdd/idsk-ui-kit/tabs/Tabs'
import { useReadConfigurationItem } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import {
    CI_ITEM_QUERY_KEY,
    ENTITY_AS,
    INVALIDATED,
    LIFE_CYCLE_PHASE,
    PROFILE_REF_LIFE_CYCLE_END,
    PROFILE_REF_LIFE_CYCLE_START,
    ciInformationTab,
} from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useUserAbility } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { ATTRIBUTE_NAME, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import {
    getCiHowToBreadCrumb,
    getDefaultCiEntityTabList,
    useCiDetailPageTitle,
    useCiListPageHeading,
    useGetEntityParamsFromUrl,
} from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { RelationsListContainer } from '@/components/containers/RelationsListContainer'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { EndOrApplicationServiceEntityIdHeader } from '@/components/views/ci/end-or-application-service/EndOrApplicationServiceEntityIdHeader'
import { useCiCheckEntityTypeRedirectHook } from '@/hooks/useCiCheckEntityTypeRedirect.hook'

const AsEntityDetailPage: React.FC = () => {
    const { t } = useTranslation()
    const { isActionSuccess } = useActionSuccess()
    const { entityId } = useGetEntityParamsFromUrl()
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState<string>()

    const userAbility = useUserAbility()

    const { data: ciTypeData, isLoading: isCiTypeDataLoading, isError: isCiTypeDataError } = useGetCiTypeWrapper(ENTITY_AS)
    const {
        data: ciItemData,
        isLoading: isCiItemDataLoading,
        isError: isCiItemDataError,
        refetch,
    } = useReadConfigurationItem(entityId ?? '', {
        query: {
            queryKey: [CI_ITEM_QUERY_KEY, entityId],
        },
    })
    useCiCheckEntityTypeRedirectHook(ciItemData, ENTITY_AS)

    const { getHeading } = useCiListPageHeading(ciTypeData?.name ?? '', t)
    const { getHeading: getHeadingDetail } = useCiDetailPageTitle(
        ciTypeData?.name ?? '',
        ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
        t,
    )
    setDocumentTitle(getHeadingDetail())
    const tabList: Tab[] = getDefaultCiEntityTabList({ userAbility, entityName: ENTITY_AS, entityId: entityId ?? '', t })

    const isInvalidated = ciItemData?.metaAttributes?.state === INVALIDATED

    const getSuccessMessage = (type?: string) => {
        if (type === 'create') return t('mutationFeedback.successfulCreated')
        if (type === 'update') return t('mutationFeedback.successfulUpdated')
        if (type === 'clone') return t('mutationFeedback.successfulCloned')
        return ''
    }

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(ENTITY_AS, t),
                    { label: getHeading(), href: `/ci/${ENTITY_AS}` },
                    {
                        label: (ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string) ?? t('breadcrumbs.noName'),
                        href: `/ci/${ENTITY_AS}/${entityId}`,
                    },
                ]}
            />

            <MainContentWrapper>
                {isCiItemDataError ? (
                    <QueryFeedback loading={false} error={isCiItemDataError} />
                ) : (
                    <CiPermissionsWrapper entityId={entityId ?? ''} entityName={ENTITY_AS}>
                        <QueryFeedback loading={isCiItemDataLoading || isCiTypeDataLoading}>
                            <FlexColumnReverseWrapper>
                                <EndOrApplicationServiceEntityIdHeader
                                    editButton={
                                        <Button
                                            label={t('ciType.editButton')}
                                            onClick={() => navigate(`/ci/${ENTITY_AS}/${entityId}/edit`, { state: location.state })}
                                        />
                                    }
                                    clonePath={`/ci/${ENTITY_AS}/${entityId}/clone`}
                                    entityData={ciItemData}
                                    entityId={entityId ?? ''}
                                    entityItemName={(ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string) ?? 'Detail'}
                                    ciRoles={ciTypeData?.roleList ?? []}
                                    isInvalidated={isInvalidated}
                                    refetchCi={refetch}
                                    tooltipLabel={t('ciType.cloneAS')}
                                />
                                <QueryFeedback loading={false} error={isCiItemDataError || isCiTypeDataError} />
                                <MutationFeedback
                                    success={isActionSuccess.value && isActionSuccess.additionalInfo?.type !== 'relationCreated'}
                                    successMessage={getSuccessMessage(isActionSuccess.additionalInfo?.type)}
                                />
                            </FlexColumnReverseWrapper>
                            <Tabs tabList={tabList} onSelect={(selected) => setSelectedTab(selected.id)} />
                            {selectedTab === ciInformationTab && (
                                <RelationsListContainer
                                    entityId={entityId ?? ''}
                                    technicalName={ENTITY_AS}
                                    tabsToShowRelAttributes={[
                                        { entityName: LIFE_CYCLE_PHASE, attributes: [PROFILE_REF_LIFE_CYCLE_START, PROFILE_REF_LIFE_CYCLE_END] },
                                    ]}
                                    entityName={ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}
                                />
                            )}
                        </QueryFeedback>
                    </CiPermissionsWrapper>
                )}
            </MainContentWrapper>
        </>
    )
}

export default AsEntityDetailPage
