import { yupResolver } from '@hookform/resolvers/yup'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { Button, ButtonGroupRow, CheckBox, IOption, Input, SimpleSelect, TextArea, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { DynamicElements } from '@isdd/metais-common/components/DynamicElements/DynamicElements'
import { transformArrayToQueryString } from '@isdd/metais-common/utils/utils'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getDescriptionByAttribute, getNameByAttribute, getRequiredByAttribute } from '@/components/views/codeLists/CodeListDetailUtils'
import { REF_PORTAL_SUBMIT_ID } from '@/components/views/ref-identifiers/RefIdentifierCreateView'
import { UriParameters } from '@/components/views/ref-identifiers/UriParameters'
import {
    RefTemplateUriFormType,
    RefTemplateUriFormTypeEnum,
    refIdentifierCreateTemplateUriSchema,
} from '@/components/views/ref-identifiers/forms/refCreateSchema'
import styles from '@/components/views/ref-identifiers/refIdentifiers.module.scss'

type RefTemplateUriFormPropsType = {
    onSubmit: (data: RefTemplateUriFormType, isSend: boolean) => void
    onCancel: () => void
    clearUriExist: () => void
    isUriExist: boolean
    isDisabled?: boolean
    isUpdate: boolean
    ciItemData?: ConfigurationItemUi
    ciCode?: string
    attributes: Attribute[] | undefined
    ownerOptions: IOption<string>[]
    templateUriOptions: IOption<string>[]
    defaultTemplateUri?: string
}

const hasVersion = (version?: boolean) => (version ? '/{version}' : '')

const getExampleDefaultUri = (version?: boolean) =>
    `https://data.gov.sk/id/legal-subject/{id}${hasVersion(version)}?nazov_query_param1={nazov_query_param1}&nazov_query_param2={nazov_query_param2}`

const attributeList = [
    ATTRIBUTE_NAME.Gen_Profil_nazov,
    ATTRIBUTE_NAME.Gen_Profil_popis,
    ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri,
    ATTRIBUTE_NAME.Profil_Individuum_platne_od,
    ATTRIBUTE_NAME.Profil_Individuum_platne_do,
    ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie,
    ATTRIBUTE_NAME.Profil_Individuum_versions,
    ATTRIBUTE_NAME.Gen_Profil_kod_metais,
    ATTRIBUTE_NAME.Gen_Profil_ref_id,
    ATTRIBUTE_NAME.Profil_Individuum_hasVersion,
    ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget,
    ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern,
    ATTRIBUTE_NAME.Profil_Individuum_parameter_name,
    ATTRIBUTE_NAME.Profil_Individuum_parameter_description,
]

export const RefTemplateUriForm: React.FC<RefTemplateUriFormPropsType> = ({
    onSubmit,
    onCancel,
    clearUriExist,
    isUriExist,
    isUpdate,
    isDisabled,
    ciItemData,
    ciCode,
    attributes,
    ownerOptions,
    templateUriOptions,
    defaultTemplateUri,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const attributesDefaultValues = Object.fromEntries(attributeList.map((item) => [item, ciItemData?.attributes?.[item]]))

    const methods = useForm<RefTemplateUriFormType>({
        defaultValues: {
            [RefTemplateUriFormTypeEnum.OWNER]: ownerOptions?.at(0)?.value,
            [RefTemplateUriFormTypeEnum.TEMPLATE_URI]: defaultTemplateUri,
            attributes: attributesDefaultValues,
        },
        mode: 'onChange',
        resolver: yupResolver(refIdentifierCreateTemplateUriSchema(t, language, attributesDefaultValues, attributes)),
    })
    const [uriPreview, setUriPreview] = useState(getExampleDefaultUri())
    const { register, formState, setValue, watch, setError, handleSubmit, clearErrors, control, trigger, unregister } = methods
    const { errors } = formState

    useEffect(() => {
        if (isUriExist) {
            setError(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri}`, { message: t('refIdentifiers.create.uriAlreadyExist') })
        }
    }, [isUriExist, setError, t])

    useEffect(() => {
        setValue(RefTemplateUriFormTypeEnum.TEMPLATE_URI, defaultTemplateUri ?? '')
    }, [defaultTemplateUri, setValue])

    const buttonRefId = document.getElementById(REF_PORTAL_SUBMIT_ID)

    const currentUri = watch(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri}`)
    const version = watch(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_hasVersion}`)
    const currentParamNames: string[] = watch(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter_name}`) || []
    const currentParamValues: string[] = watch(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter_description}`) || []
    const paramNamesURL = currentParamNames.length > 0 ? transformArrayToQueryString(currentParamNames, currentParamValues) : ''

    const getCiCode = useCallback(() => {
        return ciItemData ? ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_kod_metais] : ciCode
    }, [ciCode, ciItemData])

    useEffect(() => {
        setUriPreview(currentUri ? `${currentUri}{id}${hasVersion(version)}${paramNamesURL}` : getExampleDefaultUri(!!version))
    }, [currentUri, getCiCode, paramNamesURL, version])

    useEffect(() => {
        formState.isSubmitted && trigger()
    }, [formState.isSubmitted, t, trigger])

    return (
        <>
            <form noValidate>
                {!isUpdate && (
                    <SimpleSelect
                        label={t('refIdentifiers.create.ownerUser')}
                        options={ownerOptions ?? []}
                        setValue={setValue}
                        name={'owner'}
                        isClearable={false}
                        clearErrors={clearErrors}
                        defaultValue={ownerOptions?.at(0)?.value}
                        error={errors?.[RefTemplateUriFormTypeEnum.OWNER]?.message}
                    />
                )}
                <Input
                    disabled={isDisabled}
                    required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri))}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri),
                    )}
                    {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri}`)}
                    onBlur={clearUriExist}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri]?.message}
                />
                <Input
                    disabled={isDisabled}
                    required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov))}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov),
                    )}
                    {...register(`attributes.${ATTRIBUTE_NAME.Gen_Profil_nazov}`)}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]?.message}
                />
                <div className="govuk-form-group">
                    <CheckBox
                        disabled={isDisabled}
                        required={getRequiredByAttribute(
                            attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_hasVersion),
                        )}
                        label={getNameByAttribute(
                            language,
                            attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_hasVersion),
                        )}
                        info={getDescriptionByAttribute(
                            language,
                            attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_hasVersion),
                        )}
                        {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_hasVersion}`)}
                        error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_hasVersion]?.message}
                    />
                </div>
                <TextBody>{t('refIdentifiers.create.templateUriExample')}:</TextBody>
                <TextBody className={styles.gray}>{uriPreview}</TextBody>
                <Input
                    disabled={isDisabled}
                    required={getRequiredByAttribute(
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget),
                    )}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget),
                    )}
                    {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget}`)}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget]?.message}
                />
                <Input
                    disabled={isDisabled}
                    required={getRequiredByAttribute(
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern),
                    )}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern),
                    )}
                    {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern}`)}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern]?.message}
                />

                <SimpleSelect
                    key={`${defaultTemplateUri ?? RefTemplateUriFormTypeEnum.TEMPLATE_URI} ${templateUriOptions.length ?? 0}`}
                    required
                    label={t('refIdentifiers.create.templateUriType')}
                    options={templateUriOptions ?? []}
                    setValue={setValue}
                    name={RefTemplateUriFormTypeEnum.TEMPLATE_URI}
                    isClearable={false}
                    clearErrors={clearErrors}
                    defaultValue={defaultTemplateUri}
                    error={errors?.[RefTemplateUriFormTypeEnum.TEMPLATE_URI]?.message}
                />
                <DateInput
                    required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_od))}
                    handleDateChange={(date, name) => date && setValue(name as keyof RefTemplateUriFormType, DateTime.fromJSDate(date).toISO() ?? '')}
                    setValue={setValue}
                    {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_platne_od}`)}
                    control={control}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_od),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_od),
                    )}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_platne_od]?.message}
                />
                <DateInput
                    required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_do))}
                    handleDateChange={(date, name) => date && setValue(name as keyof RefTemplateUriFormType, DateTime.fromJSDate(date).toISO() ?? '')}
                    setValue={setValue}
                    {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_platne_do}`)}
                    control={control}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_do),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_do),
                    )}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_platne_do]?.message}
                />
                <TextArea
                    rows={3}
                    required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis))}
                    label={getNameByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis),
                    )}
                    info={getDescriptionByAttribute(
                        language,
                        attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis),
                    )}
                    {...register(`attributes.${ATTRIBUTE_NAME.Gen_Profil_popis}`)}
                    error={errors?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_popis]?.message}
                />
                <TextHeading size="L">{t('refIdentifiers.create.parameters.title')}</TextHeading>
                <TextHeading size="M">{t('refIdentifiers.create.parameters.subtitle')}</TextHeading>
                <DynamicElements<string[]>
                    renderableComponent={(index) => (
                        <UriParameters index={index} register={register} errors={errors} unregister={unregister} control={control} />
                    )}
                    defaultRenderableComponentData={[]}
                    addItemButtonLabelText={'+ ' + t('refIdentifiers.create.parameters.add')}
                    initialElementsData={
                        (ciItemData?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_parameter_name] as string[])?.map(
                            (item) => item,
                        ) as unknown as string[][]
                    }
                    removeLabelSubject={t('refIdentifiers.create.parameters')}
                />
                {buttonRefId &&
                    createPortal(
                        <ButtonGroupRow>
                            <Button onClick={handleSubmit((data) => onSubmit(data, true))} label={t('refIdentifiers.create.finishRequest')} />
                            <Button onClick={handleSubmit((data) => onSubmit(data, false))} label={t('refIdentifiers.create.saveRequest')} />
                            <Button variant="secondary" onClick={onCancel} label={t('refIdentifiers.create.cancelRequest')} />
                        </ButtonGroupRow>,
                        buttonRefId,
                    )}
            </form>
        </>
    )
}
