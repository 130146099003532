import classNames from 'classnames'
import { useEffect, useId, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useMatch } from 'react-router-dom'

import styles from '@isdd/metais-common/components/GridView.module.scss'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'

export interface NavigationItem {
    title: string
    path: string
    subItems?: NavigationItem[]
    icon?: string
    target?: string
    hideHelp?: boolean
    customPathMatcher?: string
    useExactPathMatch?: boolean
}

export interface SidebarItemProps {
    item: NavigationItem
    onToggle: (toggle?: boolean) => void
    isExpanded: boolean
    isSidebarExpanded: boolean
    defaultOpenedMenuItemsIndexes: number[]
    defaultOpenedMenuItemsPaths: string[]
}

export const SidebarItem = ({
    item,
    isSidebarExpanded,
    onToggle,
    isExpanded,
    defaultOpenedMenuItemsIndexes,
    defaultOpenedMenuItemsPaths,
}: SidebarItemProps) => {
    const { t } = useTranslation()
    const contentId = useId()

    const [expandedSubItemIndexes, setExpandedSubItemIndexes] = useState<boolean[]>(() => Array(item.subItems?.length).fill(false))
    const isISVSDetail = !!useMatch(NavigationSubRoutes.PRACOVNA_SKUPINA_DETAIL_ITVS)
    const isUrlMatched = !!useMatch({ path: item.customPathMatcher ? item.customPathMatcher : item.path, end: isISVSDetail })

    useEffect(() => {
        if (defaultOpenedMenuItemsIndexes.length > 0) {
            setExpandedSubItemIndexes(() => {
                const indexes = Array<boolean>(item.subItems?.length ?? 0).fill(false)
                indexes.splice(defaultOpenedMenuItemsIndexes[0], 1, true)
                return indexes
            })
        }
    }, [defaultOpenedMenuItemsIndexes, item.subItems?.length])

    const showMenu = `${t('navMenu.show')} ${item.title} menu`
    const hideMenu = `${t('navMenu.hide')} ${item.title} menu`
    const hasSubmenu = item.subItems?.length && item.subItems?.length > 0

    const defaultOpenedMenuItemsIndexesSliced = useMemo(() => defaultOpenedMenuItemsIndexes.slice(1), [defaultOpenedMenuItemsIndexes])
    const getSubItemsWithTopLevelItem = (navItem: NavigationItem) => {
        if (navItem.subItems?.length && navItem.subItems?.length > 0) {
            return navItem.subItems
        } else {
            return []
        }
    }

    return (
        <>
            <div className={styles.govukBottomMargin}>
                <div className={classNames(styles.sectionHeader, styles.hover)}>
                    <Link
                        to={hasSubmenu ? '#' : item.path}
                        {...(!hasSubmenu && item.target && { target: item.target })}
                        className={classNames(
                            styles.sidebarlink,
                            styles.sectionHeaderButton,
                            ((hasSubmenu && isExpanded) || isUrlMatched) && styles.expanded,
                        )}
                        aria-current={isUrlMatched ? 'page' : undefined}
                        aria-expanded={item.subItems ? isExpanded : undefined}
                        aria-controls={item.subItems ? contentId : undefined}
                        aria-haspopup={item.subItems ? 'menu' : undefined}
                        aria-label={hasSubmenu ? (isExpanded ? hideMenu : showMenu) : undefined}
                        onClick={(event) => {
                            if (hasSubmenu) {
                                event.preventDefault()
                                onToggle(!isExpanded)
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Escape') {
                                onToggle(false)
                            }
                        }}
                    >
                        {item.title}
                        {item.subItems && (
                            <div
                                className={classNames(
                                    styles.marginLeft0,
                                    styles.arrow,
                                    styles.iconGroupDesktop,
                                    'idsk-header-web__link-arrow',
                                    styles.navListItemArrowOverride,
                                    { [styles.rotate180]: isExpanded },
                                )}
                            />
                        )}
                    </Link>
                </div>
                {item.subItems && isExpanded && isSidebarExpanded && (
                    <div
                        id={contentId}
                        className={classNames(styles.hide, isExpanded && styles.unhide)}
                        aria-label={t('sidebar.groupLabel', { title: item.title })}
                        role="group"
                    >
                        <div className={styles.safeMargin}>
                            {getSubItemsWithTopLevelItem(item).map((subItem, indexSubItem) => {
                                const isExpandedSub = expandedSubItemIndexes[indexSubItem]
                                const onToggleSub = (toggle?: boolean) => {
                                    setExpandedSubItemIndexes(() => {
                                        const indexes = Array<boolean>(item.subItems?.length ?? 0)
                                        indexes.splice(indexSubItem, 1, toggle ?? !isExpanded)
                                        return indexes
                                    })
                                }

                                return (
                                    <SidebarItem
                                        key={`subItem-${indexSubItem}.${subItem.title}`}
                                        item={subItem}
                                        isSidebarExpanded={isSidebarExpanded}
                                        isExpanded={isExpandedSub}
                                        onToggle={onToggleSub}
                                        defaultOpenedMenuItemsIndexes={defaultOpenedMenuItemsIndexesSliced}
                                        defaultOpenedMenuItemsPaths={defaultOpenedMenuItemsPaths}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
