import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { PROJECT_QUALIFIER_TYPE } from '@isdd/metais-common/index'
import { isEmpty } from 'lodash'

export const getDefaultValue = (fallbackValue: string, defaultValue: string | number | boolean | string[] | undefined, isUpdate?: boolean) => {
    if (defaultValue) {
        return defaultValue.toString()
    } else if (!isUpdate) {
        return fallbackValue
    } else {
        return ''
    }
}
export const getDefaultArrayValue = (
    fallbackValue: string,
    defaultValue: string | number | boolean | string[] | undefined,
    isUpdate?: boolean,
    isRequired?: boolean,
) => {
    if (Array.isArray(defaultValue)) {
        return defaultValue
    } else if (Array.isArray(fallbackValue) && !isUpdate) {
        return fallbackValue
    } else {
        return isRequired ? [''] : []
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onKeyDownOfNumberInput = (e: any) => {
    if (e.key == '-' && isEmpty(e.target.value)) {
        return
    }

    // Check if 'Ctrl+key(a,v,c,x)' is pressed
    if ((e.ctrlKey || e.metaKey) && (e.key === 'a' || e.key === 'v' || e.key === 'c' || e.key === 'x')) {
        return
    }

    // Allow Backspace, Tab, Delete, arrow keys, digits and dot
    if (!e.key.match(/^(\d|\.|Backspace|Delete|Arrow(Up|Down|Left|Right)|Tab)$/)) {
        e.preventDefault() // Prevent other characters that are not allowed
    }
}

export const createOptions = (constraintItem: EnumType, isUpdate: boolean) => {
    const options = isUpdate
        ? constraintItem?.enumItems?.map((item) => ({
              value: item.code ?? '',
              label: `${item.value}${item.description && item.description !== item.value ? ' - ' + item.description : ''}`,
              disabled: !item.valid,
          })) ?? []
        : constraintItem?.enumItems
              ?.filter((item) => item.valid)
              .map((item) => ({
                  value: item.code ?? '',
                  label: `${item.value}${item.description && item.description !== item.value ? ' - ' + item.description : ''}`,
                  disabled: !item.valid,
              })) ?? []
    return options
}

export const createProjectOptions = (constraintItem: EnumType, isUpdate: boolean, isAgile: boolean) => {
    const options = isUpdate
        ? constraintItem?.enumItems
              ?.filter((e) => (isAgile ? e.qualifierKey === PROJECT_QUALIFIER_TYPE.AGILE : e.qualifierKey === PROJECT_QUALIFIER_TYPE.WATERFALL))
              ?.map((item) => ({
                  value: item.code ?? '',
                  label: `${item.value}${item.description && item.description !== item.value ? ' - ' + item.description : ''}`,
                  disabled: !item.valid,
              })) ?? []
        : constraintItem?.enumItems
              ?.filter(
                  (item) =>
                      item.valid &&
                      (isAgile ? item.qualifierKey === PROJECT_QUALIFIER_TYPE.AGILE : item.qualifierKey === PROJECT_QUALIFIER_TYPE.WATERFALL),
              )
              .map((item) => ({
                  value: item.code ?? '',
                  label: `${item.value}${item.description && item.description !== item.value ? ' - ' + item.description : ''}`,
                  disabled: !item.valid,
              })) ?? []
    return options
}

export const getDefaultValueForSimple = (constraintItem: EnumType, value: string) => {
    const defaultValue = constraintItem.enumItems?.find((item) => item.code === value)?.code

    return defaultValue
}

export const getDefaultValueForSimpleOfProject = (constraintItem: EnumType, value: string, isAgile: boolean) => {
    const defaultValue = constraintItem.enumItems
        ?.filter((e) => (isAgile ? e.qualifierKey === PROJECT_QUALIFIER_TYPE.AGILE : e.qualifierKey === PROJECT_QUALIFIER_TYPE.WATERFALL))
        ?.find((item) => item.code === value)?.code

    return defaultValue
}
