import { BreadCrumbs, Button, ButtonGroupRow, ErrorBlock, HomeIcon, IOption, Input, SimpleSelect, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Identity, useFindRelatedOrganizationsHook } from '@isdd/metais-common/api/generated/iam-swagger'
import { NavigationSubRoutes, RouteNames } from '@isdd/metais-common/navigation/routeNames'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { RichTextQuill } from '@isdd/metais-common/components/rich-text-quill/RichTextQuill'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { KSIVS_SHORT_NAME } from '@isdd/metais-common/constants'
import { sanitizedHtml } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'

import { GroupFormEnum, createGroupSchema, editGroupSchema } from './groupSchema'

import { IdentitySelect } from '@/components/identity-lazy-select/IdentitySelect'
import { IGroupEditViewParams } from '@/components/containers/standardization/groups/GroupEditContainer'
import { MainContentWrapper } from '@/components/MainContentWrapper'

export const GroupCreateEditView: React.FC<IGroupEditViewParams> = ({
    onSubmit,
    goBack,
    infoData,
    isEdit,
    resultApiCall,
    isLoading,
    uniqueConstraintError,
    resetResultSuccessApiCall,
}) => {
    const { t } = useTranslation()

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        clearErrors,
        reset,
        control,
        formState: { errors, isValid, isSubmitted },
        trigger,
    } = useForm({
        resolver: yupResolver(isEdit ? editGroupSchema(t) : createGroupSchema(t)),
        defaultValues: {
            name: infoData?.name ?? '',
            shortName: infoData?.shortName ?? '',
            description: sanitizedHtml(infoData?.description || ''),
            organization: '',
            user: '',
        },
    })
    useEffect(() => {
        reset({
            name: infoData?.name ?? '',
            shortName: infoData?.shortName ?? '',
            description: infoData?.description || '',
            organization: '',
            user: '',
        })
    }, [infoData?.description, infoData?.name, infoData?.shortName, reset])

    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    const [seed, setSeed] = useState(0)

    const orgOptionsHook = useFindRelatedOrganizationsHook()
    useEffect(() => {
        isSubmitted && trigger()
    }, [isSubmitted, t, trigger])
    const [selectedIdentity, setSelectedIdentity] = useState<string | undefined>(undefined)
    const [organizationOptions, setOrganizationOptions] = useState<IOption<string>[] | undefined>(undefined)

    const watchUser = watch([GroupFormEnum.USER])

    const loadOrgs = useCallback(async (selectedIdentityUuid: string) => {
        await orgOptionsHook(selectedIdentityUuid).then((res) => {
            setOrganizationOptions(
                res.map((org) => {
                    const option: IOption<string> = { value: org.cmdbId ?? '', label: org.attributes?.Gen_Profil_nazov ?? '' }
                    return option
                }),
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (resultApiCall?.message) {
            scrollToMutationFeedback()
        }
    }, [resultApiCall?.message, scrollToMutationFeedback])

    useEffect(() => {
        selectedIdentity && loadOrgs(selectedIdentity)
    }, [loadOrgs, selectedIdentity])

    useEffect(() => {
        if (watchUser[0]) {
            setSelectedIdentity(watchUser[0])
        }
    }, [watchUser])

    setDocumentTitle(`${t('titles.groupEdit')} ${infoData?.name ?? ''}`)

    return (
        <>
            {!isEdit && (
                <BreadCrumbs
                    withWidthContainer
                    links={[
                        { href: RouteNames.HOME, label: t('notifications.home'), icon: HomeIcon },
                        { href: RouteNames.HOW_TO_STANDARDIZATION, label: t('navMenu.standardization') },
                        { href: NavigationSubRoutes.PRACOVNE_SKUPINY_KOMISIE, label: t('navMenu.lists.groups') },
                        { href: NavigationSubRoutes.PRACOVNE_SKUPINY_KOMISIE, label: t('groups.groupList') },
                        {
                            href: NavigationSubRoutes.PRACOVNA_SKUPINA_CREATE,
                            label: t('groups.addNewGroup'),
                        },
                    ]}
                />
            )}
            {isEdit && (
                <>
                    <BreadCrumbs
                        withWidthContainer
                        links={[
                            { href: RouteNames.HOME, label: t('notifications.home'), icon: HomeIcon },
                            { href: RouteNames.HOW_TO_STANDARDIZATION, label: t('navMenu.standardization') },
                            { href: NavigationSubRoutes.PRACOVNE_SKUPINY_KOMISIE, label: t('navMenu.lists.groups') },
                            { href: `${NavigationSubRoutes.PRACOVNA_SKUPINA_DETAIL}/${infoData?.uuid}`, label: infoData?.name ?? '' },
                            {
                                href: `${NavigationSubRoutes.PRACOVNA_SKUPINA_EDIT}/${infoData?.uuid}/edit`,
                                label: t('groups.editGroup'),
                            },
                        ]}
                    />
                </>
            )}
            <MainContentWrapper>
                <FlexColumnReverseWrapper>
                    <TextHeading size="XL">{isEdit ? `${t('groups.editGroup')} - ${infoData?.name}` : t('groups.addNewGroup')}</TextHeading>
                    <div ref={wrapperRef}>
                        <MutationFeedback
                            error={resultApiCall?.isError}
                            errorMessage={resultApiCall?.message}
                            success={resultApiCall?.isSuccess}
                            onMessageClose={resetResultSuccessApiCall}
                        />
                    </div>
                </FlexColumnReverseWrapper>
                <QueryFeedback
                    loading={isLoading}
                    indicatorProps={{ label: isEdit ? t('groups.editingGroup') : t('groups.creatingGroup') }}
                    withChildren
                >
                    {isSubmitted && !isValid && <ErrorBlock errorTitle={t('formErrors')} hidden />}

                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Input
                            label={`${t('groups.groupName')} (${t('groups.mandatory')}):`}
                            id={GroupFormEnum.NAME}
                            {...register(GroupFormEnum.NAME, { value: infoData?.name })}
                            error={errors[GroupFormEnum.NAME]?.message || uniqueConstraintError?.[GroupFormEnum.NAME]}
                        />
                        <Input
                            label={`${t('groups.shortGroupName')} (${t('groups.mandatory')}):`}
                            id={GroupFormEnum.SHORT_NAME}
                            {...register(GroupFormEnum.SHORT_NAME, { value: infoData?.shortName })}
                            error={errors[GroupFormEnum.SHORT_NAME]?.message || uniqueConstraintError?.[GroupFormEnum.SHORT_NAME]}
                            disabled={infoData?.shortName === KSIVS_SHORT_NAME}
                        />
                        <Controller
                            name={GroupFormEnum.DESCRIPTION}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <RichTextQuill
                                        id={GroupFormEnum.DESCRIPTION}
                                        label={`${t('groups.description')} (${t('groups.mandatory')}):`}
                                        defaultValue={sanitizedHtml(infoData?.description ?? '')}
                                        error={errors[GroupFormEnum.DESCRIPTION]?.message}
                                        {...field}
                                    />
                                )
                            }}
                        />

                        {!isEdit && (
                            <>
                                <IdentitySelect
                                    label={`${t('groups.master')} (${t('groups.mandatory')})`}
                                    name={GroupFormEnum.USER}
                                    clearErrors={clearErrors}
                                    error={errors[GroupFormEnum.USER]?.message}
                                    onChange={(val) => {
                                        setValue(GroupFormEnum.USER, (val as Identity)?.uuid)
                                        setValue(GroupFormEnum.ORGANIZATION, undefined)
                                        setSeed(Math.random())
                                    }}
                                />
                                <SimpleSelect
                                    key={seed}
                                    label={`${t('groups.organization')} (${t('groups.mandatory')}):`}
                                    id={GroupFormEnum.ORGANIZATION}
                                    name={GroupFormEnum.ORGANIZATION}
                                    options={organizationOptions ?? []}
                                    error={errors[GroupFormEnum.ORGANIZATION]?.message}
                                    onChange={(val) => {
                                        setValue(GroupFormEnum.ORGANIZATION, val)
                                    }}
                                />
                            </>
                        )}
                        <ButtonGroupRow>
                            <Button label={t('form.cancel')} type="reset" variant="secondary" onClick={goBack} />
                            <Button label={t('form.submit')} type="submit" />
                        </ButtonGroupRow>
                    </form>
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}
