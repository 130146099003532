import { ATTRIBUTE_NAME, RELATION_TYPE, RefIdentifierTypeEnum } from '@isdd/metais-common/api'
import { ConfigurationItemUi, useReadCiList1, useReadCiNeighbours, useReadRelationships } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType, useGetValidEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { STAV_REGISTRACIE, TYP_DATOVEHO_PRVKU } from '@isdd/metais-common/constants'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import React, { useMemo } from 'react'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useTranslation } from 'react-i18next'
import { Languages } from '@isdd/metais-common/localization/languages'

import { PageType } from './RefIdentifiersContainer'

import { RefIdentifierDetailView } from '@/components/views/ref-identifiers/RefIdentifierDetailView'

export interface RefIdentifierDetailContainerViewProps {
    entityItemName: string
    ciItemData: ConfigurationItemUi | undefined
    attributes: Attribute[] | undefined
    registrationState: EnumType | undefined
    dataItemTypeState?: EnumType
    gestorName: string | undefined
    ciList: ConfigurationItemUi[] | undefined
    ciItemId: string
    canEdit?: boolean
    isLoading: boolean
    isError: boolean
    pageType: PageType
}

export interface RefIdentifierDetailInfoViewProps {
    ciList?: ConfigurationItemUi[]
    ciItemData: ConfigurationItemUi | undefined
    attributes: Attribute[] | undefined
    registrationState: EnumType | undefined
    dataItemTypeState?: EnumType
    gestorName?: string
}

interface RefIdentifierDetailContainerProps {
    id: string
    pageType: PageType
}

export const RefIdentifierDetailContainer: React.FC<RefIdentifierDetailContainerProps> = ({ id, pageType }) => {
    const {
        state: { user },
    } = useAuth()

    const { i18n } = useTranslation()

    const { data: registrationState, isLoading: isRegistrationStateLoading, isError: isRegistrationStateError } = useGetValidEnum(STAV_REGISTRACIE)
    const {
        data: dataItemTypeState,
        isLoading: isDataItemTypeStatesLoading,
        isError: isDataItemTypeStatesError,
    } = useGetValidEnum(TYP_DATOVEHO_PRVKU)

    const { ciItemData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(id)
    const { attributes, attributeProfiles, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(ciItemData?.type)

    const {
        data: relationData,
        isFetching: isRelationFetching,
        isError: isRelationError,
    } = useReadRelationships(id, undefined, {
        query: {
            enabled:
                ciItemData?.type === RefIdentifierTypeEnum.DatovyPrvok ||
                ciItemData?.type === RefIdentifierTypeEnum.Ontologia ||
                ciItemData?.type === RefIdentifierTypeEnum.Individuum,
        },
    })

    const {
        isFetching: isPoListFetching,
        isError: isPoListError,
        data: poList,
    } = useReadCiNeighbours(
        id,
        {
            page: 1,
            perpage: 10000,
            neighboursFilter: {
                relType: [
                    RELATION_TYPE.PO_je_gestor_URIKatalog,
                    RELATION_TYPE.PO_je_gestor_DatovyPrvok,
                    RELATION_TYPE.PO_je_gestor_Ontologia,
                    RELATION_TYPE.Individuum_je_typu_DatovyPrvok,
                ],
                metaAttributes: {
                    state: ['DRAFT'],
                },
            },
        },
        { query: { cacheTime: 0 } },
    )

    const gestorName = poList?.toNodes?.neighbourPairs?.at(0)?.configurationItem?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string

    const datasetUuids =
        relationData?.endRelationshipSet
            ?.filter((item) => item.type === RELATION_TYPE.URIDataset_patri_URIKatalog)
            .map((item) => item.startUuid ?? '') ?? []
    const dataItemUuids =
        relationData?.startRelationshipSet
            ?.filter((item) => item.type === RELATION_TYPE.DatovyPrvok_sa_sklada_DatovyPrvok && item.metaAttributes?.state !== 'INVALIDATED')
            .map((item) => item.endUuid ?? '') ?? []
    const ontologyVersionUuids =
        relationData?.startRelationshipSet?.filter((item) => item.type === RELATION_TYPE.Ontologia_ma_verziu).map((item) => item.endUuid ?? '') ?? []
    const individuumTypeUuids =
        relationData?.startRelationshipSet
            ?.filter((item) => item.type === RELATION_TYPE.Individuum_je_typu_DatovyPrvok)
            .map((item) => item.endUuid ?? '') ?? []

    const {
        data: ciList,
        isFetching: isCiListFetching,
        isError: isCiListError,
    } = useReadCiList1(
        {
            filter: {
                uuid: [...datasetUuids, ...dataItemUuids, ...ontologyVersionUuids, ...individuumTypeUuids],
            },
        },
        {
            query: {
                enabled: datasetUuids.length > 0 || dataItemUuids.length > 0 || ontologyVersionUuids.length > 0 || individuumTypeUuids.length > 0,
            },
        },
    )

    const attributesArrays = attributeProfiles?.map((item) => item.attributes || []) || []

    const attributeList = attributes?.concat(...attributesArrays)

    const canEdit = useMemo(() => {
        if (
            ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie] !== 'c_stav_registracie.4' &&
            ciItemData?.metaAttributes?.state !== 'INVALIDATED'
        ) {
            return user?.groupData.some((group) =>
                group.roles.some(
                    (role) =>
                        (role.roleName === 'REFID_URI_DEF' &&
                            ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie] !== 'c_stav_registracie.1') ||
                        role.gid === ciItemData?.metaAttributes?.owner,
                ),
            )
        }
        return false
    }, [ciItemData?.attributes, ciItemData?.metaAttributes?.owner, ciItemData?.metaAttributes?.state, user?.groupData])

    const isLoading = [
        isRegistrationStateLoading,
        isDataItemTypeStatesLoading,
        isCiItemLoading,
        isAttLoading,
        isPoListFetching,
        isRelationFetching,
        isCiListFetching,
    ].some((item) => item)

    const isError = [
        isRegistrationStateError,
        isDataItemTypeStatesError,
        isCiItemError,
        isAttError,
        isPoListError,
        isRelationError,
        isCiListError,
    ].some((item) => item)

    return (
        <>
            <RefIdentifierDetailView
                ciItemId={id}
                entityItemName={
                    i18n.language === Languages.ENGLISH && ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]
                        ? (ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov] as string)
                        : (ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string)
                }
                ciItemData={ciItemData}
                ciList={ciList?.configurationItemSet}
                dataItemTypeState={dataItemTypeState}
                registrationState={registrationState}
                gestorName={gestorName}
                attributes={attributeList}
                canEdit={canEdit}
                isLoading={isLoading}
                isError={isError}
                pageType={pageType}
            />
        </>
    )
}
